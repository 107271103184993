import { setBasicInfoAddrDetail } from 'modules/user';
import React from 'react';
import { useDispatch } from 'react-redux';
import { onClickSearchAddr } from 'utils/addr';

import useAddress from './useAddress';

const RoadInputAddress = () => {
  const dispatch = useDispatch();
  const { offlineShopAddress, storeDetailAddress } = useAddress();

  return (
    <>
      <div className="c-input wide">
        <button
          type="button"
          onClick={onClickSearchAddr}
          className="btn-style__4 btn-size__4"
        >
          주소검색
        </button>
        <div className="input-text">
          <input
            type="text"
            className="inp"
            placeholder=""
            title="주소"
            value={offlineShopAddress}
            readOnly
          />
        </div>
      </div>
      <div className="c-input address_add">
        <div className="input-text">
          <input
            type="text"
            className="inp"
            title="상세 주소"
            placeholder="상세 주소를 입력해주세요."
            value={storeDetailAddress}
            onChange={e => dispatch(setBasicInfoAddrDetail(e.target.value))}
          />
        </div>
      </div>
    </>
  );
};

export default RoadInputAddress;
