import { customCategoryListMapper } from 'components/pages/category/CategoryList';
import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { CATEGORY_DEFAULT, ICategoryState, TYPE_CODE } from './types';

export enum SELECTED_CUSTOM_ID {
  INIT = -1,
  CREATE = -2,
}
export const initialState: ICategoryState = {
  isUsingCustom: false,
  isModeAutoSwitched: false,
  basicLarge: [],
  basicMiddle: [],
  basicSmall: [],
  selectedCustomId: SELECTED_CUSTOM_ID.CREATE,
  custom: [],
  customItemTemp: {
    storeCategoryNo: SELECTED_CUSTOM_ID.INIT,
    storeCategoryName: '',
    typeCode: TYPE_CODE.PRODUCT,
    orderSeq: CATEGORY_DEFAULT.ORDER,
  },
  shouldCategorySave: false,
  customOrder: [],
  currentSelectedCustomInModal: [],
  customDetail: [],
  customDetailFetched: [],
  customDetailModified: new Map(),
  customDetailModifiedInModal: new Map(),
  shouldStoreApply: false,
  isShowDoneToast: false,
  confirmCategoryDelete: false,
};

export default createReducer<ICategoryState, ActionType<typeof actions>>(
  initialState,
  {
    '@CATEGORY/IS_USING_CUSTOM': (state, { payload }) => ({
      ...state,
      isUsingCustom: payload,
    }),
    '@CATEGORY/IS/MODE/AUTO/SWITCHED': (state, { payload }) => ({
      ...state,
      isModeAutoSwitched: payload,
    }),
    '@CATEGORY/BASIC/LARGE/SUCCESS': (state, { payload }) => ({
      ...state,
      basicLarge: payload,
    }),
    '@CATEGORY/BASIC/MIDDLE/SUCCESS': (state, { payload }) => ({
      ...state,
      basicMiddle: payload,
    }),
    '@CATEGORY/BASIC/SMALL/SUCCESS': (state, { payload }) => ({
      ...state,
      basicSmall: payload,
    }),
    '@CATEGORY/CUSTOM/SELECTED/ID': (state, { payload }) => ({
      ...state,
      selectedCustomId: payload,
    }),
    '@CATEGORY/CUSTOM/SELECTED/ID/RESET': state => ({
      ...state,
      selectedCustomId: SELECTED_CUSTOM_ID.INIT,
    }),
    '@CATEGORY/CUSTOM/SUCCESS': (state, { payload }) => ({
      ...state,
      custom: payload,
      customOrder: payload.map(customCategoryListMapper),
    }),
    '@CATEGORY/CUSTOM/ITEM/TEMP': (state, { payload }) => ({
      ...state,
      customItemTemp: { ...payload },
    }),
    '@CATEGORY/CUSTOM/SHOULD/SAVE': (state, { payload }) => ({
      ...state,
      shouldCategorySave: payload,
    }),
    '@CATEGORY/CUSTOM/ORDER': (state, { payload }) => ({
      ...state,
      customOrder: payload,
    }),
    '@CATEGORY/CUSTOM/POST/SUCCESS': (
      state,
      { payload: [{ storeCategoryNo }] },
    ) => ({
      ...state,
      selectedCustomId: storeCategoryNo,
    }),
    '@CATEGORY/CUSTOM/SELECTED': (state, { payload }) => ({
      ...state,
      currentSelectedCustomInModal: payload,
    }),
    '@CATEGORY/CUSTOM/DETAIL': (state, { payload }) => ({
      ...state,
      customDetail: payload,
    }),
    '@CATEGORY/CUSTOM/DETAIL/SUCCESS': (state, { payload }) => ({
      ...state,
      currentSelectedCustomInModal: payload,
      customDetailFetched: payload,
      customDetail: payload,
    }),
    '@CATEGORY/CUSTOM/DETAIL/RESET': state => ({
      ...state,
      customDetail: [],
    }),
    '@CATEGORY/CUSTOM/DETAIL/MODIFIED/RESET': state => ({
      ...state,
      customDetailModified: new Map(),
    }),
    '@CATEGORY/CUSTOM/DETAIL/MODIFIED/SET': (state, { payload }) => ({
      ...state,
      customDetailModified: payload,
    }),
    '@CATEGORY/CUSTOM/DETAIL/MODIFIED/ADD': (state, { payload }) => {
      const { customDetailModified } = state;
      const map = new Map(customDetailModified);
      payload.forEach(({ keyValue, ...prop }) => {
        map.set(keyValue, prop);
      });

      return {
        ...state,
        customDetailModified: map,
      };
    },
    '@CATEGORY/CUSTOM/DETAIL/MODIFIED/MODAL': (state, { payload }) => ({
      ...state,
      customDetailModifiedInModal: payload,
    }),
    '@CATEGORY/SHOULD/STORE/APPLY': (state, { payload }) => ({
      ...state,
      shouldStoreApply: payload,
    }),
    '@CATEGORY/STORE/APPLY/CLICKED': (state, { payload }) => ({
      ...state,
      isShowDoneToast: payload,
    }),
    '@CATEGORY/CONFIRM/DELETE': (state, { payload }) => ({
      ...state,
      confirmCategoryDelete: payload,
    }),
  },
);
