import Pagination from 'components/common/Pagination';
import CategoryList from 'components/pages/category/CategoryList';
import useBasicCategory from 'hooks/useBasicCategory';
import usePagination from 'hooks/usePagination';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  getBasicLargeCategoryAsync,
  setCustomDetailModifiedInModal,
  setSelectedCustomCategory,
} from 'modules/category';
import { CATEGORY_DEFAULT, CategoryItemIdType } from 'modules/category/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { distinctArray } from 'utils';

interface ISelectedCategoryItem {
  categoryName: string;
  onClickDelete: () => void;
}

const DialogSubTitle = ({ children }: { children: React.ReactNode }) => (
  <div className="dialog-body__title title-type__em">
    <h4 className="title">{children}</h4>
  </div>
);

const CategoryDescription = () => (
  <p className="text_em">
    <span className="icon icon-mark" /> 표시는 한번도 매핑이 되지 않은 카테고리
    입니다. 매핑이 되지 않은 카테고리는 스토어에 노출되지 않습니다.
  </p>
);

const AddCategoryButton = ({ onClick }: { onClick: () => void }) => (
  <div className="area-cont__btn">
    <button
      type="submit"
      onClick={onClick}
      className="btn-style__1 btn-size__4"
    >
      추가
    </button>
  </div>
);

const SelectedCategoryItem = ({
  categoryName,
  onClickDelete,
}: ISelectedCategoryItem) => (
  <li className="item_cate">
    <span className="cate">{categoryName}</span>
    <button
      type="button"
      onClick={onClickDelete}
      className="btn-style__icon btn-icon__delete2"
    >
      삭제
    </button>
  </li>
);

const PAGE_SIZE = 5;

const SelectCategory = () => {
  const dispatch = useDispatch();
  const basicMiddle = useSelector(
    (state: RootState) => state.category.basicMiddle,
  );
  const selectedAllCategories = useSelector(
    (state: RootState) => state.category.currentSelectedCustomInModal,
  );
  const customDetail = useSelector(
    (state: RootState) => state.category.customDetail,
  );
  const customDetailModifiedInModal = useSelector(
    (state: RootState) => state.category.customDetailModifiedInModal,
  );
  const { storeNo, encSellerNo } = useSelector(
    (state: RootState) => state.user.headerInfo,
    shallowEqual,
  );

  const [
    basicLargeCategories,
    basicMiddleCategories,
    onClickLargeCategoryItem,
    selectedLargeCategoryId,
  ] = useBasicCategory();

  const [selectedMiddleCategoryId, setSelectedMiddleCategoryId] = useState<
    CategoryItemIdType
  >(-1);

  const [currentPage, selectedCategories, onChangePage] = usePagination(
    selectedAllCategories,
    PAGE_SIZE,
  );

  const isSelected = useMemo(() => selectedMiddleCategoryId !== -1, [
    selectedMiddleCategoryId,
  ]);
  const selectedAllCategoriesLength = useMemo(
    () => selectedAllCategories.length,
    [selectedAllCategories],
  );

  const onClickMiddleCategory = useCallback(
    (id: CategoryItemIdType) => setSelectedMiddleCategoryId(id),
    [],
  );

  const onClickAddCategory = useCallback(() => {
    if (isSelected) {
      if (selectedAllCategoriesLength >= 50) {
        dispatch(openAlert('REGISTER_OVER_50_CATEGORIES'));

        return;
      }

      if (
        customDetail.findIndex(
          ({ categoryNo }) => categoryNo === selectedMiddleCategoryId,
        ) !== -1
      ) {
        return;
      }

      dispatch(
        setCustomDetailModifiedInModal(
          new Map(customDetailModifiedInModal).set(selectedMiddleCategoryId, {
            displayPriority: CATEGORY_DEFAULT.ORDER,
            typeCode: 'SAVE',
          }),
        ),
      );
      dispatch(
        setSelectedCustomCategory(
          distinctArray(
            [
              ...basicMiddle
                .filter(
                  ({ categoryNo }) => categoryNo === selectedMiddleCategoryId,
                )
                .map(item => ({
                  ...item,
                  orderSeq: CATEGORY_DEFAULT.ORDER,
                })),
              ...selectedAllCategories,
            ],
            'categoryNo',
          ),
        ),
      );
      setSelectedMiddleCategoryId(-1);
    }
  }, [
    basicMiddle,
    customDetail,
    customDetailModifiedInModal,
    dispatch,
    isSelected,
    selectedAllCategories,
    selectedAllCategoriesLength,
    selectedMiddleCategoryId,
  ]);

  const onClickDeleteCategory = useCallback(
    (id: CategoryItemIdType) => {
      if (customDetailModifiedInModal.has(id)) {
        const map = new Map(customDetailModifiedInModal);
        map.delete(id);
        dispatch(setCustomDetailModifiedInModal(map));
      } else {
        dispatch(
          setCustomDetailModifiedInModal(
            new Map(customDetailModifiedInModal).set(id, {
              displayPriority: 0,
              typeCode: 'DELETE',
            }),
          ),
        );
      }

      dispatch(
        setSelectedCustomCategory(
          selectedAllCategories.filter(({ categoryNo }) => categoryNo !== id),
        ),
      );
    },
    [customDetailModifiedInModal, dispatch, selectedAllCategories],
  );

  useEffect(() => {
    onChangePage(1);
  }, [onChangePage]);

  useEffect(() => {
    dispatch(getBasicLargeCategoryAsync.request({ encSellerNo, storeNo }));
  }, [dispatch, encSellerNo, storeNo]);

  return (
    <div className="dialog-body body-style__scroll">
      <DialogSubTitle>판매중인 상품이 등록된 카테고리</DialogSubTitle>
      <div className="area-cont__cate">
        <CategoryList
          list={basicLargeCategories}
          onClickItem={onClickLargeCategoryItem}
          selectedId={selectedLargeCategoryId}
          rightArrow
          firstSelected
        >
          <CategoryList
            list={basicMiddleCategories}
            onClickItem={onClickMiddleCategory}
            selectedId={selectedMiddleCategoryId}
            depth="2"
            showMark
          />
        </CategoryList>
      </div>
      <CategoryDescription />
      <AddCategoryButton onClick={onClickAddCategory} />
      <DialogSubTitle>
        선택한 카테고리 <i>( {selectedAllCategoriesLength}개 )</i>
      </DialogSubTitle>
      <ul className="area-cont__list">
        {selectedCategories.map(
          ({ parentCategoryName, categoryName, categoryNo }) => (
            <SelectedCategoryItem
              key={categoryNo}
              categoryName={`${parentCategoryName} > ${categoryName}`}
              onClickDelete={() => onClickDeleteCategory(categoryNo)}
            />
          ),
        )}
      </ul>
      <Pagination
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        total={selectedAllCategoriesLength}
        onChange={onChangePage}
      />
    </div>
  );
};

export default SelectCategory;
