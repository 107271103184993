import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

export interface IOption {
  id: string;
  value: string;
  name: string;
}

export type DropdownType = 'size__2' | 'w-100' | 'w-200';

export interface DropdownProps {
  id: string;
  options: IOption[];
  placeholder: string;
  disabled?: boolean;
  initialValue?: string;
  width?: DropdownType;
  onOpen?: () => void;
  onSelect?: (value: string) => void;
}

/**
 * 드랍다운 컴포넌트입니다.
 * options을 async하게 가져와야 할 경우 onOpen에 주입해주세요.
 */
const Dropdown = ({
  id,
  options,
  disabled,
  width,
  placeholder,
  onOpen,
  onSelect,
  initialValue,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const dropdownListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (open && dropdownListRef.current) {
      dropdownListRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (initialValue) {
      setSelectedName(
        options.find(item => item.value === initialValue)?.name ?? '',
      );
    }
  }, [initialValue, options]);

  return (
    <div
      className={classNames('c-dropdown', width, {
        filled: selectedName !== '',
        open,
      })}
    >
      <div className="dropdown">
        <button
          type="button"
          className="btn"
          id={id}
          aria-haspopup="listbox"
          aria-labelledby={id}
          disabled={disabled}
          onClick={() => {
            if (onOpen) {
              onOpen();
            }
            setOpen(true);
          }}
        >
          <span className="text">{selectedName || placeholder}</span>
        </button>
        <ul
          className={classNames('c-dropdown__list', { open })}
          id="dropdownList"
          tabIndex={0}
          role="listbox"
          aria-labelledby={id}
          aria-activedescendant={`option_${setSelectedName}`}
          onBlur={() => setOpen(false)}
          ref={dropdownListRef}
        >
          {options.map(option => (
            <li
              id={`option_${option.name}`}
              key={option.id}
              role="option"
              aria-selected={selectedName === option.value}
              onClick={() => {
                if (onSelect) {
                  onSelect(option.value);
                }
                setSelectedName(option.name);
                setOpen(false);
              }}
              onKeyDown={() => {}}
            >
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
