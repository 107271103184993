/* eslint-disable no-param-reassign */
import { postImageUpload } from 'apis/display';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { setStoreImageTemp, setStoreLocationImage } from 'modules/user';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isValidExtension,
  isValidImageFileSize,
  validateImageSize,
} from 'utils/validate';

type AreaDivisionType = 'PROFILE' | 'OFFLINE' | 'STORE';

const useBasicInfoImageUpload = (
  areaType: AreaDivisionType,
  ref: any,
): {
  onChangeImage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClearImage: () => void;
} => {
  const dispatch = useDispatch();
  const profileImageUrl =
    useSelector(
      (state: RootState) => state.user.basicInfo.details.profileImageUrl,
    ) ?? '';
  const locationImageUrl = useSelector(
    (state: RootState) => state.user.basicInfo.locationImageUrl,
  );

  const clearFile = useCallback(() => {
    if (ref && ref.current) {
      ref.current.value = '';
    }
  }, [ref]);

  const upload = useCallback(
    (file, tempUrl) => {
      const formData = new FormData();
      formData.append('file', file);
      postImageUpload(areaType, formData)
        .then(([data]) => {
          if (data.resultCode === 200) {
            return data.uploadImagePath;
          }

          return null;
        })
        .then(imageUrl => {
          if (areaType === 'STORE') {
            dispatch(setStoreImageTemp(imageUrl));
          }
          if (areaType === 'OFFLINE') {
            dispatch(setStoreLocationImage(imageUrl));
          }
        })
        .catch(({ status }) => {
          clearFile();
          if (status === 400) {
            dispatch(openAlert('IS_INVALID_PROFILE_IMAGE_WIDTH_AND_HEIGHT'));
          }
          if (areaType === 'STORE') {
            dispatch(setStoreImageTemp(profileImageUrl));
          }
          if (areaType === 'OFFLINE') {
            dispatch(setStoreLocationImage(locationImageUrl));
          }
        })
        .finally(() => {
          URL.revokeObjectURL(tempUrl);
        });
    },
    [areaType, clearFile, dispatch, locationImageUrl, profileImageUrl],
  );

  const onChangeImage = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];

        if (!isValidImageFileSize(file)) {
          dispatch(openAlert('IS_INVALID_PROFILE_IMAGE_FILE_SIZE'));
          clearFile();

          return;
        }

        if (!isValidExtension(file)) {
          dispatch(openAlert('IS_INVALID_STORE_IMAGE_EXTENSION'));
          clearFile();

          return;
        }
        const tempUrl = URL.createObjectURL(file);
        const imageType =
          // eslint-disable-next-line no-nested-ternary
          areaType === 'STORE'
            ? 'PROFILE'
            : areaType === 'OFFLINE'
            ? 'LOCATION'
            : 'DEFAULT';

        validateImageSize(tempUrl, imageType).then(isValid => {
          if (isValid) {
            if (areaType === 'STORE') {
              dispatch(setStoreImageTemp(tempUrl));
            }
            if (areaType === 'OFFLINE') {
              dispatch(setStoreLocationImage(tempUrl));
            }
            upload(file, tempUrl);
          } else {
            clearFile();
            URL.revokeObjectURL(tempUrl);
            dispatch(openAlert('IS_INVALID_PROFILE_IMAGE_WIDTH_AND_HEIGHT'));
          }
        });
      }
    },
    [areaType, clearFile, dispatch, upload],
  );

  const onClearImage = useCallback(() => {
    dispatch(setStoreLocationImage(''));
  }, [dispatch]);

  return { onChangeImage, onClearImage };
};

export default useBasicInfoImageUpload;
