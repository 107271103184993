import {
  IGetPromotionItemParam,
  IGetPromotionListParam,
  IPostPromotionDetail,
  IPutPromotionDetail,
  IPutPromotionExpiresParam,
} from 'apis/promotion';
import { isToday, subDays } from 'date-fns';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from 'typesafe-actions';

export const getPromotionListAsync = createAsyncAction(
  '@PROMOTION/GET_LIST/FETCH',
  '@PROMOTION/GET_LIST/SUCCESS',
  '@PROMOTION/GET_LIST/FAILURE',
)<IGetPromotionListParam, IPromotionItem[], Error>();

export const getPromotionItemAsync = createAsyncAction(
  '@PROMOTION/GET_ITEM/FETCH',
  '@PROMOTION/GET_ITEM/SUCCESS',
  '@PROMOTION/GET_ITEM/FAILURE',
)<IGetPromotionItemParam, IPromotionDetail[], Error>();

export const putPromotionExpiresAsync = createAsyncAction(
  '@PROMOTION/PUT_EXPIRES/FETCH',
  '@PROMOTION/PUT_EXPIRES/SUCCESS',
  '@PROMOTION/PUT_EXPIRES/FAILURE',
)<IPutPromotionExpiresParam, undefined, Error>();

export const expiresPromotionListItem = createAction(
  '@PROMOTION/LIST/ITEM/EXPIRES',
)<string>();

export const setPromotionDetailTitle = createAction(
  '@PROMOTION/DETAIL/TITLE/SET',
)<string>();

export const setPromotionDetailSubTitle = createAction(
  '@PROMOTION/DETAIL/SUB_TITLE/SET',
)<string>();

export const setPromotionDetailStartDate = createAction(
  '@PROMOTION/DETAIL/START_DATE/SET',
)<string>();

export const setPromotionDetailEndDate = createAction(
  '@PROMOTION/DETAIL/END_DATE/SET',
)<string>();

export const addLiveProductListFromSearchResult = createAction(
  '@PROMOTION/LIVE_PRODUCT_LIST/ADD',
)();

export const setLiveProductList = createAction(
  '@PROMOTION/LIVE_PRODUCT_LIST/SET',
)<IPromotionsProduct[]>();

export const setPromotionProductOrderSeq = createAction(
  '@PROMOTION/PROMOTION_PRODUCT_ORDERSEQ/SET',
)<number, number>();

export const setPromotionProductSelected = createAction(
  '@PROMOTION/PROMOTION_PRODUCT_SELECTED/SET',
)<number>();

export const setPromotionProductSelectedAll = createAction(
  '@PROMOTION/PROMOTION_PRODUCT_SELECTED_ALL/SET',
)();

export const deletePromotionProduct = createAction(
  '@PROMOTION/PROMOTION_PRODUCT/DELETE',
)();

export const createPromotionDetail = createAction(
  '@PROMOTION/PROMOTION_DETAIL/CREATE',
)();

export const postPromotionDetailAsync = createAsyncAction(
  '@PROMOTION/POST_PROMOTION_DETAIL/FETCH',
  '@PROMOTION/POST_PROMOTION_DETAIL/SUCCESS',
  '@PROMOTION/POST_PROMOTION_DETAIL/FAILURE',
)<IPostPromotionDetail, undefined, Error>();

export const modifyPromotionDetail = createAction(
  '@PROMOTION/PROMOTION_DETAIL/MODIFY',
)<string>();

export const putPromotionDetailAsync = createAsyncAction(
  '@PROMOTION/PUT_PROMOTION_DETAIL/FETCH',
  '@PROMOTION/PUT_PROMOTION_DETAIL/SUCCESS',
  '@PROMOTION/PUT_PROMOTION_DETAIL/FAILURE',
)<IPutPromotionDetail, undefined, Error>();

export const setPromotionItem = createAction('@PROMOTION/ITEM/SET')<
  IPromotionDetail
>();
export const clearPromotionDetail = createAction(
  '@PROMOTION/PROMOTION_DETAIL/CLEAR',
)();

export const setSelectedPromotion = createAction(
  '@PROMOTION/SELECTED_PROMOTION/SET',
)<IPromotionItem>();
export interface IPromotionItem {
  promotionNo: string;
  promotionStatus: string;
  title: string;
  subTitle: string;
  startDate: string;
  endDate: string;
}

export interface IPromotionDetail {
  liveProductList: IPromotionsProduct[];
  startDate: string;
  endDate: string;
  subTitle: string;
  title: string;
  promotionNo: string;
  selected: number[];
}

export interface IPromotionsProduct {
  orderSeq: number;
  productNo: number;
  productName: string;
  sellStatusTypeDesc: string;
  displayCategoryName: string;
}

export interface IPromotion {
  promotionList: IPromotionItem[];
  promotionDetail: IPromotionDetail;
  selectPromotion?: IPromotionItem;
}

const actions = {
  getPromotionItemAsync,
  getPromotionListAsync,
  setPromotionDetailTitle,
  setPromotionDetailSubTitle,
  setPromotionDetailStartDate,
  setPromotionDetailEndDate,
  setLiveProductList,
  setPromotionProductOrderSeq,
  setPromotionProductSelected,
  setPromotionProductSelectedAll,
  deletePromotionProduct,
  clearPromotionDetail,
  setSelectedPromotion,
  setPromotionItem,
  setPromotionListItem: expiresPromotionListItem,
};

const initialState: IPromotion = {
  promotionList: [],
  promotionDetail: {
    promotionNo: '',
    startDate: '',
    endDate: '',
    subTitle: '',
    title: '',
    liveProductList: [],
    selected: [],
  },
};

export default createReducer<IPromotion, ActionType<typeof actions>>(
  initialState,
  {
    '@PROMOTION/GET_LIST/SUCCESS': (state, action) => ({
      ...state,
      promotionList: action.payload,
    }),
    '@PROMOTION/GET_ITEM/SUCCESS': (state, action) => {
      const [data] = action.payload;

      return {
        ...state,
        promotionDetail: { ...data, selected: [] },
      };
    },
    '@PROMOTION/ITEM/SET': (state, action) => {
      return {
        ...state,
        promotionDetail: { ...action.payload, selected: [] },
      };
    },
    '@PROMOTION/DETAIL/TITLE/SET': (state, action) => ({
      ...state,
      promotionDetail: {
        ...state.promotionDetail,
        title: action.payload,
      },
    }),
    '@PROMOTION/DETAIL/SUB_TITLE/SET': (state, action) => ({
      ...state,
      promotionDetail: {
        ...state.promotionDetail,
        subTitle: action.payload,
      },
    }),
    '@PROMOTION/DETAIL/START_DATE/SET': (state, action) => ({
      ...state,
      promotionDetail: {
        ...state.promotionDetail,
        startDate: action.payload,
      },
    }),
    '@PROMOTION/DETAIL/END_DATE/SET': (state, action) => ({
      ...state,
      promotionDetail: {
        ...state.promotionDetail,
        endDate: action.payload,
      },
    }),
    '@PROMOTION/LIVE_PRODUCT_LIST/SET': (state, action) => ({
      ...state,
      promotionDetail: {
        ...state.promotionDetail,
        liveProductList: action.payload,
      },
    }),
    '@PROMOTION/PROMOTION_PRODUCT_ORDERSEQ/SET': (state, action) => ({
      ...state,
      promotionDetail: {
        ...state.promotionDetail,
        liveProductList: state.promotionDetail.liveProductList.map(item =>
          item.productNo === action.meta
            ? { ...item, orderSeq: action.payload }
            : item,
        ),
      },
    }),
    '@PROMOTION/PROMOTION_PRODUCT_SELECTED/SET': (
      { promotionDetail: { selected, ...innerRest }, ...rest },
      action,
    ) => ({
      ...rest,
      promotionDetail: {
        ...innerRest,
        selected: selected.includes(action.payload)
          ? selected.filter(id => id !== action.payload)
          : [...selected, action.payload],
      },
    }),
    '@PROMOTION/PROMOTION_PRODUCT_SELECTED_ALL/SET': ({
      promotionDetail: { liveProductList, selected, ...innerRest },
      ...rest
    }) => ({
      ...rest,
      promotionDetail: {
        ...innerRest,
        liveProductList,
        selected:
          liveProductList.length > 0 &&
          selected.length === liveProductList.length
            ? []
            : liveProductList.map(({ productNo }) => productNo),
      },
    }),
    '@PROMOTION/PROMOTION_PRODUCT/DELETE': ({
      promotionDetail: { liveProductList, selected, ...innerRest },
      ...rest
    }) => ({
      ...rest,
      promotionDetail: {
        ...innerRest,
        selected: [],
        liveProductList: liveProductList.filter(
          product => !selected.includes(product.productNo),
        ),
      },
    }),
    '@PROMOTION/PROMOTION_DETAIL/CLEAR': state => ({
      ...state,
      promotionDetail: initialState.promotionDetail,
    }),
    '@PROMOTION/SELECTED_PROMOTION/SET': (state, action) => ({
      ...state,
      selectPromotion: action.payload,
    }),
    '@PROMOTION/LIST/ITEM/EXPIRES': (state, action) => ({
      ...state,
      promotionList: state.promotionList.map(item =>
        item.promotionNo === action.payload
          ? {
              ...item,
              promotionStatus: '종료',
              startDate: !isToday(new Date(item.startDate))
                ? item.startDate
                : subDays(new Date(), 1).toISOString(),
              endDate: subDays(new Date(), 1).toISOString(),
            }
          : item,
      ),
    }),
  },
);
