import { RootState } from 'modules';
import { resetAsyncTask } from 'modules/asyncTask';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  action: string;
  successText: string;
  failureText?: string;
}

const ToastMessage = ({ action, successText, failureText }: IProps) => {
  const dispatch = useDispatch();
  const status = useSelector((state: RootState) => state.asyncTask[action]);

  useEffect(() => {
    if (status !== 'IDLE') {
      setTimeout(() => {
        dispatch(resetAsyncTask(action));
      }, 1200);
    }
  }, [action, dispatch, status]);

  return (
    <>
      {status === 'SUCCESS' && (
        <div className="st-msg confirm">{successText}</div>
      )}
      {status === 'FAILURE' && (
        <div className="st-msg">{failureText || '실패했습니다.'}</div>
      )}
    </>
  );
};

export default ToastMessage;
