import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import {
  getDisplayBasicInfoAsync,
  putTemplateTypeAsync,
} from 'modules/display';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';

import TemplateMotion from './TemplateMotion';
import TemplateSaveButton from './TemplateSaveButton';

const Template = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDisplayBasicInfoAsync.request());
  }, [dispatch]);

  return (
    <div className="st-tab__cont" id="soContent">
      <DocumentTitle title="스토어 템플릿 - 스토어 전시관리 - 스토어 관리" />
      <p className="view-guide">
        내 스토어와 어울리는 레이아웃을 선택해보세요.
        <br />내 상품에 맞는 <b>스토어 정보</b>와 셀러추천상품중
        <b> 프로모션 컬렉션, 베스트 리뷰</b>의 디자인을 선택할 수 있어요.
      </p>
      <TemplateMotion />
      <TemplateSaveButton />
      <ToastMessage
        action={getType(putTemplateTypeAsync.request)}
        successText="저장되었습니다."
      />
    </div>
  );
};

export default Template;
