import { RootState } from 'modules';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import BaseIntro from './BaseIntro';
import StoreApplyButton from './StoreApplyButton';
import useCategoryApplied from './useCategoryApplied';
import useLargeCategories from './useLargeCategories';

interface IViewGuide {
  isCustomMode: boolean;
  onClickAddCategory: () => void;
}

export const DisplayViewGuide = () => (
  <div className="view-guide__2">
    <p className="text">
      11번가에서 <b>기본으로 제공하는 카테고리</b>를 이용하세요.
    </p>
    <div className="img">
      <img
        src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/category_new_example.png`}
        alt="카테고리 노출 예시"
      />
      <span className="alt">[모바일 예시화면]</span>
    </div>
  </div>
);

const ViewGuide = ({ isCustomMode, onClickAddCategory }: IViewGuide) => {
  const { hasNotProducts } = useLargeCategories();

  return isCustomMode ? (
    <div className="view-guide__2">
      <p className="text">
        내 상품에 맞는 <b>카테고리를 직접 만들어서 사용</b>하세요.
      </p>
      <div className="btn">
        <button
          type="submit"
          onClick={onClickAddCategory}
          disabled={hasNotProducts}
          className="btn-style__2 btn-size__1"
        >
          <span className="icon icon-add" />
          {' 카테고리 추가'}
        </button>
      </div>
      <div className="img">
        <img
          src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/category_new_example2.png`}
          alt="카테고리 노출 예시"
        />
        <span className="alt">[모바일 예시화면]</span>
      </div>
    </div>
  ) : (
    <DisplayViewGuide />
  );
};

const Basic = () => {
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { storeNo } = useSelector(
    (state: RootState) => state.user.headerInfo,
    shallowEqual,
  );
  const isCustomMode = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );
  const isShowDoneToast = useSelector(
    (state: RootState) => state.category.isShowDoneToast,
  );

  const { isCustomCategoryApplied } = useCategoryApplied();

  const onClickAddCategory = useCallback(() => history.push('/category/edit'), [
    history,
  ]);

  useEffect(() => {
    if (storeNo) {
      if (isCustomCategoryApplied && !search.includes('mod')) {
        history.replace('/category/done');
      }
    }
  }, [dispatch, search, history, storeNo, isCustomCategoryApplied]);

  useEffect(() => {
    if (isShowDoneToast) {
      history.push('/category/done?mod');
    }
    // eslint-disable-next-line
  }, [isShowDoneToast]);

  return (
    <BaseIntro>
      <div className="cate-view" id="soContent">
        <ViewGuide
          isCustomMode={isCustomMode}
          onClickAddCategory={onClickAddCategory}
        />
        <StoreApplyButton />
      </div>
    </BaseIntro>
  );
};

export default Basic;
