import {
  ICurationItem,
  IGetRecommendInfoParam,
  IPostRecommendInfoParam,
  IPutRecommendInfoParam,
} from 'apis/recommend';
import addMonths from 'date-fns/addMonths';
import { menuIdType } from 'modules/display';
import { IDisplayProduct } from 'modules/product';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from 'typesafe-actions';

export type RecommendMenuIdType =
  | menuIdType.recommend1
  | menuIdType.recommend2
  | menuIdType.recommend3;

export interface IRecommendProduct extends IDisplayProduct {
  orderSeq: number;
}
export interface IRecommend {
  type: string;
  startDate: string;
  endDate: string;
  title: string;
  subTitle: string;
  productData: IRecommendProduct[];
  promotionNo: string;
  selected: string[];
  curationModuleNo: string;
}

export interface IRecommendRequest {
  id: RecommendMenuIdType;
  index: number;
}

export interface IRecommendGroup {
  [menuIdType.recommend1]: IRecommend;
  [menuIdType.recommend2]: IRecommend;
  [menuIdType.recommend3]: IRecommend;
}

export interface IGetRecommendInfoResponse {
  curationTypeCode: string;
  moduleTitle: string;
  moduleSubTitle: string;
  displayBeginDate: string;
  displayEndDate: string;
  details: ICurationItem[];
  curationModuleNo: string;
}
export const setRecommendType = createAction('@DISPLAY/RECOMMEND/TYPE/SET')<
  string,
  RecommendMenuIdType
>();

export const setRecommendStartDate = createAction(
  '@DISPLAY/RECOMMEND/START_DATE/SET',
)<string, RecommendMenuIdType>();

export const setRecommendEndDate = createAction(
  '@DISPLAY/RECOMMEND/END_DATE/SET',
)<string, RecommendMenuIdType>();

export const setRecommendTitle = createAction('@DISPLAY/RECOMMEND/TITLE/SET')<
  string,
  RecommendMenuIdType
>();

export const setRecommendSubTitle = createAction(
  '@DISPLAY/RECOMMEND/SUB_TITLE/SET',
)<string, RecommendMenuIdType>();

export const addRecommendItemFromSearchResult = createAction(
  '@DISPLAY/RECOMMEND/ITEM_FROM_SEARCH_RESULT/ADD',
)();

export const setProductDataList = createAction(
  '@DISPLAY/RECOMMEND/PRODUCT_DATA_LIST/SET',
)<IRecommendProduct[], RecommendMenuIdType>();

export const addRecommendPromotionFromSearchResult = createAction(
  '@DISPLAY/RECOMMEND/PROMOTION_FROM_SEARCH_RESULT/ADD',
)();

export const setRecommendPromotion = createAction(
  '@DISPLAY/RECOMMEND/RECOMMEND_RPOMOTION/SET',
)<string, RecommendMenuIdType>();

export const initializeRecommendData = createAction(
  '@DISPLAY/RECOMMEND/DATA/INITIALIZE',
)<RecommendMenuIdType>();

export interface IRecommendActionMeta {
  moduleId: RecommendMenuIdType;
  dataId: string;
}
export const setRecommendItemSelected = createAction(
  '@DISPLAY/RECOMMEND/ITEM_SELECTED/SET',
)<string, RecommendMenuIdType>();

export const setRecommendItemSelectedAll = createAction(
  '@DISPLAY/RECOMMEND/ITEM_SELECTED_ALL/SET',
)<RecommendMenuIdType>();

export const deleteRecommendItem = createAction(
  '@DISPLAY/RECOMMEND/ITEM/DELETE',
)<RecommendMenuIdType>();

export const setRecommendItemOrder = createAction(
  '@DISPLAY/RECOMMEND/ORDER_SEQ/SET',
)<number, IRecommendActionMeta>();

export const getRecommendInfoAsync = createAsyncAction(
  '@DISPLAY/RECOMMEND/GET_INFO/FETCH',
  '@DISPLAY/RECOMMEND/GET_INFO/SUCCESS',
  '@DISPLAY/RECOMMEND/GET_INFO/FAILURE',
)<IGetRecommendInfoParam, IGetRecommendInfoResponse[], Error>();

export const getRecommend2InfoAsync = createAsyncAction(
  '@DISPLAY/RECOMMEND2/GET_INFO/FETCH',
  '@DISPLAY/RECOMMEND2/GET_INFO/SUCCESS',
  '@DISPLAY/RECOMMEND2/GET_INFO/FAILURE',
)<IGetRecommendInfoParam, IGetRecommendInfoResponse[], Error>();

export const getRecommend3InfoAsync = createAsyncAction(
  '@DISPLAY/RECOMMEND3/GET_INFO/FETCH',
  '@DISPLAY/RECOMMEND3/GET_INFO/SUCCESS',
  '@DISPLAY/RECOMMEND3/GET_INFO/FAILURE',
)<IGetRecommendInfoParam, IGetRecommendInfoResponse[], Error>();

export const getRecommend4InfoAsync = createAsyncAction(
  '@DISPLAY/RECOMMEND4/GET_INFO/FETCH',
  '@DISPLAY/RECOMMEND4/GET_INFO/SUCCESS',
  '@DISPLAY/RECOMMEND4/GET_INFO/FAILURE',
)<IGetRecommendInfoParam, IGetRecommendInfoResponse[], Error>();

export const postRecommendInfoAsync = createAsyncAction(
  '@DISPLAY/RECOMMEND/POST_INFO/FETCH',
  '@DISPLAY/RECOMMEND/POST_INFO/SUCCESS',
  '@DISPLAY/RECOMMEND/POST_INFO/FAILURE',
)<IPostRecommendInfoParam, undefined, Error>();

export const putRecommendInfoAsync = createAsyncAction(
  '@DISPLAY/RECOMMEND/PUT_INFO/FETCH',
  '@DISPLAY/RECOMMEND/PUT_INFO/SUCCESS',
  '@DISPLAY/RECOMMEND/PUT_INFO/FAILURE',
)<IPutRecommendInfoParam, undefined, Error>();

export const saveRecommendInfo = createAction('@DISPLAY/RECOMMEND/INFO/SAVE')();

export const setCurationModuleNo = createAction(
  '@DISPLAY/RECOMMEND/CURATION_MODULE_NO/SET',
)<string, RecommendMenuIdType>();

const actions = {
  setRecommendType,
  setRecommendStartDate,
  setRecommendEndDate,
  setRecommendTitle,
  setRecommendSubTitle,
  setProductDataList,
  setRecommendPromotion,
  initializeRecommendData,
  setRecommendItemSelected,
  setRecommendItemSelectedAll,
  setRecommendItemOrder,
  deleteRecommendItem,
  setCurationModuleNo,
};

const initialState = {
  [menuIdType.recommend1]: {
    type: '01',
    startDate: '',
    endDate: '',
    title: '',
    subTitle: '',
    productData: [],
    promotionNo: '',
    selected: [],
    curationModuleNo: '',
  },
  [menuIdType.recommend2]: {
    type: '01',
    startDate: '',
    endDate: '',
    title: '',
    subTitle: '',
    productData: [],
    promotionNo: '',
    selected: [],
    curationModuleNo: '',
  },
  [menuIdType.recommend3]: {
    type: '01',
    startDate: '',
    endDate: '',
    title: '',
    subTitle: '',
    productData: [],
    promotionNo: '',
    selected: [],
    curationModuleNo: '',
  },
};

export default createReducer<IRecommendGroup, ActionType<typeof actions>>(
  initialState,
  {
    '@DISPLAY/RECOMMEND/TYPE/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        type: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/START_DATE/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        startDate: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/END_DATE/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        endDate: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/TITLE/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        title: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/SUB_TITLE/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        subTitle: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/PRODUCT_DATA_LIST/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        productData: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/RECOMMEND_RPOMOTION/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        promotionNo: action.payload,
      },
    }),
    '@DISPLAY/RECOMMEND/DATA/INITIALIZE': (state, action) => ({
      ...state,
      [action.payload]: {
        ...state[action.payload],
        promotionNo: '',
        title: '',
        subTitle: '',
        startDate: new Date().toISOString(),
        endDate: addMonths(new Date(), 3).toISOString(),
        selected: [],
        productData: [],
      },
    }),
    '@DISPLAY/RECOMMEND/ITEM_SELECTED/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        selected: state[action.meta].selected.includes(action.payload)
          ? state[action.meta].selected.filter(item => item !== action.payload)
          : [...state[action.meta].selected, action.payload],
      },
    }),
    '@DISPLAY/RECOMMEND/ITEM_SELECTED_ALL/SET': (state, action) => ({
      ...state,
      [action.payload]: {
        ...state[action.payload],
        selected:
          state[action.payload].selected.length > 0 &&
          state[action.payload].selected.length ===
            state[action.payload].productData.length
            ? []
            : state[action.payload].productData.map(({ productNo }) =>
                String(productNo),
              ),
      },
    }),
    '@DISPLAY/RECOMMEND/ITEM/DELETE': (state, action) => ({
      ...state,
      [action.payload]: {
        ...state[action.payload],
        productData: state[action.payload].productData.filter(
          item =>
            !state[action.payload].selected.includes(String(item.productNo)),
        ),
        selected: [],
      },
    }),
    '@DISPLAY/RECOMMEND/ORDER_SEQ/SET': (state, action) => ({
      ...state,
      [action.meta.moduleId]: {
        ...state[action.meta.moduleId],
        productData: state[action.meta.moduleId].productData.map(item =>
          String(item.productNo) === action.meta.dataId
            ? { ...item, orderSeq: action.payload }
            : item,
        ),
      },
    }),
    '@DISPLAY/RECOMMEND/CURATION_MODULE_NO/SET': (state, action) => ({
      ...state,
      [action.meta]: {
        ...state[action.meta],
        curationModuleNo: action.payload,
      },
    }),
  },
);
