import { RootState } from 'modules';
import { menuIdType } from 'modules/display';
import React from 'react';
import { useSelector } from 'react-redux';

const getTitle = (id: string) => {
  switch (id) {
    case menuIdType.profile:
      return '대표 이미지';
    case menuIdType.recommend1:
      return '셀러추천';
    case menuIdType.recommend2:
      return '셀러추천';
    case menuIdType.recommend3:
      return '셀러추천';
    case menuIdType.special:
      return '기획전';
    default:
      return '';
  }
};

const ContentTitle = () => {
  const id = useSelector((state: RootState) => state.display.selectedModule.id);

  return (
    <div className="view-title title-style__3">
      <h3 className="title">{getTitle(id)}</h3>
    </div>
  );
};

export default ContentTitle;
