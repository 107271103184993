import classNames from 'classnames';
import React from 'react';

import CalendarSelectBox from './CalendarSelectBox';
import { IHelpers } from './index';

interface ICalendarHeader {
  title: string;
  handleMonth: (month: number) => void;
  handleYear: (years: number) => void;
  month: number;
  year: number;
  minYear: number;
  maxYear: number;
  calendarHelpers: IHelpers;
}
const CalendarHeader = ({
  title,
  handleMonth,
  handleYear,
  month,
  year,
  minYear,
  maxYear,
  calendarHelpers,
}: ICalendarHeader) => {
  const { yearList, monthList } = calendarHelpers;
  const prevMonth = () => {
    handleMonth(month !== 0 ? month - 1 : 11);
    handleYear(month !== 0 ? year : year - 1);
  };
  const nextMonth = () => {
    handleMonth(month !== 11 ? month + 1 : 0);
    handleYear(month !== 11 ? year : year + 1);
  };

  return (
    <div className="st-calendar__header">
      <strong
        className="calendar-title hide"
        style={{
          fontSize: 0,
          width: 0,
          height: 0,
        }}
      >
        {title}
      </strong>
      <div className="c-select select-style__1">
        <CalendarSelectBox
          selected={year}
          data={yearList(year, maxYear, minYear)}
          handleChange={handleYear}
        />
        <CalendarSelectBox
          selected={`${month + 1}`}
          data={monthList}
          nameFormatter="월"
          handleChange={(m: number) => handleMonth(m - 1)}
        />
      </div>
      <button
        tabIndex={0}
        type="button"
        className={classNames('btn-style__icon', 'btn-icon__prev2')}
        onClick={prevMonth}
      >
        이전
      </button>
      <button
        tabIndex={0}
        type="button"
        className={classNames('btn-style__icon', 'btn-icon__next2')}
        onClick={nextMonth}
      >
        다음
      </button>
    </div>
  );
};

export default CalendarHeader;
