import { RootState } from 'modules';
import {
  applyCustomCategoryAsync,
  saveCategoryOrderAsync,
} from 'modules/category';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const categoryOrderMapper = (list: any[]) =>
  list.map(({ id }, index) => ({
    storeCategoryNo: id,
    displayPriority: index,
  }));

const useSaveCategoryOrder = () => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const isUsingCustom = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );
  const custom = useSelector((state: RootState) => state.category.custom);
  const customOrder = useSelector(
    (state: RootState) => state.category.customOrder,
  );

  const isOrderModified = useMemo(
    () =>
      custom.some(
        ({ storeCategoryNo }, index) =>
          storeCategoryNo !== customOrder[index]?.id,
      ),
    [custom, customOrder],
  );

  const saveCategoryOrder = useCallback(() => {
    dispatch(
      saveCategoryOrderAsync.request({
        storeNo,
        isStoreCategorySwitch: true,
        values: categoryOrderMapper(customOrder),
      }),
    );
  }, [customOrder, dispatch, storeNo]);

  const applyCustomCategory = useCallback(() => {
    dispatch(
      applyCustomCategoryAsync.request({
        storeNo,
        isStoreCategorySwitch: isUsingCustom,
        values: categoryOrderMapper(customOrder),
      }),
    );
  }, [customOrder, dispatch, isUsingCustom, storeNo]);

  return { isOrderModified, saveCategoryOrder, applyCustomCategory };
};

export default useSaveCategoryOrder;
