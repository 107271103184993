import { RootState } from 'modules';
import { ITag, setSelectedRoadShops, setSelectedTags } from 'modules/user';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useTag = (
  isRoadShopTag: boolean = false,
): {
  isTagChecked: (tagNum: string) => boolean;
  onChangeTag: (tag: ITag) => void;
  selectedRoads: ITag[];
  selectedFashions: ITag[];
} => {
  const dispatch = useDispatch();
  const selectedRoads = useSelector(
    (state: RootState) => state.user.selectedRoadShops,
  );
  const selectedFashions = useSelector(
    (state: RootState) => state.user.selectedTags,
  );

  const isTagChecked = useCallback(
    (tagNum: string): boolean =>
      !!(isRoadShopTag ? selectedRoads : selectedFashions).find(
        ({ tagNo }) => String(tagNo) === String(tagNum),
      ),
    [isRoadShopTag, selectedFashions, selectedRoads],
  );

  const onChangeTag = useCallback(
    (tag: ITag) => {
      const { tagNo: tagNum } = tag;
      if (isRoadShopTag && tagNum === selectedRoads[0]?.tagNo) {
        return;
      }
      const selectedTags = isRoadShopTag ? selectedRoads : selectedFashions;
      const newSelectedTags = isTagChecked(tagNum)
        ? selectedTags.filter(({ tagNo }) => tagNo !== tagNum)
        : [...selectedTags, tag];
      const { length } = newSelectedTags;
      const startIndex = isRoadShopTag ? length - 1 : length - 3;
      const payload = newSelectedTags.slice(
        startIndex < 0 ? 0 : startIndex,
        length,
      );
      if (isRoadShopTag) {
        dispatch(setSelectedRoadShops(payload));
      } else {
        dispatch(setSelectedTags(payload));
      }
    },
    [dispatch, isRoadShopTag, isTagChecked, selectedFashions, selectedRoads],
  );

  return { isTagChecked, onChangeTag, selectedRoads, selectedFashions };
};

export default useTag;
