/* eslint-disable no-nested-ternary */
import { CheckBox, TextInput } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import DatePicker from 'components/common/DatePicker';
import { isBefore } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { openDialog } from 'modules/dialog';
import { setTouched } from 'modules/display';
import {
  deleteRecommendItem,
  RecommendMenuIdType,
  setRecommendEndDate,
  setRecommendItemOrder,
  setRecommendItemSelected,
  setRecommendItemSelectedAll,
  setRecommendStartDate,
  setRecommendTitle,
} from 'modules/recommend';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cutByMaxLength } from 'utils';
import { formatDate, formatNumber } from 'utils/format';
import { INPUT_REGEX } from 'utils/validate';

const TableHeader = ({ id }: { id: RecommendMenuIdType }) => {
  const productData = useSelector(
    (state: RootState) => state.recommend[id].productData,
  );
  const selected = useSelector(
    (state: RootState) => state.recommend[id].selected,
  );
  const dispatch = useDispatch();
  const isSelected = useMemo(
    () => productData.length > 0 && productData.length === selected.length,
    [productData.length, selected.length],
  );

  return (
    <thead>
      <tr>
        <th scope="col">
          <CheckBox
            type={CheckBoxType.basic}
            id="상품선택_전체선택"
            name="셀러추천_전체선택"
            label=""
            checked={isSelected}
            onChange={() => {
              dispatch(setRecommendItemSelectedAll(id));
              dispatch(setTouched(true));
            }}
            labelHidden
          />
        </th>
        <th scope="col">전시순서</th>
        <th scope="col">상품번호</th>
        <th scope="col">상품명</th>
        <th scope="col">판매상태</th>
        <th scope="col">판매가</th>
        <th scope="col">즉시할인</th>
        <th scope="col">할인모음가</th>
      </tr>
    </thead>
  );
};

const getSellStatus = (sellPrice: string, status: string) => {
  if (sellPrice) {
    return status;
  }
  if (status !== '판매중') {
    return status;
  }

  return '-';
};

const TableBody = ({ id }: IProps) => {
  const productData = useSelector(
    (state: RootState) => state.recommend[id].productData,
  );
  const selected = useSelector(
    (state: RootState) => state.recommend[id].selected,
  );
  const dispatch = useDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    dataId: string,
  ) => {
    const value = Math.floor(Number(e.target.value));
    if (value > 100) {
      dispatch(openAlert('IS_OVER_100_SPECIAL_ITEM_ORDER'));
      dispatch(setRecommendItemOrder(100, { moduleId: id, dataId }));
    } else if (value < 0) {
      dispatch(openAlert('IS_NEGATIVE_SPECIAL_ITEM_ORDER'));
      dispatch(setRecommendItemOrder(0, { moduleId: id, dataId }));
    } else {
      dispatch(setRecommendItemOrder(value, { moduleId: id, dataId }));
    }
    dispatch(setTouched(true));
  };

  return (
    <tbody>
      {productData.length > 0 ? (
        productData.map(data => (
          <tr key={data.productNo}>
            <td>
              <CheckBox
                type={CheckBoxType.basic}
                id={`상품선택_${data.productNo}`}
                name="셀러추천_상품선택"
                label=""
                checked={selected.includes(String(data.productNo))}
                onChange={() => {
                  dispatch(
                    setRecommendItemSelected(String(data.productNo), id),
                  );
                  dispatch(setTouched(true));
                }}
                labelHidden
              />
            </td>
            <td>
              <TextInput
                type="number"
                value={data.orderSeq.toString()}
                title={`전시순서_${data.productNo}`}
                width="size__2"
                onChange={e => handleChange(e, String(data.productNo))}
              />
            </td>
            <td>
              <span className="text-num">{data.productNo}</span>
            </td>
            <td>
              <span className="text-title">{data.productName}</span>
            </td>
            <td>{getSellStatus(data.sellPrice, data.sellStatusDesc)}</td>
            <td>
              <span className="text-num">
                {data.sellPrice ? formatNumber(Number(data.sellPrice)) : '-'}
              </span>
            </td>
            <td>
              <span className="text-num">
                {data.sellPrice && data.finalDiscountPrice
                  ? formatNumber(
                      Number(data.sellPrice) - data.finalDiscountPrice,
                    )
                  : '-'}
              </span>
            </td>
            <td>
              <span className="text-num">
                {data.finalDiscountPrice
                  ? formatNumber(data.finalDiscountPrice)
                  : '-'}
              </span>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={8} className="non-data">
            상품이 없습니다.
          </td>
        </tr>
      )}
    </tbody>
  );
};

interface IProps {
  id: RecommendMenuIdType;
}

const Product = ({ id }: IProps) => {
  const data = useSelector((state: RootState) => state.recommend[id]);
  const show = useSelector((state: RootState) => state.alert.show);
  const dispatch = useDispatch();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 30) {
        dispatch(setRecommendTitle(e.target.value, id));
      } else {
        dispatch(setRecommendTitle(cutByMaxLength(e.target.value, 30), id));
      }
      dispatch(setTouched(true));
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  const dataLimit = useMemo(() => (data.type === '01' ? 3 : 6), [data.type]);

  return (
    <div className="write-box type_bg">
      <div className="write-box__row inline">
        <div className="row_title">
          {'노출 기간 '}
          <span className="icon icon-must">필수입력</span>
        </div>
        <div className="row_cont">
          <div className="c-date__calender">
            <DatePicker
              value={formatDate(data.startDate)}
              source={data.startDate}
              minDate={new Date()}
              placeholder="시작일"
              title="시작일"
              onChange={(date: Date) => {
                dispatch(setRecommendStartDate(date.toISOString(), id));
                dispatch(
                  setRecommendEndDate(addMonths(date, 3).toISOString(), id),
                );
                dispatch(setTouched(true));
              }}
            />
            <span className="section-bar">~</span>
            <DatePicker
              value={formatDate(data.endDate)}
              source={data.endDate}
              minDate={
                data.startDate
                  ? isBefore(new Date(data.startDate), new Date())
                    ? new Date()
                    : new Date(data.startDate)
                  : new Date()
              }
              maxDate={
                data.startDate
                  ? addMonths(new Date(data.startDate), 3)
                  : undefined
              }
              placeholder="마지막일"
              title="마지막일"
              onChange={(date: Date) => {
                dispatch(setRecommendEndDate(date.toISOString(), id));
                dispatch(setTouched(true));
              }}
            />
          </div>
        </div>
      </div>
      <div className="write-box__row inline">
        <div className="row_title">
          {'타이틀 '}
          <span className="icon icon-must">필수입력</span>
        </div>
        <div className="row_cont">
          <TextInput
            value={data.title}
            width="wide"
            onChange={handleTitleChange}
            title="RecommendTitle"
            placeholder="한글 12자, 영문 20자 이내로 입력해주세요."
          />
        </div>
      </div>
      <div className="write-box__row divide">
        <div className="row_title">
          상품 등록 <i>{`(최대 ${dataLimit}개)`}</i>
          <div className="side_btn">
            {data.productData.length < dataLimit && (
              <button
                type="button"
                className="btn-style__1 btn-size__6"
                onClick={() => {
                  if (data.type === '01') {
                    dispatch(openDialog('SelectProductsFromRecommendCardView'));
                  } else {
                    dispatch(openDialog('SelectProductsFromRecommendVideo'));
                  }
                }}
              >
                상품추가
              </button>
            )}
            <button
              type="button"
              className="btn-style__1 btn-size__6"
              onClick={() => {
                if (data.selected.length > 0) {
                  dispatch(deleteRecommendItem(id));
                } else {
                  dispatch(openAlert('DELETE_PRODUCT_WITH_NO_DATA'));
                }
              }}
            >
              삭제
            </button>
          </div>
        </div>
        <div className="row_cont">
          <div className="table-wrap">
            <table className="table">
              <caption>상품등록</caption>
              <colgroup>
                <col width="40px" />
                <col width="80px" />
                <col width="100px" />
                <col />
                <col width="80px" />
                <col width="100px" />
                <col width="100px" />
                <col width="100px" />
              </colgroup>
              <TableHeader id={id} />
              <TableBody id={id} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
