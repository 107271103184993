/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import {
  getCustomCategoryProductDetail,
  getDisplayProductList,
} from 'apis/product';
import { RootState } from 'modules';
import {
  DISPLAY_PRODUCT_PAGE_SIZE,
  getCustomCategoryProductDetailAsync,
  getDisplayProductAsync,
  setFinalSelectedProducts,
} from 'modules/product';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { createAsyncSaga, distinctArray } from 'utils';

export const getDisplayProductListSaga = createAsyncSaga(
  getDisplayProductAsync,
  getDisplayProductList,
);

function* getCustomCategoryProductNumberListSaga() {
  const {
    category: { selectedCustomId },
    user: {
      headerInfo: { storeNo },
    },
  } = yield select((state: RootState) => state);
  yield createAsyncSaga(
    getCustomCategoryProductDetailAsync,
    getCustomCategoryProductDetail,
  )(
    getCustomCategoryProductDetailAsync.request({
      storeCategoryNo: selectedCustomId,
      storeNo,
    }),
  );
  const {
    product: { productNumbers },
    user: {
      headerInfo: { encSellerNo },
    },
  } = yield select((state: RootState) => state);

  for (const index in Array.from({
    length: Math.ceil(productNumbers.length / DISPLAY_PRODUCT_PAGE_SIZE),
  })) {
    const i = Number(index);
    const finalProducts = yield select(
      (state: RootState) => state.product.finalSelectedProducts,
    );
    yield getDisplayProductListSaga(
      getDisplayProductAsync.request({
        encSellerNo,
        pageSize: DISPLAY_PRODUCT_PAGE_SIZE,
        pageNumber: 0,
        productNumberList: productNumbers.slice(
          i * DISPLAY_PRODUCT_PAGE_SIZE,
          (i + 1) * DISPLAY_PRODUCT_PAGE_SIZE,
        ),
        isProductNoFirst: true,
      }),
    );
    const justFoundProducts = yield select(
      (state: RootState) => state.product.foundProducts,
    );

    yield put(
      setFinalSelectedProducts(
        distinctArray([...finalProducts, ...justFoundProducts], 'productNo'),
      ),
    );
  }
}

export default function* categorySaga() {
  yield takeLatest(
    getType(getDisplayProductAsync.request),
    getDisplayProductListSaga,
  );
  yield takeLatest(
    getType(getCustomCategoryProductDetailAsync.request),
    getCustomCategoryProductNumberListSaga,
  );
}
