import { isFuture, isToday } from 'date-fns';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { menuIdType } from 'modules/display';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

let popup: Window | null;

const DOMAIN = `${window.location.protocol}//shop.11st.co.kr`;

const getPreviewType = (type: string) => {
  if (type === '01') return 'CARD';
  if (type === '02') return 'MOVIE';

  return 'PROMOTION';
};

const useMobilePreview = () => {
  const [status, setStatus] = useState(false);
  const dispatch = useDispatch();

  const storeID = useSelector(
    (state: RootState) => state.user.headerInfo.storeID,
  );
  const id = useSelector((state: RootState) => state.display.selectedModule.id);
  const profileData = useSelector((state: RootState) => state.profile.data);
  const profileType = useSelector((state: RootState) => state.profile.type);
  const recommend2Data = useSelector(
    (state: RootState) => state.recommend['2'],
  );
  const recommend3Data = useSelector(
    (state: RootState) => state.recommend['3'],
  );
  const recommend4Data = useSelector(
    (state: RootState) => state.recommend['4'],
  );
  const specialData = useSelector((state: RootState) => state.special.data);

  const messageHandler = (event: MessageEvent) => {
    if (event.origin === DOMAIN && event.data === 'previewload') {
      setStatus(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
      setStatus(false);
    };
  }, []);

  useEffect(() => {
    if (popup && status) {
      switch (id) {
        case menuIdType.profile: {
          const profileFilteredData = profileData
            .filter(item => {
              if (item.type === 'IMAGE')
                return item.imageUrl || item.title || item.subTitle;
              if (item.type === 'PRODUCT' || item.type === 'PROMOTION')
                return (
                  item.imageUrl || item.title || item.subTitle || item.itemNo
                );

              return false;
            })
            .filter(item => item.statusCode === '0');
          popup.postMessage(
            {
              type: 'repImage',
              payload: profileFilteredData.map(item => ({
                imageURL: item.imageUrl,
                isSquareView: profileType === 'SQUARE',
                objectValue: item.itemNo,
                profileTargetCode: item.type,
                title: item.title,
                subTitle: item.subTitle,
              })),
            },
            DOMAIN,
          );
          break;
        }
        case menuIdType.recommend1: {
          popup.postMessage(
            {
              type: 'module',
              payload: {
                moduleType: getPreviewType(recommend2Data.type),
                moduleNo: 2,
                moduleTitle: {
                  title: recommend2Data.title,
                  subTitle: recommend2Data.subTitle,
                },
                products:
                  isToday(new Date(recommend2Data.endDate)) ||
                  isFuture(new Date(recommend2Data.endDate))
                    ? [...recommend2Data.productData]
                        .sort((a, b) => {
                          if (a.orderSeq < b.orderSeq) return -1;
                          if (a.orderSeq > b.orderSeq) return 1;
                          if (a.productName < b.productName) return -1;
                          if (a.productName > b.productName) return 1;

                          return 0;
                        })
                        .map(({ productNo }) => productNo)
                    : [],
                values:
                  recommend2Data.type === '01' || recommend2Data.type === '02'
                    ? null
                    : recommend2Data.promotionNo,
              },
            },
            DOMAIN,
          );
          break;
        }
        case menuIdType.recommend2: {
          popup.postMessage(
            {
              type: 'module',
              payload: {
                moduleType: getPreviewType(recommend3Data.type),
                moduleNo: 3,
                moduleTitle: {
                  title: recommend3Data.title,
                  subTitle: recommend3Data.subTitle,
                },
                products:
                  isToday(new Date(recommend3Data.endDate)) ||
                  isFuture(new Date(recommend3Data.endDate))
                    ? [...recommend3Data.productData]
                        .sort((a, b) => {
                          if (a.orderSeq < b.orderSeq) return -1;
                          if (a.orderSeq > b.orderSeq) return 1;
                          if (a.productName < b.productName) return -1;
                          if (a.productName > b.productName) return 1;

                          return 0;
                        })
                        .map(({ productNo }) => productNo)
                    : [],
                values:
                  recommend3Data.type === '01' || recommend3Data.type === '02'
                    ? null
                    : recommend3Data.promotionNo,
              },
            },
            DOMAIN,
          );
          break;
        }
        case menuIdType.recommend3: {
          popup.postMessage(
            {
              type: 'module',
              payload: {
                moduleType: getPreviewType(recommend4Data.type),
                moduleNo: 4,
                moduleTitle: {
                  title: recommend4Data.title,
                  subTitle: recommend4Data.subTitle,
                },
                products:
                  isToday(new Date(recommend4Data.endDate)) ||
                  isFuture(new Date(recommend4Data.endDate))
                    ? [...recommend4Data.productData]
                        .sort((a, b) => {
                          if (a.orderSeq < b.orderSeq) return -1;
                          if (a.orderSeq > b.orderSeq) return 1;
                          if (a.productName < b.productName) return -1;
                          if (a.productName > b.productName) return 1;

                          return 0;
                        })
                        .map(({ productNo }) => productNo)
                    : [],
                values:
                  recommend4Data.type === '01' || recommend4Data.type === '02'
                    ? null
                    : [recommend4Data.promotionNo],
              },
            },
            DOMAIN,
          );
          break;
        }
        case menuIdType.special: {
          popup.postMessage(
            {
              type: 'EXHIBITION',
              payload: [...specialData]
                .sort((a, b) => {
                  if (a.orderSeq < b.orderSeq) return -1;
                  if (a.orderSeq > b.orderSeq) return 1;
                  if (a.exhibitionName < b.exhibitionName) return -1;
                  if (a.exhibitionName > b.exhibitionName) return 1;
                  if (a.exhibitionNo < b.exhibitionNo) return 1;
                  if (a.exhibitionNo > b.exhibitionNo) return -1;

                  return 0;
                })
                .map(({ exhibitionNo, exhibitionName, bannerImageUrl }) => ({
                  exhibitionNo,
                  exhibitionName,
                  bannerImageUrl,
                })),
            },
            DOMAIN,
          );
          break;
        }
      }
      setStatus(false);
    }
  }, [
    dispatch,
    id,
    profileData,
    profileType,
    recommend2Data.endDate,
    recommend2Data.productData,
    recommend2Data.promotionNo,
    recommend2Data.subTitle,
    recommend2Data.title,
    recommend2Data.type,
    recommend3Data.endDate,
    recommend3Data.productData,
    recommend3Data.promotionNo,
    recommend3Data.subTitle,
    recommend3Data.title,
    recommend3Data.type,
    recommend4Data.endDate,
    recommend4Data.productData,
    recommend4Data.promotionNo,
    recommend4Data.subTitle,
    recommend4Data.title,
    recommend4Data.type,
    specialData,
    status,
  ]);

  const openPopup = () => {
    popup = window.open(
      `${DOMAIN}/m/${storeID}/preview`,
      '모바일 미리보기',
      'resizable=yes,scrollbars=yes,width=375,height=812',
    );
  };

  const openMobilePreview = () => {
    if (popup) {
      popup.close();
      setStatus(false);
    }

    switch (id) {
      case menuIdType.profile: {
        const profileFilteredData = profileData.filter(item => {
          if (item.type === 'IMAGE')
            return item.imageUrl || item.title || item.subTitle;
          if (item.type === 'PRODUCT' || item.type === 'PROMOTION')
            return item.imageUrl || item.title || item.subTitle || item.itemNo;

          return false;
        });

        if (profileFilteredData.length === 0)
          profileFilteredData.push({
            id: Date.now().toString(),
            type: 'IMAGE',
            imageUrl: '',
            title: '',
            subTitle: '',
            statusCode: '0',
          });

        if (
          profileFilteredData
            .filter((o, i) => i > 0)
            .some(item => item.imageUrl === '' || !item.imageUrl)
        ) {
          dispatch(openAlert('SAVE_PROFILE_WITH_EMPTY_IMAGE'));
        } else if (
          profileFilteredData.some(
            item => item.type === 'PRODUCT' && item.itemNo === '',
          )
        ) {
          dispatch(openAlert('SAVE_PROFILE_WITHOUT_PRODUCT_NO'));
        } else if (
          profileFilteredData.some(
            item => item.type === 'PROMOTION' && item.itemNo === '',
          )
        ) {
          dispatch(openAlert('SAVE_PROFILE_WITHOUT_PROMOTION_NO'));
        } else {
          openPopup();
        }
        break;
      }
      case menuIdType.recommend1: {
        if (recommend2Data.type === '03' && recommend2Data.promotionNo === '') {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_PROMOTION_NO'));
        } else if (
          recommend2Data.type === '03' &&
          recommend2Data.title.length === 0
        ) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
        } else if (
          recommend2Data.type === '03' &&
          recommend2Data.subTitle.length === 0
        ) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_SUBTITLE'));
        } else if (recommend2Data.title.length === 0) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
        } else if (recommend2Data.productData.length === 0) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_PRODUCT_DATA'));
        } else {
          openPopup();
        }
        break;
      }
      case menuIdType.recommend2: {
        if (recommend3Data.type === '03' && recommend3Data.promotionNo === '') {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_PROMOTION_NO'));
        } else if (
          recommend3Data.type === '03' &&
          recommend3Data.title.length === 0
        ) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
        } else if (
          recommend3Data.type === '03' &&
          recommend3Data.subTitle.length === 0
        ) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_SUBTITLE'));
        } else if (recommend3Data.title.length === 0) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
        } else if (recommend3Data.productData.length === 0) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_PRODUCT_DATA'));
        } else {
          openPopup();
        }
        break;
      }
      case menuIdType.recommend3: {
        if (recommend4Data.type === '03' && recommend4Data.promotionNo === '') {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_PROMOTION_NO'));
        } else if (
          recommend4Data.type === '03' &&
          recommend4Data.title.length === 0
        ) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
        } else if (
          recommend4Data.type === '03' &&
          recommend4Data.subTitle.length === 0
        ) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_SUBTITLE'));
        } else if (recommend4Data.title.length === 0) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
        } else if (recommend4Data.productData.length === 0) {
          dispatch(openAlert('SAVE_RECOMMEND_WITHOUT_PRODUCT_DATA'));
        } else {
          openPopup();
        }
        break;
      }
      case menuIdType.special: {
        if (specialData.length === 0) {
          dispatch(openAlert('PREVIEW_SPECIAL_WITH_EMPTY'));
        } else {
          openPopup();
        }
        break;
      }
    }
  };

  return [openMobilePreview];
};

export default useMobilePreview;
