import { RootState } from 'modules';
import { ISpecialItem } from 'modules/special';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Swiper from 'swiper';

const compareSpecialItem = (a: ISpecialItem, b: ISpecialItem): number => {
  if (a.orderSeq < b.orderSeq) return -1;
  if (a.orderSeq > b.orderSeq) return 1;
  if (a.exhibitionName < b.exhibitionName) return -1;
  if (a.exhibitionName > b.exhibitionName) return 1;
  if (a.exhibitionNo < b.exhibitionNo) return 1;
  if (a.exhibitionNo > b.exhibitionNo) return -1;

  return 0;
};

const Special = () => {
  const data = useSelector((state: RootState) => state.special.data);
  const sortedData = useMemo(() => [...data].sort(compareSpecialItem), [data]);
  useEffect(() => {
    /* eslint-disable */
    // TODO: 실제 Front 모듈은 loop: true가 적용되어 있으나 해당 옵션이 dynamic contents를 감지하는 observer 옵션과 충돌하는 버그가 있음
    const swiper = new Swiper('.w-swiper', {
      pagination: {
        el: '.c-pagination__indicator',
        type: 'bullets',
        clickable: true,
        bulletActiveClass: 'on',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

      observer: true,
    });

    /* eslint-enable */
    return () => swiper.destroy(true, true);
  }, []);

  return (
    <div className="item_cont" style={{ height: 127 }}>
      <div className="l-grid">
        <div className="l-grid__row" data-ui-type="Store_BillBoard">
          <div className="l-grid__col l-grid__col--12">
            <div className="w-swiper swiper-container-horizontal">
              <ul className="swiper-wrapper">
                {sortedData.length > 0 ? (
                  sortedData.map(({ exhibitionNo, bannerImageUrl }) => (
                    <li
                      className="swiper-slide"
                      style={{ width: 360 }}
                      key={exhibitionNo}
                    >
                      {bannerImageUrl && (
                        <img
                          src={`${process.env.REACT_APP_CDN}${bannerImageUrl}`}
                          alt=""
                        />
                      )}
                    </li>
                  ))
                ) : (
                  <li className="swiper-slide" style={{ width: 360 }}>
                    <img
                      src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/layout_banner.png`}
                      alt=""
                    />
                  </li>
                )}
              </ul>
              <nav className="c-pagination">
                <span className="c-pagination__indicator" />
              </nav>
              <button
                type="button"
                className="swiper-button-prev"
                tabIndex={0}
                aria-label="Previous slide"
              >
                이전
              </button>
              <button
                type="button"
                className="swiper-button-next"
                tabIndex={0}
                aria-label="Next slide"
              >
                다음
              </button>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Special;
