import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from 'typesafe-actions';

export interface ICouponItem {
  couponName: string;
  couponNo: string;
  couponStatus: string;
  couponType: string;
  discountAmount: number;
  discountMethodCode: string;
  discountRate: number;
  effectiveDate: string;
  issueDate: string;
  issueMethod: string;
  issuedCount: number;
  overlapDiscountYn: string;
  registerDate: string;
  usedCount: number;
}

export interface CouponStatus {
  couponNo: string;
  issueStatusCode: string;
}

export const getCouponListAsync = createAsyncAction(
  '@COUPON/LIST/FETCH',
  '@COUPON/LIST/SUCCESS',
  '@COUPON/LIST/FAILURE',
)<undefined, ICouponItem[], Error>();

export const getCouponItemStatusAsync = createAsyncAction(
  '@COUPON/ITEM_STATUS/FETCH',
  '@COUPON/ITEM_STATUS/SUCCESS',
  '@COUPON/ITEM_STATUS/FAILURE',
)<string, CouponStatus[], Error>();

export const setSelectedCoupon = createAction('@COUPON/SELECTED/SET')<string>();

export const send11TalkCoupons = createAction('@COUPON/11TALK_MESSAGE/SEND')<
  string
>();

const actions = {
  getCouponListAsync,
  setSelectedCoupon,
  getCouponItemStatusAsync,
};

interface ICoupon {
  data: ICouponItem[];
  selectedCoupon: string;
  isPossible: boolean;
}

const initialState: ICoupon = {
  data: [],
  selectedCoupon: '',
  isPossible: true,
};

export default createReducer<ICoupon, ActionType<typeof actions>>(
  initialState,
  {
    '@COUPON/LIST/SUCCESS': (state, action) => ({
      ...state,
      data: action.payload,
    }),
    '@COUPON/SELECTED/SET': (state, action) => ({
      ...state,
      selectedCoupon: action.payload,
    }),
    '@COUPON/ITEM_STATUS/SUCCESS': (state, action) => {
      const [data] = action.payload;

      return {
        ...state,
        isPossible: data.issueStatusCode === '03',
      };
    },
  },
);
