/* eslint-disable no-unused-expressions */
import { MutableRefObject, useCallback, useRef } from 'react';

const useImageUpload = (): [MutableRefObject<null>, (e: any) => void] => {
  const inputFileRef = useRef(null);

  const onClick = useCallback((e: any) => {
    if (e.target?.type === 'file') {
      return;
    }
    if (inputFileRef.current) {
      ((inputFileRef.current as unknown) as HTMLElement)?.click();
    }
    e.preventDefault();
  }, []);

  return [inputFileRef, onClick];
};

export default useImageUpload;
