import useAnimationFrame from 'hooks/useAnimationFrame';
import { RootState } from 'modules';
import { IModule, menuIdType } from 'modules/display';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const getPreviewTitle = (type: string) => {
  if (type === '01') {
    return '카드뷰';
  }
  if (type === '02') {
    return '동영상뷰';
  }

  return '프로모션';
};

const getPreviewType = (type: string) => {
  if (type === '01') {
    return 'skin_cardview';
  }
  if (type === '02') {
    return 'skin_movie';
  }

  return 'skin_promotion';
};

const SortablePreview = () => {
  const [position, setPosition] = useState(0);
  const [height, setHeight] = useState(0);
  const data = useSelector((state: RootState) => state.display.tempModuleData);
  const targetId = useSelector((state: RootState) => state.display.targetId);
  const containerRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const recommend = useSelector((state: RootState) => state.recommend);

  const getPreviewItem = useCallback(
    ({ id }: IModule, tRef: React.RefObject<HTMLDivElement> | null) => {
      switch (id) {
        case menuIdType.bestReview:
          return (
            <span className="skin_review" ref={tRef} key={id}>
              베스트리뷰
            </span>
          );
        case menuIdType.recommend1:
          return (
            <span
              className={getPreviewType(recommend[id].type)}
              ref={tRef}
              key={id}
            >
              {getPreviewTitle(recommend[id].type)}
            </span>
          );
        case menuIdType.recommend2:
          return (
            <span
              className={getPreviewType(recommend[id].type)}
              ref={tRef}
              key={id}
            >
              {getPreviewTitle(recommend[id].type)}
            </span>
          );
        case menuIdType.recommend3:
          return (
            <span
              className={getPreviewType(recommend[id].type || '')}
              ref={tRef}
              key={id}
            >
              {getPreviewTitle(recommend[id].type)}
            </span>
          );
        case menuIdType.special:
          return (
            <span className="skin_banner" ref={tRef} key={id}>
              기획전
            </span>
          );
        default:
          return null;
      }
    },
    [recommend],
  );

  useEffect(() => {
    if (containerRef.current && targetRef.current) {
      // eslint-disable-next-line no-unused-expressions

      containerRef.current.scrollTop = targetRef.current.offsetTop - 30;
    }
  }, [targetId, data]);

  useAnimationFrame(() => {
    if (targetRef.current && containerRef.current) {
      const top =
        targetRef.current?.getBoundingClientRect().top -
        containerRef.current?.getBoundingClientRect().top;
      const bottom =
        targetRef.current?.getBoundingClientRect().bottom -
        containerRef.current?.getBoundingClientRect().top;
      const containerHeight = containerRef.current?.getBoundingClientRect()
        .height;
      if (bottom <= 0 || top >= containerHeight) {
        setHeight(0);
        setPosition(0);
      } else {
        setHeight(
          (bottom >= containerHeight ? containerHeight : bottom) -
            (top >= 0 ? top : 0),
        );
        setPosition(top >= 0 ? top + 30 : 30);
      }
    }
  });

  return (
    <>
      <div className="view-display__img">
        <div className="img-in" ref={containerRef}>
          <span className="skin_gnb">GNB</span>
          <span className="skin_visual">스토어정보</span>
          <span className="skin_store">스토어정보</span>
          <span className="skin_info">스토어쇼핑정보</span>
          <span className="skin_search">스토어 검색</span>
          <span className="skin_recommend">추천상품</span>
          <span className="skin_shortcut">바로가기</span>
          {data.map(item =>
            getPreviewItem(item, item.id === targetId ? targetRef : null),
          )}
          <span className="skin_product">전체상품</span>
        </div>
      </div>
      <span className="view-display__area" style={{ height, top: position }}>
        스토어정보 영역
      </span>
    </>
  );
};

export default SortablePreview;
