import { WriteBox } from 'components/common';
import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AceEditorButton from './AceEditorButton';
import CancelButton from './CancelButton';
import DisplayDate from './DisplayDate';
import NoticeType from './NoticeType';
import PageTitle from './PageTitle';
import SaveButton from './SaveButton';
import Title from './Title';
import useAceEditor from './useAceEditor';
import useCreateNoticeLog from './useCreateNoticeLog';
import useGetNoticeDetail from './useGetNoticeDetail';

const GuideText = memo(() => (
  <p className="text_info info-type__3">
    최신 공지사항은 최대 180일까지 스토어 메인에 자동 노출됩니다.
  </p>
));

const Edit = () => {
  const { id = '' } = useParams();

  const isUpdate = useMemo(() => !!id, [id]);

  const { hasContent, onClickCallEditor } = useAceEditor();

  useGetNoticeDetail({
    isUpdate,
    noticeNo: id,
  });
  useCreateNoticeLog(isUpdate);

  return (
    <div className="content-in">
      <PageTitle editMode={isUpdate} />
      <div className="view-cont">
        <div className="write-box__2 type_bg">
          <NoticeType />
          <WriteBox title="제목">
            <Title />
          </WriteBox>
          <WriteBox title="상세내용">
            <AceEditorButton
              editMode={isUpdate}
              hasContent={hasContent}
              onClick={onClickCallEditor}
            />
          </WriteBox>
          <WriteBox title="전시기간 설정">
            <DisplayDate editMode={isUpdate} />
          </WriteBox>
        </div>
        <GuideText />
      </div>
      <div className="view-btn__area">
        <CancelButton />
        <SaveButton editMode={isUpdate} />
      </div>
    </div>
  );
};

export default Edit;
