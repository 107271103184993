import { all } from 'redux-saga/effects';
import categorySaga from 'sagas/category';
import couponSaga from 'sagas/coupon';
import displaySaga from 'sagas/display';
import noticeSaga from 'sagas/notice';
import productSaga from 'sagas/product';
import profileSaga from 'sagas/profile';
import promotionSaga from 'sagas/promotion';
import recommendSaga from 'sagas/recommend';
import specialSaga from 'sagas/special';
import userSaga from 'sagas/user';

export default function* rootSaga() {
  yield all([
    userSaga(),
    displaySaga(),
    categorySaga(),
    productSaga(),
    couponSaga(),
    noticeSaga(),
    specialSaga(),
    recommendSaga(),
    promotionSaga(),
    profileSaga(),
  ]);
}
