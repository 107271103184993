import { useCallback, useMemo, useState } from 'react';

type ListType = Array<any>;

/**
 * 모든 페이지의 데이터를 들고있을때 사용가능
 * [currentPage, currentList, onChangePage]
 */

const usePagination = (
  allList: ListType,
  pageSize: number = 10,
  initialCurrentPage: number = 1,
): [number, ListType, (pageNumber: number) => void] => {
  const [currentPage, setCurrentPage] = useState(initialCurrentPage);

  const currentList = useMemo(() => {
    const end = currentPage * pageSize;
    const start = end - pageSize;

    return allList.slice(start, end);
  }, [allList, currentPage, pageSize]);

  const onChangePage = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  return [currentPage, currentList, onChangePage];
};

export default usePagination;
