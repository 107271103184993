/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import useImageStyle from 'hooks/useImageStyle';
import { RootState } from 'modules';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const HeaderProfileImage = () => {
  const history = useHistory();

  const imageUrl = useSelector(
    (state: RootState) => state.user.headerInfo.repImageURL,
  );
  const [showGuide, setShowGuide] = useState(true);

  const imageSrc = useMemo(() => `${process.env.REACT_APP_CDN}${imageUrl}`, [
    imageUrl,
  ]);
  const imageStyle = useImageStyle(imageSrc);

  const onClick = useCallback(() => {
    history.push('/basicinfo');
  }, [history]);

  useEffect(() => {
    if (imageUrl) {
      setShowGuide(false);
    }
  }, [imageUrl]);

  return (
    <div
      className="info_img"
      data-log-actionid-area="seller"
      data-log-actionid-label="profile"
    >
      {imageUrl ? (
        <>
          <span className="img">
            <span className="img_in" style={imageStyle}>
              셀러 프로필 이미지
            </span>
          </span>
          <button
            type="button"
            className="btn-style__icon btn-icon__edit"
            onClick={onClick}
          >
            수정
          </button>
        </>
      ) : (
        <>
          <span className="img" />
          <button
            type="button"
            className="btn-style__icon btn-icon__edit"
            onClick={onClick}
          >
            수정
          </button>
          {showGuide && (
            <div className="st-msg__guide">
              <p>{'프로필 이미지를 등록해주세요. '}</p>
              <button
                type="button"
                className="btn-style__icon btn-icon__close"
                onClick={() => {
                  setShowGuide(false);
                }}
              >
                닫기
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HeaderProfileImage;
