export const DISPLAY_PRODUCT_PAGE_SIZE = 100;
export const DISPLAY_PRODUCT_POPUP_PAGE_SIZE = 50;

export interface IProductState {
  foundProductsTotal: number;
  foundProducts: IDisplayProduct[];
  currentSelectedProducts: IDisplayProduct[];
  finalSelectedProducts: IDisplayProduct[];
  productNumbers: number[];
  productTableCurrentPage: number;
}

export type ProductStatusType = '판매중' | '판매중지';
export interface IProduct {
  productNo: number;
  productName: string;
  productStatus: ProductStatusType;
  productCategory: string;
}

export interface IDisplayProduct {
  productNo: number;
  productName: string;
  sellPrice: string;
  discountPrice: number;
  finalDiscountPrice: number;
  displayCategoryNo1: string;
  displayCategoryNo2: string;
  displayCategoryNo3: string;
  displayCategoryNo4: string;
  displayCategoryName1: string;
  displayCategoryName2: string;
  displayCategoryName3: string;
  displayCategoryName4: string;
  sellStatusDesc: string;
  productType: string;
  additionalInfo: {
    benefitTexts: string[];
    viewType: string;
  };
  originalImageUrl: string;
  cardViewImageUrl: string;
  movieImageUrl: string;
  movieUrl: string;
  discountRate: number;
}
