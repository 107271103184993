import classNames from 'classnames';
import useTextInput from 'hooks/useTextInput';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { putStoreUrlAsync } from 'modules/user';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INPUT_REGEX } from 'utils/validate';

import { TextUrl } from './components';

const PublicDomain = () => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const elevenStreetDomainUrl = useSelector(
    (state: RootState) =>
      state.user.basicInfo.details.elevenStreetDomainUrl ?? '',
  );
  const isStoreUrlLocked = useSelector(
    (state: RootState) => state.user.basicInfo.isStoreUrlLocked,
  );
  const disabled = useMemo(() => !!elevenStreetDomainUrl, [
    elevenStreetDomainUrl,
  ]);

  const [storeUrl, onChangeStoreUrl, setStoreUrl] = useTextInput({
    validatorOption: { type: 'urlParam', max: 25 },
    textModifier: (text: string) => text.toLowerCase(),
  });

  const onClickPutStorUrl = useCallback(() => {
    const len = storeUrl.length;
    if (len < 6 || len > 25) {
      dispatch(openAlert('STORE_URL_INVALID_LENGTH'));

      return;
    }
    if (INPUT_REGEX.number.test(storeUrl)) {
      dispatch(openAlert('STORE_URL_INVALID'));

      return;
    }

    dispatch(putStoreUrlAsync.request({ storeNo, storeID: storeUrl }));
  }, [dispatch, storeNo, storeUrl]);

  useEffect(() => {
    setStoreUrl(elevenStreetDomainUrl);
  }, [elevenStreetDomainUrl, setStoreUrl]);

  return (
    <div
      className={classNames('tab_cont', {
        disabled,
      })}
    >
      <p className="text_info info-type__4">
        11번가 스토어 주소는 1회 설정 이후 수정이 불가합니다. <br />
        스토어 주소에 개인정보성 정보는 포함하지 말아주세요.
      </p>
      <div className="c-input w-350">
        <div className="input-text">
          <input
            type="text"
            className="inp"
            value={storeUrl}
            onChange={onChangeStoreUrl}
            disabled={isStoreUrlLocked}
          />
        </div>
        <button
          type="button"
          className="btn-style__1 btn-size__4"
          onClick={onClickPutStorUrl}
          disabled={isStoreUrlLocked}
        >
          {isStoreUrlLocked ? '변경완료' : '변경'}
        </button>
      </div>
      <TextUrl>{isStoreUrlLocked ? storeUrl : storeNo}</TextUrl>
    </div>
  );
};

export default PublicDomain;
