import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from 'router';
import { bindAddrSearch } from 'utils/addr';
import rakeLog from 'utils/log';

import store from 'configureStore';

bindAddrSearch();
rakeLog();
document.domain = '11st.co.kr';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
