import React, { useCallback } from 'react';

const useAnimationFrame: (callback: () => void) => void = callback => {
  const requestRef = React.useRef<number>(-1);
  const previousTimeRef = React.useRef<number>(-1);

  const animate = useCallback(
    (time: number) => {
      if (previousTimeRef.current !== -1) {
        callback();
      }

      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback],
  );

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);
};

export default useAnimationFrame;
