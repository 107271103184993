import { saveTemplate, setTouched } from 'modules/display';
import React from 'react';
import { useDispatch } from 'react-redux';

const TemplateSaveButton = () => {
  const dispatch = useDispatch();

  return (
    <div className="cont-btn">
      <button
        type="submit"
        className="btn-style__3 btn-size__1"
        onClick={() => {
          dispatch(saveTemplate());
          dispatch(setTouched(false));
        }}
        data-log-actionid-area="template"
        data-log-actionid-label="save"
      >
        저장
      </button>
    </div>
  );
};

export default TemplateSaveButton;
