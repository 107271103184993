import useBasicCategory from 'hooks/useBasicCategory';
import { RootState } from 'modules';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { customCategoryListMapper } from './CategoryList';

const useLargeCategories = () => {
  const isUsingCustom = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );
  const custom = useSelector((state: RootState) => state.category.custom);

  const [basicLargeCategories] = useBasicCategory();

  const customCategories = useMemo(() => custom.map(customCategoryListMapper), [
    custom,
  ]);
  const largeCategories = useMemo(
    () => (isUsingCustom ? customCategories : basicLargeCategories),
    [basicLargeCategories, customCategories, isUsingCustom],
  );
  const hasNotProducts = useMemo(() => !basicLargeCategories.length, [
    basicLargeCategories.length,
  ]);

  return { hasNotProducts, largeCategories };
};

export default useLargeCategories;
