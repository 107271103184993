import { RootState } from 'modules';
import { closeDialog } from 'modules/dialog';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DialogBody from './DialogBody';
import DialogBottom from './DialogBottom';
import DialogHeader from './DialogHeader';

const Dialog = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dialog.show);

  return show ? (
    <div className="dialog-bg" style={{ display: 'block' }}>
      <div
        role="dialog"
        id="dialog"
        aria-labelledby="dialog_label"
        aria-modal="true"
        aria-hidden="true"
        className="dialog"
      >
        <DialogHeader />
        <DialogBody />
        <DialogBottom />
        <button
          type="button"
          onClick={() => dispatch(closeDialog())}
          className="dialog-close"
        >
          닫기
        </button>
      </div>
    </div>
  ) : null;
};

export default Dialog;
