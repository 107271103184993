import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import { RootState } from 'modules';
import {
  addProfileItem,
  getProfileInfoAsync,
  putProfileInfoAsync,
} from 'modules/profile';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';

import ProfileList from './ProfileList';
import ProfileTypeRadioGroup from './ProfileTypeRadioGroup';

const ProfileItemAddButton = () => {
  const dispatch = useDispatch();

  const dataLength = useSelector(
    (state: RootState) => state.profile.dataLength,
  );

  return (
    <>
      {dataLength < 5 && (
        <div className="write-box__btn">
          <button
            type="button"
            className="btn-style__1 btn-size__4"
            onClick={() => {
              dispatch(
                addProfileItem({
                  id: Date.now().toString(),
                  type: 'IMAGE',
                  imageUrl: '',
                  title: '',
                  subTitle: '',
                  statusCode: '0',
                }),
              );
            }}
          >
            추가등록
          </button>
        </div>
      )}
    </>
  );
};

const Profile = () => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  useEffect(() => {
    if (storeNo) {
      dispatch(getProfileInfoAsync.request(storeNo));
    }
  }, [dispatch, storeNo]);

  return (
    <>
      <DocumentTitle title="대표 이미지 - 스토어 전시관리 - 스토어 관리" />
      <div className="write-box">
        <div className="write-box__row inline">
          <h4 className="row_title">배너 타입 선택</h4>
          <div className="row_cont">
            <ProfileTypeRadioGroup />
          </div>
        </div>
      </div>
      <div className="view-title title-style__4">
        <h5 className="title">
          {'배너 설정 '}
          <i>(최대 5개)</i>
        </h5>
      </div>
      <div className="box-wrapper">
        <ProfileList />
        <ProfileItemAddButton />
        <ToastMessage
          action={getType(putProfileInfoAsync.request)}
          successText="저장되었습니다."
        />
      </div>
    </>
  );
};

export default Profile;
