import { RootState } from 'modules';
import { setIsShowDoneToast } from 'modules/category';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseIntro from './BaseIntro';
import { DisplayViewGuide } from './Basic';
import StoreApplyButton from './StoreApplyButton';

const DoneViewGuide = () => {
  const history = useHistory();

  return (
    <div className="view-guide__3">
      <em className="title confirm">스토어 카테고리 설정이 완료되었습니다.</em>
      <p className="text">서비스 반영에 최대 1일 소요될 수 있습니다.</p>
      <p className="text">
        스토어 카테고리 설정 이후에도 판매중인 상품에 연결된 카테고리가 4개
        미만일 경우, <br />
        11번가 카테고리로 자동 변경되어 서비스되니 유의해 주세요.
      </p>
      <p className="text">
        스토어 카테고리에 11번가 카테고리/상품이 제대로 연결되지 않으면, 스토어
        전체상품에는 노출되지만 <br />
        카테고리별 탐색 시 노출되지 않습니다.
      </p>
      <div className="btn">
        <button
          type="submit"
          onClick={() => history.push('/category/edit')}
          className="btn-style__2 btn-size__1"
        >
          카테고리 편집
        </button>
      </div>
    </div>
  );
};

const ViewGuide = ({
  isShowDoneToast,
  isUsingCustom,
}: {
  isShowDoneToast: boolean;
  isUsingCustom: boolean;
}) => (
  <>
    {isUsingCustom ? <DoneViewGuide /> : <DisplayViewGuide />}
    <StoreApplyButton isShowDoneToast={isShowDoneToast} done />
  </>
);

const Done = () => {
  const dispatch = useDispatch();
  const isUsingCustom = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );
  const isShowDoneToast = useSelector(
    (state: RootState) => state.category.isShowDoneToast,
  );

  useEffect(() => {
    if (isShowDoneToast) {
      setTimeout(() => {
        dispatch(setIsShowDoneToast(false));
      }, 1200);
    }
  }, [dispatch, isShowDoneToast]);

  return (
    <BaseIntro done>
      {isUsingCustom ? (
        <div className="cate-view view-type__2" id="soContent">
          <ViewGuide
            isShowDoneToast={isShowDoneToast}
            isUsingCustom={isUsingCustom}
          />
        </div>
      ) : (
        <div className="cate-view" id="soContent">
          <ViewGuide
            isShowDoneToast={isShowDoneToast}
            isUsingCustom={isUsingCustom}
          />
        </div>
      )}
    </BaseIntro>
  );
};

export default Done;
