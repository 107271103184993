import { useCallback, useState } from 'react';

const useToast = (delay: number = 1200): [boolean, () => void] => {
  const [flag, setFlag] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setFlag(true);
    setTimeout(() => {
      setFlag(false);
    }, delay);
  }, [delay]);

  return [flag, toggle];
};

export default useToast;
