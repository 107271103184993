import classNames from 'classnames';
import { RootState } from 'modules';
import { TemplateType } from 'modules/display';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import TemplateCompareButton from './TemplateCompareButton';
import TemplateItem from './TemplateItem';
import TemplateRadio from './TemplateRadio';
import TemplateTargetLabel from './TemplateTargetLabel';

interface IAction {
  type: string;
  payload: {
    target?: string;
    scrollTop?: number;
    text?: string;
    opacity?: number;
    duration?: number;
  };
}

interface IMotion {
  delay: number;
  action: IAction;
}

interface IState {
  animated: boolean;
  scroll: {
    target?: string;
    scrollTop?: number;
    duration?: number;
  };
  fade: {
    target?: string;
    text?: string;
    opacity?: number;
    duration?: number;
  };
}

const initState = Object.freeze({
  animated: false,
  scroll: {},
  fade: {},
});

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'START_ANIMATION':
      return { ...state, animated: true };
    case 'STOP_ANIMATION':
      return { ...initState };
    case 'ITEM_FADE':
      return {
        ...state,
        fade: {
          target: 'item',
          opacity: action.payload.opacity,
          duration: action.payload.duration,
        },
      };
    case 'LABEL_FADE':
      return {
        ...state,
        fade: {
          target: 'label',
          text: action.payload.text,
          opacity: action.payload.opacity,
          duration: action.payload.duration,
        },
      };
    case 'SCROLL_TO_TOP':
      return {
        ...state,
        scroll: {
          scrollTop: 0,
          duration: action.payload.duration,
        },
      };
    case 'SCROLL_TO_TARGET':
      return {
        ...state,
        scroll: {
          target: action.payload.target,
          duration: action.payload.duration,
        },
      };
    default:
      return state;
  }
};

const motions = Object.freeze([
  {
    delay: 0,
    action: {
      type: 'ITEM_FADE',
      payload: { opacity: 0, duration: 500 },
    },
  },
  {
    delay: 500,
    action: {
      type: 'SCROLL_TO_TOP',
      payload: { duration: 0 },
    },
  },
  {
    delay: 500,
    action: {
      type: 'ITEM_FADE',
      payload: { opacity: 1, duration: 2000 },
    },
  },
  {
    delay: 0,
    action: {
      type: 'SCROLL_TO_TARGET',
      payload: { target: 'template-store', duration: 0 },
    },
  },
  {
    delay: 0, // scroll 시작지점이므로 동시에 실행
    action: {
      type: 'LABEL_FADE',
      payload: { text: '스토어 정보', opacity: 1, duration: 1000 },
    },
  },
  {
    delay: 2000,
    action: {
      type: 'SCROLL_TO_TARGET',
      payload: { target: 'template-promotion', duration: 2000 },
    },
  },
  {
    delay: 0,
    action: {
      type: 'LABEL_FADE',
      payload: { text: '스토어 정보', opacity: 0, duration: 1000 },
    },
  },
  {
    delay: 1333, // 위 scroll action의 2/3이 실행될 시점 (2000 * 2/3)
    action: {
      type: 'LABEL_FADE',
      payload: { text: '프로모션 컬렉션', opacity: 1, duration: 1000 },
    },
  },
  {
    delay: 2000,
    action: {
      type: 'SCROLL_TO_TARGET',
      payload: { target: 'template-review', duration: 2000 },
    },
  },
  {
    delay: 0,
    action: {
      type: 'LABEL_FADE',
      payload: { text: '프로모션 컬렉션', opacity: 0, duration: 1000 },
    },
  },
  {
    delay: 1333,
    action: {
      type: 'LABEL_FADE',
      payload: { text: '베스트 리뷰', opacity: 1, duration: 1000 },
    },
  },
  {
    delay: 2000,
    action: {
      type: 'SCROLL_TO_TOP',
      payload: { duration: 2500 },
    },
  },
  {
    delay: 0,
    action: {
      type: 'LABEL_FADE',
      payload: { text: '베스트 리뷰', opacity: 0, duration: 1000 },
    },
  },
  {
    delay: 2500, // scroll action이 끝나는 시점.
    action: {
      type: 'STOP_ANIMATION',
      payload: {},
    },
  },
]);

const templates = Object.freeze([
  {
    type: 'default' as TemplateType,
    label: '기본형',
  },
  {
    type: 'visual' as TemplateType,
    label: '비주얼 강조형',
  },
]);

const TemplateMotion = () => {
  const selected = useSelector((state: RootState) => state.display.template);
  const motionsRef = useRef<IMotion[]>([]);
  const [state, dispatch] = useReducer(reducer, initState);
  const { animated, scroll, fade } = state;

  const startAnimation = useCallback(() => {
    dispatch({ type: 'START_ANIMATION', payload: {} });
    motionsRef.current = motions.slice();
  }, []);

  const stopAnimation = useCallback(() => {
    dispatch({ type: 'STOP_ANIMATION', payload: {} });
  }, []);

  // mount 1초 후에 바로 실행
  useEffect(() => {
    const timer = setTimeout(startAnimation, 1000);

    return () => clearTimeout(timer);
  }, [startAnimation]);

  useEffect(() => {
    let animationFrame: number;
    let start: number = 0;

    if (animated) {
      const onFrame = () => {
        if (start === 0) {
          start = Date.now();
        }
        if (motionsRef.current.length > 0) {
          // 순서대로 실행한 후 배열에서 삭제한다.
          const motion = motionsRef.current[0];
          if (start + motion.delay < Date.now()) {
            dispatch(motion.action);
            motionsRef.current.shift();
            start = Date.now();
          }
          animationFrame = requestAnimationFrame(onFrame);
        }
      };
      onFrame();
    }

    return () => cancelAnimationFrame(animationFrame);
  }, [animated]);

  const itemFadeStyle =
    fade.target === 'item'
      ? {
          opacity: fade.opacity,
          transitionDuration: `${fade.duration}ms`,
        }
      : {};

  const labelFadeStyle =
    fade.target === 'label'
      ? {
          opacity: fade.opacity,
          top: fade.text === '스토어 정보' ? 288 : 40,
          transitionDuration: `${fade.duration}ms`,
        }
      : {};

  return (
    <div className="view-item">
      {templates.map(({ type, label }) => (
        <div
          key={type}
          className={classNames('item', { active: selected === type })}
        >
          <TemplateRadio type={type} selected={selected} label={label} />
          <div className={`item-img type_${type}`}>
            <div
              role="presentation"
              className="item-img__in"
              onWheel={stopAnimation}
              onMouseDown={stopAnimation}
            >
              <TemplateItem
                type={type}
                target={scroll.target}
                top={scroll.scrollTop}
                duration={scroll.duration}
                fadeStyle={itemFadeStyle}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="compare">
        {animated ? (
          <TemplateTargetLabel text={fade.text} style={labelFadeStyle} />
        ) : (
          <TemplateCompareButton onClick={startAnimation} />
        )}
      </div>
    </div>
  );
};

export default TemplateMotion;
