import React, { useEffect, useRef } from 'react';
import { positionValues, Scrollbars } from 'react-custom-scrollbars';

// https://github.com/streamich/ts-easing/blob/master/src/index.ts
const ease = {
  inOutCubic: (t: number) =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
};

interface IProps {
  children: React.ReactNode;
  to?: number;
  duration?: number;
  onScrollFrame?: (values: positionValues) => void;
}

const ScrollMotion = (props: IProps) => {
  const { to, duration = 0, onScrollFrame, children } = props;
  const scrollRef = useRef<Scrollbars>(null);

  useEffect(() => {
    let animationFrame: number;
    let from: number;
    let start: number = 0;

    if (to !== undefined && duration !== undefined) {
      const onFrame = () => {
        if (scrollRef.current) {
          if (duration === 0) {
            scrollRef.current.scrollTop(to);
          } else {
            if (start === 0) {
              start = Date.now();
              from = scrollRef.current.getScrollTop();
            }
            const current = Date.now() - start;
            const progress = current / duration;
            if (progress <= 1) {
              const move = Math.round((to - from) * ease.inOutCubic(progress));
              scrollRef.current.scrollTop(from + move);
              animationFrame = requestAnimationFrame(onFrame);
            }
          }
        }
      };

      onFrame();
    }

    return () => cancelAnimationFrame(animationFrame);
  }, [to, duration]);

  return (
    <Scrollbars autoHide ref={scrollRef} onScrollFrame={onScrollFrame}>
      {children}
    </Scrollbars>
  );
};

export default ScrollMotion;
