export interface IEditorContents {
  editorTemplateContent: string;
  editorTemplateJson: string;
}

interface IGetEditorParam {
  onSave: (editorContents: IEditorContents) => void;
  onDisconnect?: () => void;
}

let editor: any = null;

export const setEditorContents = (editorContents: IEditorContents) => {
  if (editor) {
    editor.config.options.editorContents = editorContents;
  }
};
const getEditor = ({ onSave, onDisconnect }: IGetEditorParam) => {
  if (!editor) {
    editor = new window.ACE.Editor.Parent({
      onSave,
      onDisconnect,
      options: {
        editorContents: { editorTemplateContent: '', editorTemplateJson: '' },
        channel: 'SO',
        channelType: '02',
      },
    });
  }

  return editor;
};

export default getEditor;
