import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import { getDisplayBasicInfoAsync, putSkinTypeAsync } from 'modules/display';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';

import SkinButton from './SkinButton';
import SkinPreview from './SkinPreview';
import SkinRadioGroup from './SkinRadioGroup';

const Skin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDisplayBasicInfoAsync.request());
  }, [dispatch]);

  return (
    <div className="st-tab__cont" id="soContent">
      <DocumentTitle title="스토어 스킨 - 스토어 전시관리 - 스토어 관리" />
      <p className="view-guide">
        내 스토어에 어울리는 대표컬러를 골라 보세요!
        <br />
        스토어 컨셉에 맞는 컬러 설정으로 상품을 돋보이게 할 수 있어요.
      </p>
      <div className="view-skin">
        <SkinRadioGroup />
        <SkinPreview />
      </div>
      <SkinButton />
      <ToastMessage
        action={getType(putSkinTypeAsync.request)}
        successText="저장되었습니다."
      />
    </div>
  );
};

export default Skin;
