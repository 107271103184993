import {
  getRecommendInfo,
  IPostRecommendInfoParam,
  postRecommendInfo,
  putRecommendInfo,
} from 'apis/recommend';
import { addMonths } from 'date-fns';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { resetAsyncTask } from 'modules/asyncTask';
import { closeDialog } from 'modules/dialog';
import { menuIdType } from 'modules/display';
import { getDisplayProductAsync, IDisplayProduct } from 'modules/product';
import {
  clearPromotionDetail,
  getPromotionItemAsync,
  IPromotionDetail,
  IPromotionItem,
} from 'modules/promotion';
import {
  addRecommendItemFromSearchResult,
  addRecommendPromotionFromSearchResult,
  getRecommend2InfoAsync,
  getRecommend3InfoAsync,
  getRecommend4InfoAsync,
  getRecommendInfoAsync,
  IGetRecommendInfoResponse,
  IRecommend,
  postRecommendInfoAsync,
  putRecommendInfoAsync,
  RecommendMenuIdType,
  saveRecommendInfo,
  setCurationModuleNo,
  setProductDataList,
  setRecommendEndDate,
  setRecommendPromotion,
  setRecommendStartDate,
  setRecommendSubTitle,
  setRecommendTitle,
  setRecommendType,
} from 'modules/recommend';
import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';
import { formatDate } from 'utils/format';

import { getDisplayProductListSaga } from './product';
import { getPromotionItemSaga } from './promotion';

export function* addRecommendItemFromSearchResultSaga() {
  const data: IDisplayProduct[] = yield select(
    (state: RootState) => state.product.currentSelectedProducts,
  );
  const id: RecommendMenuIdType = yield select(
    (state: RootState) => state.display.selectedModule.id,
  );

  const { type, productData }: IRecommend = yield select(
    (state: RootState) => state.recommend[id],
  );

  const dataLimit = type === '01' ? 3 : 6;

  const filteredData = data.filter(
    item => !productData.find(product => product.productNo === item.productNo),
  );

  if (productData.length + filteredData.length > dataLimit) {
    if (type === '01') {
      yield put(openAlert('SAVE_RECOMMEND_OVER_3_ITEMS'));
    } else if (type === '02') {
      yield put(openAlert('SAVE_RECOMMEND_OVER_6_ITEMS'));
    }
  } else {
    yield put(
      setProductDataList(
        [
          ...productData,
          ...filteredData.map(item => ({ ...item, orderSeq: 100 })),
        ],
        id,
      ),
    );
    yield put(closeDialog());
  }
}

export function* addRecommendPromotionFromSearchResultSaga() {
  const data: IPromotionItem = yield select(
    (state: RootState) => state.promotion.selectPromotion,
  );
  const id = yield select(
    (state: RootState) => state.display.selectedModule.id,
  );
  const {
    encSellerNo,
    storeNo,
  }: { encSellerNo: string; storeNo: string } = yield select(
    (state: RootState) => state.user.headerInfo,
  );

  yield put(setRecommendPromotion(data.promotionNo, id));
  yield getPromotionItemSaga(
    getPromotionItemAsync.request({ storeNo, promotionNo: data.promotionNo }),
  );

  const {
    title,
    subTitle,
    startDate,
    endDate,
    liveProductList,
  }: IPromotionDetail = yield select(
    (state: RootState) => state.promotion.promotionDetail,
  );

  yield put(setRecommendTitle(title || '', id));
  yield put(setRecommendSubTitle(subTitle || '', id));
  yield put(setRecommendStartDate(startDate, id));
  yield put(setRecommendEndDate(endDate, id));

  yield getDisplayProductListSaga(
    getDisplayProductAsync.request({
      encSellerNo,
      productNumberList: liveProductList.map(item => item.productNo.toString()),
      isProductNoFirst: true,
    }),
  );

  const displayProductList: IDisplayProduct[] = yield select(
    (state: RootState) => state.product.foundProducts,
  );

  yield put(
    setProductDataList(
      displayProductList
        .slice(0, 6)
        .map((item, index) => ({ ...item, orderSeq: index * 10 })),
      id,
    ),
  );
  yield put(clearPromotionDetail());
}

export const getRecommendInfoSaga = createAsyncSaga(
  getRecommendInfoAsync,
  getRecommendInfo,
);

export const getRecommend2InfoSaga = createAsyncSaga(
  getRecommend2InfoAsync,
  getRecommendInfo,
);

export const getRecommend3InfoSaga = createAsyncSaga(
  getRecommend3InfoAsync,
  getRecommendInfo,
);

export const getRecommend4InfoSaga = createAsyncSaga(
  getRecommend4InfoAsync,
  getRecommendInfo,
);

function* getRecommendInfoSuccessSaga(
  action: PayloadAction<
    '@DISPLAY/RECOMMEND/GET_INFO/SUCCESS',
    IGetRecommendInfoResponse[]
  >,
) {
  const id: RecommendMenuIdType = yield select(
    (state: RootState) => state.display.selectedModule.id,
  );

  if (action.payload.length > 0) {
    const [data]: IGetRecommendInfoResponse[] = action.payload;
    yield all([
      put(setCurationModuleNo(data.curationModuleNo, id)),
      put(
        setRecommendType(
          // eslint-disable-next-line no-nested-ternary
          data.curationTypeCode === 'CARD'
            ? '01'
            : data.curationTypeCode === 'MOVIE'
            ? '02'
            : '03',
          id,
        ),
      ),
      put(setRecommendTitle(data.moduleTitle ?? '', id)),
      put(setRecommendSubTitle(data.moduleSubTitle ?? '', id)),
      put(setRecommendStartDate(data.displayBeginDate, id)),
      put(setRecommendEndDate(data.displayEndDate, id)),
    ]);
    const encSellerNo = yield select(
      (state: RootState) => state.user.headerInfo.encSellerNo,
    );
    if (data.curationTypeCode === 'PROMOTION') {
      yield put(
        setRecommendPromotion(data.details[0].moduleObjectValue.toString(), id),
      );
      const storeNo = yield select(
        (state: RootState) => state.user.headerInfo.storeNo,
      );
      yield getPromotionItemSaga(
        getPromotionItemAsync.request({
          storeNo,
          promotionNo: data.details[0].moduleObjectValue.toString(),
        }),
      );

      const {
        startDate,
        endDate,
        liveProductList,
      }: IPromotionDetail = yield select(
        (state: RootState) => state.promotion.promotionDetail,
      );

      yield put(setRecommendStartDate(startDate, id));
      yield put(setRecommendEndDate(endDate, id));

      yield getDisplayProductListSaga(
        getDisplayProductAsync.request({
          encSellerNo,
          productNumberList: liveProductList.map(item =>
            item.productNo.toString(),
          ),
          isProductNoFirst: true,
        }),
      );

      const displayProductList: IDisplayProduct[] = yield select(
        (state: RootState) => state.product.foundProducts,
      );

      yield put(
        setProductDataList(
          displayProductList.slice(0, 6).map((item, index) => ({
            ...item,
            orderSeq: liveProductList[index].orderSeq,
          })),
          id,
        ),
      );
      yield put(clearPromotionDetail());
    } else {
      yield getDisplayProductListSaga(
        getDisplayProductAsync.request({
          encSellerNo,
          productNumberList: data.details.map(item =>
            item.moduleObjectValue.toString(),
          ),
          isProductNoFirst: true,
        }),
      );
      const displayProductList: IDisplayProduct[] = yield select(
        (state: RootState) => state.product.foundProducts,
      );

      yield put(
        setProductDataList(
          displayProductList.map((item, index) => ({
            ...item,
            orderSeq: data.details[index].displayPriority,
          })),
          id,
        ),
      );
    }
  } else {
    yield all([
      put(setRecommendStartDate(new Date().toISOString(), id)),
      put(setRecommendEndDate(addMonths(new Date(), 3).toISOString(), id)),
    ]);
  }
}

// getRecommend2InfoSuccessSaga ~ getRecommend4InfoSuccessSaga
// 스토어 홈/전시에 최초 접근했을 때 메뉴에 타입을 표기하기 위해서 & 동기화하기 위해 임의로 분리한 사가
// FIXME: 중복 코드기 때문에 최적화했으면 좋겠음.. (Response에 현재 조회한 moduleNo가 반환되면 해결됨)
function* getRecommend2InfoSuccessSaga(
  action: PayloadAction<
    '@DISPLAY/RECOMMEND2/GET_INFO/SUCCESS',
    IGetRecommendInfoResponse[]
  >,
) {
  const id = menuIdType.recommend1;

  if (action.payload.length > 0) {
    const [data]: IGetRecommendInfoResponse[] = action.payload;
    yield put(
      setRecommendType(
        // eslint-disable-next-line no-nested-ternary
        data.curationTypeCode === 'CARD'
          ? '01'
          : data.curationTypeCode === 'MOVIE'
          ? '02'
          : '03',
        id,
      ),
    );
  }
}

function* getRecommend3InfoSuccessSaga(
  action: PayloadAction<
    '@DISPLAY/RECOMMEND3/GET_INFO/SUCCESS',
    IGetRecommendInfoResponse[]
  >,
) {
  const id = menuIdType.recommend2;

  if (action.payload.length > 0) {
    const [data]: IGetRecommendInfoResponse[] = action.payload;
    yield put(
      setRecommendType(
        // eslint-disable-next-line no-nested-ternary
        data.curationTypeCode === 'CARD'
          ? '01'
          : data.curationTypeCode === 'MOVIE'
          ? '02'
          : '03',
        id,
      ),
    );
  }
}

function* getRecommend4InfoSuccessSaga(
  action: PayloadAction<
    '@DISPLAY/RECOMMEND4/GET_INFO/SUCCESS',
    IGetRecommendInfoResponse[]
  >,
) {
  const id = menuIdType.recommend3;

  if (action.payload.length > 0) {
    const [data]: IGetRecommendInfoResponse[] = action.payload;
    yield put(
      setRecommendType(
        // eslint-disable-next-line no-nested-ternary
        data.curationTypeCode === 'CARD'
          ? '01'
          : data.curationTypeCode === 'MOVIE'
          ? '02'
          : '03',
        id,
      ),
    );
  }
}

const postRecommendInfoSaga = createAsyncSaga(
  postRecommendInfoAsync,
  postRecommendInfo,
);

const putRecommendInfoSaga = createAsyncSaga(
  putRecommendInfoAsync,
  putRecommendInfo,
);

export function* saveRecommendInfoSaga() {
  const id: RecommendMenuIdType = yield select(
    (state: RootState) => state.display.selectedModule.id,
  );
  const data: IRecommend = yield select(
    (state: RootState) => state.recommend[id],
  );

  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  const payload: IPostRecommendInfoParam = {
    storeNo,
    moduleNo: id,
    curationTypeCode:
      // eslint-disable-next-line no-nested-ternary
      data.type === '01' ? 'CARD' : data.type === '02' ? 'MOVIE' : 'PROMOTION',
    moduleTitle: data.title,
    moduleSubTitle: data.subTitle,
    displayBeginDate: formatDate(data.startDate).replace(/[.]/g, '-'),
    displayEndDate: formatDate(data.endDate).replace(/[.]/g, '-'),
    adminCurationValueList:
      data.type === '03'
        ? [{ moduleObjectValue: Number(data.promotionNo), displayPriority: 1 }]
        : data.productData.map(({ productNo, orderSeq }) => ({
            moduleObjectValue: productNo,
            displayPriority: orderSeq,
          })),
  };

  if (data.type === '03' && data.promotionNo === '') {
    yield put(openAlert('SAVE_RECOMMEND_WITHOUT_PROMOTION_NO'));
  } else if (data.type === '03' && data.title.length === 0) {
    yield put(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
  } else if (data.type === '03' && data.subTitle.length === 0) {
    yield put(openAlert('SAVE_RECOMMEND_WITHOUT_SUBTITLE'));
  } else if (data.title.length === 0) {
    yield put(openAlert('SAVE_RECOMMEND_WITHOUT_TITLE'));
  } else if (data.productData.length === 0) {
    yield put(openAlert('SAVE_RECOMMEND_WITHOUT_PRODUCT_DATA'));
  } else if (data.curationModuleNo) {
    yield putRecommendInfoSaga(
      putRecommendInfoAsync.request({
        ...payload,
        curationModuleNo: data.curationModuleNo,
      }),
    );

    // FIXME: comparator 다 분리하기
    yield put(
      setProductDataList(
        [...data.productData].sort((a, b) => {
          if (a.orderSeq < b.orderSeq) return -1;
          if (a.orderSeq > b.orderSeq) return 1;
          if (a.productName < b.productName) return -1;
          if (a.productName > b.productName) return 1;
          if (a.productNo < b.productNo) return 1;
          if (a.productNo > b.productNo) return -1;

          return 0;
        }),
        id,
      ),
    );
    yield delay(1200);
    yield put(resetAsyncTask(getType(putRecommendInfoAsync.request)));
  } else {
    yield postRecommendInfoSaga(postRecommendInfoAsync.request(payload));

    yield put(
      setProductDataList(
        [...data.productData].sort((a, b) => {
          if (a.orderSeq < b.orderSeq) return -1;
          if (a.orderSeq > b.orderSeq) return 1;
          if (a.productName < b.productName) return -1;
          if (a.productName > b.productName) return 1;
          if (a.productNo < b.productNo) return 1;
          if (a.productNo > b.productNo) return -1;

          return 0;
        }),
        id,
      ),
    );
    yield delay(1200);
    yield put(resetAsyncTask(getType(postRecommendInfoAsync.request)));
  }
}

export default function* RecommendSaga() {
  yield takeLatest(
    getType(addRecommendItemFromSearchResult),
    addRecommendItemFromSearchResultSaga,
  );
  yield takeLatest(
    getType(addRecommendPromotionFromSearchResult),
    addRecommendPromotionFromSearchResultSaga,
  );
  yield takeLatest(
    getType(getRecommendInfoAsync.request),
    getRecommendInfoSaga,
  );
  yield takeLatest(
    getType(getRecommendInfoAsync.success),
    getRecommendInfoSuccessSaga,
  );
  yield takeLatest(
    getType(getRecommend2InfoAsync.request),
    getRecommend2InfoSaga,
  );
  yield takeLatest(
    getType(getRecommend2InfoAsync.success),
    getRecommend2InfoSuccessSaga,
  );
  yield takeLatest(
    getType(getRecommend3InfoAsync.request),
    getRecommend3InfoSaga,
  );
  yield takeLatest(
    getType(getRecommend3InfoAsync.success),
    getRecommend3InfoSuccessSaga,
  );
  yield takeLatest(
    getType(getRecommend4InfoAsync.request),
    getRecommend4InfoSaga,
  );
  yield takeLatest(
    getType(getRecommend4InfoAsync.success),
    getRecommend4InfoSuccessSaga,
  );
  yield takeLatest(getType(saveRecommendInfo), saveRecommendInfoSaga);
}
