import { IPostNoticeDetailParam } from 'apis/notice';
import isBefore from 'date-fns/isBefore';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  postNoticeDetailAsync,
  putNoticeDetailAsync,
} from 'modules/notice/actions';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import useAceEditor from './useAceEditor';

interface ISaveButtonProps {
  editMode: boolean;
}
const SaveButton = ({ editMode }: ISaveButtonProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const detail = useSelector((state: RootState) => state.notice.detail);
  const { hasContent } = useAceEditor();

  const shouldCheckDate = useMemo(() => detail.isShowDisplayDate, [detail]);
  const isValidDateInput = useMemo(
    () =>
      shouldCheckDate
        ? detail.displayBeginDate &&
          detail.displayEndDate &&
          !isBefore(
            new Date(detail.displayEndDate),
            new Date(detail.displayBeginDate),
          )
        : true,
    [detail, shouldCheckDate],
  );
  const isEmptyTitle = useMemo(() => !detail.title, [detail.title]);
  const isEmptyInput = useMemo(
    () => !(hasContent && detail.noticeClassifyCode),
    [detail.noticeClassifyCode, hasContent],
  );

  const buttonText = useMemo(() => (editMode ? '수정' : '등록'), [editMode]);

  const onClickSave = useCallback(() => {
    if (isEmptyTitle) {
      dispatch(openAlert('NOTICE_INPUT_TITLE_EMPTY'));

      return;
    }
    if (isEmptyInput) {
      dispatch(openAlert('NOTICE_INPUT_CONTENT_EMPTY'));

      return;
    }
    if (!isValidDateInput) {
      dispatch(openAlert('NOTICE_INVALID_DATE'));

      return;
    }

    const postBody: IPostNoticeDetailParam = {
      storeNo,
      content: detail.editorContents?.editorTemplateContent ?? '',
      isPeriodOn: shouldCheckDate,
      displayBeginDate: shouldCheckDate ? detail.displayBeginDate : undefined,
      displayEndDate: shouldCheckDate ? detail.displayEndDate : undefined,
      editorJson: detail.editorContents?.editorTemplateJson ?? '',
      isImportant: detail.isImportant,
      noticeClassifyCode: detail.noticeClassifyCode ?? '',
      title: detail.title,
    };
    if (editMode) {
      dispatch(
        putNoticeDetailAsync.request({
          ...postBody,
          noticeNo: detail.noticeNo,
        }),
      );
    } else {
      dispatch(postNoticeDetailAsync.request(postBody));
    }
    history.push('/notice');
  }, [
    detail.displayBeginDate,
    detail.displayEndDate,
    detail.editorContents,
    detail.isImportant,
    detail.noticeClassifyCode,
    detail.noticeNo,
    detail.title,
    dispatch,
    editMode,
    history,
    isEmptyInput,
    isEmptyTitle,
    isValidDateInput,
    shouldCheckDate,
    storeNo,
  ]);

  return (
    <>
      <button
        type="button"
        onClick={onClickSave}
        className="btn-style__3 btn-size__2"
        data-log-actionid-area="notice"
        data-log-actionid-label="registration"
      >
        {buttonText}
      </button>
    </>
  );
};

export default SaveButton;
