import classNames from 'classnames';
import React from 'react';

import { TextInputProps } from './TextInput';

interface IProps extends TextInputProps {
  label: string;
  onButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const TextInputWithLabel = ({
  value,
  title,
  onChange,
  placeholder,
  width,
  label,
  disabled,
  onButtonClick,
}: IProps) => {
  return (
    <div className={classNames('c-input', width)}>
      <div className="input-text">
        <div className="input_in">
          <label htmlFor={title} className="lab">
            {label}
          </label>
          <input
            type="text"
            id={title}
            className="inp"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
      {onButtonClick && (
        <button
          type="button"
          className="btn-style__1 btn-size__3"
          onClick={onButtonClick}
        >
          검색
        </button>
      )}
    </div>
  );
};

export default TextInputWithLabel;
