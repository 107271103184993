import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

const HeaderNameButton = () => {
  const { storeName, storeID } = useSelector(
    (state: RootState) => state.user.headerInfo,
  );

  return (
    <span>
      <a
        href={`//shop.11st.co.kr/m/${storeID}`}
        target="_blank"
        rel="noopener noreferrer"
        className="info_title"
        data-log-actionid-area="seller"
        data-log-actionid-label="name"
      >
        {storeName}
      </a>
    </span>
  );
};

export default HeaderNameButton;
