import { RootState } from 'modules';
import { AsyncTaskType, resetAsyncTask } from 'modules/asyncTask';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useAsyncTaskStatus = (action: string): [AsyncTaskType, () => void] => {
  const status =
    useSelector((state: RootState) => state.asyncTask[action]) || 'IDLE';
  const dispatch = useDispatch();
  const reset = useCallback(() => {
    dispatch(resetAsyncTask(action));
  }, [action, dispatch]);

  return [status, reset];
};

export default useAsyncTaskStatus;
