import TextInput from 'components/common/TextInput';
import { RootState } from 'modules';
import {
  getSpecialListAsync,
  SelectedRadioType,
  setExhibitionNumberInput,
} from 'modules/special';
import React, { createElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableBody from './TableBody';
import TableHeader from './TableHeader';
import Title from './Title';

const SelectBySellerBody = () => {
  return (
    <div className="table-wrap">
      <table className="table">
        <caption>기획전 목록</caption>
        <colgroup>
          <col width="40px" />
          <col width="90px" />
          <col />
          <col width="80px" />
          <col width="100px" />
          <col width="100px" />
        </colgroup>
        <TableHeader />
        <TableBody />
      </table>
    </div>
  );
};

const SelectByNumberBody = () => {
  const dispatch = useDispatch();
  const exhibitionNumberInput = useSelector(
    (state: RootState) => state.special.exhibitionNumberInput,
  );
  const exhibitionNumberError = useSelector(
    (state: RootState) => state.special.exhibitionNumberError,
  );
  const textInputStyle = exhibitionNumberError ? 'waring' : 'size__2';

  return (
    <div className="event_number_search">
      <h4 className="skip" id="ar-eventInquiry2">
        기획전 번호 입력
      </h4>
      <p className="text">
        11번가와 함께 행사를 진행하는 경우, 11번가 기획전을 스토어에도
        등록해보세요.
      </p>
      <TextInput
        width={textInputStyle}
        title="타이틀"
        value={exhibitionNumberInput}
        placeholder="기획전 번호를 입력해주세요"
        onChange={e => dispatch(setExhibitionNumberInput(e.target.value))}
        innerChildren={
          <p className="input-message">*기획전 번호를 다시 확인해주세요.</p>
        }
      />
    </div>
  );
};

const components: { [index in SelectedRadioType]: () => JSX.Element } = {
  SELLER: SelectBySellerBody,
  NUMBER: SelectByNumberBody,
};

const SelectSpecial = () => {
  const dispatch = useDispatch();
  const addSpecialSelectedRadio = useSelector(
    (state: RootState) => state.special.addSpecialSelectedRadio,
  );

  useEffect(() => {
    dispatch(getSpecialListAsync.request('ON_AND_READY_FOR'));
  }, [dispatch]);

  return (
    <div className="dialog-body event_inquiry_box">
      <Title />
      <div
        role="tabpanel"
        aria-labelledby="ar-eventInquiry2"
        id="tabEventInquiry2"
        className="tab_02"
      />
      {createElement(components[addSpecialSelectedRadio])}
    </div>
  );
};

export default SelectSpecial;
