import { RefObject, useCallback, useEffect } from 'react';

function useClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (event: Event) => void,
  element: Node = document,
) {
  const listener: EventListener = useCallback(
    (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    },
    [handler, ref],
  );

  useEffect(() => {
    element.addEventListener('mousedown', listener);

    return () => {
      element.removeEventListener('mousedown', listener);
    };
  }, [ref, handler, listener, element]);
}

export default useClickOutside;
