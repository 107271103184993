/* eslint-disable no-param-reassign */
import { useCallback, useRef, useState } from 'react';

/**
 * { onDragStart, onDragOver, onDragEnd, onDragLeave, onDrop, dropTargetIndex, dropOrder }
 */
export interface IUseDrag {
  onDragStart: (index: number) => void;
  onDragOver: (e: React.DragEvent, index: number) => void;
  onDragEnd: (e: React.DragEvent) => void;
  onDragLeave: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent) => void;
  dragTargetIndex?: number;
  dropTargetIndex: number;
  dropOrder: number;
}
const INIT_ORDER = 0;
const INIT_INDEX = -1;
function useDrag<T>(
  list: T[],
  onChangeList: (newList: T[]) => void,
  isVertical: boolean = true,
): IUseDrag {
  const [dragTargetIndex, setDragTarget] = useState<number>(INIT_INDEX);
  const [dropTargetIndex, setDropTarget] = useState<number>(INIT_INDEX);
  const [dropOrder, setDropOrder] = useState<number>(INIT_ORDER);
  const memoized = useRef({
    order: INIT_ORDER,
    index: INIT_INDEX,
  });

  const onDragStart = useCallback((index: number) => {
    setDragTarget(index);
  }, []);
  const onDragOver = useCallback(
    (e: React.DragEvent, index: number) => {
      const target = e.target as HTMLElement;
      const bounds = (target as Element).getBoundingClientRect();
      const ratio = isVertical
        ? (e.clientY - bounds.top) / bounds.height
        : (e.clientX - bounds.left) / bounds.width;

      const order = ratio < 0.5 ? 0 : 1;
      setDropOrder(order);
      setDropTarget(index);

      memoized.current.order = order;
      memoized.current.index = index;

      e.stopPropagation();
      e.preventDefault();
    },
    [isVertical],
  );
  // eslint-disable-next-line no-unused-vars
  const onDragFinish = useCallback((e: React.DragEvent) => {
    setDropTarget(INIT_INDEX);
  }, []);
  const onDragEnd = useCallback(
    (e: React.DragEvent) => {
      setDragTarget(INIT_INDEX);
      onDragFinish(e);
    },
    [onDragFinish],
  );
  const onDragLeave = useCallback(
    (e: React.DragEvent) => {
      onDragFinish(e);
    },
    [onDragFinish],
  );
  const onDrop = useCallback(
    (e: React.DragEvent) => {
      const dropIndex = dropTargetIndex + dropOrder;

      if (dropIndex >= 0 && dropIndex !== dragTargetIndex) {
        const newList = [...list];
        const el = newList.splice(dragTargetIndex, 1);
        newList.splice(
          dropIndex <= dragTargetIndex ? dropIndex : dropIndex - 1,
          0,
          ...el,
        );
        onChangeList(newList);
      }
      onDragFinish(e);
    },
    [
      dragTargetIndex,
      dropOrder,
      dropTargetIndex,
      list,
      onChangeList,
      onDragFinish,
    ],
  );

  return {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDragLeave,
    onDrop,
    dragTargetIndex,
    dropTargetIndex,
    dropOrder,
  };
}

export default useDrag;
