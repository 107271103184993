import config from 'apis/endpoint.config';
import httpClient, { httpRequest } from 'apis/httpClient';
import download from 'downloadjs';
import { IPostPrivateDomainParam, IPutPrivateDomainParam } from 'modules/user';

export interface IGetPageViewDownload {
  storeNo: string;
}

export interface IGetPageViewCounts {
  storeNo: string;
}

export interface IPutUserBasicInfoParam {
  storeNo: string;
  putUserBasicInfoBody: IPutUserBasicInfoBody;
}
export interface IPutUserBasicInfoBody {
  closedSaleDescription: string;
  elevenStreetDomainUrl: string;
  instagramAddress: string;
  introduceContents: string;
  locationInfoImageUrl: string;
  buildingManageNo: string;
  storeDetailAddress: string;
  profileImageUrl: string;
  roadShopAreaNo: number;
  storeStyleTagNoList: number[];
  useElevenStreetDomain: boolean;
  useStorePersonalDomain: boolean;
  storePersonalDomainUrl: string;
  useOfflineShopInfo: boolean;
  useRoadShop: boolean;
  weekDaySaleBeginTime: string;
  weekDaySaleEndTime: string;
  weekEndSaleBeginTime: string;
  weekEndSaleEndTime: string;
}

export interface IPutStoreNameParam {
  storeNo: string;
  storeName: string;
}
export interface IPutStoreUrlParam {
  storeNo: string;
  storeID: string;
}

export const getHeaderInfo = () => httpClient(config.user.headerInfo());
export const getUserBasicInfo = ({ storeNo }: { storeNo: string }) =>
  httpClient(config.user.basicInfo({ storeNo }));
export const putUserBasicInfo = ({
  storeNo,
  putUserBasicInfoBody,
}: IPutUserBasicInfoParam) =>
  httpClient(
    config.user.putBasicInfo({ storeNo }),
    'PUT',
    putUserBasicInfoBody,
  );
export const putStoreName = ({ storeName, storeNo }: IPutStoreNameParam) =>
  httpClient(config.user.putStoreName({ storeNo }), 'PUT', storeName);
export const putStoreUrl = ({ storeNo, storeID }: IPutStoreUrlParam) =>
  httpClient(config.user.putStoreUrl({ storeNo }), 'PUT', storeID);
export const postPrivateDomain = ({
  storeNo,
  ...restParams
}: IPostPrivateDomainParam) =>
  httpRequest(config.user.savePrivateDomain({ storeNo }), 'POST', restParams);
export const putPrivateDomain = ({
  storeNo,
  ...restParams
}: IPutPrivateDomainParam) =>
  httpRequest(config.user.savePrivateDomain({ storeNo }), 'PUT', restParams);
export const deletePrivateDomain = ({
  storeNo,
  ...restParams
}: IPostPrivateDomainParam) =>
  httpClient(config.user.savePrivateDomain({ storeNo }), 'DELETE', restParams);
export const getRoadShopList = () => httpClient(config.user.roadShop());
export const getTagList = () => httpClient(config.user.tags());

export const getPageViewDownload = (param: IGetPageViewDownload) =>
  fetch(config.user.pageViewDownload(param), {
    method: 'GET',
    credentials: 'include' as const,
    headers: {
      'Content-Type': 'application/vnd.ms-excel',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
  })
    .then(res => {
      if (res.status === 200) {
        return res.blob();
      }

      return null;
    })
    .then(body => {
      if (body) download(body, 'pageView.xls', 'application/octet-stream');
    });

export const getPageViewCounts = (param: IGetPageViewCounts) =>
  httpClient(config.user.pageViewCounts(param));

export const get11TalkAgreements = () =>
  httpRequest(config.talk11.agreements());

export const post11TalkAgreements = () =>
  httpRequest(config.talk11.agreements(), 'POST', {
    used: true,
    agreements: true,
  });
