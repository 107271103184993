import { ActionType, createReducer } from 'typesafe-actions';
import { isShowNoticeDisplayDate } from 'utils';
import { IEditorContents } from 'utils/editor';

import * as actions from './actions';

export interface INoticeDetailType {
  noticeClassifyName: string;
  noticeClassifyCode?: string;
}

export interface INoticeItem extends INoticeDetailType {
  isShowDisplayDate: boolean;
  createDate: string;
  displayBeginDate: string;
  displayEndDate: string;
  isImportant: boolean;
  noticeNo: string;
  storeNo: number;
  title: string;
  usable: boolean;
  editorContents: IEditorContents;
}

interface INotice {
  data: INoticeItem[];
  detail: INoticeItem;
}

const initialEditorContentsState = {
  editorTemplateContent: '',
  editorTemplateJson: '',
};

const initialDetailState = {
  isShowDisplayDate: false,
  createDate: '',
  displayBeginDate: '',
  displayEndDate: '',
  isImportant: false,
  noticeClassifyName: '',
  noticeNo: '',
  storeNo: -1,
  title: '',
  usable: false,
  noticeClassifyCode: '',
  editorContents: { ...initialEditorContentsState },
};

const initialState: INotice = {
  data: [],
  detail: { ...initialDetailState },
};

export default createReducer<INotice, ActionType<typeof actions>>(
  initialState,
  {
    '@NOTICE/LIST/SUCCESS': (state, action) => ({
      ...state,
      data: action.payload,
    }),
    '@NOTICE/DETAIL/SUCCESS': (state, action) => {
      const payload = action.payload?.[0] ?? {};

      return {
        ...state,
        detail: {
          ...state.detail,
          ...payload,
          isShowDisplayDate: isShowNoticeDisplayDate(payload?.displayEndDate),
          editorContents: {
            editorTemplateContent: payload?.content ?? '',
            editorTemplateJson: payload?.editorJson ?? '',
          },
        },
      };
    },
    '@NOTICE/DETAIL/RESET': state => ({
      ...state,
      detail: {
        ...initialDetailState,
        editorContents: { ...initialEditorContentsState },
      },
    }),
    '@NOTICE/DETAIL/TYPE': (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        ...action.payload,
      },
    }),
    '@NOTICE/DETAIL/IMPORTANT': (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        isImportant: action.payload,
      },
    }),
    '@NOTICE/DETAIL/TITLE': (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        title: action.payload,
      },
    }),
    '@NOTICE/DETAIL/CONTENTS': (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        editorContents: action.payload,
      },
    }),
    '@NOTICE/DETAIL/SHOW/DATE': (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        isShowDisplayDate: action.payload,
      },
    }),
    '@NOTICE/DETAIL/START/DATE': (state, action) => ({
      ...state,
      detail: {
        ...state.detail,
        displayBeginDate: action.payload,
      },
    }),
    '@NOTICE/DETAIL/END/DATE': (state, action) => ({
      ...state,
      detail: { ...state.detail, displayEndDate: action.payload },
    }),
  },
);
