/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import React from 'react';

export enum RadioType {
  basic = 'radio-style__1',
  bold = 'radio-style__2',
  color = 'radio-style__11',
}

interface RadioProps {
  /** basic, bold, color */
  type: RadioType;
  /** 라디오 버튼 식별자 */
  name: string;
  /** 라디오 라벨 */
  label?: string;
  /** 라디오 값 */
  value: string;
  /** 라디오 선택 여부 */
  checked: boolean;
  /** 비활성화 여부 */
  disabled?: boolean;
  /** 라벨 숨기기 여부 */
  labelHidden?: boolean;
  /** 클릭했을 때 실행되는 함수 */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  role?: string;
  id?: string;
}

export interface DefaultRadioProps extends RadioProps {
  type: RadioType.basic | RadioType.bold;
  label: string;
  colorStyle?: undefined;
}

export interface ColorRadioProps extends RadioProps {
  type: RadioType.color;
  label?: undefined;
  /** 라디오에 적용될 CSS */
  colorStyle: React.CSSProperties;
}

/**
 * 라디오 기본 컴포넌트입니다.
 *
 * - `basic`, `bold` 타입은 `label`이 필수값이고 `colorStyle`을 받지 않습니다.
 * - `color` 타입은 `colorStyle`이 필수값입니다.
 */
const Radio = ({
  name,
  type,
  value,
  label,
  checked,
  labelHidden,
  disabled,
  onChange,
  colorStyle,
  ...props
}: DefaultRadioProps | ColorRadioProps) => {
  const htmlFor = props.id ?? `${name}_${value}`;

  return (
    <div className={classNames(type, { 'label-hidden': labelHidden })}>
      <input
        type="radio"
        className="inp"
        name={name}
        value={value}
        id={`${name}_${value}`}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        {...props}
      />
      <label htmlFor={htmlFor} style={colorStyle} className="lab">
        {label}
      </label>
    </div>
  );
};

export default Radio;
