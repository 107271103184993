import { Radio, TextInput, WriteBox } from 'components/common';
import { RadioType } from 'components/common/Radio';
import React, { ChangeEvent, memo } from 'react';

interface ICategoryForm {
  categoryName: string;
  onChangeCategoryName: (event: ChangeEvent<HTMLInputElement>) => void;
  isProductConnect: boolean;
  setIsProductConnect: (isProductConnect: boolean) => void;
}

const CategoryForm = memo(
  ({
    categoryName,
    onChangeCategoryName,
    isProductConnect,
    setIsProductConnect,
  }: ICategoryForm) => {
    return (
      <div className="write-box__4 type_bg">
        <WriteBox title="카테고리명" essential>
          <TextInput
            width="wide"
            title="카테고리명"
            placeholder="1자 이상 8자 이하로 입력해주세요."
            value={categoryName}
            onChange={onChangeCategoryName}
          />
        </WriteBox>
        <WriteBox title="상품 연결" essential>
          <Radio
            type={RadioType.basic}
            name="radioConnect"
            value="0"
            checked={!isProductConnect}
            label="카테고리 연결"
            onChange={() => setIsProductConnect(false)}
          />
          <Radio
            type={RadioType.basic}
            name="radioConnect"
            value="1"
            checked={isProductConnect}
            label="개별 상품 연결"
            onChange={() => setIsProductConnect(true)}
          />
        </WriteBox>
      </div>
    );
  },
);

export default CategoryForm;
