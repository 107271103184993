import { RootState } from 'modules';
import { RecommendMenuIdType } from 'modules/recommend';
import React from 'react';
import { useSelector } from 'react-redux';

import Product from './Product';
import Promotion from './Promotion';

const getDescription = (type: string): string => {
  if (type === '01')
    return '카드뷰 이미지가 등록된 상품을 최대 3개까지 등록할 수 있습니다.';
  if (type === '02')
    return '11번가 앱에서 자동재생으로 설정해놓은 고객들은 영상이 자동재생됩니다. 단, PC에서는 동영상 컬렉션이 노출되지 않습니다.';

  return '프로모션 관리 메뉴에서 등록한 스토어 프로모션을 등록할 수 있으며, 동영상이 등록된 상품을 움직이는 이미지로 제공합니다.';
};

const getDataPanel = (id: RecommendMenuIdType, type: string) => {
  switch (type) {
    case '01':
    case '02':
      return <Product id={id} />;
    case '03':
      return <Promotion id={id} />;
    default:
      return null;
  }
};

interface IProps {
  id: RecommendMenuIdType;
}

const RecommendDataPanel = ({ id }: IProps) => {
  const type = useSelector((state: RootState) => state.recommend[id].type);

  return (
    <>
      <p className="text_guide">{getDescription(type)}</p>
      {getDataPanel(id, type)}
    </>
  );
};

export default RecommendDataPanel;
