import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

import ProfileItem from './ProfileItem';

const ProfileList = () => {
  const data = useSelector((state: RootState) => state.profile.data);

  return (
    <>
      {data.map(({ id }, index) => (
        <ProfileItem key={id} id={id} index={index} />
      ))}
    </>
  );
};

export default ProfileList;
