import React from 'react';

const Footer = () => {
  return (
    <footer className="footer-box">
      <ul className="footer-box__link">
        <ul className="link_in">
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/commons/CommonAbout.tmall?method=corp1_1"
              target="_blank"
            >
              {'회사소개 '}
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/annc/AnncMainPreview.tmall?method=getProvision&amp;anncCd=05"
              target="_blank"
            >
              이용약관
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/annc/AnncMainPreview.tmall?method=getProvision&amp;anncCd=06"
              target="_blank"
            >
              전자금융거래약관
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/annc/AnncMainPreview.tmall?method=getProvision&amp;anncCd=03"
              target="_blank"
            >
              개인정보처리방침
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/commons/CommonAbout.tmall?method=corp1_2"
              target="_blank"
            >
              입점문의
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/safety/SafetyMain.tmall"
              target="_blank"
            >
              안전거래센터
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//i.011st.com/ui_img/cm_display/2017/02/SO/201-02/so-201.html"
              target="_blank"
            >
              상품정보제공고시 가이드
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              href="//www.11st.co.kr/html/Servicemap.html"
              target="_blank"
            >
              서비스맵
            </a>
          </li>
        </ul>
      </ul>
      <div className="footer-box__info">
        <div className="logo">
          <span className="logo-11st">11st</span>
        </div>
        <dl className="info">
          <dt>11번가(주)</dt>
          <dd className="tel">
            {'1599-0110 '}
            <span className="col">운영시간 08:00~22:00</span>
          </dd>
          <dd>
            (04637) 서울특별시 중구 한강대로 416 (남대문로5가, 서울스퀘어)
          </dd>
          <dd>
            {'대표이사: 하형일, 안정은 '}
            <span className="col">Fax: 02-849-4962</span>
          </dd>
          <dd>
            {'사업자등록번호: 815-81-01244 '}
            <span className="col">통신판매업신고: 2018-서울중구-1445</span>
          </dd>
        </dl>
        <dl className="info">
          <dt>판매자 서비스센터</dt>
          <dd className="tel">
            {'1599-5115 '}
            <span className="col">운영시간 08:00~22:00</span>
          </dd>
          <dd>(08378) 서울특별시 구로구 디지털로 306 (구로동)</dd>
          <dd>Fax: 02-849-4962</dd>
          <dd>
            {'E-mail: '}
            <a
              rel="noopener noreferrer"
              href="mailto:customerservice@11st.co.kr"
              target="_blank"
            >
              customerservice@11st.co.kr
            </a>
          </dd>
        </dl>
        <dl className="info">
          <dt>전자금융거래분쟁담당</dt>
          <dd className="tel">1599-0110</dd>
          <dd>Fax: 02-849-4962</dd>
          <dd>
            {'E-mail: '}
            <a
              rel="noopener noreferrer"
              href="mailto:customerservice@11st.co.kr"
              target="_blank"
            >
              customerservice@11st.co.kr
            </a>
          </dd>
        </dl>
      </div>
      <div className="footer-box_service">
        <div className="service_in">
          <a
            rel="noopener noreferrer"
            href="//www.11st.co.kr/browsing/MembershipBenefitPlace.tmall?method=getCompensationBenefit&addCtgrNo=952005"
            target="_blank"
            className="service_link"
          >
            최저가110%보상제
          </a>
          <a
            rel="noopener noreferrer"
            href="//www.11st.co.kr/browsing/MembershipBenefitPlace.tmall?method=getCompensationBenefit&addCtgrNo=952020&tabIdx=2"
            target="_blank"
            className="service_link"
          >
            위조품110%보상제
          </a>
          <a
            rel="noopener noreferrer"
            href="//www.11st.co.kr/browsing/MembershipBenefitPlace.tmall?method=getCompensationBenefit&addCtgrNo=952021&tabIdx=3"
            target="_blank"
            className="service_link"
          >
            고객실수 보상 서비스
          </a>
          <a
            rel="noopener noreferrer"
            href="//cs.11st.co.kr/page/seller"
            target="_blank"
            className="service_link"
          >
            판매자고객센터
          </a>
        </div>
      </div>
      <div className="footer-box_copy">
        <p className="txt">
          11번가(주)는 통신판매중개자로서 오픈마켓 11번가의 거래당사자가 아니며,
          입점판매자가 등록한 상품정보 및 거래에 대해 11번가(주)는 일체 책임을
          지지 않습니다.
        </p>
        <p className="copy">
          Copyright © 2019 11Street Co.,Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
