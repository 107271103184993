import { RootState } from 'modules';
import { setSortable, setTouched } from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AlertBottom from '.';

const MoveTabWithoutSaving = () => {
  const history = useHistory();

  const id = useSelector((state: RootState) => state.alert.id);
  const dispatch = useDispatch();

  return (
    <AlertBottom
      cancelText="취소"
      confirmText="확인"
      confirmAction={() => {
        if (id === 'sortable') {
          dispatch(setSortable(true));
        } else {
          history.push(id);
        }
        dispatch(setTouched(false));
        if (id === '/display/home') {
          dispatch(setSortable(false));
        }
      }}
    />
  );
};

export default MoveTabWithoutSaving;
