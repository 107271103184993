import React from 'react';

interface ICalendarSelectBox {
  data: (string | number)[];
  selected: string | number;
  nameFormatter?: string;
  handleChange: (e: number) => void;
}

const CalendarSelectBox = ({
  selected,
  data,
  nameFormatter = '',
  handleChange,
}: ICalendarSelectBox) => {
  const onSelect = (e: React.ChangeEvent<HTMLSelectElement>) =>
    handleChange(Number(e.target.value));

  return (
    <div className="select-box">
      <select className="select" onChange={onSelect}>
        {data.map(v => (
          <option
            selected={String(selected) === String(v)}
            value={String(v)}
            key={v}
          >
            {`${v}${nameFormatter}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CalendarSelectBox;
