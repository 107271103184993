import config from 'apis/endpoint.config';
import httpClient from 'apis/httpClient';

export interface IGetPromotionListParam {
  storeNo: string;
  status: string;
}

export interface IGetPromotionItemParam {
  storeNo: string;
  promotionNo: string;
}

export interface IPutPromotionExpiresParam {
  storeNo: string;
  promotionNo: string;
}

export interface IPromotionProductMin {
  orderSeq: number;
  productNo: number;
}

export interface IPostPromotionDetail {
  storeNo: string;
  startDate: string;
  endDate: string;
  title: string;
  subTitle: string;
  promotionProducts: IPromotionProductMin[];
}

export interface IPutPromotionDetail {
  storeNo: string;
  promotionNo: string;
  startDate: string;
  endDate: string;
  title: string;
  subTitle: string;
  promotionProducts: IPromotionProductMin[];
}

export const getPromotionList = (param: IGetPromotionListParam) =>
  httpClient(config.promotion.promotionList(param));

export const getPromotionItem = (param: IGetPromotionItemParam) =>
  httpClient(config.promotion.promotionItem(param));

export const putPromotionExpires = (param: IPutPromotionExpiresParam) =>
  httpClient(config.promotion.expiresPromotion(param), 'PUT');

export const postPromotionDetail = ({
  promotionProducts,
  startDate,
  endDate,
  title,
  subTitle,
  storeNo,
}: IPostPromotionDetail) =>
  httpClient(
    config.promotion.createPromotion({
      startDate,
      endDate,
      title,
      subTitle,
      storeNo,
    }),
    'POST',
    {
      startDate,
      endDate,
      title,
      subTitle,
      storeNo,
      promotionProducts,
      using: true,
    },
  );

export const putPromotionDetail = ({
  storeNo,
  promotionNo,
  startDate,
  endDate,
  title,
  subTitle,
  promotionProducts,
}: IPutPromotionDetail) =>
  httpClient(
    config.promotion.modifyPromotion({
      storeNo,
      promotionNo,
      startDate,
      endDate,
      title,
      subTitle,
    }),
    'PUT',
    { endDate, startDate, title, subTitle, using: true, promotionProducts },
  );
