import useToast from 'hooks/useToast';
import { RootState } from 'modules';
import { setCustomCategory } from 'modules/category';
import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import useSaveCategoryOrder from './Edit/useSaveCategoryOrder';
import useCategoryApplied from './useCategoryApplied';

const appRoot = document.getElementById('root');
const el = document.createElement('div');
el.className = 'st-bottom';

export const STORE_APPLY_BUTTON = 'STORE_APPLY_BUTTON';
const StoreApplyButton = ({
  done,
  isShowDoneToast,
}: {
  done?: boolean;
  isShowDoneToast?: boolean;
}) => {
  const dispatch = useDispatch();

  const customCategory = useSelector(
    (state: RootState) => state.category.custom,
  );
  const isUsingCustom = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );

  const [isShowErrorToast, showErrorToast] = useToast();
  const { applyButtonText, applyButtonDisabled } = useCategoryApplied();
  const { applyCustomCategory } = useSaveCategoryOrder();

  const onClickApplyButton = useCallback(() => {
    if (done) {
      return;
    }
    if (applyButtonDisabled) {
      return;
    }

    if (isUsingCustom && customCategory.length < 4) {
      showErrorToast();

      return;
    }

    if (!isUsingCustom) {
      dispatch(setCustomCategory([]));
    }

    applyCustomCategory();
  }, [
    applyButtonDisabled,
    applyCustomCategory,
    customCategory.length,
    dispatch,
    done,
    isUsingCustom,
    showErrorToast,
  ]);

  useEffect(() => {
    const stContent = document.getElementById('st-content');
    // eslint-disable-next-line no-unused-expressions
    stContent?.parentNode?.insertBefore(el, stContent?.nextSibling);

    return () => {
      // eslint-disable-next-line no-unused-expressions
      appRoot?.firstChild?.removeChild?.(el);
    };
  });

  return ReactDOM.createPortal(
    <>
      {isShowErrorToast && (
        <p className="st-msg__guide bottom guide-type__2">
          스토어 카테고리는 4개 이상일 경우만 사용할 수 있습니다.
        </p>
      )}
      {done && isShowDoneToast && (
        <p className="st-msg confirm">저장되었습니다.</p>
      )}
      <button
        id={STORE_APPLY_BUTTON}
        type="submit"
        onClick={onClickApplyButton}
        className="btn-style__3 btn-size__10"
        disabled={applyButtonDisabled}
        data-log-actionid-area="store"
        data-log-actionid-label="apply"
        data-log-body={JSON.stringify({
          btn_name: isUsingCustom ? '스토어카테고리 직접관리' : '기본카테고리',
        })}
      >
        {applyButtonText}
      </button>
    </>,
    el,
  );
};

export default StoreApplyButton;
