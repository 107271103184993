import { getProfileInfo, putProfileInfo } from 'apis/profile';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { closeDialog } from 'modules/dialog';
import { IDisplayProduct } from 'modules/product';
import {
  addProfileItem,
  addProfileProductFromSearchResult,
  addProfilePromotionFromSearchResult,
  clearProfileList,
  getProfileInfoAsync,
  IProfileInfoResponse,
  putProfileInfoAsync,
  setProfileItemImageUrl,
  setProfileItemNo,
  setProfileItemStatusCode,
  setProfileItemSubTitle,
  setProfileItemTitle,
  setProfileType,
} from 'modules/profile';
import {
  getPromotionItemAsync,
  IPromotionDetail,
  IPromotionItem,
} from 'modules/promotion';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getPromotionItemSaga } from 'sagas/promotion';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';
import { INPUT_REGEX } from 'utils/validate';

export function* addProfilePromotionFromSearchResultSaga() {
  const data: IPromotionItem = yield select(
    (state: RootState) => state.promotion.selectPromotion,
  );
  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const id = yield select((state: RootState) => state.dialog.id);
  yield getPromotionItemSaga(
    getPromotionItemAsync.request({ storeNo, promotionNo: data.promotionNo }),
  );

  const { liveProductList }: IPromotionDetail = yield select(
    (state: RootState) => state.promotion.promotionDetail,
  );
  if (liveProductList.length === 0) {
    yield put(openAlert('SET_PROFILE_DATA_WITH_INVALID_PROMOTION'));
  } else {
    yield all([
      put(setProfileItemNo(data.promotionNo, id)),
      put(setProfileItemTitle(data.title, id)),
      put(setProfileItemSubTitle(data.subTitle, id)),
      put(setProfileItemStatusCode('0', id)),
      put(closeDialog()),
    ]);
  }
}

export function* addProfileProductFromSearchResultSaga() {
  const [data]: IDisplayProduct[] = yield select(
    (state: RootState) => state.product.currentSelectedProducts,
  );
  const id = yield select((state: RootState) => state.dialog.id);

  if (data) {
    yield all([
      put(setProfileItemNo(String(data.productNo), id)),
      put(setProfileItemImageUrl(data.originalImageUrl, id)),
      put(
        setProfileItemTitle(
          data.productName.replace(INPUT_REGEX.notBasic, ''),
          id,
        ),
      ),
      put(setProfileItemStatusCode('0', id)),
    ]);
  }
}

export const getProfileInfoSaga = createAsyncSaga(
  getProfileInfoAsync,
  getProfileInfo,
);

export function* getProfileInfoSuccessSaga(
  action: PayloadAction<
    '@DISPLAY/PROFILE/GET_INFO/SUCCESS',
    IProfileInfoResponse[]
  >,
) {
  yield put(clearProfileList());
  if (action.payload.length > 0) {
    yield put(setProfileType(action.payload[0].imageViewTypeCode));
    yield all(
      action.payload.map((data, index) =>
        put(
          addProfileItem({
            id: `${data.displayPriority}${index}`,
            type: data.profileTargetCode,
            imageUrl: data.moduleImageUrl,
            title: data.moduleTitle || '',
            subTitle: data.moduleSubTitle || '',
            itemNo: data.moduleObjectValue,
            statusCode: data.profileValueStatusCode,
          }),
        ),
      ),
    );
  } else {
    yield put(setProfileType('SQUARE'));
    yield put(
      addProfileItem({
        id: Date.now().toString(),
        type: 'IMAGE',
        title: '',
        subTitle: '',
        imageUrl: '',
        statusCode: '0',
      }),
    );
  }
}

export const putProfileInfoSaga = createAsyncSaga(
  putProfileInfoAsync,
  putProfileInfo,
);

export default function* profileSaga() {
  yield takeLatest(
    getType(addProfilePromotionFromSearchResult),
    addProfilePromotionFromSearchResultSaga,
  );
  yield takeLatest(
    getType(addProfileProductFromSearchResult),
    addProfileProductFromSearchResultSaga,
  );
  yield takeLatest(getType(getProfileInfoAsync.request), getProfileInfoSaga);
  yield takeLatest(
    getType(getProfileInfoAsync.success),
    getProfileInfoSuccessSaga,
  );
  yield takeLatest(getType(putProfileInfoAsync.request), putProfileInfoSaga);
}
