import { RootState } from 'modules';
import {
  getNoticeDetailAsync,
  resetNoticeDetail,
} from 'modules/notice/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IUseSetNoticeDetail {
  isUpdate: boolean;
  noticeNo: string;
}
const useGetNoticeDetail = ({
  isUpdate = false,
  noticeNo,
}: IUseSetNoticeDetail) => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  const initNoticeDetail = useCallback(() => {
    dispatch(resetNoticeDetail());
  }, [dispatch]);

  const fetchNoticeDetail = useCallback(() => {
    if (storeNo && noticeNo) {
      dispatch(getNoticeDetailAsync.request({ storeNo, noticeNo }));
    }
  }, [dispatch, noticeNo, storeNo]);

  useEffect(() => {
    if (isUpdate) {
      fetchNoticeDetail();
    }
  }, [fetchNoticeDetail, isUpdate]);

  useEffect(() => {
    return () => {
      initNoticeDetail();
    };
  }, [initNoticeDetail]);
};

export default useGetNoticeDetail;
