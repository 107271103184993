/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import useImageStyle from 'hooks/useImageStyle';
import useImageUpload from 'hooks/useImageUpload';
import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

import { UploadImage } from './components';
import useBasicInfoImageUpload from './useBasicInfoImageUpload';

const ProfileImage = () => {
  const profileImageUrl =
    useSelector(
      (reduxState: RootState) =>
        reduxState.user.basicInfo.details.profileImageUrl,
    ) ?? '';
  const [imageFileRef, onClickChangeImage] = useImageUpload();

  const { onChangeImage } = useBasicInfoImageUpload('STORE', imageFileRef);
  const imageStyle = useImageStyle(profileImageUrl);

  return (
    <div onClick={onClickChangeImage} className="cont_profile">
      {profileImageUrl ? (
        <UploadImage ref={imageFileRef} onChangeFile={onChangeImage}>
          <span className="img_wrap">
            <span className="img_in" style={imageStyle}>
              @프로필이미지
            </span>
          </span>
          <span className="btn-style__1 btn-size__7 wide">이미지 변경</span>
        </UploadImage>
      ) : (
        <UploadImage ref={imageFileRef} onChangeFile={onChangeImage}>
          <span className="btn-style__1 btn-size__7 wide">이미지 등록</span>
        </UploadImage>
      )}
    </div>
  );
};

export default ProfileImage;
