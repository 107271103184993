import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

const SaveProfileWithInValidStatusCode = () => {
  const data = useSelector((state: RootState) =>
    state.profile.data
      .map((item, index) => ({
        statusCode: item.statusCode,
        index,
      }))
      .filter(item => item.statusCode !== '0')
      .map(item => item.index + 1),
  );

  return (
    <>
      스토어 노출 불가한 대표 이미지가 설정되어 있습니다. <br />
      {data.length > 0 && `${data}번 배너를 변경해주세요.`}
    </>
  );
};

export default SaveProfileWithInValidStatusCode;
