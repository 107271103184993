import { RootState } from 'modules';
import { openDialog } from 'modules/dialog';
import {
  postPrivateDomainAsync,
  putPrivateDomainAsync,
  setPrivateDomain,
  setPrivateDomainOk,
} from 'modules/user';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DomainAlreadyUsed, TextUrl, UrlError } from './components';
import useStoreDomain from './useStoreDomain';

const DomainSettingIntro = () => {
  const dispatch = useDispatch();
  const onClickDomainSettingIntro = () => {
    dispatch(openDialog('DomainSettingIntro'));
  };

  return (
    <p className="text_info info-type__4">
      직접 구입한 도메인을 11번가 스토어와 연결하는 서비스 입니다. <br />
      개인 도메인 설정을 위해서는 먼저 도메인 호스팅 업체에서 ‘호스팅 설정’을
      해주셔야 합니다.{' '}
      <button
        type="button"
        onClick={onClickDomainSettingIntro}
        className="btn-style__1 btn-size__6"
      >
        설정방법
      </button>
    </p>
  );
};

const PrivateDomain = () => {
  const dispatch = useDispatch();
  const {
    serverSavedPrivateDomain,
    privateDomain,
    isPrivateDomainConfirmed,
    hasPrivateDomainError,
    isPrivateDomainAlreadyUsed,
    mobileStoreUrl,
    pcStoreUrl,
  } = useStoreDomain();

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  const onChangePrivateDomain = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      dispatch(setPrivateDomain(value.toLowerCase()));
      if (isPrivateDomainConfirmed) {
        dispatch(setPrivateDomainOk(false));
      }
    },
    [dispatch, isPrivateDomainConfirmed],
  );

  const onClickSave = () => {
    if (serverSavedPrivateDomain) {
      dispatch(
        putPrivateDomainAsync.request({
          storeNo,
          domainAddressAfter: privateDomain,
          domainAddressBefore: serverSavedPrivateDomain,
          mobileStoreUrl,
          pcStoreUrl,
        }),
      );
    } else {
      dispatch(
        postPrivateDomainAsync.request({
          storeNo,
          domainAddress: privateDomain,
          mobileStoreUrl,
          pcStoreUrl,
        }),
      );
    }
  };

  return (
    <>
      <DomainSettingIntro />
      <div className="c-input w-350">
        <span className="lab">PC 도메인</span>
        <div className="input-text">
          <input
            type="text"
            className="inp"
            placeholder="http:// 를 제외하고 입력해주세요."
            value={privateDomain}
            onChange={onChangePrivateDomain}
          />
        </div>
        <button
          type="button"
          onClick={onClickSave}
          className="btn-style__1 btn-size__4"
        >
          변경
        </button>
      </div>
      {isPrivateDomainConfirmed && <TextUrl isPrivate>{privateDomain}</TextUrl>}
      {hasPrivateDomainError && <UrlError />}
      {isPrivateDomainAlreadyUsed && <DomainAlreadyUsed />}
    </>
  );
};

export default PrivateDomain;
