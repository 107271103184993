import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  CATEGORY_DEFAULT,
  postCustomCategoryAsync,
  putCustomCategoryAsync,
  setCustomItemTemp,
  setShouldStoreApply,
  TYPE_CODE,
} from 'modules/category';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IUseOnClickSaveProps {
  isEditMode: boolean;
  isProductConnect: boolean;
  categoryName: string;
}

const useOnClickSave = ({
  isEditMode,
  isProductConnect,
  categoryName,
}: IUseOnClickSaveProps) => {
  const dispatch = useDispatch();
  const custom = useSelector((state: RootState) => state.category.custom);
  const selectedCustomId = useSelector(
    (state: RootState) => state.category.selectedCustomId,
  );
  const customDetail = useSelector(
    (state: RootState) => state.category.customDetail,
  );
  const customDetailModified = useSelector(
    (state: RootState) => state.category.customDetailModified,
  );

  const finalSelectedProducts = useSelector(
    (state: RootState) => state.product.finalSelectedProducts,
  );

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  const onClickSave = useCallback(() => {
    const type = isProductConnect ? TYPE_CODE.PRODUCT : TYPE_CODE.CATEGORY;
    if (categoryName === '') {
      dispatch(openAlert('INPUT_CATEGORY_NAME'));

      return;
    }
    if (categoryName.length < 2 || categoryName.length > 8) {
      dispatch(openAlert('INPUT_CATEGORY_NAME_LENGTH'));

      return;
    }
    if (
      (type === TYPE_CODE.PRODUCT && finalSelectedProducts.length === 0) ||
      (type === TYPE_CODE.CATEGORY && customDetail.length === 0)
    ) {
      dispatch(openAlert('SAVE_CATEGORY_WITHOUT_CONNECTING'));

      return;
    }

    const storeCategoryNo = selectedCustomId;
    const categoryOrder = 0;
    dispatch(
      setCustomItemTemp({
        storeCategoryName: categoryName,
        typeCode: type,
        orderSeq: categoryOrder,
        storeCategoryNo,
      }),
    );
    if (isEditMode) {
      const isTypeChanged =
        custom.find(
          ({ storeCategoryNo: cateNo }) => cateNo === selectedCustomId,
        )?.typeCode !== type;
      const values = Array.from(customDetailModified).map(
        ([key, { displayPriority, typeCode }]) => ({
          keyValue: key,
          displayPriority,
          typeCode,
        }),
      );
      dispatch(
        putCustomCategoryAsync.request({
          storeNo,
          storeCategoryName: categoryName,
          storeCategoryNo: selectedCustomId,
          isTypeChanged,
          type,
          values,
        }),
      );
    } else {
      const detailValues = isProductConnect
        ? finalSelectedProducts.map(({ productNo }, orderSeq) => ({
            keyValue: productNo,
            displayPriority: orderSeq ?? CATEGORY_DEFAULT.ORDER,
            typeCode: 'SAVE',
          }))
        : customDetail.map(({ categoryNo, orderSeq }) => ({
            keyValue: categoryNo,
            displayPriority: orderSeq ?? CATEGORY_DEFAULT.ORDER,
            typeCode: 'SAVE',
          }));

      dispatch(
        postCustomCategoryAsync.request({
          storeNo,
          storeCategoryName: categoryName,
          displayPriority: custom.length * -1,
          type,
          values: detailValues,
        }),
      );
    }
    dispatch(setShouldStoreApply(true));
  }, [
    categoryName,
    custom,
    customDetail,
    customDetailModified,
    dispatch,
    finalSelectedProducts,
    isEditMode,
    isProductConnect,
    selectedCustomId,
    storeNo,
  ]);

  return onClickSave;
};

export default useOnClickSave;
