import { RootState } from 'modules';
import { putEnableMenuAsync } from 'modules/display';
import { putProfileInfoAsync } from 'modules/profile';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertBottom from '.';

interface IProps {
  enable: boolean;
}

const ChangeMenuVisible = ({ enable }: IProps) => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.alert.id);
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const { type, data } = useSelector((state: RootState) => state.profile);

  return (
    <AlertBottom
      confirmText="확인"
      cancelText="취소"
      confirmAction={() => {
        dispatch(
          putEnableMenuAsync.request({
            storeNo,
            moduleNo: id,
            isModuleOn: enable,
          }),
        );
        // 메뉴가 노출되지 않는 상태의 요청에서 계속진행할경우 프로필 데이터 저장
        if (!enable) {
          const filteredData = data.filter(item => {
            if (item.type === 'IMAGE') return true;
            if (item.type === 'PRODUCT' || item.type === 'PROMOTION')
              return (
                item.imageUrl || item.title || item.subTitle || item.itemNo
              );

            return false;
          });
          dispatch(
            putProfileInfoAsync.request({
              storeNo,
              imageViewTypeCode: type,
              profileDataList: filteredData.map((item, index) => ({
                displayPriority: String(index + 1),
                moduleImageUrl: item.imageUrl,
                moduleTitle: item.title.substring(0, 30),
                moduleSubTitle: item.subTitle,
                profileTargetCode: item.type,
                moduleObjectValue: item.itemNo ?? '',
              })),
            }),
          );
        }
      }}
    />
  );
};

export default ChangeMenuVisible;
