import React from 'react';

interface ICategoryAddButtonProps {
  disabled: boolean;
  onClick: () => void;
}
const CategoryAddButton = ({ onClick, disabled }: ICategoryAddButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className="btn-style__1 btn-size__3"
    disabled={disabled}
  >
    <span className="icon icon-add" />
    {' 카테고리 추가'}
  </button>
);

export default CategoryAddButton;
