import useAsyncTaskStatus from 'hooks/useAsyncTaskStatus';
import { useMemo } from 'react';

const useCustomDetailLoading = (): boolean => {
  const [fetchProductAsyncActionStatus] = useAsyncTaskStatus(
    '@PRODUCT/DISPLAY/FETCH',
  );
  const [fetchProductNumbersAsyncActionStatus] = useAsyncTaskStatus(
    '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS/FETCH',
  );
  const [fetchCustomCategoryDetailasyncActionStatus] = useAsyncTaskStatus(
    '@CATEGORY/CUSTOM/DETAIL/FETCH',
  );
  const isLoading: boolean = useMemo(
    () =>
      fetchProductAsyncActionStatus === 'LOADING' ||
      fetchProductNumbersAsyncActionStatus === 'LOADING' ||
      fetchCustomCategoryDetailasyncActionStatus === 'LOADING',
    [
      fetchCustomCategoryDetailasyncActionStatus,
      fetchProductAsyncActionStatus,
      fetchProductNumbersAsyncActionStatus,
    ],
  );

  return isLoading;
};

export default useCustomDetailLoading;
