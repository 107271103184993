import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

const SaveMenuWithDisable = () => {
  const name = useSelector(
    (state: RootState) => state.display.selectedModule.name,
  );

  return (
    <>
      <strong className="color_brand">{name}</strong>
      가 사용안함으로 설정되어 있습니다.
      <br />
      사용함으로 변경하시겠습니까?
    </>
  );
};

export default SaveMenuWithDisable;
