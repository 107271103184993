import { CheckBox, WriteBox } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

import { RoadShopInfo } from './components';
import useTag from './useTag';

const RoadShopTag = ({ isRoadShopSeller }: { isRoadShopSeller: boolean }) => {
  const roads = useSelector((state: RootState) => state.user.roadShops);

  const { isTagChecked, onChangeTag } = useTag(true);

  return isRoadShopSeller ? (
    <WriteBox title="로드# 지역" essential>
      <div className="c-check__local">
        {roads.map(({ tagNo, tagName }) => {
          return (
            <CheckBox
              key={tagNo}
              id={`checkHashtag${tagNo}`}
              name="checkHashtag"
              type={CheckBoxType.keyword}
              label={tagName}
              checked={isTagChecked(tagNo)}
              onChange={() => onChangeTag({ tagNo, tagName })}
            />
          );
        })}
      </div>
      <RoadShopInfo />
    </WriteBox>
  ) : null;
};

export default RoadShopTag;
