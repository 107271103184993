import { Radio } from 'components/common';
import { RadioType } from 'components/common/Radio';
import { RootState } from 'modules';
import { setTouched } from 'modules/display';
import {
  initializeRecommendData,
  RecommendMenuIdType,
  setRecommendType,
} from 'modules/recommend';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IData {
  value: string;
  text: string;
  imageUrl: string;
  imageAlt: string;
}

const DATA: IData[] = [
  {
    value: '01',
    text: '카드뷰 컬렉션',
    imageUrl: `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template01_09cardview.png`,
    imageAlt: '카드뷰',
  },
  {
    value: '02',
    text: '동영상 컬렉션',
    imageUrl: `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template01_10movie.png`,
    imageAlt: '동영상',
  },
  {
    value: '03',
    text: '프로모션 컬렉션',
    imageUrl: `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template01_08promotion.png`,
    imageAlt: '프로모션',
  },
];

interface IProps {
  id: RecommendMenuIdType;
}

const RecommendTypeRadioGroup = ({ id }: IProps) => {
  const dispatch = useDispatch();
  const type = useSelector((state: RootState) => state.recommend[id].type);

  return (
    <ul className="view-item__select">
      {DATA.map(({ value, text, imageUrl, imageAlt }: IData) => (
        <li className="item" key={value}>
          <Radio
            type={RadioType.basic}
            label={text}
            value={value}
            name={`radioDesign${value}`}
            checked={type === value}
            onChange={() => {
              dispatch(setRecommendType(value, id));
              dispatch(setTouched(true));
              dispatch(initializeRecommendData(id));
            }}
          />
          <div className="item_cont">
            <img src={imageUrl} alt={imageAlt} />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default React.memo(RecommendTypeRadioGroup);
