import ButtonLink from 'components/common/ButtonLink';
import React from 'react';

const TabData = [
  {
    id: '1',
    text: '스토어 전시 관리',
    to: '/display',
    label: '스토어전시관리',
  },
  {
    id: '2',
    text: '프로모션 관리',
    to: '/promotion',
    label: '프로모션관리',
  },
  {
    id: '3',
    text: '쿠폰 관리',
    to: '/coupon',
    label: '쿠폰관리',
  },
  {
    id: '4',
    text: '카테고리 관리',
    to: '/category',
    label: '카테고리관리',
  },
  { id: '5', text: '스토어 공지관리', to: '/notice', label: '공지관리' },
  {
    id: '6',
    text: '스토어 기본 정보',
    to: '/basicinfo',
    label: '기본정보',
  },
];

const GNB = () => {
  return (
    <nav className="st-nav__gnb" data-log-actionid-area="main">
      <div className="gnb_in">
        <ul>
          {TabData.map(({ id, text, to, label }) => (
            <ButtonLink
              key={id}
              text={text}
              to={to}
              logLabel="menu"
              logBody={JSON.stringify({
                btn_name: label,
              })}
            />
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default GNB;
