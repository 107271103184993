import React, { useCallback, useMemo } from 'react';

interface IPagination {
  currentPage: number;
  pageSize?: number;
  total: number;
  stepSize?: number;
  onChange?: (num: number) => void;
}

const Pagination = ({
  currentPage = 1,
  pageSize = 10,
  total,
  stepSize = 5,
  onChange,
}: IPagination) => {
  const step = useMemo(() => Math.ceil(currentPage / stepSize) - 1, [
    currentPage,
    stepSize,
  ]);
  const pageLength = useMemo(() => Math.ceil(total / pageSize), [
    total,
    pageSize,
  ]);
  const hasPrevStep = useMemo(() => step > 0, [step]);
  const hasNextStep = useMemo(() => pageLength / stepSize > step + 1, [
    pageLength,
    stepSize,
    step,
  ]);
  const stepList = useMemo(
    () =>
      [...Array(pageLength < stepSize ? pageLength : stepSize)].map(
        (__, i) => step * stepSize + i + 1,
      ),
    [pageLength, stepSize, step],
  );

  const handleChangePage = useCallback(
    (num: number) => {
      if (onChange) {
        onChange(num);
      }
    },
    [onChange],
  );
  const onClickPrev = useCallback(() => {
    if (hasPrevStep) {
      const prevStep = step - 1;
      handleChangePage(prevStep * stepSize + 1);
    }
  }, [handleChangePage, hasPrevStep, step, stepSize]);
  const onClickNext = useCallback(() => {
    if (hasNextStep) {
      const nextStep = step + 1;
      handleChangePage(nextStep * stepSize + 1);
    }
  }, [handleChangePage, hasNextStep, step, stepSize]);

  return total ? (
    <div className="c-paging">
      <button
        type="button"
        onClick={onClickPrev}
        disabled={!hasPrevStep}
        className="prev"
      >
        이전 목록
      </button>
      {stepList
        .filter(num => num <= pageLength)
        .map(num =>
          currentPage === num ? (
            <em key={num}>{num}</em>
          ) : (
            <button
              key={num}
              type="button"
              onClick={() => handleChangePage(num)}
            >
              {num}
            </button>
          ),
        )}
      <button
        type="button"
        onClick={onClickNext}
        className="next"
        disabled={!hasNextStep}
      >
        다음 목록
      </button>
    </div>
  ) : null;
};

export default Pagination;
