import { RootState } from 'modules';
import { setNoticeDetailEditorContents } from 'modules/notice/actions';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getEditor, { IEditorContents, setEditorContents } from 'utils/editor';

const useAceEditor = () => {
  const dispatch = useDispatch();

  const editorContents = useSelector(
    (state: RootState) => state.notice.detail.editorContents,
  );

  const hasContent = useMemo(() => !!editorContents?.editorTemplateContent, [
    editorContents,
  ]);

  const onClickCallEditor = useCallback(() => {
    const editor = getEditor({
      onSave(content: IEditorContents) {
        dispatch(setNoticeDetailEditorContents(content));
      },
    });
    editor.open();
  }, [dispatch]);

  useEffect(() => {
    setEditorContents(editorContents);
  }, [editorContents]);

  return { hasContent, onClickCallEditor };
};

export default useAceEditor;
