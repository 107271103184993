import React from 'react';

const Talk11UsedInfo = () => (
  <div className="dialog-body">
    <ul className="text_list">
      <li>
        본 11톡 서비스의 원활한 제공을 위해 구매자님과 판매자님의 대화 내용은
        최대 3개월 동안 보관됩니다. (이후 보관 기간이 경과되면 파기됩니다.)
      </li>
      <li>
        해당 대화내용은 대화 양당사자의 동의를 구하지 않는 한 제3자에게
        열람,공개 제공되지 않습니다.
      </li>
      <li>
        동의를 하지 않으셔도 11톡 서비스를 제외한 11번가 서비스는 정상적으로
        이용이 가능합니다.
      </li>
    </ul>
  </div>
);

export default Talk11UsedInfo;
