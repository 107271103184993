import classNames from 'classnames';
import { Radio } from 'components/common';
import Pagination from 'components/common/Pagination';
import { RadioType } from 'components/common/Radio';
import TableHead from 'components/common/TableHead';
import { RootState } from 'modules';
import { getPromotionListAsync, setSelectedPromotion } from 'modules/promotion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/format';

const COLUMNS = [
  { id: 1, text: '', width: 'auto' },
  { id: 2, text: '프로모션', width: 'auto' },
  { id: 3, text: '프로모션명', width: 'auto' },
  { id: 4, text: '진행상태', width: 'auto' },
  { id: 5, text: '시작일', width: 'auto' },
  { id: 6, text: '종료일', width: 'auto' },
];
const PAGE_SIZE = 10;

const SelectPromotion = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const data = useSelector((state: RootState) => state.promotion.promotionList);
  const selected = useSelector(
    (state: RootState) => state.promotion.selectPromotion,
  );
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const storeId = useSelector(
    (state: RootState) => state.user.headerInfo.storeID,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPromotionListAsync.request({ storeNo, status: 'INPROGRESS' }));
  }, [dispatch, storeNo]);

  return (
    <div className="dialog-body">
      <div className="dialog-body__title">
        <p>현재 진행중인 프로모션만 등록할 수 있습니다.</p>
      </div>
      <div className="table-wrap">
        <table className="table">
          <caption>프로모션 목록</caption>
          <colgroup>
            <col width="40px" />
            <col width="90px" />
            <col />
            <col width="80px" />
            <col width="100px" />
            <col width="100px" />
          </colgroup>
          <TableHead columns={COLUMNS} />
          <tbody>
            {data.length > 0 ? (
              data
                .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                .map(item => (
                  <tr
                    key={item.promotionNo}
                    className={classNames({
                      active: selected?.promotionNo === item.promotionNo,
                    })}
                  >
                    <td>
                      <Radio
                        type={RadioType.basic}
                        value={item.promotionNo}
                        label={`promotionRadio${item.promotionNo}`}
                        name="promotionSelect"
                        checked={selected?.promotionNo === item.promotionNo}
                        onChange={() => {
                          dispatch(setSelectedPromotion(item));
                        }}
                        labelHidden
                      />
                    </td>
                    <td>
                      <span className="text-num">{item.promotionNo}</span>
                    </td>
                    <td>
                      <button
                        className="text-title"
                        type="button"
                        onClick={() =>
                          window.open(
                            `//shop.11st.co.kr/m/${storeId}/promotion/${item.promotionNo}`,
                            '',
                            'resizable=yes,scrollbars=yes,width=375,height=812',
                          )
                        }
                      >
                        {item.title}
                      </button>
                    </td>
                    <td>{item.promotionStatus}</td>
                    <td>
                      <span className="text-date">
                        {formatDate(item.startDate)}
                      </span>
                    </td>
                    <td>
                      <span className="text-date">
                        {formatDate(item.endDate)}
                      </span>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={6} className="non-data">
                  검색 조건에 맞는 결과가 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          pageSize={PAGE_SIZE}
          total={data.length}
          onChange={num => {
            setCurrentPage(num);
          }}
        />
      </div>
    </div>
  );
};

export default SelectPromotion;
