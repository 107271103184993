import { RootState } from 'modules';
import { putEnableMenuAsync, setEnableMenu } from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertBottom from '.';

const SaveMenuWithDisable = () => {
  const dispatch = useDispatch();
  const id = useSelector((state: RootState) => state.display.selectedModule.id);
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  return (
    <AlertBottom
      confirmText="사용함"
      cancelText="사용안함"
      confirmAction={() => {
        dispatch(setEnableMenu(true, id));
        if (id === '1')
          dispatch(
            putEnableMenuAsync.request({
              storeNo,
              moduleNo: id,
              isModuleOn: true,
            }),
          );
      }}
      cancelAction={() => {}}
    />
  );
};

export default SaveMenuWithDisable;
