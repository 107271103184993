const rakePath = '//c.011st.com/js/rake';
const rakeNewPath = '//assets.011st.com/MW/js/rake'; // 레이크로그 신규 경로 - https://11jira.11stcorp.com/browse/MPSR-141921
window.rakeLogPageInfo = {
  DeviceId: '',
  AppSessionIdXsite: '',
  _$RAKE_TOKEN: process.env.REACT_APP_RAKE_LOG_TOKEN,
  _$RAKE_TOKEN_NPI: process.env.REACT_APP_RAKE_LOG_TOKEN_NPI,
  _$RAKE_ENV: process.env.REACT_APP_RAKE_ENV,
  DataMerge: false,
  AppSessionId: '',
  Switch: {
    LogDisablePageshow: false,
    ImpPC: true,
    ImpMW: true,
    LogDisable: false,
  },
  PageInfo: {
    page_id: '',
    env: process.env.REACT_APP_RAKE_PAGE_ENV,
  },
};
const loadJS = (url: string) =>
  new Promise(resolve => {
    const script = document.createElement('script');
    script.src = url;
    script.onload = () => resolve();
    document.body.appendChild(script);
  });

export default function rakeLog() {
  loadJS(`${rakePath}/shuttle/Log11stClientSentinelShuttle-2.0.3-62.js`).then(
    () => {
      loadJS(`${rakeNewPath}/v1.2.0/rakeLog-pc.js`);
    },
  );
}

export const setPageId = (id: string) => {
  window.rakeLogPageInfo.PageInfo = {
    ...window.rakeLogPageInfo.PageInfo,
    page_id: id,
  };
  if (window.rakeLog) window.rakeLog.refresh();
};

export const isSamePageId = (id: string) =>
  window.rakeLogPageInfo.PageInfo.page_id === id;
