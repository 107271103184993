import React from 'react';

import SpecialTableBody from './SpecialTableBody';
import SpecialTableHeader from './SpecialTableHeader';

const SpecialTable = () => (
  <div className="table-wrap">
    <table className="table">
      <caption>전시순서 변경</caption>
      <colgroup>
        <col width="40px" />
        <col width="80px" />
        <col width="100px" />
        <col />
        <col width="80px" />
        <col width="100px" />
        <col width="100px" />
      </colgroup>
      <SpecialTableHeader />
      <SpecialTableBody />
    </table>
  </div>
);

export default SpecialTable;
