import useDrag from 'hooks/useDrag';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import {
  CategoryItemIdType,
  IBasicCategoryItem,
  ICustomCategoryItem,
} from 'modules/category/types';
import React, { useCallback, useEffect, useMemo } from 'react';

import CategoryAddButton from './CategoryAddButton';
import CategoryListItem from './CategoryListItem';
import MenuDepth from './MenuDepth';
import MoveButton from './MoveButton';

export interface ICategoryListItem {
  id: CategoryItemIdType;
  text: string;
  mark: boolean;
}

interface ICategoryListProps {
  title?: string;
  depth?: string;
  list?: ICategoryListItem[];
  selectedId?: CategoryItemIdType;
  firstSelected?: boolean;
  showMark?: boolean;
  onClickItem?: (id: CategoryItemIdType) => void;
  productCount?: number;
  deleteButtonDisabled?: boolean;
  rightArrow?: boolean;
  draggable?: boolean;
  editMode?: boolean;
  createMode?: boolean;
  editingText?: string;
  onChange?: (categoryList: ICategoryListItem[]) => void;
  onClickDelete?: () => void;
  onClickAddButton?: () => void;
  addButtonDisabled?: boolean;
  children?: React.ReactNode;
}

export const basicCategoryListMapper = ({
  categoryName,
  categoryNo,
  mappedCategory,
  ...props
}: IBasicCategoryItem): ICategoryListItem => ({
  ...props,
  id: categoryNo,
  text: categoryName,
  mark: !mappedCategory,
});

export const customCategoryListMapper = ({
  storeCategoryName,
  storeCategoryNo,
  ...props
}: ICustomCategoryItem): ICategoryListItem => ({
  ...props,
  id: storeCategoryNo,
  text: storeCategoryName,
  mark: false,
});
const CATEGORY_LIST_MAX_HEIGHT = 664;
const CategoryList = ({
  title = '',
  list = [],
  depth = '1',
  onClickItem = () => {},
  onClickAddButton,
  addButtonDisabled = false,
  rightArrow = false,
  selectedId = SELECTED_CUSTOM_ID.INIT,
  firstSelected = false,
  showMark = false,
  productCount = -1,
  draggable = false,
  createMode = false,
  editMode = false,
  editingText = '',
  deleteButtonDisabled = false,
  onChange = () => {},
  onClickDelete = () => {},
  children,
}: ICategoryListProps) => {
  const {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDragLeave,
    onDrop,
    dragTargetIndex,
    dropTargetIndex,
    dropOrder,
  } = useDrag(list, onChange);

  const isShowRightArrow = useMemo(() => depth === '1' && rightArrow, [
    depth,
    rightArrow,
  ]);
  const noBorder = useMemo(() => depth === '2', [depth]);
  const isProductCategory = useMemo(() => productCount >= 0, [productCount]);
  const isAddButtonDisabled = useMemo(
    () => isProductCategory || addButtonDisabled,
    [addButtonDisabled, isProductCategory],
  );
  const isInitMode = useMemo(() => selectedId === SELECTED_CUSTOM_ID.INIT, [
    selectedId,
  ]);
  const containerStyle = useMemo(
    () =>
      list.length > 13 && draggable
        ? { height: CATEGORY_LIST_MAX_HEIGHT }
        : undefined,
    [draggable, list.length],
  );

  const getText = useCallback(
    (id, text: string) => {
      if (!editMode && !createMode) {
        return text;
      }

      return selectedId === id ? editingText : text;
    },
    [createMode, editMode, editingText, selectedId],
  );
  const isActive = useCallback((id: number) => selectedId === id, [selectedId]);
  const handleSelectedId = useCallback(
    (id: CategoryItemIdType) => {
      if (onClickItem) {
        onClickItem(id);
      }
    },
    [onClickItem],
  );
  const selectFirstOnListChange = useCallback(() => {
    if (isInitMode && firstSelected && list[0]) {
      const { id } = list[0];
      handleSelectedId(id);
    }
  }, [isInitMode, firstSelected, list, handleSelectedId]);

  useEffect(() => {
    selectFirstOnListChange();
  }, [selectFirstOnListChange]);

  return (
    <>
      <MenuDepth noBorder={noBorder}>
        <div className={`menu-depth__${depth}`} style={containerStyle}>
          {draggable && onClickAddButton && (
            <div className="depth_btn">
              <CategoryAddButton
                onClick={onClickAddButton}
                disabled={addButtonDisabled}
              />
              <button
                type="button"
                className="btn-style__1 btn-size__8"
                onClick={onClickDelete}
                disabled={deleteButtonDisabled}
              >
                삭제
              </button>
            </div>
          )}
          <div className="depth_list">
            {!draggable && (
              <>
                {title && <strong className="title">{title}</strong>}
                {isProductCategory && (
                  <p className="text_item">
                    {productCount === 0 ? (
                      <>
                        판매중인 상품이 없습니다. <br />
                        상품을 먼저 등록해주세요.
                      </>
                    ) : (
                      <>
                        <em>{productCount}</em>개의 상품이 등록되어 있습니다.
                      </>
                    )}
                  </p>
                )}
              </>
            )}
            <ul className="list">
              {draggable && createMode && (
                <CategoryListItem text={editingText} active createMode />
              )}
              {list.map(({ id, text, mark }, index) => (
                <CategoryListItem
                  key={id}
                  text={getText(id, text)}
                  active={isActive(id)}
                  rightArrow={isShowRightArrow}
                  draggable={draggable}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  onDragEnd={onDragEnd}
                  onDrop={onDrop}
                  index={index}
                  dragTargetIndex={dragTargetIndex}
                  dropTargetIndex={dropTargetIndex}
                  dropOrder={dropOrder}
                  mark={showMark && mark}
                  onClick={() => handleSelectedId(id)}
                />
              ))}
            </ul>
          </div>
          {!draggable && onClickAddButton && (
            <div className="depth_btn">
              <CategoryAddButton
                onClick={onClickAddButton}
                disabled={isAddButtonDisabled}
              />
            </div>
          )}
        </div>
        {children}
      </MenuDepth>
      {draggable && (
        <MoveButton
          categoryList={list}
          selectedId={selectedId}
          onChangeList={onChange}
        />
      )}
    </>
  );
};

export default CategoryList;
