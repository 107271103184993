import { RootState } from 'modules';
import { setStoreNameInput } from 'modules/user';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStoreNameConfirm = (clickElementOnAlertConfirm: any) => {
  const dispatch = useDispatch();
  const isStoreNameConfirmed = useRef({ confirm: true });
  const storeName = useSelector(
    (state: RootState) => state.user.headerInfo.storeName,
  );

  const [
    isShowStoreNameConfirmedAlert,
    setIsShowStoreNameConfirmedAlert,
  ] = useState<boolean>(false);

  const onClickStoreNameConfirmAlert = useCallback(() => {
    setIsShowStoreNameConfirmedAlert(false);
    isStoreNameConfirmed.current.confirm = true;
    // eslint-disable-next-line no-unused-expressions
    clickElementOnAlertConfirm?.click();
    dispatch(setStoreNameInput(storeName));
  }, [clickElementOnAlertConfirm, dispatch, storeName]);
  const onClickStoreNameCancelAlert = useCallback(() => {
    setIsShowStoreNameConfirmedAlert(false);
    isStoreNameConfirmed.current.confirm = false;
  }, []);

  return {
    isStoreNameConfirmed,
    isShowStoreNameConfirmedAlert,
    setIsShowStoreNameConfirmedAlert,
    onClickStoreNameConfirmAlert,
    onClickStoreNameCancelAlert,
  };
};

export default useStoreNameConfirm;
