import { getCouponItemStatus, getCouponList } from 'apis/coupon';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  getCouponItemStatusAsync,
  getCouponListAsync,
  send11TalkCoupons,
  setSelectedCoupon,
} from 'modules/coupon';
import { openDialog } from 'modules/dialog';
import {
  get11TalkAgreementsAsync,
  post11TalkAgreementsAsync,
} from 'modules/user';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';

import { get11TalkAgreementsSaga } from './user';

export const getCouponListSaga = createAsyncSaga(
  getCouponListAsync,
  getCouponList,
);

export const getCouponItemStatusSaga = createAsyncSaga(
  getCouponItemStatusAsync,
  getCouponItemStatus,
);

export function* send11TalkCouponsSaga(
  action: PayloadAction<'@COUPON/11TALK_MESSAGE/SEND', string>,
) {
  yield get11TalkAgreementsSaga(get11TalkAgreementsAsync.request());

  const used = yield select((state: RootState) => state.user.talk11.used);

  if (used) {
    yield put(setSelectedCoupon(''));
    yield getCouponItemStatusSaga(
      getCouponItemStatusAsync.request(action.payload),
    );
    const isPossible = yield select(
      (state: RootState) => state.coupon.isPossible,
    );
    if (isPossible) {
      window.open(
        `https://soffice.11st.co.kr/tictoc/bridge.tmall?method=goChatPage&couponNo=${action.payload}`,
        '11톡',
        'resizable=yes,scrollbars=yes,width=1100,height=800',
      );
    } else {
      yield put(openAlert('SEND_11TALK_WITH_INVALID_COUPON'));
    }
  } else {
    yield put(openDialog('Talk11UsedInfo'));
    yield put(setSelectedCoupon(action.payload));
  }
}

export function* post11TalkAgreementsSucessSaga() {
  const selectedCoupon = yield select(
    (state: RootState) => state.coupon.selectedCoupon,
  );

  yield put(setSelectedCoupon(''));
  yield getCouponItemStatusSaga(
    getCouponItemStatusAsync.request(selectedCoupon),
  );
  const isPossible = yield select(
    (state: RootState) => state.coupon.isPossible,
  );
  if (isPossible) {
    window.open(
      `https://soffice.11st.co.kr/tictoc/bridge.tmall?method=goChatPage&couponNo=${selectedCoupon}`,
      '11톡',
      'resizable=yes,scrollbars=yes,width=1100,height=800',
    );
  } else {
    yield put(openAlert('SEND_11TALK_WITH_INVALID_COUPON'));
  }
}

export default function* couponSaga() {
  yield takeLatest(getType(getCouponListAsync.request), getCouponListSaga);
  yield takeLatest(getType(send11TalkCoupons), send11TalkCouponsSaga);
  yield takeLatest(
    getType(post11TalkAgreementsAsync.success),
    post11TalkAgreementsSucessSaga,
  );
  yield takeLatest(
    getType(getCouponItemStatusAsync.request),
    getCouponItemStatusSaga,
  );
}
