type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export interface IApiError {
  status: number;
  statusText: string;
}

export interface IApiResponse {
  status: number;
  count: number;
  message: string;
  data: any[];
}

export class ApiError implements IApiError {
  status = 0;

  statusText = '';

  constructor(status: number, statusText: string) {
    this.status = status;
    this.statusText = statusText;
  }
}

const handdleError = ({ status, statusText }: Response) => {
  if (status === 401) {
    const { origin } = window.location;
    window.location.replace(
      `https://login.11st.co.kr/auth/front/login.tmall?returnURL=${origin}`,
    );
  } else if (status === 403) {
    window.location.replace('https://www.11st.co.kr');
  } else {
    throw new ApiError(status, statusText);
  }
};

const handleApiResponse = ({ status, data, message }: IApiResponse) => {
  if (status === 200) {
    return data;
  }
  if (status === 204) {
    return [];
  }

  if (status === 401) {
    const { origin } = window.location;
    window.location.replace(
      `https://login.11st.co.kr/auth/front/login.tmall?returnURL=${origin}`,
    );
  } else if (status === 403) {
    window.location.replace('https://www.11st.co.kr');
  }

  throw new ApiError(status, message);
};

const handleResponse = (response: Response) => {
  if (!response.ok) {
    handdleError(response);
  }

  return response.json();
};

const defaultOption = {
  credentials: 'include' as const,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'Vine-Mode': 'realtime',
  },
};

const httpClient = (url: string, method: HttpMethod = 'GET', body?: any) => {
  const option = body
    ? {
        ...defaultOption,
        method,
        body: body instanceof Object ? JSON.stringify(body) : body,
      }
    : { ...defaultOption, method };

  return fetch(url, option)
    .then(handleResponse)
    .then(handleApiResponse);
};

export const httpRequest = (
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
) => {
  const option = body
    ? { ...defaultOption, method, body: JSON.stringify(body) }
    : { ...defaultOption, method };

  return fetch(url, option).then(handleResponse);
};

export const fileUploader = (url: string, formData: FormData) =>
  fetch(url, {
    method: 'POST',
    credentials: 'include' as const,
    body: formData,
  })
    .then(handleResponse)
    .then(handleApiResponse);

export default httpClient;
