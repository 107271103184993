import { RootState } from 'modules';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useAddress = () => {
  const buildingManageNo =
    useSelector((state: RootState) => state.user.basicInfo.addr.buildMngNo) ??
    '';
  const roadAddrNm = useSelector(
    (state: RootState) => state.user.basicInfo.addr.roadAddrNm,
  );
  const zipAddrNm = useSelector(
    (state: RootState) => state.user.basicInfo.addr.zipAddrNm,
  );
  const storeDetailAddress = useSelector(
    (state: RootState) => state.user.basicInfo.addrDetail,
  );

  const offlineShopAddress: string = useMemo(() => roadAddrNm || zipAddrNm, [
    roadAddrNm,
    zipAddrNm,
  ]);

  const locationInfoImageUrl = useSelector(
    (state: RootState) =>
      state.user.basicInfo.details.offlineShopInfo?.locationInfoImageUrl ?? '',
  );

  return {
    offlineShopAddress,
    storeDetailAddress,
    buildingManageNo,
    locationInfoImageUrl,
  };
};

export default useAddress;
