import { Dropdown, ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import Pagination from 'components/common/Pagination';
import TableHead, { IColumn } from 'components/common/TableHead';
import { RootState } from 'modules';
import {
  clearPromotionDetail,
  getPromotionListAsync,
  postPromotionDetailAsync,
  putPromotionDetailAsync,
  putPromotionExpiresAsync,
} from 'modules/promotion';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import { formatDate } from 'utils/format';

interface IData {
  [index: string]: any;
  id: number;
  name: string;
  url: string;
  status: string;
  startDate: string;
  endDate: string;
}

interface IButtonProps {
  onClick: (e: any) => void;
  children?: React.ReactNode;
}

interface IDisplayDate {
  date: string;
}

const PAGE_SIZE = 20;

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze(
  [
    { text: 'NO' },
    { text: '프로모션명' },
    { text: '진행상태' },
    { text: '프로모션 시작일' },
    { text: '프로모션 종료일' },
    { text: '편집' },
    { text: '상태여부' },
  ].map((col, index) => ({ ...col, id: index })),
);

const NewPromotionButton = () => {
  const { push } = useHistory();

  return (
    <button
      type="button"
      className="btn-style__1 btn-size__5"
      onClick={() => push('/promotion/new')}
    >
      신규 프로모션 등록
    </button>
  );
};

const DisplayDate = ({ date }: IDisplayDate) => (
  <td>
    <span className="text-date">{formatDate(date)}</span>
  </td>
);

const EditButton = ({ onClick, children }: IButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className="btn-style__1 btn-size__6"
    data-log-actionid-area="promotion"
    data-log-actionid-label="edit"
  >
    {children}
  </button>
);

const DeleteButton = ({ onClick, children }: IButtonProps) => (
  <button type="button" onClick={onClick} className="btn-style__1 btn-size__6">
    {children}
  </button>
);

const EmptyPromotion = () => (
  <tr>
    <td colSpan={7} className="non-data__2">
      <p>
        등록된 스토어 프로모션이 없습니다.
        <br />
        인기상품, 신상품, 세일상품 등 다양한 컨셉으로 프로모션을 등록해보세요.
      </p>
      <NewPromotionButton />
    </td>
  </tr>
);
const OPTIONS = [
  { id: '1', value: 'ALL', name: '전체' },
  { id: '2', value: 'INPROGRESS', name: '진행중' },
  { id: '3', value: 'BEFOREPROCESSING', name: '진행예정' },
  { id: '4', value: 'FINISHED', name: '종료' },
];
const PromotionDropdown = () => {
  const [status, setStatus] = useState<string>('ALL');
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  useEffect(() => {
    if (storeNo) {
      dispatch(getPromotionListAsync.request({ storeNo, status }));
    }
  }, [dispatch, status, storeNo]);

  return (
    <Dropdown
      id="listboxButton"
      options={OPTIONS}
      placeholder=""
      initialValue="ALL"
      width="size__2"
      onSelect={(v: string) => setStatus(v)}
    />
  );
};

const PromotionList = () => {
  const history = useHistory();
  const rawPromotions = useSelector(
    (state: RootState) => state.promotion.promotionList,
  );
  const promotions = useMemo(
    () =>
      [...rawPromotions].sort((a, b) => {
        if (a.promotionNo > b.promotionNo) return -1;
        if (a.promotionNo === b.promotionNo) return 0;

        return 1;
      }),
    [rawPromotions],
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const dispatch = useDispatch();
  const promotionsLength = useMemo(() => promotions.length, [
    promotions.length,
  ]);
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const storeId = useSelector(
    (state: RootState) => state.user.headerInfo.storeID,
  );

  const onClickEditPromotion = (id: string) => {
    history.push(`/promotion/edit/${id}`);
  };

  const isProgress = (value: string): boolean => value === '진행중';

  const onClickTerminatePromotion = (promotionNo: string) => {
    dispatch(putPromotionExpiresAsync.request({ storeNo, promotionNo }));
  };

  useEffect(() => {
    setCurrentPage(1);
    dispatch(clearPromotionDetail());
  }, [dispatch]);

  return (
    <>
      <div className="table-wrap">
        <table className="table">
          <caption>프로모션 리스트</caption>
          <colgroup>
            <col width="80px" />
            <col />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
            <col width="120px" />
          </colgroup>
          <TableHead columns={COLUMNS} />
          <tbody>
            {promotionsLength > 0 ? (
              promotions
                .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                .map(
                  ({
                    promotionNo,
                    title,
                    promotionStatus,
                    startDate,
                    endDate,
                  }) => {
                    return (
                      <tr key={promotionNo}>
                        <td>
                          <span className="text-num">{promotionNo}</span>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="text-title"
                            onClick={() =>
                              window.open(
                                `//shop.11st.co.kr/m/${storeId}/promotion/${promotionNo}`,
                                '',
                                'resizable=yes,scrollbars=yes,width=375,height=812',
                              )
                            }
                            data-log-actionid-area="promotion"
                            data-log-actionid-label="name"
                          >
                            {title}
                          </button>
                        </td>
                        <td>{promotionStatus}</td>
                        <DisplayDate date={startDate} />
                        <DisplayDate date={endDate} />
                        <td>
                          <EditButton
                            onClick={(e: any) => {
                              onClickEditPromotion(promotionNo);
                              if (window.rakeLog) {
                                window.rakeLog.sendRakeLog(e.target);
                              }
                            }}
                          >
                            수정
                          </EditButton>
                        </td>
                        <td>
                          {isProgress(promotionStatus) && (
                            <DeleteButton
                              onClick={() =>
                                onClickTerminatePromotion(promotionNo)
                              }
                            >
                              종료
                            </DeleteButton>
                          )}
                        </td>
                      </tr>
                    );
                  },
                )
            ) : (
              <EmptyPromotion />
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        total={promotionsLength}
        pageSize={PAGE_SIZE}
      />
    </>
  );
};

const Promotion = () => {
  return (
    <div className="content-in" id="soContent">
      <DocumentTitle title="내 프로모션 리스트 - 프로모션 관리 - 스토어 관리" />
      <div className="view-title title-style__2">
        <h2 className="title">내 프로모션 리스트</h2>
      </div>
      <div className="view-cont">
        <div className="cont-filter">
          <div className="filter-side__1">
            <NewPromotionButton />
          </div>
          <div className="filter-side__2">
            <PromotionDropdown />
          </div>
        </div>
        <PromotionList />
      </div>
      <ToastMessage
        action={getType(postPromotionDetailAsync.request)}
        successText="저장되었습니다."
      />
      <ToastMessage
        action={getType(putPromotionDetailAsync.request)}
        successText="저장되었습니다."
      />
    </div>
  );
};

export default Promotion;
