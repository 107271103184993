import { RootState } from 'modules';
import { IRecommendProduct, RecommendMenuIdType } from 'modules/recommend';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  id: RecommendMenuIdType;
}

const TITLE_HEIGHT = 42;
const CARD_HEIGHT = 181;
const CARD_INFO_HEIGHT = 30;

const CardViewPreview = () => (
  <li className="l-grid__col l-grid__col--12">
    <div className="c-card c-card__dealcard">
      <div className="c-card__link">
        <span className="c-card__thumb">
          <span className="c-lazyload c-lazyload--ratio_2x1">
            <img
              src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/layout_cardview.png`}
              alt=""
            />
          </span>
        </span>
        <div className="c-card__info">
          <span className="c-card__name">햇반 흑미밥 210g X 24개</span>
          <span className="c-card__counsel">
            <em>무료상담상품</em>
          </span>
        </div>
      </div>
      <div className="c-card__info">
        <div className="c-card__counsel">결제 없이 상담 먼저 받아보세요.</div>
      </div>
    </div>
  </li>
);

const compareRecommendItem = (
  a: IRecommendProduct,
  b: IRecommendProduct,
): number => {
  if (a.orderSeq < b.orderSeq) return -1;
  if (a.orderSeq > b.orderSeq) return 1;
  if (a.productName < b.productName) return -1;
  if (a.productName > b.productName) return 1;
  if (a.productNo < b.productNo) return 1;
  if (a.productNo > b.productNo) return -1;

  return 0;
};

const getProductPriceComponent = (
  productType: string,
  discountRate: number,
) => {
  if (productType === 'COUNSEL') {
    return (
      <span className="c-card__counsel">
        <em>무료상담상품</em>
      </span>
    );
  }
  if (discountRate < 1 && productType === 'DEAL') {
    return (
      <span className="c-card__rate">
        <em>쇼킹딜가</em>
      </span>
    );
  }
  if (discountRate >= 1) {
    return (
      <span className="c-card__rate">
        <em>{`${discountRate}%`}</em>
      </span>
    );
  }

  return null;
};

const CardView = ({ id }: IProps) => {
  const title = useSelector((state: RootState) => state.recommend[id].title);
  const productData = useSelector(
    (state: RootState) => state.recommend[id].productData,
  );
  const sortedData = useMemo(
    () =>
      [...productData]
        .sort(compareRecommendItem)
        .filter(data => data.sellPrice !== null)
        .filter(data => data.cardViewImageUrl !== null),
    [productData],
  );

  const containerHeight = useMemo(
    () =>
      TITLE_HEIGHT +
      CARD_HEIGHT * (sortedData.length || 1) +
      CARD_INFO_HEIGHT *
        sortedData.filter(item => item.productType === 'COUNSEL').length,
    [sortedData],
  );

  return (
    <div
      className="item_cont"
      style={{
        height: containerHeight,
        backgroundColor: '#eee',
      }}
    >
      <div className="l-grid l-grid--nobg">
        <div className="l-grid__row" data-ui-type="Store_PageTitle">
          <div className="l-grid__col l-grid__col--12">
            <div className="c-headline c-headline--center">
              <h3 className="c-headline__title">{title}</h3>
            </div>
          </div>
        </div>
        <ul className="l-grid__row" data-ui-type="Store_Product_Card">
          {sortedData.length > 0 ? (
            sortedData.map(data => (
              <li className="l-grid__col l-grid__col--12" key={data.productNo}>
                <div className="c-card c-card__dealcard">
                  <div className="c-card__link">
                    <span className="c-card__thumb">
                      <span className="c-lazyload c-lazyload--ratio_2x1">
                        <img
                          src={`${process.env.REACT_APP_CDN}${data.cardViewImageUrl}`}
                          alt=""
                        />
                      </span>
                    </span>
                    <div className="c-card__info">
                      <span className="c-card__name">{data.productName}</span>
                      {getProductPriceComponent(
                        data.productType,
                        data.discountRate,
                      )}
                      <div className="c-card__price">
                        <strong>{data.finalDiscountPrice}</strong>원
                        {data.finalDiscountPrice < Number(data.sellPrice) && (
                          <del>{data.sellPrice}</del>
                        )}
                      </div>
                    </div>
                  </div>
                  {data.productType === 'COUNSEL' && (
                    <div className="c-card__info">
                      <div className="c-card__counsel">
                        결제 없이 상담 먼저 받아보세요.
                      </div>
                    </div>
                  )}
                </div>
              </li>
            ))
          ) : (
            <CardViewPreview />
          )}
        </ul>
      </div>
    </div>
  );
};

export default CardView;
