import ButtonLink from 'components/common/ButtonLink';
import React from 'react';

const TabData = [
  {
    id: '1',
    text: '스토어 템플릿',
    to: '/display/template',
    label: '스토어템플릿',
  },
  { id: '2', text: '스토어 스킨', to: '/display/skin', label: '스토어스킨' },
  {
    id: '3',
    text: '스토어 홈 / 전시',
    to: '/display/home',
    label: '스토어홈/전시',
  },
];

const TabMenu = () => {
  return (
    <div className="st-tab__menu" data-log-actionid-area="main">
      <div className="view-title title-style__2 skip">
        <h2 className="title">스토어 전시관리</h2>
      </div>
      <nav className="tab-menu">
        <ul>
          {TabData.map(({ id, text, to, label }) => (
            <ButtonLink
              key={id}
              text={text}
              to={to}
              logLabel="menu"
              logBody={JSON.stringify({ btn_name: label })}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default TabMenu;
