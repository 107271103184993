import classNames from 'classnames';
import { RootState } from 'modules';
import { openAlertWithId } from 'modules/alert';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

interface IProps {
  text: string;
  to: string;
  logLabel?: string;
  logBody?: string;
}

const ButtonLink = ({ text, to, logLabel, logBody }: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const touched = useSelector((state: RootState) => state.display.touched);
  const dispatch = useDispatch();

  return (
    <li className={classNames({ active: location.pathname.includes(to) })}>
      <button
        type="button"
        onClick={() => {
          if (!touched) {
            history.push(to);
          } else {
            dispatch(openAlertWithId('MOVE_MENU_WITHOUT_SAVING_DATA', to));
          }
        }}
        data-log-actionid-label={logLabel}
        data-log-body={logBody}
      >
        <span>{text}</span>
      </button>
    </li>
  );
};

export default ButtonLink;
