import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import Pagination from 'components/common/Pagination';
import TableHead, { IColumn } from 'components/common/TableHead';
import { RootState } from 'modules';
import {
  postNoticeDetailAsync,
  putNoticeDetailAsync,
} from 'modules/notice/actions';
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import { isShowNoticeDisplayDate } from 'utils';
import { formatDate } from 'utils/format';

import useClickNoticeItem from './useClickNoticeItem';
import useFetchNoticeList from './useFetchNoticeList';

interface IButtonProps {
  onClick: () => void;
  children?: React.ReactNode;
}

interface IDisplayDateProps {
  date: string;
}

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze(
  [
    { text: 'NO', width: 80 },
    { text: '분류', width: 100 },
    { text: '제목', width: 'auto' },
    { text: '시작일', width: 120 },
    { text: '종료일', width: 120 },
    { text: '등록일', width: 120 },
    { text: '수정', width: 120 },
    // { text: '11톡 보내기', width: 120 },
  ].map((col, index) => ({ ...col, id: index })),
);

const NewNoticeButton = () => {
  const history = useHistory();
  const onClick = () => history.push(`/notice/new/`);

  return (
    <button
      type="button"
      className="btn-style__1 btn-size__5"
      onClick={onClick}
    >
      공지사항 등록
    </button>
  );
};

interface ITitleProps {
  title: string;
  isImportant: boolean;
  onClick: () => void;
}
const Title = ({ title, isImportant, onClick }: ITitleProps) => (
  <td>
    <button
      type="button"
      className="text-title"
      onClick={onClick}
      data-log-actionid-area="notice"
      data-log-actionid-label="name"
    >
      {isImportant && <span className="flag-em">중요</span>}
      {` ${title}`}
    </button>
  </td>
);

const DisplayDate = ({ date }: IDisplayDateProps) => {
  const dateString = useMemo(() => formatDate(date), [date]);
  const displayDate = isShowNoticeDisplayDate(dateString) ? dateString : '-';

  return (
    <td>
      <span className="text-date">{displayDate || '-'}</span>
    </td>
  );
};

const FuncButton = ({ onClick, children, ...props }: IButtonProps) => {
  const onClickButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (window.rakeLog) {
        window.rakeLog.sendRakeLog(e.target);
      }
      onClick();
    },
    [onClick],
  );

  return (
    <td>
      <button
        type="button"
        onClick={onClickButton}
        className="btn-style__1 btn-size__7"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </button>
    </td>
  );
};

const EmptyNotice = () => (
  <tr>
    <td colSpan={COLUMNS.length} className="non-data__2">
      <p>등록된 공지사항이 없습니다.</p>
      <NewNoticeButton />
    </td>
  </tr>
);

const PAGE_SIZE = 10;
const NoticeList = () => {
  const notices = useSelector((state: RootState) => state.notice.data);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const noticesLength = useMemo(() => notices.length, [notices]);
  const currentNotices = useMemo(
    () => notices.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE),
    [currentPage, notices],
  );

  const { onClickReadNotice, onClickEditNotice } = useClickNoticeItem();
  const isLoading = useFetchNoticeList();

  return (
    <>
      <div className="table-wrap">
        <table className="table">
          <caption>공지사항 목록</caption>
          <TableHead columns={COLUMNS} hasColgroup />
          {isLoading ? null : (
            <tbody>
              {noticesLength > 0 ? (
                currentNotices.map(
                  ({
                    isImportant,
                    noticeNo: id,
                    title,
                    displayBeginDate,
                    displayEndDate,
                    noticeClassifyName,
                    createDate,
                    usable,
                    storeNo: storeNumber,
                  }) => (
                    <tr key={id}>
                      <td>{isImportant ? '-' : id}</td>
                      <td>{noticeClassifyName}</td>
                      <Title
                        title={title}
                        isImportant={isImportant}
                        onClick={() => onClickReadNotice(id, storeNumber)}
                      />
                      <DisplayDate date={displayBeginDate} />
                      <DisplayDate date={displayEndDate} />
                      <DisplayDate date={createDate} />
                      {usable ? (
                        <>
                          {storeNumber === 0 ? (
                            <td />
                          ) : (
                            <FuncButton
                              onClick={() => onClickEditNotice(id)}
                              data-log-actionid-area="notice"
                              data-log-actionid-label="edit"
                            >
                              수정
                            </FuncButton>
                          )}
                          {/* <FuncButton
                          onClick={() => onClickSend11Talk()}
                          data-log-actionid-area="notice"
                          data-log-actionid-label="11talk_send"
                        >
                          선택
                        </FuncButton> */}
                        </>
                      ) : (
                        <>
                          <td />
                          <td />
                        </>
                      )}
                    </tr>
                  ),
                )
              ) : (
                <EmptyNotice />
              )}
            </tbody>
          )}
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        total={noticesLength}
        pageSize={PAGE_SIZE}
      />
    </>
  );
};

const Notice = () => (
  <>
    <DocumentTitle title="공지사항 리스트 - 스토어 공지 관리 - 스토어 관리" />
    <div className="content-in">
      <div className="view-title title-style__2">
        <h2 className="title">등록된 공지사항 리스트</h2>
      </div>
      <div className="view-cont">
        <div className="cont-filter">
          <div className="filter-side__1">
            <NewNoticeButton />
          </div>
        </div>
        <NoticeList />
      </div>
    </div>
    <ToastMessage
      action={getType(postNoticeDetailAsync.request)}
      successText="저장되었습니다."
    />
    <ToastMessage
      action={getType(putNoticeDetailAsync.request)}
      successText="저장되었습니다."
    />
  </>
);

export default Notice;
