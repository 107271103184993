import { RootState } from 'modules';
import { RecommendMenuIdType } from 'modules/recommend';
import React from 'react';
import { useSelector } from 'react-redux';

import CardView from './CardView';
import Promotion from './Promotion';
import Video from './Video';

interface IProps {
  id: RecommendMenuIdType;
}

const getRecommendPreview = (id: RecommendMenuIdType, type: string) => {
  switch (type) {
    case '01':
      return <CardView id={id} />;
    case '02':
      return <Video id={id} />;
    case '03':
      return <Promotion id={id} />;
    default:
      return null;
  }
};

const Recommend = ({ id }: IProps) => {
  const type = useSelector((state: RootState) => state.recommend[id].type);

  return <>{getRecommendPreview(id, type)}</>;
};

export default Recommend;
