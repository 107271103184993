import config from 'apis/endpoint.config';
import httpClient from 'apis/httpClient';
import { ISpecialItemRequest } from 'modules/special';

export interface ISpecialItem {
  exhibitionNo: string;
  orderSeq: number;
}
export interface IPostMySpecialListParam {
  storeNo: string;
  data: ISpecialItem[];
}

export const getMySpecialList = (storeNo: string) =>
  httpClient(config.exhibition.myExhibitions(storeNo));

export const getSpecialList = (display: string) =>
  httpClient(config.exhibition.exhibition(display));

export const getSpecialById = ({
  display = 'ON_AND_READY_FOR',
  exhibitionNo,
}: ISpecialItemRequest) =>
  httpClient(config.exhibition.exhibitionNo({ display, exhibitionNo }));

export const postMySpecialList = (param: IPostMySpecialListParam) =>
  httpClient(config.exhibition.myExhibitions(param.storeNo), 'POST', {
    data: param.data,
    moduleNo: '05',
  });
