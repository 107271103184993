import { ICategoryListItem } from 'components/pages/category/CategoryList';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';

export type CategoryAction = ActionType<typeof actions>;

export enum TYPE_CODE {
  PRODUCT = 'PRODUCT',
  CATEGORY = 'CATEGORY',
}

export enum CATEGORY_DEFAULT {
  ORDER = 10,
}

export type TypeCodeType = TYPE_CODE.PRODUCT | TYPE_CODE.CATEGORY;

export type CategoryItemIdType = number;

export interface ICategoryItem {
  categoryNo: CategoryItemIdType;
  categoryName: string;
  parentCategoryNo: number;
  parentCategoryName: string;
}
export interface IBasicCategoryItem extends ICategoryItem {
  mappedCategory?: boolean;
}

export interface ICustomCategoryItem {
  storeCategoryNo: CategoryItemIdType;
  storeCategoryName: string;
  typeCode: TypeCodeType;
  orderSeq: number;
}

export interface ICategoryDetail extends ICategoryItem {
  orderSeq?: number;
}

export interface IProductCategoryItem {
  productNo: CategoryItemIdType;
  productName: string;
  sellStatusType: string;
  sellStatusTypeDesc: string;
}

export type ICustomDetailModifiedKey = CategoryItemIdType;
export interface ICustomDetailModifiedValue {
  displayPriority: number;
  typeCode: string;
}
export interface ICustomDetailModified extends ICustomDetailModifiedValue {
  keyValue: ICustomDetailModifiedKey;
}

export type CategoryItemType = ICustomCategoryItem | IProductCategoryItem;
export type CategoryListType = CategoryItemType[];

export type CustomDetailModifiedType = Map<
  ICustomDetailModifiedKey,
  ICustomDetailModifiedValue
>;

export interface ICategoryState {
  isUsingCustom: boolean;
  isModeAutoSwitched: boolean;
  basicLarge: IBasicCategoryItem[]; // 전시 카테고리
  basicMiddle: IBasicCategoryItem[];
  basicSmall: IBasicCategoryItem[];
  selectedCustomId: CategoryItemIdType; // 스토어 카테고리 현재 선택한 id (storeCategoryNo), category/Edit에서 editMode를 판별하는데 사용
  custom: ICustomCategoryItem[]; // 스토어 카테고리
  customItemTemp: ICustomCategoryItem;
  shouldCategorySave: boolean;
  customOrder: ICategoryListItem[]; // 스토어 카테고리 순서정보.
  currentSelectedCustomInModal: ICategoryDetail[]; // SelectCategory 모달에서 선택한 카테고리
  customDetail: ICategoryDetail[]; // API로 받아온 커스텀 카테고리 상세 - 카테고리가 맵핑된 경우
  customDetailFetched: ICategoryDetail[]; // API로 받아온 커스텀 카테고리 상세 - 변경안함
  customDetailModified: CustomDetailModifiedType; // 커스텀 카테고리 상세 수정 API에 보낼 values를 Map 객체로 관리. (keyValue가 map의 key값)
  customDetailModifiedInModal: CustomDetailModifiedType; // 커스텀 카테고리 상세 모달에서 사용하는 map객체. 모달에서 뭐가 추가되고, 삭제됐는지 확인할 수 있음
  shouldStoreApply: boolean;
  isShowDoneToast: boolean;
  confirmCategoryDelete: boolean;
}
