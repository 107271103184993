import {
  getMySpecialList,
  getSpecialById,
  getSpecialList,
  postMySpecialList,
} from 'apis/special';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { closeDialog } from 'modules/dialog';
import {
  addSpecialItem,
  addSpecialItemFromSearchResult,
  clearSpecialSearchData,
  getMySpecialListAsync,
  getSpecialByIdAsync,
  getSpecialListAsync,
  initializeSpecial,
  ISpecialItem,
  ISpecialItemResponse,
  postMySpecialListAsync,
  setExhibitionNumberError,
  setSpecialList,
} from 'modules/special';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';
import { formatDate } from 'utils/format';

export const getMySpecialListSaga = createAsyncSaga(
  getMySpecialListAsync,
  getMySpecialList,
);
export function* getMySpecialListSuccessSaga(
  action: PayloadAction<string, ISpecialItemResponse[]>,
) {
  yield put(initializeSpecial());

  yield put(
    setSpecialList(
      action.payload.map(
        ({
          bannerImageUrl,
          display,
          displayBeginDate,
          displayEndDate,
          exhibitionName,
          exhibitionNo,
          orderSeq,
          ...prop
        }) => ({
          ...prop,
          exhibitionNo,
          display,
          exhibitionName,
          displayBeginDate: formatDate(displayBeginDate),
          displayEndDate: formatDate(displayEndDate),
          bannerImageUrl,
          orderSeq,
        }),
      ),
    ),
  );
}

export const getSpecialListSaga = createAsyncSaga(
  getSpecialListAsync,
  getSpecialList,
);

export const specialIdListSelector = (state: RootState) => state.special.data;

export const candidateListSelector = (state: RootState) =>
  state.special.candidateList;

export const searchResultSelector = (state: RootState) =>
  state.special.searchResult;

export function* addSpecialItemFromSearchResultSaga() {
  const data: ISpecialItem[] = yield select(specialIdListSelector);
  const candidateList: string[] = yield select(candidateListSelector);
  const searchResult: ISpecialItemResponse[] = yield select(
    searchResultSelector,
  );

  const filteredCandidateList = candidateList
    .filter(id => !data.map(({ exhibitionNo }) => exhibitionNo).includes(id))
    .map(id =>
      searchResult.findIndex(({ exhibitionNo }) => exhibitionNo === id),
    )
    .filter(idx => idx !== -1)
    .map(idx => searchResult[idx]);

  if (filteredCandidateList.length + data.length > 5) {
    yield put(openAlert('SAVE_SPECIAL_OVER_5_ITEMS'));
  } else {
    yield all(
      filteredCandidateList.map(
        ({
          bannerImageUrl,
          display,
          displayBeginDate,
          displayEndDate,
          exhibitionName,
          exhibitionNo,
          ...prop
        }) =>
          put(
            addSpecialItem({
              ...prop,
              bannerImageUrl,
              display,
              displayBeginDate: formatDate(displayBeginDate),
              displayEndDate: formatDate(displayEndDate),
              exhibitionName,
              exhibitionNo,
              orderSeq: 100,
            }),
          ),
      ),
    );
    yield put(closeDialog());
    yield put(clearSpecialSearchData());
  }
}

export const getSpecialByIdSaga = createAsyncSaga(
  getSpecialByIdAsync,
  getSpecialById,
);

function* closeSpecialDialog() {
  yield put(setExhibitionNumberError(false));
  yield put(closeDialog());
  yield put(clearSpecialSearchData());
}

function* getSpecialByIdSuccessSaga({
  payload: [specialItem],
}: PayloadAction<string, ISpecialItemResponse[]>) {
  const data: ISpecialItem[] = yield select(specialIdListSelector);
  const isDataExist = data.some(
    ({ exhibitionNo }) => specialItem?.exhibitionNo === exhibitionNo,
  );
  if (isDataExist) {
    yield closeSpecialDialog();
  } else if (specialItem) {
    const {
      display,
      bannerImageUrl,
      displayBeginDate,
      displayEndDate,
      exhibitionName,
      exhibitionNo,
      ...prop
    } = specialItem;

    yield put(
      addSpecialItem({
        ...prop,
        display,
        bannerImageUrl,
        displayBeginDate: formatDate(displayBeginDate),
        displayEndDate: formatDate(displayEndDate),
        exhibitionName,
        exhibitionNo,
        orderSeq: 100,
      }),
    );
    yield closeSpecialDialog();
  } else {
    yield put(setExhibitionNumberError(true));
  }
}

function* getSpecialByIdFailureSaga() {
  yield put(setExhibitionNumberError(true));
}

export const postMySpecialListSaga = createAsyncSaga(
  postMySpecialListAsync,
  postMySpecialList,
);

export default function* specialSaga() {
  yield takeLatest(
    getType(getMySpecialListAsync.request),
    getMySpecialListSaga,
  );
  yield takeLatest(
    getType(getMySpecialListAsync.success),
    getMySpecialListSuccessSaga,
  );
  yield takeLatest(getType(getSpecialListAsync.request), getSpecialListSaga);
  yield takeLatest(getType(getSpecialByIdAsync.request), getSpecialByIdSaga);
  yield takeLatest(
    getType(getSpecialByIdAsync.success),
    getSpecialByIdSuccessSaga,
  );
  yield takeLatest(
    getType(getSpecialByIdAsync.failure),
    getSpecialByIdFailureSaga,
  );
  yield takeLatest(
    getType(addSpecialItemFromSearchResult),
    addSpecialItemFromSearchResultSaga,
  );
  yield takeLatest(
    getType(postMySpecialListAsync.request),
    postMySpecialListSaga,
  );
}
