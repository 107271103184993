import useAsyncTaskStatus from 'hooks/useAsyncTaskStatus';
import { RootState } from 'modules';
import { getNoticeListAsync } from 'modules/notice/actions';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';

const useFetchNoticeList = () => {
  const dispatch = useDispatch();

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  const [status] = useAsyncTaskStatus(getType(getNoticeListAsync.request));
  const isLoading = useMemo(() => status === 'LOADING', [status]);

  useEffect(() => {
    if (storeNo) {
      dispatch(getNoticeListAsync.request({ storeNo }));
    }
  }, [dispatch, storeNo]);

  return isLoading;
};

export default useFetchNoticeList;
