import { RootState } from 'modules';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import TabMenu from './TabMenu';

interface IProps {
  children?: React.ReactNode;
}

const DisplayLayout = ({ children }: IProps) => {
  const initialPage = useSelector(
    (state: RootState) => state.display.initialPage,
  );
  const { pathname } = useLocation();
  const { replace } = useHistory();

  useEffect(() => {
    if (initialPage && pathname === '/display') {
      replace(`display/${initialPage}`);
    }
  }, [initialPage, pathname, replace]);

  return (
    <>
      <TabMenu />
      {children}
    </>
  );
};

export default DisplayLayout;
