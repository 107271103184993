import React from 'react';

type WidthType = 'auto' | number | string;

export interface IColumn {
  id: number;
  text: React.ReactNode;
  width?: WidthType;
}

interface ITableHead {
  columns: ReadonlyArray<IColumn>;
  hasColgroup?: boolean;
  children?: React.ReactNode;
}

const TableHead = ({ columns, hasColgroup = false, children }: ITableHead) => {
  return (
    <>
      {hasColgroup && (
        <colgroup>
          {columns.map(({ id, width }) => (
            <col key={id} style={{ width }} />
          ))}
        </colgroup>
      )}
      <thead>
        <tr>
          {children}
          {columns.map(({ id, text }) => (
            <th key={id} scope="col">
              {text}
            </th>
          ))}
        </tr>
      </thead>
    </>
  );
};

export default TableHead;
