import DocumentTitle from 'components/common/DocumentTitle';
import Pagination from 'components/common/Pagination';
import TableHead, { IColumn } from 'components/common/TableHead';
import { RootState } from 'modules';
import { getCouponListAsync, send11TalkCoupons } from 'modules/coupon';
import { get11TalkAgreementsAsync } from 'modules/user';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isSamePageId, setPageId } from 'utils/log';

const PAGE_SIZE = 20;

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze(
  [
    { text: '상태', width: 'auto' },
    { text: '쿠폰번호', width: 'auto' },
    { text: '쿠폰명', width: 'auto' },
    { text: '쿠폰종류', width: 'auto' },
    { text: '할인액(율)', width: 'auto' },
    { text: '발급수', width: 'auto' },
    { text: '사용수', width: 'auto' },
    { text: '발행기간', width: 'auto' },
    { text: '유효기간', width: 'auto' },
    { text: '중복할인', width: 'auto' },
    { text: '등록일', width: 'auto' },
    { text: '11톡 보내기', width: 'auto' },
  ].map((col, index) => ({ ...col, id: index })),
);

const NewCouponButton = () => {
  return (
    <button
      type="button"
      className="btn-style__1 btn-size__5"
      onClick={() => {
        window.open(
          '//soffice.11st.co.kr/view/8100?method=goRegister',
          '_blank',
        );
      }}
      data-log-actionid-area="coupon"
      data-log-actionid-label="create"
    >
      신규 쿠폰 생성
    </button>
  );
};

const EmptyCoupon = () => (
  <tr>
    <td colSpan={12} className="non-data__2">
      <p>현재 발행중인 스토어찜, 묶음쿠폰, 11톡 친구 쿠폰이 없습니다.</p>
      <NewCouponButton />
    </td>
  </tr>
);

const getDateString = (value: string, br: boolean) => {
  if (!value.includes('~')) {
    return value;
  }
  const [a, b] = value.split('~').map(v => v.split('-').join('.'));

  return (
    <span className="text-num">
      {a}~{br && <br />}
      {b}
    </span>
  );
};

const Coupon = () => {
  const coupons = useSelector((state: RootState) => state.coupon.data);
  const couponsLength = useMemo(() => coupons.length, [coupons]);
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const onClickSend11Talk = (couponNo: string) => {
    dispatch(send11TalkCoupons(couponNo));
  };

  useEffect(() => {
    dispatch(getCouponListAsync.request());
    dispatch(get11TalkAgreementsAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (!isSamePageId('/seller_office/store/coupon')) {
      setPageId('/seller_office/store/coupon');
    }
  }, []);

  return (
    <>
      <DocumentTitle title="쿠폰 리스트 - 쿠폰 관리 - 스토어 관리" />
      <div className="content-in" id="soContent">
        <div className="view-title title-style__2">
          <h2 className="title">현재 발행중인 쿠폰 리스트</h2>
        </div>
        <div className="view-cont">
          <div className="cont-filter">
            <div className="filter-side__1">
              <NewCouponButton />
            </div>
          </div>
          <div className="table-wrap">
            <table className="table">
              <caption>쿠폰 목록</caption>
              <colgroup>
                <col width="60px" />
                <col width="90px" />
                <col />
                <col width="110px" />
                <col width="100px" />
                <col width="80px" />
                <col width="80px" />
                <col width="100px" />
                <col width="110px" />
                <col width="70px" />
                <col width="85px" />
                <col width="85px" />
              </colgroup>
              <TableHead columns={COLUMNS} />
              <tbody>
                {couponsLength ? (
                  coupons
                    .slice(
                      (currentPage - 1) * PAGE_SIZE,
                      currentPage * PAGE_SIZE,
                    )
                    .map(
                      ({
                        couponNo,
                        couponStatus,
                        couponName,
                        couponType,
                        discountAmount,
                        issuedCount,
                        discountRate,
                        issueDate,
                        effectiveDate,
                        overlapDiscountYn,
                        registerDate,
                        usedCount,
                        issueMethod,
                      }) => (
                        <tr key={couponNo}>
                          <td>{couponStatus}</td>
                          <td>
                            <span className="text-num">{couponNo}</span>
                          </td>
                          <td>
                            <span className="text-title">{couponName}</span>
                          </td>
                          <td>
                            <span className="text-title">{couponType}</span>
                          </td>
                          {/* <td>{data.issuanceType}</td> */}
                          <td>
                            <span className="text-num">
                              {discountAmount === -1
                                ? `${discountRate}%`
                                : `${discountAmount}원`}
                            </span>
                          </td>
                          <td>
                            <span className="text-num">{issuedCount}</span>
                          </td>
                          <td>
                            <span className="text-num">{usedCount} </span>
                          </td>
                          <td className="td-side">
                            {getDateString(issueDate, true)}
                          </td>
                          <td className="td-side">
                            {getDateString(effectiveDate, false)}
                          </td>
                          <td>{overlapDiscountYn}</td>
                          <td>
                            <span className="text-date">
                              {registerDate
                                ? registerDate
                                    .split(' ')[0]
                                    .split('-')
                                    .join('.')
                                : ''}
                            </span>
                          </td>
                          <td>
                            {couponStatus === '발행중' &&
                              (couponType !== '스토어묶음쿠폰' ||
                                (couponType === '스토어묶음쿠폰' &&
                                  issueMethod !== '계정발급')) && (
                                <button
                                  type="button"
                                  className="btn-style__1 btn-size__9"
                                  onClick={() => onClickSend11Talk(couponNo)}
                                  data-log-actionid-area="coupon"
                                  data-log-actionid-label="11talk_send"
                                >
                                  전송
                                </button>
                              )}
                          </td>
                        </tr>
                      ),
                    )
                ) : (
                  <EmptyCoupon />
                )}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            onChange={setCurrentPage}
            total={coupons.length}
            pageSize={PAGE_SIZE}
          />
        </div>
      </div>
    </>
  );
};

export default Coupon;
