import config from 'apis/endpoint.config';
import httpClient, { fileUploader } from 'apis/httpClient';

export interface IPutTemplateTypeParam {
  storeNo: string;
  templateType: string;
}
export interface IPutSkinTypeParam {
  storeNo: string;
  colorType: string;
}
export interface IPutMenuEnableParam {
  storeNo: string;
  moduleNo: string;
  isModuleOn: boolean;
}

export interface IMenuPriorityItem {
  displayPriority: number;
  moduleNo: string;
}
export interface IPutMenuPriorityParam {
  storeNo: string;
  modules: IMenuPriorityItem[];
}

export const putTemplateType = (param: IPutTemplateTypeParam) =>
  httpClient(config.display.template(param), 'PUT', param.templateType);

export const putSkinType = (param: IPutSkinTypeParam) =>
  httpClient(config.display.skin(param), 'PUT', param.colorType);

export const getDisplayBasicInfo = () => httpClient(config.display.basicInfo());

export const putMenuEnable = (param: IPutMenuEnableParam) =>
  httpClient(config.display.menuEnable(param), 'PUT', {
    moduleNo: param.moduleNo,
    moduleOn: param.isModuleOn,
  });

export const postImageUpload = (areaDivison: string, formData: FormData) =>
  fileUploader(config.image.Upload(areaDivison), formData);

export const putMenuPriority = (param: IPutMenuPriorityParam) =>
  httpClient(config.display.menuPriority(param.storeNo), 'PUT', param.modules);
