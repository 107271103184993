import {
  IGetPageViewCounts,
  IGetPageViewDownload,
  IPutStoreNameParam,
  IPutStoreUrlParam,
  IPutUserBasicInfoParam,
} from 'apis/user';
import { IDisplayBasicInfoResponse } from 'modules/display';
import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  IBasicInfoAddr,
  IOfflineShopSalesTime,
  IPostPrivateDomainParam,
  IPutPrivateDomainParam,
  IPutStoreNameResponse,
  IPutStoreUrlResponse,
  ISavePrivateDomainResponse,
  ITag,
  IUserBasicInfoResponse,
  ViewCountType,
} from './types';

export const getHeaderInfoAsync = createAsyncAction(
  '@USER/HEADER_INFO/FETCH',
  '@USER/HEADER_INFO/SUCCESS',
  '@USER/HEADER_INFO/FAILURE',
)<undefined, IDisplayBasicInfoResponse[], undefined>();

export const getUserBasicInfoAsync = createAsyncAction(
  '@USER/BASIC/INFO/FETCH',
  '@USER/BASIC/INFO/SUCCESS',
  '@USER/BASIC/INFO/FAILURE',
)<{ storeNo: string }, IUserBasicInfoResponse[], undefined>();

export const saveUserBasicInfo = createAction('@USER/BASIC/INFO/SAVE')<
  undefined
>();
export const putUserBasicInfoAsync = createAsyncAction(
  '@USER/BASIC/INFO/PUT/FETCH',
  '@USER/BASIC/INFO/PUT/SUCCESS',
  '@USER/BASIC/INFO/PUT/FAILURE',
)<IPutUserBasicInfoParam, undefined, undefined>();

export const setStoreName = createAction('@USER/HEADER/INFO/STORE/NAME')<
  string
>();
export const setStoreNameInput = createAction(
  '@USER/BASIC/INFO/DETAILS/STORE/NAME',
)<string>();
export const putStoreNameAsync = createAsyncAction(
  '@USER/STORE/NAME/PUT/FETCH',
  '@USER/STORE/NAME/PUT/SUCCESS',
  '@USER/STORE/NAME/PUT/FAILURE',
)<IPutStoreNameParam, IPutStoreNameResponse[], undefined>();
export const setStoreImage = createAction('@USER/HEADER/INFO/STORE/IMAGE')<
  string
>();
export const setStoreImageTemp = createAction(
  '@USER/HEADER/INFO/STORE/IMAGE/TEMP',
)<string>();
export const setIsStoreUrlLocked = createAction(
  '@USER/BASIC/INFO/STORE/URL/LOCK',
)<boolean>();

export const putStoreUrlAsync = createAsyncAction(
  '@USER/STORE/URL/PUT/FETCH',
  '@USER/STORE/URL/PUT/SUCCESS',
  '@USER/STORE/URL/PUT/FAILURE',
)<IPutStoreUrlParam, IPutStoreUrlResponse[], undefined>();
export const postPrivateDomainAsync = createAsyncAction(
  '@USER/PRIVATE/DOMAIN/POST/FETCH',
  '@USER/PRIVATE/DOMAIN/POST/SUCCESS',
  '@USER/PRIVATE/DOMAIN/POST/FAILURE',
)<IPostPrivateDomainParam, ISavePrivateDomainResponse[], undefined>();
export const putPrivateDomainAsync = createAsyncAction(
  '@USER/PRIVATE/DOMAIN/PUT/FETCH',
  '@USER/PRIVATE/DOMAIN/PUT/SUCCESS',
  '@USER/PRIVATE/DOMAIN/PUT/FAILURE',
)<IPutPrivateDomainParam, ISavePrivateDomainResponse[], undefined>();
export const deletePrivateDomainAsync = createAsyncAction(
  '@USER/PRIVATE/DOMAIN/DELETE/FETCH',
  '@USER/PRIVATE/DOMAIN/DELETE/SUCCESS',
  '@USER/PRIVATE/DOMAIN/DELETE/FAILURE',
)<IPostPrivateDomainParam, ISavePrivateDomainResponse[], undefined>();
export const setIsUsingPrivateDomain = createAction(
  '@USER/BASIC/INFO/DETAILS/IS/USING/PRIVATE/DOMAIN',
)<boolean>();
export const setStorePersonalDomainUrl = createAction(
  '@USER/BASIC/INFO/DETAILS/STORE/PERSONAL/DOMAIN/URL',
)<string>();
export const setPrivateDomain = createAction(
  '@USER/BASIC/INFO/DETAILS/PRIVATE/DOMAIN',
)<string>();
export const setPrivateDomainOk = createAction(
  '@USER/BASIC/INFO/PRIVATE/DOMAIN/OK',
)<boolean>();
export const setHasPrivateDomainIPError = createAction(
  '@USER/BASIC/INFO/PRIVATE/DOMAIN/IP/ERROR',
)<boolean>();
export const setIsPrivateDomainAlreadyUsed = createAction(
  '@USER/BASIC/INFO/PRIVATE/DOMAIN/USED',
)<boolean>();
export const setStoreDescription = createAction(
  '@USER/BASIC/INFO/STORE/DESCRIPTION',
)<string>();

export const setAuthentication = createAction('@USER/SET_AUTHENTICATION')<
  boolean
>();

export const setBasicInfoAddr = createAction('@USER/SET_BASIC_INFO_ADDR')<
  IBasicInfoAddr
>();

export const setBasicInfoAddrDetail = createAction(
  '@USER/BASIC/INFO/ADDR/DETAIL',
)<string>();
export const setStoreLocationImage = createAction('@USER/STORE/LOCATION/IMAGE')<
  string
>();
export const setInstagramAddress = createAction('@USER/STORE/INSTA')<string>();
export const setElevenStreetDomainUrl = createAction('@USER/STORE/11ST/DOMAIN')<
  string
>();
export const getRoadShopListAsync = createAsyncAction(
  '@USER/ROAD_SHOP_LIST/FETCH',
  '@USER/ROAD_SHOP_LIST/SUCCESS',
  '@USER/ROAD_SHOP_LIST/FAILURE',
)<undefined, ITag[], Error>();
export const setSelectedRoadShops = createAction(
  '@USER/BASIC/INFO/SELECTED/ROADSHOPS',
)<ITag[]>();
export const setHasOfflineShopAddress = createAction(
  '@USER/BASIC/INFO/HAS/OFFLINE/ADDRESS',
)<boolean>();
export const setOfflineShopSalesTime = createAction(
  '@USER/BASIC/INFO/OFFLINE/SALE/TIME',
)<IOfflineShopSalesTime>();

export const getTagsAsync = createAsyncAction(
  '@USER/TAGS/FETCH',
  '@USER/TAGS/SUCCESS',
  '@USER/TAGS/FAILURE',
)<undefined, ITag[], Error>();
export const setSelectedTags = createAction('@USER/BASIC/INFO/SELECTED/TAGS')<
  ITag[]
>();

export const getPageViewDownloadAsync = createAsyncAction(
  '@USER/PAGE_VIEW_DOWNLOAD/FETCH',
  '@USER/PAGE_VIEW_DOWNLOAD/SUCCESS',
  '@USER/PAGE_VIEW_DOWNLOAD/FAILURE',
)<IGetPageViewDownload, undefined, Error>();

export const getPageViewCountsAsync = createAsyncAction(
  '@USER/PAGE_VIEW_COUNTS/FETCH',
  '@USER/PAGE_VIEW_COUNTS/SUCCESS',
  '@USER/PAGE_VIEW_COUNTS/FAILURE',
)<IGetPageViewCounts, ViewCountType[], Error>();

export const get11TalkAgreementsAsync = createAsyncAction(
  '@USER/11TALK/GET_AGREEMENTS/FETCH',
  '@USER/11TALK/GET_AGREEMENTS/SUCCESS',
  '@USER/11TALK/GET_AGREEMENTS/FAILURE',
)<undefined, { agreement: boolean; used: boolean }, Error>();

export const post11TalkAgreementsAsync = createAsyncAction(
  '@USER/11TALK/USE_AGREEMENTS/FETCH',
  '@USER/11TALK/USE_AGREEMENTS/SUCCESS',
  '@USER/11TALK/USE_AGREEMENTS/FAILURE',
)<undefined, undefined, Error>();

export const setIsStoreCategorySet = createAction('@USER/CATEGORY/IS/STORE')<
  boolean
>();
