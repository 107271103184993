import { Dropdown, TextInput } from 'components/common';
import { IOption } from 'components/common/Dropdown';
import { IOfflineShopSalesTime, setOfflineShopSalesTime } from 'modules/user';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const TIME_LIST: IOption[] = [...Array(48)].map((__, i) => {
  const h = Math.floor(i / 2);
  const hour = h < 10 ? `0${h}` : h;
  const min = i % 2 === 0 ? '00' : '30';

  return {
    value: `${hour}${min}`,
    id: String(i),
    name: `${hour}:${min}`,
  };
});

const SaleTime = ({ salesTime }: { salesTime: IOfflineShopSalesTime }) => {
  const {
    weekDaySaleBeginTime,
    weekDaySaleEndTime,
    weekEndSaleBeginTime,
    weekEndSaleEndTime,
    closedSaleDescription,
  } = salesTime;

  const dispatch = useDispatch();

  const onChangeclosedSaleDescription = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setOfflineShopSalesTime({
          ...salesTime,
          closedSaleDescription: e.target.value,
        }),
      );
    },
    [dispatch, salesTime],
  );

  return (
    <div className="cont_add">
      <strong className="title">영업시간</strong>
      <dl className="list-item">
        <dt>평일</dt>
        <dd>
          <div className="c-dropdown__wrap">
            <Dropdown
              width="w-100"
              id="listboxButton1"
              options={TIME_LIST}
              initialValue={weekDaySaleBeginTime}
              onSelect={time =>
                dispatch(
                  setOfflineShopSalesTime({
                    ...salesTime,
                    weekDaySaleBeginTime: time,
                  }),
                )
              }
              placeholder="선택"
            />
            <span className="section-bar">~</span>
            <Dropdown
              width="w-100"
              id="listboxButton2"
              options={TIME_LIST}
              initialValue={weekDaySaleEndTime}
              onSelect={time =>
                dispatch(
                  setOfflineShopSalesTime({
                    ...salesTime,
                    weekDaySaleEndTime: time,
                  }),
                )
              }
              placeholder="선택"
            />
          </div>
        </dd>
        <dt>주말/공휴일</dt>
        <dd>
          <div className="c-dropdown__wrap">
            <Dropdown
              width="w-100"
              id="listboxButton3"
              options={TIME_LIST}
              initialValue={weekEndSaleBeginTime}
              onSelect={time =>
                dispatch(
                  setOfflineShopSalesTime({
                    ...salesTime,
                    weekEndSaleBeginTime: time,
                  }),
                )
              }
              placeholder="선택"
            />
            <span className="section-bar">~</span>
            <Dropdown
              width="w-100"
              id="listboxButton4"
              options={TIME_LIST}
              initialValue={weekEndSaleEndTime}
              onSelect={time =>
                dispatch(
                  setOfflineShopSalesTime({
                    ...salesTime,
                    weekEndSaleEndTime: time,
                  }),
                )
              }
              placeholder="선택"
            />
          </div>
        </dd>
        <dt>휴무일</dt>
        <dd className="dd-size__1">
          <TextInput
            width="wide"
            title=""
            value={closedSaleDescription}
            onChange={onChangeclosedSaleDescription}
          />
        </dd>
      </dl>
    </div>
  );
};

export default SaleTime;
