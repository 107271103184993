import {
  getDisplayBasicInfo,
  IPutMenuEnableParam,
  IPutMenuPriorityParam,
  putMenuEnable,
  putMenuPriority,
  putSkinType,
  putTemplateType,
} from 'apis/display';
import { RootState } from 'modules';
import { closeAlert, openAlert, openAlertWithId } from 'modules/alert';
import { AsyncTaskType, resetAsyncTask } from 'modules/asyncTask';
import {
  getDisplayBasicInfoAsync,
  IDisplayBasicInfoResponse,
  IModule,
  menuIdType,
  putEnableMenuAsync,
  putMenuPriorityAsync,
  putSkinTypeAsync,
  putTemplateTypeAsync,
  saveContents,
  saveSkin,
  saveTemplate,
  setEnableMenu,
  setInitialPage,
  setModuleData,
  setSelectedModule,
  setSkin,
  setSortable,
  setTemplate,
  TemplateType,
} from 'modules/display';
import {
  IProfile,
  IProfileItem,
  putProfileInfoAsync,
  setProfileList,
} from 'modules/profile';
import {
  getRecommend2InfoAsync,
  getRecommend3InfoAsync,
  getRecommend4InfoAsync,
} from 'modules/recommend';
import {
  ISpecial,
  ISpecialItem,
  postMySpecialListAsync,
  setSpecialList,
} from 'modules/special';
import { getHeaderInfoAsync } from 'modules/user';
import { all, delay, put, select, take, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import createAsyncSaga from 'utils/sagaUtils';

import { putProfileInfoSaga } from './profile';
import { saveRecommendInfoSaga } from './recommend';
import { postMySpecialListSaga } from './special';

const DEFAULT_DELAY = 1200;

const getModuleName = (id: string) => {
  switch (id) {
    case menuIdType.profile:
      return '대표 이미지';
    case menuIdType.recommend1:
      return '셀러추천1';
    case menuIdType.recommend2:
      return '셀러추천2';
    case menuIdType.recommend3:
      return '셀러추천3';
    case menuIdType.special:
      return '기획전';
    case menuIdType.bestReview:
      return '베스트 리뷰';
    default:
      return '';
  }
};

export const putTemplateTypeSaga = createAsyncSaga(
  putTemplateTypeAsync,
  putTemplateType,
);

export function* saveTemplateSaga() {
  const templateType: TemplateType = yield select(
    (state: RootState) => state.display.template,
  );
  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  yield putTemplateTypeSaga(
    putTemplateTypeAsync.request({
      storeNo,
      templateType: `"${templateType.toUpperCase()}"`,
    }),
  );
  yield delay(DEFAULT_DELAY);
  const status = yield select(
    (state: RootState) =>
      state.asyncTask[getType(putTemplateTypeAsync.request)],
  );
  if (status !== 'IDLE') {
    yield put(resetAsyncTask(getType(putTemplateTypeAsync.request)));
  }
}

export const putSkinTypeSaga = createAsyncSaga(putSkinTypeAsync, putSkinType);

export function* saveSkinSaga() {
  const colorType = yield select((state: RootState) => state.display.skin);
  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  yield putSkinTypeSaga(putSkinTypeAsync.request({ storeNo, colorType }));
  yield delay(DEFAULT_DELAY);

  yield put(resetAsyncTask(getType(putSkinTypeAsync.request)));
}

export const putEnableMenuFetchSaga = createAsyncSaga(
  putEnableMenuAsync,
  putMenuEnable,
);

export function* putEnableMenuSaga(
  action: PayloadAction<string, IPutMenuEnableParam>,
) {
  yield putEnableMenuFetchSaga(putEnableMenuAsync.request(action.payload));
  const status: AsyncTaskType = yield select(
    (state: RootState) => state.asyncTask[getType(putEnableMenuAsync.request)],
  );
  if (status === 'SUCCESS') {
    yield put(
      setEnableMenu(action.payload.isModuleOn, action.payload.moduleNo),
    );
    const moduleData: IModule[] = yield select(
      (state: RootState) => state.display.moduleData,
    );
    const selectedModule: IModule = yield select(
      (state: RootState) => state.display.selectedModule,
    );
    const module = moduleData.find(item => item.id === action.payload.moduleNo);
    if (
      action.payload.isModuleOn &&
      action.payload.moduleNo !== menuIdType.bestReview &&
      module &&
      selectedModule.id !== action.payload.moduleNo
    ) {
      yield put(setSelectedModule(module));
    }
  }
  yield delay(DEFAULT_DELAY);
  yield put(resetAsyncTask(getType(putEnableMenuAsync.request)));
}

const sortSpecialList = (a: ISpecialItem, b: ISpecialItem) => {
  if (a.orderSeq < b.orderSeq) return -1;
  if (a.orderSeq > b.orderSeq) return 1;
  if (a.exhibitionName < b.exhibitionName) return -1;
  if (a.exhibitionName > b.exhibitionName) return 1;
  if (a.exhibitionNo < b.exhibitionNo) return 1;
  if (a.exhibitionNo > b.exhibitionNo) return -1;

  return 0;
};

export function* saveContentSaga() {
  const selected: IModule = yield select(
    (state: RootState) => state.display.selectedModule,
  );

  const storeNo: string = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );

  // TODO: 각 저장 로직 saga 분리
  if (selected.id === menuIdType.special) {
    const { data }: ISpecial = yield select(
      (state: RootState) => state.special,
    );
    if (data.length > 0) {
      yield postMySpecialListSaga(
        postMySpecialListAsync.request({
          storeNo,
          data: [...data].sort(sortSpecialList),
        }),
      );
      yield put(setSpecialList([...data].sort(sortSpecialList)));
      yield delay(DEFAULT_DELAY);
      yield put(resetAsyncTask(getType(postMySpecialListAsync.request)));
    } else {
      yield put(openAlert('SAVE_SPECIAL_WITH_EMPTY'));
    }
  } else if (selected.id === menuIdType.profile) {
    const { type, data }: IProfile = yield select(
      (state: RootState) => state.profile,
    );

    const filteredData = data.filter(item => {
      if (item.type === 'IMAGE') return true;
      if (item.type === 'PRODUCT' || item.type === 'PROMOTION')
        return item.imageUrl || item.title || item.subTitle || item.itemNo;

      return false;
    });

    const emptyImage = (item: IProfileItem) =>
      item.imageUrl === '' || !item.imageUrl;
    const emptyImageWithoutTitle = filteredData.every(
      item => emptyImage(item) && item.title === '' && item.subTitle === '',
    );
    const emptyImageWithTitle = filteredData.some(
      item => emptyImage(item) && (item.title !== '' || item.subTitle !== ''),
    );

    if (emptyImageWithoutTitle) {
      yield put(openAlertWithId('SELECT_DISABLE_MENU', menuIdType.profile));
    } else if (emptyImageWithTitle) {
      yield put(openAlert('SAVE_PROFILE_WITH_EMPTY_IMAGE'));
    } else if (filteredData.some(item => item.statusCode !== '0')) {
      yield put(openAlert('SAVE_PROFILE_WITH_INVALID_STATUS_CODE'));
    } else if (
      filteredData.some(item => item.type === 'PRODUCT' && item.itemNo === '')
    ) {
      yield put(openAlert('SAVE_PROFILE_WITHOUT_PRODUCT_NO'));
    } else if (
      filteredData.some(item => item.type === 'PROMOTION' && item.itemNo === '')
    ) {
      yield put(openAlert('SAVE_PROFILE_WITHOUT_PROMOTION_NO'));
    } else {
      if (!selected.enable) {
        yield put(openAlert('SAVE_MENU_WITH_DISABLE'));
        yield take(closeAlert);
      }
      yield putProfileInfoSaga(
        putProfileInfoAsync.request({
          storeNo,
          imageViewTypeCode: type,
          profileDataList: filteredData.map((item, index) => ({
            displayPriority: String(index + 1),
            moduleImageUrl: item.imageUrl,
            moduleTitle: item.title.substring(0, 30),
            moduleSubTitle: item.subTitle,
            profileTargetCode: item.type,
            moduleObjectValue: item.itemNo ?? '',
          })),
        }),
      );
      yield put(setProfileList([...filteredData]));
      yield delay(DEFAULT_DELAY);
      yield put(resetAsyncTask(getType(putProfileInfoAsync.request)));
    }
  } else {
    yield saveRecommendInfoSaga();
  }
}

const getDisplayBasicInfoSaga = createAsyncSaga(
  getDisplayBasicInfoAsync,
  getDisplayBasicInfo,
);

export function* getDisplayBasicInfoSuccessSaga(
  action: PayloadAction<string, IDisplayBasicInfoResponse[]>,
) {
  const [data] = action.payload;

  if (data) {
    yield all([
      put(setSkin(data.colorType)),
      put(setTemplate(data.templateTypeCode === '01' ? 'default' : 'visual')),
      put(setInitialPage(data.displayStoreTemplateTab ? 'template' : 'home')),
      put(
        setModuleData(
          data.moduleInfoList.map(
            ({ moduleNo, isModuleOn, curationTypeCode }) => ({
              id: moduleNo.toString(),
              name: getModuleName(moduleNo.toString()),
              enable: isModuleOn,
              type: curationTypeCode,
            }),
          ),
        ),
      ),
      put(getHeaderInfoAsync.success([{ ...data }])),
    ]);
  }
  const [firstModule]: IModule[] = yield select(
    (state: RootState) => state.display.moduleData,
  );
  if (firstModule) {
    yield put(setSelectedModule(firstModule));
  }

  yield put(
    getRecommend2InfoAsync.request({ storeNo: data.storeNo, moduleNo: '2' }),
  );
  yield put(
    getRecommend3InfoAsync.request({ storeNo: data.storeNo, moduleNo: '3' }),
  );
  yield put(
    getRecommend4InfoAsync.request({ storeNo: data.storeNo, moduleNo: '4' }),
  );
}

const putMenuPrioritySaga = createAsyncSaga(
  putMenuPriorityAsync,
  putMenuPriority,
);

function* putMenuPriorityRequestSaga(
  action: PayloadAction<
    '@DISPLAY/PUT_MENU_PRIORITY/FETCH',
    IPutMenuPriorityParam
  >,
) {
  yield putMenuPrioritySaga(action);
  const status: AsyncTaskType = yield select(
    (state: RootState) => state.asyncTask['@DISPLAY/PUT_MENU_PRIORITY/FETCH'],
  );
  if (status === 'SUCCESS') {
    const moduleData = yield select(
      (state: RootState) => state.display.moduleData,
    );
    const tempModuleData = yield select(
      (state: RootState) => state.display.tempModuleData,
    );
    yield put(setModuleData([moduleData[0], ...tempModuleData]));
  }
  yield put(setSortable(false));
  yield delay(DEFAULT_DELAY);
  yield put(resetAsyncTask(getType(putMenuPriorityAsync.request)));
}

export default function* displaySaga() {
  yield takeLatest(getType(saveTemplate), saveTemplateSaga);
  yield takeLatest(getType(saveSkin), saveSkinSaga);
  yield takeLatest(getType(saveContents), saveContentSaga);
  yield takeLatest(
    getType(getDisplayBasicInfoAsync.request),
    getDisplayBasicInfoSaga,
  );
  yield takeLatest(
    getType(getDisplayBasicInfoAsync.success),
    getDisplayBasicInfoSuccessSaga,
  );
  yield takeLatest(getType(putEnableMenuAsync.request), putEnableMenuSaga);
  yield takeLatest(
    getType(putMenuPriorityAsync.request),
    putMenuPriorityRequestSaga,
  );
}
