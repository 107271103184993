import classNames from 'classnames';
import CheckBox, { CheckBoxType } from 'components/common/CheckBox';
import Pagination from 'components/common/Pagination';
import TableHead, { IColumn } from 'components/common/TableHead';
import LocalAlert from 'components/layouts/default-layout/Alert/LocalAlert';
import useCheckBox from 'hooks/useCheckBox';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { CATEGORY_DEFAULT, setCustomDetailModified } from 'modules/category';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import { openDialog } from 'modules/dialog';
import {
  DISPLAY_PRODUCT_PAGE_SIZE,
  getCustomCategoryProductDetailAsync,
  setFinalSelectedProducts,
  setProductTablePage,
} from 'modules/product';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCategoryString } from 'utils/format';

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze(
  [
    { text: <>상품번호</>, width: 'auto' },
    { text: <>상품명</>, width: 'auto' },
    { text: <>상품상태</>, width: 'auto' },
    { text: <>카테고리</>, width: 'auto' },
  ].map((col, index) => ({ ...col, id: index })),
);
interface IProductItemProps {
  productNo: number;
  productName: string;
  productStatus: string;
  productCategory: string;
  checked: boolean;
  onClickProductName: () => void;
  onChangeCheckBox: () => void;
}
const ProductItem = memo(
  ({
    productNo,
    productName,
    productStatus,
    productCategory,
    checked,
    onClickProductName = () => {},
    onChangeCheckBox = () => {},
  }: IProductItemProps) => (
    <tr className={classNames({ active: checked })}>
      <td>
        <CheckBox
          id={`checkSpecial${productNo}`}
          name="checkSpecial"
          type={CheckBoxType.basic}
          label={`상품번호${productNo}`}
          labelHidden
          checked={checked}
          onChange={onChangeCheckBox}
        />
      </td>

      <td>
        <span className="text-num">{productNo}</span>
      </td>
      <td>
        <button
          type="button"
          onClick={onClickProductName}
          className="text-title"
        >
          {productName}
        </button>
      </td>
      <td>{productStatus}</td>
      <td className="td-side">{productCategory}</td>
    </tr>
  ),
);

const EMPTY_COLSPAN = COLUMNS.length + 1;
const EmptyProduct = memo(
  ({ onClickSelectProduct }: { onClickSelectProduct: () => void }) => (
    <tr>
      <td colSpan={EMPTY_COLSPAN} className="non-data__2">
        <p>
          개별상품을 선택해서 카테고리를 구성할 수 있으며, <br />
          상품은 인기순으로 정렬됩니다.
        </p>
        <button
          type="button"
          onClick={onClickSelectProduct}
          className="btn-style__1 btn-size__5"
        >
          상품 조회
        </button>
      </td>
    </tr>
  ),
);

const ProductTable = ({
  editMode,
  isLoading,
}: {
  editMode: boolean;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch();

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const selectedCustomId = useSelector(
    (state: RootState) => state.category.selectedCustomId,
  );
  const customDetailModified = useSelector(
    (state: RootState) => state.category.customDetailModified,
  );
  const products = useSelector(
    (state: RootState) => state.product.finalSelectedProducts,
  );
  const currentPage = useSelector(
    (state: RootState) => state.product.productTableCurrentPage,
  );

  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [isClickedConfirm, setIsClickedConfirm] = useState<boolean>(false);
  const [
    clickPageNumberOnAlertConfirm,
    setClickPageNumberOnAlertConfirm,
  ] = useState<number>(1);

  const currentPageProducts = useMemo(
    () =>
      products.slice(
        currentPage * DISPLAY_PRODUCT_PAGE_SIZE - DISPLAY_PRODUCT_PAGE_SIZE,
        currentPage * DISPLAY_PRODUCT_PAGE_SIZE,
      ),
    [currentPage, products],
  );
  const productsLength = useMemo(
    () => (Array.isArray(products) ? products.length : 0),
    [products],
  );
  const [
    isCheckedAll,
    onClickSelectAll,
    isChecked,
    onClickCheckBox,
    selectedProductIndexes,
    setSelectedProductIndexes,
  ] = useCheckBox(productsLength);

  const onChangePage = useCallback(
    (pageNumber: number) => {
      if (selectedProductIndexes.some(checked => checked)) {
        setClickPageNumberOnAlertConfirm(pageNumber);
        setIsShowAlert(true);

        return;
      }
      dispatch(setProductTablePage(pageNumber));
    },
    [dispatch, selectedProductIndexes],
  );
  const onClickSelectProduct = useCallback(
    () => dispatch(openDialog('SelectProducts')),
    [dispatch],
  );
  const onClickDelete = useCallback(() => {
    if (selectedProductIndexes.every(flag => !flag)) {
      dispatch(openAlert('DELETE_AFTER_SELECT_PRODUCT'));

      return;
    }

    const beforeBoxes = Array.from({
      length: DISPLAY_PRODUCT_PAGE_SIZE * (currentPage - 1),
    }).map(() => false);
    const afterBoxes = Array.from({
      length: products.length - beforeBoxes.length - currentPageProducts.length,
    }).map(() => false);

    const allProductIndexes = [
      ...beforeBoxes,
      ...selectedProductIndexes,
      ...afterBoxes,
    ];

    dispatch(
      setFinalSelectedProducts(
        products.filter((__, i) => !allProductIndexes[i]),
      ),
    );
    if (editMode) {
      const selectedProducts = products.filter(
        (__, i) => selectedProductIndexes[i],
      );

      const map = new Map(customDetailModified);
      selectedProducts.forEach(({ productNo }) => {
        if (map.has(productNo)) {
          map.delete(productNo);
        } else {
          map.set(productNo, {
            displayPriority: CATEGORY_DEFAULT.ORDER,
            typeCode: 'DELETE',
          });
        }
      });
      dispatch(setCustomDetailModified(map));
    }
  }, [
    currentPage,
    currentPageProducts.length,
    customDetailModified,
    dispatch,
    editMode,
    products,
    selectedProductIndexes,
  ]);

  const onClickProductName = (productNo: number) => {
    window.open(
      `http://11st.co.kr/product/SellerProductDetail.tmall?method=getSellerProductDetail&prdNo=${productNo}`,
      '_blank',
    );
  };

  useEffect(() => {
    if (currentPage !== 1 && currentPageProducts.length === 0) {
      dispatch(setProductTablePage(currentPage - 1));
    }
  }, [currentPage, currentPageProducts.length, dispatch]);

  useEffect(() => {
    if (
      selectedCustomId !== SELECTED_CUSTOM_ID.CREATE &&
      selectedCustomId !== SELECTED_CUSTOM_ID.INIT
    ) {
      dispatch(
        getCustomCategoryProductDetailAsync.request({
          storeNo,
          storeCategoryNo: selectedCustomId,
        }),
      );
    }
  }, [dispatch, selectedCustomId, storeNo]);

  useEffect(() => {
    if (isClickedConfirm) {
      dispatch(setProductTablePage(clickPageNumberOnAlertConfirm));
      setSelectedProductIndexes([...Array(productsLength)].fill(false));
      setClickPageNumberOnAlertConfirm(1);
      setIsShowAlert(false);
      setIsClickedConfirm(false);
    }
  }, [
    clickPageNumberOnAlertConfirm,
    dispatch,
    isClickedConfirm,
    onChangePage,
    productsLength,
    setSelectedProductIndexes,
  ]);

  return (
    <>
      {isShowAlert && (
        <LocalAlert
          bodyType="WILL_UNCHECK_ON_MOVE_PAGE"
          confirmText="확인"
          onClickConfirm={() => setIsClickedConfirm(true)}
          cancelText="취소"
          onClickCancel={() => setIsShowAlert(false)}
        />
      )}
      <div className="cont-filter">
        <div className="filter-side__1">
          <button
            type="button"
            onClick={onClickSelectProduct}
            className="btn-style__1 btn-size__6"
          >
            상품 조회
          </button>
        </div>
        <div className="filter-side__2">
          <button
            type="button"
            onClick={onClickDelete}
            className="btn-style__1 btn-size__6"
          >
            선택 삭제
          </button>
        </div>
      </div>
      <div className="table-wrap table-style__2">
        <table className="table">
          <caption>개별 상품 연결</caption>
          <colgroup>
            <col width="40px" />
            <col width="100px" />
            <col />
            <col width="90px" />
            <col />
          </colgroup>
          <TableHead columns={COLUMNS}>
            <th scope="col">
              <CheckBox
                id="checkSpecial0"
                name="checkSpecial"
                type={CheckBoxType.basic}
                label="전체선택"
                labelHidden
                checked={isCheckedAll}
                onChange={onClickSelectAll}
              />
            </th>
          </TableHead>
          {isLoading ? null : (
            <tbody>
              {productsLength > 0 ? (
                currentPageProducts.map(
                  (
                    {
                      productNo,
                      productName,
                      sellStatusDesc,
                      displayCategoryName1,
                      displayCategoryName2,
                      displayCategoryName3,
                      displayCategoryName4,
                    },
                    index,
                  ) => {
                    const productCategory =
                      sellStatusDesc === '판매중'
                        ? getProductCategoryString(
                            displayCategoryName1,
                            displayCategoryName2,
                            displayCategoryName3,
                            displayCategoryName4,
                          )
                        : '-';

                    return (
                      <ProductItem
                        key={productNo}
                        productNo={productNo}
                        productName={productName}
                        productStatus={sellStatusDesc}
                        productCategory={productCategory}
                        checked={isChecked(index)}
                        onClickProductName={() => onClickProductName(productNo)}
                        onChangeCheckBox={() => onClickCheckBox(index)}
                      />
                    );
                  },
                )
              ) : (
                <EmptyProduct onClickSelectProduct={onClickSelectProduct} />
              )}
            </tbody>
          )}
        </table>
      </div>
      {productsLength > DISPLAY_PRODUCT_PAGE_SIZE && (
        <Pagination
          currentPage={currentPage}
          onChange={onChangePage}
          pageSize={DISPLAY_PRODUCT_PAGE_SIZE}
          total={productsLength}
        />
      )}
    </>
  );
};

export default ProductTable;
