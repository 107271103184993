import React from 'react';

import AlertButton from './AlertButton';

export interface IAlertBottom {
  confirmText: string;
  confirmAction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  cancelText?: string;
  cancelAction?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

const AlertBottom = ({
  confirmText,
  confirmAction = () => {},
  cancelText,
  cancelAction,
}: IAlertBottom) => {
  return (
    <>
      {cancelText && (
        <AlertButton type="CANCEL" text={cancelText} onClick={cancelAction} />
      )}
      <AlertButton type="CONFIRM" text={confirmText} onClick={confirmAction} />
    </>
  );
};

export default AlertBottom;
