import React, { useCallback, useEffect, useRef, useState } from 'react';

import CalendarHeader from './CalendarHeader';
import CalendarTable from './CalendarTable';

const MONTHS: string[] = Array.from({ length: 12 })
  .fill(0)
  .map((a, i) => `${i + 1}`);
const DAYS: string[] = ['일', '월', '화', '수', '목', '금', '토'];

export type RangeStepType = 1 | -1;
export interface IHelpers {
  monthList: string[];
  dayList: string[];
  yearList: (currentYear: number, max?: number, min?: number) => number[];
  range: (start: number, stop: number, step: RangeStepType) => number[];
  weekName: (dayOfWeek: number) => string;
  monthName: (month: number) => string;
  isSameDay: (a: Date, b: Date) => boolean;
  isPast: (day: Date, minDate: Date) => boolean;
  isExpired: (day: Date, maxDate: Date) => boolean;
}
export interface ICalendarProps {
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  onSelectDay: (day: Date | null) => void;
  minYear?: number;
  maxYear?: number;
  show: boolean;
  setShow: (v: boolean) => void;
}

export const helpers = (months: string[], days: string[]): IHelpers => {
  const weekName = (dayOfWeek: number) => days[dayOfWeek];
  const monthName = (month: number) => months[month + 1];
  const range = (start: number, stop: number, step: RangeStepType) => {
    const size = Math.round((stop - start) / step + 1);

    return Array.from({ length: size }, (_, i) => start + i * step);
  };
  const isSameDay = (a: Date, b: Date) =>
    a &&
    b &&
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate();
  const isPast = (day: Date, minDate: Date) => {
    const compared = new Date(minDate);
    const selected = new Date(day);
    // 시간을 지우고 날짜만 비교한다.

    return day && minDate
      ? new Date(
          Date.UTC(
            selected.getFullYear(),
            selected.getMonth(),
            selected.getDate(),
          ),
        ) <
          new Date(
            Date.UTC(
              compared.getFullYear(),
              compared.getMonth(),
              compared.getDate(),
            ),
          )
      : false;
  };
  const isExpired = (day: Date, maxDate: Date) =>
    day && maxDate ? Math.floor(Number(day) - Number(maxDate)) > 0 : false;
  const yearList = (currentYear: number, max = 0, min = 0) =>
    range(currentYear + max, currentYear - min, -1);
  const monthList = months;
  const dayList = days;

  return {
    monthList,
    dayList,
    yearList,
    range,
    weekName,
    monthName,
    isSameDay,
    isPast,
    isExpired,
  };
};

export const calendarHelpers = helpers(MONTHS, DAYS);
const { monthName } = calendarHelpers;
const Calendar = ({
  value = new Date(),
  minDate,
  maxDate,
  show,
  setShow,
  minYear = 0,
  maxYear = 3,
  onSelectDay = () => {},
}: ICalendarProps) => {
  const [month, setMonth] = useState(value.getMonth());
  const [year, setYear] = useState(value.getFullYear());
  const calendarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value && minDate && value < minDate) {
      setMonth(new Date(minDate).getMonth());
      setYear(new Date(minDate).getFullYear());
    }
  }, [value, minDate]);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (calendarRef.current) {
        if (!calendarRef.current.contains(event.target)) {
          setShow(false);
        }
      }
    },
    [setShow],
  );

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, show]);

  return (
    <div className="st-layer" ref={calendarRef}>
      <div className="st-calendar">
        <CalendarHeader
          title={`${year}년 ${monthName(month - 1)}월`}
          month={month}
          year={year}
          minYear={minYear}
          maxYear={maxYear}
          handleYear={setYear}
          handleMonth={setMonth}
          calendarHelpers={calendarHelpers}
        />
        <CalendarTable
          month={month}
          year={year}
          minDate={minDate}
          maxDate={maxDate}
          handleSelectDay={onSelectDay}
          value={value}
          calendarHelpers={calendarHelpers}
        />
      </div>
    </div>
  );
};

export default Calendar;
