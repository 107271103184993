/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import React from 'react';

export enum CheckBoxType {
  basic = 'check-style__1',
  toggle = 'check-style__2',
  keyword = 'check-style__31',
  location = 'check-style__32',
}

export interface CheckBoxProps {
  /** 체크박스 id */
  id: string;
  name: string;
  /** basic, toggle, keyword, location */
  type: CheckBoxType;
  /** 체크박스 라벨. keyword, location type은 필수로 넣어주세요. */
  label?: string;
  labelClass?: string;
  /** 체크 여부 */
  checked: boolean;
  /** 라벨 숨기기 여부. basic type만 사용해주세요. */
  labelHidden?: boolean;
  /** 체크박스 비활성화 여부 */
  disabled?: boolean;
  /** 클릭했을 때 실행되는 함수 */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  logArea?: string;
  logLabel?: string;
  logBody?: string;
}

/** 체크박스 기본 컴포넌트입니다. */
const CheckBox = ({
  id,
  name,
  type,
  label,
  labelClass,
  checked,
  labelHidden,
  disabled,
  onChange,
  logArea,
  logLabel,
  logBody,
}: CheckBoxProps) => (
  <div className={classNames(type, { 'label-hidden': labelHidden })}>
    <input
      type="checkbox"
      className="inp"
      name={name}
      id={id}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      data-log-actionid-area={logArea}
      data-log-actionid-label={logLabel}
      data-log-body={logBody}
    />
    <label htmlFor={id} className={classNames('lab', labelClass)}>
      {label}
    </label>
  </div>
);

export default CheckBox;
