import { RootState } from 'modules';
import {
  CATEGORY_DEFAULT,
  CustomDetailModifiedType,
  ICustomDetailModified,
  setCustomDetail,
  setCustomDetailModified,
  setCustomDetailModifiedInModal,
  setSelectedCustomCategory,
} from 'modules/category';
import { closeDialog, DialogType } from 'modules/dialog';
import { setFinalSelectedProducts, setProductTablePage } from 'modules/product';
import {
  addProfileProductFromSearchResult,
  addProfilePromotionFromSearchResult,
} from 'modules/profile';
import { addLiveProductListFromSearchResult } from 'modules/promotion';
import {
  addRecommendItemFromSearchResult,
  addRecommendPromotionFromSearchResult,
} from 'modules/recommend';
import {
  addSpecialItemFromSearchResult,
  getSpecialByIdAsync,
  setAddSpecialSelectedRadio,
  setExhibitionNumberError,
  setExhibitionNumberInput,
} from 'modules/special';
import { post11TalkAgreementsAsync } from 'modules/user';
import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { distinctArray } from 'utils';

const categoryCustomDetailModifiedHelper = (
  map: CustomDetailModifiedType,
  key: number,
) => {
  if (map.has(key)) {
    if ((map.get(key) as ICustomDetailModified)?.typeCode === 'DELETE') {
      map.delete(key);

      return true;
    }
  }

  return false;
};

type IDialogBottomMapper = {
  [index in DialogType]: {
    submit: () => void;
    cancel?: () => void;
  };
};

const DialogBottom = () => {
  const dispatch = useDispatch();
  const { type } = useSelector((state: RootState) => state.dialog);
  const { currentSelectedProducts, finalSelectedProducts } = useSelector(
    (state: RootState) => state.product,
    shallowEqual,
  );
  const currentSelectedCustomInModal = useSelector(
    (state: RootState) => state.category.currentSelectedCustomInModal,
  );
  const customDetailModified = useSelector(
    (state: RootState) => state.category.customDetailModified,
  );
  const customDetailModifiedInModal = useSelector(
    (state: RootState) => state.category.customDetailModifiedInModal,
  );
  const addSpecialSelectedRadio = useSelector(
    (state: RootState) => state.special.addSpecialSelectedRadio,
  );
  const exhibitionNumberInput = useSelector(
    (state: RootState) => state.special.exhibitionNumberInput,
  );

  const dialogBottomMapper: IDialogBottomMapper = {
    SelectProduct: {
      submit() {
        dispatch(addProfileProductFromSearchResult());
        dispatch(closeDialog());
      },
      cancel() {},
    },
    SelectProducts: {
      submit() {
        const map = new Map(customDetailModified);
        currentSelectedProducts.forEach(({ productNo: key }) => {
          if (categoryCustomDetailModifiedHelper(map, key)) {
            return;
          }
          map.set(key, {
            displayPriority: CATEGORY_DEFAULT.ORDER,
            typeCode: finalSelectedProducts.every(
              ({ productNo }) => productNo !== key,
            )
              ? 'SAVE'
              : 'UPDATE',
          });
        });
        dispatch(setCustomDetailModified(map));

        dispatch(
          setFinalSelectedProducts(
            distinctArray(
              [...currentSelectedProducts, ...finalSelectedProducts],
              'productNo',
            ),
          ),
        );
        dispatch(setProductTablePage(1));
        dispatch(closeDialog());
      },
      cancel() {},
    },
    SelectProductsFromRecommendCardView: {
      submit() {
        dispatch(addRecommendItemFromSearchResult());
      },
      cancel() {},
    },
    SelectProductsFromRecommendVideo: {
      submit() {
        dispatch(addRecommendItemFromSearchResult());
      },
      cancel() {},
    },
    SelectProductsFromPromotionDetail: {
      submit() {
        dispatch(addLiveProductListFromSearchResult());
      },
      cancel() {},
    },
    SelectPromotionFromRecommendPromotion: {
      submit() {
        dispatch(addRecommendPromotionFromSearchResult());
        dispatch(closeDialog());
      },
      cancel() {},
    },
    SelectPromotionFromProfile: {
      submit() {
        dispatch(addProfilePromotionFromSearchResult());
      },
      cancel() {},
    },
    SelectSpecial: {
      submit() {
        if (addSpecialSelectedRadio === 'NUMBER') {
          if (!exhibitionNumberInput) {
            dispatch(setExhibitionNumberError(true));

            return;
          }
          dispatch(
            getSpecialByIdAsync.request({
              exhibitionNo: Number(exhibitionNumberInput),
            }),
          );

          return;
        }
        dispatch(addSpecialItemFromSearchResult());
      },
      cancel() {},
    },
    SelectCategory: {
      submit() {
        const map = new Map(customDetailModified);

        Array.from(customDetailModifiedInModal).forEach(([keyValue, prop]) => {
          if (categoryCustomDetailModifiedHelper(map, keyValue)) {
            return;
          }
          map.set(keyValue, prop);
        });
        dispatch(setCustomDetailModified(map));

        dispatch(setCustomDetail(currentSelectedCustomInModal));
        dispatch(setSelectedCustomCategory([]));
        dispatch(setCustomDetailModifiedInModal(new Map()));
        dispatch(closeDialog());
      },
      cancel() {
        dispatch(setSelectedCustomCategory([]));
      },
    },
    Talk11UsedInfo: {
      submit() {
        dispatch(post11TalkAgreementsAsync.request());
        dispatch(closeDialog());
      },
      cancel() {},
    },
    DomainSettingIntro: {
      submit() {
        dispatch(closeDialog());
      },
    },
  };
  const { submit, cancel } = useMemo(() => dialogBottomMapper[type], [
    dialogBottomMapper,
    type,
  ]);
  const onClickSubmit = useCallback(() => {
    submit();
  }, [submit]);

  const onClickCancel = useCallback(() => {
    if (cancel) {
      cancel();
    }
    dispatch(closeDialog());
  }, [cancel, dispatch]);

  useEffect(
    () => () => {
      dispatch(setAddSpecialSelectedRadio('SELLER'));
      dispatch(setExhibitionNumberInput(''));
      dispatch(setExhibitionNumberError(false));
    },
    [dispatch],
  );

  return (
    <div className="dialog-bottom">
      {cancel && (
        <button
          type="button"
          className="btn-style__2 btn-size__1"
          onClick={onClickCancel}
        >
          {type !== 'Talk11UsedInfo' ? '취소' : '비동의'}
        </button>
      )}

      <button
        type="button"
        className="btn-style__3 btn-size__1"
        onClick={onClickSubmit}
      >
        {type !== 'Talk11UsedInfo' ? '확인' : '동의'}
      </button>
    </div>
  );
};

export default DialogBottom;
