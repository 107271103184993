import DatePicker from 'components/common/DatePicker';
import { addDays } from 'date-fns';
import { RootState } from 'modules';
import {
  setNoticeDetailEndDate,
  setNoticeDetailStartDate,
} from 'modules/notice/actions';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, formatNoticeDate } from 'utils/format';

const Calendar = () => {
  const dispatch = useDispatch();
  const { displayBeginDate, displayEndDate } = useSelector(
    (state: RootState) => state.notice.detail,
  );
  const minDate = useMemo(() => addDays(new Date(), -1), []);

  return (
    <div className="c-date__calender">
      <DatePicker
        title="시작일"
        placeholder="시작일"
        value={formatDate(displayBeginDate)}
        source={displayBeginDate}
        minDate={minDate}
        onChange={(date: Date) => {
          dispatch(setNoticeDetailStartDate(formatNoticeDate(date)));
        }}
      />
      <span className="section-bar">~</span>
      <DatePicker
        title="마지막일"
        placeholder="마지막일"
        value={formatDate(displayEndDate)}
        source={displayEndDate}
        minDate={minDate}
        onChange={(date: Date) => {
          dispatch(setNoticeDetailEndDate(formatNoticeDate(date)));
        }}
      />
    </div>
  );
};

export default Calendar;
