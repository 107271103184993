import useAnimationFrame from 'hooks/useAnimationFrame';
import { RootState } from 'modules';
import { menuIdType, setSelectedModule } from 'modules/display';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Content from './Content';
import Menu from './Menu';

const Home: React.FC = () => {
  const [fixed, setFixed] = useState('');
  const divRef = useRef<HTMLDivElement>(null);
  const sortable = useSelector((state: RootState) => state.display.sortable);
  const moduleData = useSelector(
    (state: RootState) => state.display.moduleData[0],
  );
  const id = useSelector((state: RootState) => state.display.selectedModule.id);
  const dispatch = useDispatch();

  const calcFixed = useCallback(() => {
    if (id === menuIdType.special) {
      setFixed('');
    } else if (
      !sortable &&
      divRef.current &&
      divRef.current.getBoundingClientRect().height > window.innerHeight
    ) {
      if (divRef.current.getBoundingClientRect().bottom < window.innerHeight) {
        setFixed('fixed_end');
      } else if (divRef.current.getBoundingClientRect().top < 0) {
        setFixed('fixed');
      } else {
        setFixed('');
      }
    } else {
      setFixed('');
    }
  }, [id, sortable]);

  useAnimationFrame(() => {
    calcFixed();
  });

  useEffect(() => {
    if (moduleData) {
      dispatch(setSelectedModule(moduleData));
    }
  }, [dispatch, moduleData]);

  return (
    <div
      className={`st-tab__cont cont-type__2 ${fixed}`}
      ref={divRef}
      id="soContent"
    >
      <Menu />
      <Content fixed={fixed} />
    </div>
  );
};

export default Home;
