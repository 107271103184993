import { TextInputWithCheck } from 'components/common';
import { RootState } from 'modules';
import { setNoticeDetailTitle } from 'modules/notice/actions';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Title = () => {
  const dispatch = useDispatch();
  const { title } = useSelector((state: RootState) => state.notice.detail);

  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      if (value.length <= 50) {
        dispatch(setNoticeDetailTitle(value));
      }
    },
    [dispatch],
  );

  return (
    <TextInputWithCheck
      width="wide"
      max={50}
      value={title}
      title=""
      onChange={onChangeTitle}
    />
  );
};

export default Title;
