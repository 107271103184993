import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

/**
 * [isCheckedAll, toggleCheckAll, isChecked, toggleCheckBox]
 */

const useCheckbox = (
  checkBoxLength: number,
): [
  boolean,
  () => void,
  (index: number) => boolean,
  (index: number) => void,
  boolean[],
  Dispatch<SetStateAction<boolean[]>>,
] => {
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [checkBoxes, setCheckboxes] = useState<boolean[]>([]);

  const isChecked = useCallback(
    (index: number): boolean => !!checkBoxes[index],
    [checkBoxes],
  );

  const toggleCheckAll = useCallback(() => {
    setCheckboxes(checkBoxes.map(() => !isCheckedAll));
    setIsCheckedAll(!isCheckedAll);
  }, [isCheckedAll, checkBoxes]);

  const toggleCheckBox = useCallback(
    (index: number) => {
      setCheckboxes(
        checkBoxes.map((isBoxChecked, idx) =>
          index === idx ? !isBoxChecked : isBoxChecked,
        ),
      );
    },
    [checkBoxes],
  );

  useEffect(() => {
    if (checkBoxes.length) {
      setIsCheckedAll(checkBoxes.every(isBoxChecked => isBoxChecked));
    }
  }, [checkBoxes]);

  useEffect(() => {
    setCheckboxes(Array(checkBoxLength).fill(false));
  }, [checkBoxLength]);

  return [
    isCheckedAll,
    toggleCheckAll,
    isChecked,
    toggleCheckBox,
    checkBoxes,
    setCheckboxes,
  ];
};

export default useCheckbox;
