/* eslint-disable no-param-reassign */
import { TextInput } from 'components/common';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { putStoreNameAsync, setStoreNameInput } from 'modules/user';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INPUT_REGEX, isValidTextInput } from 'utils/validate';

const StoreName = ({
  isStoreNameConfirmed,
}: {
  isStoreNameConfirmed: React.MutableRefObject<{
    confirm: boolean;
  }>;
}) => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const storeName =
    useSelector((state: RootState) => state.user.basicInfo.details.storeName) ??
    '';
  const setStoreName = useCallback(
    (string: string) => {
      dispatch(setStoreNameInput(string));
    },
    [dispatch],
  );
  const onChangeStoreName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (value !== storeName) {
        isStoreNameConfirmed.current.confirm = false;
      }
      if (isValidTextInput(value)) {
        setStoreName(value);
      }
    },
    [isStoreNameConfirmed, setStoreName, storeName],
  );

  const onClickPutName = useCallback(() => {
    if (!isValidTextInput(storeName, { max: 25 })) {
      dispatch(openAlert('STORE_NAME_INVALID_LENGTH'));

      return;
    }
    if (!isValidTextInput(storeName, { type: 'title' })) {
      dispatch(openAlert('STORE_NAME_SPECIAL_CHAR'));
      setStoreName(storeName.replace(INPUT_REGEX.notMatchTitle, ''));

      return;
    }
    isStoreNameConfirmed.current.confirm = true;
    dispatch(putStoreNameAsync.request({ storeNo, storeName }));
  }, [dispatch, isStoreNameConfirmed, setStoreName, storeName, storeNo]);

  return (
    <TextInput
      width="w-350"
      value={storeName}
      title=""
      placeholder="25자 이내로 입력해주세요."
      onChange={onChangeStoreName}
    >
      <button
        type="button"
        onClick={onClickPutName}
        className="btn-style__1 btn-size__4"
        data-log-actionid-area="store_name"
        data-log-actionid-label="change"
      >
        변경
      </button>
      <p className="text_info">특수문자는 _ 만 가능 합니다.</p>
    </TextInput>
  );
};

export default StoreName;
