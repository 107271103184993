import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type AsyncTaskType = 'IDLE' | 'LOADING' | 'SUCCESS' | 'FAILURE';

export interface IAsyncTask {
  [key: string]: AsyncTaskType;
}

export const startAsyncTask = createAction('@ASYNC_TASK/START')<string>();
export const finishAsyncTask = createAction('@ASYNC_TASK/FINISH')<string>();
export const failAsyncTask = createAction('@ASYNC_TASK/FAILURE')<string>();
export const resetAsyncTask = createAction('@ASYNC_TASK/RESET')<string>();

const actions = {
  startAsyncTask,
  finishAsyncTask,
  failAsyncTask,
  resetAsyncTask,
};

const initialState: IAsyncTask = {};

export default createReducer<IAsyncTask, ActionType<typeof actions>>(
  initialState,
  {
    '@ASYNC_TASK/START': (state, action) => ({
      ...state,
      [action.payload]: 'LOADING',
    }),
    '@ASYNC_TASK/FINISH': (state, action) => ({
      ...state,
      [action.payload]: 'SUCCESS',
    }),
    '@ASYNC_TASK/FAILURE': (state, action) => ({
      ...state,
      [action.payload]: 'FAILURE',
    }),
    '@ASYNC_TASK/RESET': (state, action) => ({
      ...state,
      [action.payload]: 'IDLE',
    }),
  },
);
