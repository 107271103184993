import classNames from 'classnames';
import { ToastMessage } from 'components/common';
import { RootState } from 'modules';
import { openAlertWithId } from 'modules/alert';
import { putMenuPriorityAsync, setSortable } from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';

interface IProps {
  sortable: boolean;
}

const DefaultButton = ({ sortable }: IProps) => {
  const touched = useSelector((state: RootState) => state.display.touched);
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className={classNames('btn-style__2', 'btn-size__1 wide')}
      onClick={e => {
        if (window.rakeLog) {
          window.rakeLog.sendRakeLog(e.target);
        }
        if (touched) {
          dispatch(
            openAlertWithId('MOVE_MENU_WITHOUT_SAVING_DATA', 'sortable'),
          );
        } else {
          dispatch(setSortable(!sortable));
        }
      }}
      data-log-actionid-area="order_edit"
      data-log-actionid-label="button"
    >
      <span className="icon icon-order" />
      순서 편집
    </button>
  );
};

const ModuleMenuButton: React.FC<IProps> = ({ sortable }) => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const moduleData = useSelector(
    (state: RootState) => state.display.moduleData,
  );
  const tempModuleData = useSelector(
    (state: RootState) => state.display.tempModuleData,
  );

  return (
    <div className="cont-menu__edit">
      {sortable ? (
        <button
          type="button"
          className={classNames('btn-style__3', 'btn-size__1 wide')}
          onClick={() => {
            dispatch(
              putMenuPriorityAsync.request({
                storeNo,
                modules: [moduleData[0], ...tempModuleData].map(
                  (item, index) => ({
                    moduleNo: item.id,
                    displayPriority: index + 1,
                  }),
                ),
              }),
            );
          }}
          data-log-actionid-area="order_edit"
          data-log-actionid-label="top_save"
        >
          <span className="icon icon-order" />
          순서 저장
        </button>
      ) : (
        <DefaultButton sortable={sortable} />
      )}
      <ToastMessage
        action={getType(putMenuPriorityAsync.request)}
        successText="저장되었습니다."
        failureText="순서변경 저장에 실패하였습니다. 다시 시도해주세요."
      />
    </div>
  );
};

export default ModuleMenuButton;
