import { ToastMessage, WriteBox } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import LocalAlert from 'components/layouts/default-layout/Alert/LocalAlert';
import useStoreNameConfirm from 'hooks/useStoreNameConfirm';
import { ChangeEventType } from 'hooks/useTextInput';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  getRoadShopListAsync,
  getTagsAsync,
  getUserBasicInfoAsync,
  putUserBasicInfoAsync,
  saveUserBasicInfo,
  setHasOfflineShopAddress,
  setInstagramAddress,
  setIsUsingPrivateDomain,
  setStoreDescription,
} from 'modules/user/actions';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import { getSalesTimeByOfflineShopInfo } from 'utils/format';
import { isValidTextInput } from 'utils/validate';

import { ImageDescription, InstagramForm, OfflineRadio } from './components';
import FashionTag from './FashionTag';
import PrivateDomain from './PrivateDomain';
import ProfileImage from './ProfileImage';
import PublicDomain from './PublicDomain';
import RoadInputAddress from './RoadInputAddress';
import RoadShopTag from './RoadShopTag';
import SaleTime from './SaleTime';
import StoreLocation from './StoreLocation';
import StoreName from './StoreName';
import StoreUrlRadio from './StoreUrlRadio';
import useAddress from './useAddress';
import useStoreDomain from './useStoreDomain';

const BasicInfo = () => {
  const dispatch = useDispatch();
  const saveButtonRef = useRef(null);
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const details = useSelector(
    (state: RootState) => state.user.basicInfo.details,
  );
  const instagramAddress = useMemo(() => details.instagramAddress ?? '', [
    details.instagramAddress,
  ]);
  const hasOfflineShopAddress = useMemo(
    () => details.hasOfflineShopAddress ?? false,
    [details.hasOfflineShopAddress],
  );
  const salesTime = useMemo(
    () => getSalesTimeByOfflineShopInfo(details.offlineShopInfo),
    [details.offlineShopInfo],
  );

  const isShowOfflineShopInfo = useMemo(
    () => details?.hasRoadShopInfo || hasOfflineShopAddress,
    [details, hasOfflineShopAddress],
  );
  const {
    isUsingPrivateDomain,
    isNeedPrivateDomainConfirm,
    resetPrivateDomainGuideText,
  } = useStoreDomain();

  const storeDescription =
    useSelector(
      (state: RootState) => state.user.basicInfo.details.introduceContents,
    ) ?? '';
  const onChangeStoreDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    if (isValidTextInput(value, { max: 100 })) {
      dispatch(setStoreDescription(value));
    }
  };

  const onChangeInstagram = useCallback(
    (e: ChangeEventType) => {
      const { value } = e.target;
      if (isValidTextInput(value, { type: 'insta' })) {
        dispatch(setInstagramAddress(value));
      }
    },
    [dispatch],
  );

  const {
    offlineShopAddress,
    storeDetailAddress,
    locationInfoImageUrl,
  } = useAddress();

  const isRoadShopSeller = useMemo(() => details?.hasRoadShopInfo ?? false, [
    details,
  ]);

  const onClickStoreUrlRadio = useCallback(
    (isPrivate: boolean) => {
      dispatch(setIsUsingPrivateDomain(isPrivate));
    },
    [dispatch],
  );

  const onChangeUseOffline = useCallback(
    (isUsed: boolean) => {
      dispatch(setHasOfflineShopAddress(isUsed));
    },
    [dispatch],
  );

  const {
    isStoreNameConfirmed,
    isShowStoreNameConfirmedAlert,
    setIsShowStoreNameConfirmedAlert,
    onClickStoreNameConfirmAlert,
    onClickStoreNameCancelAlert,
  } = useStoreNameConfirm(saveButtonRef.current);

  const onClickSave = useCallback(() => {
    if (!isStoreNameConfirmed.current.confirm) {
      setIsShowStoreNameConfirmedAlert(true);

      return;
    }

    if (isNeedPrivateDomainConfirm) {
      dispatch(openAlert('NEED_PRIVATE_DOMAIN_CONFIRM'));

      return;
    }

    if (
      hasOfflineShopAddress &&
      (offlineShopAddress.trim().length === 0 ||
        storeDetailAddress.trim().length === 0 ||
        Object.values(salesTime).some(time => !time))
    ) {
      dispatch(openAlert('INPUT_OFFLINE_INFO'));

      return;
    }

    dispatch(saveUserBasicInfo());
  }, [
    dispatch,
    hasOfflineShopAddress,
    isNeedPrivateDomainConfirm,
    isStoreNameConfirmed,
    offlineShopAddress,
    salesTime,
    setIsShowStoreNameConfirmedAlert,
    storeDetailAddress,
  ]);

  useEffect(() => {
    dispatch(getRoadShopListAsync.request());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTagsAsync.request());
  }, [dispatch]);

  useEffect(() => {
    if (storeNo) {
      dispatch(getUserBasicInfoAsync.request({ storeNo }));
    }
  }, [dispatch, storeNo]);

  useEffect(() => {
    return () => {
      resetPrivateDomainGuideText();
    };
  }, [dispatch, resetPrivateDomainGuideText]);

  return (
    <div className="content-in" id="soContent">
      <DocumentTitle title="스토어 기본 정보 - 스토어 관리" />
      <div className="view-title title-style__2">
        <h2 className="title">스토어 기본 정보 관리</h2>
      </div>
      <div className="view-cont cont-type__full">
        <div className="write-box__3">
          <WriteBox title="스토어명" essential>
            <StoreName isStoreNameConfirmed={isStoreNameConfirmed} />
          </WriteBox>
          <WriteBox title="스토어 프로필">
            <ProfileImage />
            <ImageDescription />
          </WriteBox>
          <WriteBox title="스토어 URL">
            <div className="c-radio__tab">
              <StoreUrlRadio
                isPrivateDomain={isUsingPrivateDomain}
                onClickStoreUrlRadio={onClickStoreUrlRadio}
                onClickSave={onClickSave}
              />
              {isUsingPrivateDomain ? (
                <div className="tab_cont">
                  <PrivateDomain />
                </div>
              ) : (
                <PublicDomain />
              )}
            </div>
          </WriteBox>
          <WriteBox title="스토어 소개글">
            <div className="c-textarea wide">
              <textarea
                rows={4}
                cols={100}
                className="textarea"
                placeholder="스토어 소개글 작성 시 100자 이내로 작성 가능하며 개인정보를 포함해서는 안됩니다."
                value={storeDescription}
                onChange={onChangeStoreDescription}
              />
            </div>
          </WriteBox>
          <WriteBox title="인스타그램">
            <InstagramForm
              url={instagramAddress}
              onChange={onChangeInstagram}
            />
          </WriteBox>
          <RoadShopTag isRoadShopSeller={isRoadShopSeller} />
          <WriteBox title="오프라인 매장 주소">
            <div className="c-radio__tab">
              <OfflineRadio
                isUsed={isShowOfflineShopInfo}
                isRoadShopSeller={isRoadShopSeller}
                onChangeUseOffline={onChangeUseOffline}
              />
              {isShowOfflineShopInfo && (
                <div className="tab_cont">
                  <RoadInputAddress />
                  <SaleTime salesTime={salesTime} />
                  <StoreLocation imageUrl={locationInfoImageUrl} />
                </div>
              )}
            </div>
          </WriteBox>
          <FashionTag />
        </div>
      </div>
      <ToastMessage
        action={getType(putUserBasicInfoAsync.request)}
        successText="저장되었습니다."
      />
      {isShowStoreNameConfirmedAlert && (
        <LocalAlert
          bodyType="STORE_NAME_NOT_CONFIRMED"
          confirmText="확인"
          onClickConfirm={onClickStoreNameConfirmAlert}
          cancelText="취소"
          onClickCancel={onClickStoreNameCancelAlert}
        />
      )}
      <div className="cont-btn">
        <button
          type="submit"
          onClick={onClickSave}
          ref={saveButtonRef}
          className="btn-style__3 btn-size__1"
          data-log-actionid-area="information"
          data-log-actionid-label="save"
        >
          저장
        </button>
      </div>
    </div>
  );
};

export default BasicInfo;
