import {
  deletePrivateDomain,
  get11TalkAgreements,
  getHeaderInfo,
  getPageViewCounts,
  getPageViewDownload,
  getRoadShopList,
  getTagList,
  getUserBasicInfo,
  post11TalkAgreements,
  postPrivateDomain,
  putPrivateDomain,
  putStoreName,
  putStoreUrl,
  putUserBasicInfo,
} from 'apis/user';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  deletePrivateDomainAsync,
  get11TalkAgreementsAsync,
  getHeaderInfoAsync,
  getPageViewCountsAsync,
  getPageViewDownloadAsync,
  getRoadShopListAsync,
  getTagsAsync,
  getUserBasicInfoAsync,
  IPutStoreBasicInfoResponse,
  IPutStoreNameResponse,
  IPutStoreUrlResponse,
  ISavePrivateDomainResponse,
  IUser,
  IUserBasicInfoResponse,
  post11TalkAgreementsAsync,
  postPrivateDomainAsync,
  putPrivateDomainAsync,
  putStoreNameAsync,
  putStoreUrlAsync,
  putUserBasicInfoAsync,
  saveUserBasicInfo,
  setHasPrivateDomainIPError,
  setIsPrivateDomainAlreadyUsed,
  setIsStoreUrlLocked,
  setPrivateDomainOk,
  setStoreImage,
  setStoreName,
  setStorePersonalDomainUrl,
} from 'modules/user';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { getSalesTimeByOfflineShopInfo } from 'utils/format';
import createAsyncSaga from 'utils/sagaUtils';

const getStoreAdminInfoSaga = createAsyncSaga(
  getHeaderInfoAsync,
  getHeaderInfo,
);

const getUserBasicInfoSaga = createAsyncSaga(
  getUserBasicInfoAsync,
  getUserBasicInfo,
);

function* getUserBasicInfoSuccessSaga() {
  const privateDomain = yield select(
    (state: RootState) => state.user.basicInfo.details.storePersonalDomainUrl,
  ) ?? '';
  yield put(setPrivateDomainOk(!!privateDomain));
}

function* saveUserBasicInfoSaga() {
  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const {
    elevenStreetDomainUrl = '',
    instagramAddress = '',
    introduceContents = '',
    offlineShopInfo,
    profileImageUrl = '',
    hasOfflineShopAddress = false,
    hasRoadShopInfo = false,
  }: IUserBasicInfoResponse = yield select(
    (state: RootState) => state.user.basicInfo.details,
  );
  const buildingManageNo = yield select(
    (state: RootState) => state.user.basicInfo.addr.buildMngNo,
  ) ?? '';
  const storeDetailAddress = yield select(
    (state: RootState) => state.user.basicInfo.addrDetail,
  );
  const { selectedRoadShops, selectedTags }: IUser = yield select(
    (state: RootState) => state.user,
  );
  const isUsingPrivateDomain = yield select(
    (state: RootState) => state.user.basicInfo.isUsingPrivateDomain,
  );
  const privateDomain = yield select(
    (state: RootState) => state.user.basicInfo.privateDomain,
  );

  yield createAsyncSaga(
    putUserBasicInfoAsync,
    putUserBasicInfo,
  )(
    putUserBasicInfoAsync.request({
      storeNo,
      putUserBasicInfoBody: {
        ...getSalesTimeByOfflineShopInfo(offlineShopInfo),
        elevenStreetDomainUrl,
        instagramAddress,
        introduceContents,
        locationInfoImageUrl: offlineShopInfo?.locationInfoImageUrl ?? '',
        buildingManageNo,
        storeDetailAddress,
        profileImageUrl,
        roadShopAreaNo: Number(selectedRoadShops[0]?.tagNo),
        storeStyleTagNoList: selectedTags.map(({ tagNo }) => Number(tagNo)),
        useElevenStreetDomain: !isUsingPrivateDomain,
        useStorePersonalDomain: isUsingPrivateDomain,
        storePersonalDomainUrl: privateDomain,
        useOfflineShopInfo: hasOfflineShopAddress,
        useRoadShop: hasRoadShopInfo,
      },
    }),
  );
}

const putStoreNameSaga = createAsyncSaga(putStoreNameAsync, putStoreName);
function* putStoreNameSuccessSaga(
  action: PayloadAction<string, IPutStoreNameResponse[]>,
) {
  const [data] = action.payload;
  switch (data.resultCode) {
    case 200: {
      yield put(openAlert('STORE_NAME_OK'));
      const storeNameInput = yield select(
        (state: RootState) => state.user.basicInfo.details.storeName,
      );
      yield put(setStoreName(storeNameInput));
      break;
    }
    case 1001:
      yield put(openAlert('STORE_NAME_INVALID_LENGTH'));
      break;
    case 1002:
      yield put(openAlert('STORE_NAME_SPECIAL_CHAR'));
      break;
    case 1003:
      yield put(openAlert('STORE_NAME_ALREADY_USED'));
      break;
    case 1004:
      yield put(openAlert('STORE_NAME_NO_SELLER_ID'));
      break;
    case 1005:
      yield put(openAlert('STORE_NAME_NOT_EXIST'));
      break;
    default:
      yield put(openAlert('SAVE_CATCH'));
      break;
  }
}
function* putStoreNameFailureSaga() {
  yield put(openAlert('SAVE_CATCH'));
}

const putStoreUrlSaga = createAsyncSaga(putStoreUrlAsync, putStoreUrl);
function* putStoreUrlSuccessSaga(
  action: PayloadAction<string, IPutStoreUrlResponse[]>,
) {
  const [data] = action.payload;

  switch (data.resultCode) {
    case 200: {
      yield put(openAlert('STORE_URL_OK'));
      yield put(setIsStoreUrlLocked(true));
      break;
    }
    case -1000:
      yield put(openAlert('STORE_URL_NO_SELLER_ID'));
      break;
    case -2000:
      yield put(openAlert('STORE_URL_ALREADY_SET'));
      break;
    case -3000:
      yield put(openAlert('STORE_URL_INVALID'));
      break;
    case -4000:
      yield put(openAlert('STORE_URL_INVALID_LENGTH'));
      break;
    case -5000:
      yield put(openAlert('STORE_URL_INVALID'));
      break;
    case -6000:
      yield put(openAlert('STORE_URL_ALREADY_USED'));
      break;
    default:
      yield put(openAlert('SAVE_CATCH'));
      break;
  }
}
function* putStoreUrlFailureSaga() {
  yield put(openAlert('SAVE_CATCH'));
}

const postPrivateDomainSaga = createAsyncSaga(
  postPrivateDomainAsync,
  postPrivateDomain,
);
const putPrivateDomainSaga = createAsyncSaga(
  putPrivateDomainAsync,
  putPrivateDomain,
);
const deletePrivateDomainSaga = createAsyncSaga(
  deletePrivateDomainAsync,
  deletePrivateDomain,
);

function* savePrivateDomainFinallySaga(code: number) {
  yield put(setPrivateDomainOk(false));
  yield put(setHasPrivateDomainIPError(false));
  yield put(setIsPrivateDomainAlreadyUsed(false));

  switch (code) {
    case 0: {
      yield put(openAlert('PRIVATE_DOMAIN_OK'));
      yield put(setPrivateDomainOk(true));
      break;
    }
    case 1700:
      yield put(setHasPrivateDomainIPError(true));
      break;
    case 1800:
      yield put(setIsPrivateDomainAlreadyUsed(true));
      break;
    default:
      yield put(openAlert('PRIVATE_DOMAIN_CATCH'));
      break;
  }
}

function* savePrivateDomainSuccessSaga(
  action: PayloadAction<string, ISavePrivateDomainResponse>,
) {
  const { code, status } = action.payload;
  const privateDomain = yield select(
    (state: RootState) => state.user.basicInfo.privateDomain,
  );

  if (status === 200) {
    yield put(setStorePersonalDomainUrl(privateDomain));
  }
  yield savePrivateDomainFinallySaga(code);
}
function* savePrivateDomainFailureSaga(
  action: PayloadAction<string, ISavePrivateDomainResponse>,
) {
  const { code } = action.payload;
  yield savePrivateDomainFinallySaga(code);
}

function* putUserBasicInfoSuccessSaga(
  action: PayloadAction<string, IPutStoreBasicInfoResponse[]>,
) {
  yield put(setHasPrivateDomainIPError(false));
  yield put(setIsPrivateDomainAlreadyUsed(false));
  const [data] = action.payload;
  switch (data.resultCode) {
    case 200: {
      const profileImageUrl = yield select(
        (state: RootState) => state.user.basicInfo.details.profileImageUrl,
      );
      yield put(setStoreImage(profileImageUrl));

      break;
    }
    default:
      yield put(openAlert('SAVE_CATCH'));
      break;
  }
}
function* putUserBasicInfoFailureSaga() {
  yield put(openAlert('SAVE_CATCH'));
}

export const get11TalkAgreementsSaga = createAsyncSaga(
  get11TalkAgreementsAsync,
  get11TalkAgreements,
);

export const post11TalkAgreementsSaga = createAsyncSaga(
  post11TalkAgreementsAsync,
  post11TalkAgreements,
);

const getRoadShopListSaga = createAsyncSaga(
  getRoadShopListAsync,
  getRoadShopList,
);

const getTagsSaga = createAsyncSaga(getTagsAsync, getTagList);

const getPageViewDownloadSaga = createAsyncSaga(
  getPageViewDownloadAsync,
  getPageViewDownload,
);

const getPageViewCountsSaga = createAsyncSaga(
  getPageViewCountsAsync,
  getPageViewCounts,
);
export default function* userSaga() {
  yield takeLatest(getType(getHeaderInfoAsync.request), getStoreAdminInfoSaga);
  yield takeLatest(
    getType(getUserBasicInfoAsync.request),
    getUserBasicInfoSaga,
  );
  yield takeLatest(
    getType(getUserBasicInfoAsync.success),
    getUserBasicInfoSuccessSaga,
  );
  yield takeLatest(getType(saveUserBasicInfo), saveUserBasicInfoSaga);
  yield takeLatest(getType(putStoreNameAsync.request), putStoreNameSaga);
  yield takeLatest(getType(putStoreNameAsync.success), putStoreNameSuccessSaga);
  yield takeLatest(getType(putStoreNameAsync.failure), putStoreNameFailureSaga);
  yield takeLatest(getType(putStoreUrlAsync.request), putStoreUrlSaga);
  yield takeLatest(getType(putStoreUrlAsync.success), putStoreUrlSuccessSaga);
  yield takeLatest(getType(putStoreUrlAsync.failure), putStoreUrlFailureSaga);
  yield takeLatest(
    getType(postPrivateDomainAsync.request),
    postPrivateDomainSaga,
  );
  yield takeLatest(
    getType(postPrivateDomainAsync.success),
    savePrivateDomainSuccessSaga,
  );
  yield takeLatest(
    getType(postPrivateDomainAsync.failure),
    savePrivateDomainFailureSaga,
  );
  yield takeLatest(
    getType(putPrivateDomainAsync.request),
    putPrivateDomainSaga,
  );
  yield takeLatest(
    getType(putPrivateDomainAsync.success),
    savePrivateDomainSuccessSaga,
  );
  yield takeLatest(
    getType(putPrivateDomainAsync.failure),
    savePrivateDomainFailureSaga,
  );
  yield takeLatest(
    getType(deletePrivateDomainAsync.request),
    deletePrivateDomainSaga,
  );
  yield takeLatest(
    getType(putUserBasicInfoAsync.success),
    putUserBasicInfoSuccessSaga,
  );
  yield takeLatest(
    getType(putUserBasicInfoAsync.failure),
    putUserBasicInfoFailureSaga,
  );
  yield takeLatest(getType(getRoadShopListAsync.request), getRoadShopListSaga);
  yield takeLatest(getType(getTagsAsync.request), getTagsSaga);
  yield takeLatest(
    getType(getPageViewDownloadAsync.request),
    getPageViewDownloadSaga,
  );
  yield takeLatest(
    getType(getPageViewCountsAsync.request),
    getPageViewCountsSaga,
  );
  yield takeLatest(
    getType(get11TalkAgreementsAsync.request),
    get11TalkAgreementsSaga,
  );
  yield takeLatest(
    getType(post11TalkAgreementsAsync.request),
    post11TalkAgreementsSaga,
  );
}
