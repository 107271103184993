import { CheckBox, WriteBox } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import { setSelectedTags } from 'modules/user';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectedFashionItem } from './components';
import useTag from './useTag';

const FashionTag = () => {
  const dispatch = useDispatch();
  const fashions = useSelector((state: RootState) => state.user.tags);
  const selectedFashions = useSelector(
    (state: RootState) => state.user.selectedTags,
  );

  const { isTagChecked, onChangeTag } = useTag();

  const onClickRemoveSelectedFashion = useCallback(
    (tagNo: string) =>
      dispatch(
        setSelectedTags(
          selectedFashions.filter(({ tagNo: tagNum }) => tagNum !== tagNo),
        ),
      ),
    [dispatch, selectedFashions],
  );

  return (
    <WriteBox title="패션키워드">
      <div className="area-keyword">
        <div className="area-keyword__selected">
          {selectedFashions.length > 0 ? (
            selectedFashions.map(({ tagNo, tagName }) => (
              <SelectedFashionItem
                key={tagNo}
                tagName={tagName}
                onClick={() => onClickRemoveSelectedFashion(tagNo)}
              />
            ))
          ) : (
            <span className="placeholder">
              스토어 메인에 노출할 키워드를 선택해주세요. (최대 3개)
            </span>
          )}
        </div>
      </div>
      <div className="c-check__keyword">
        {fashions.map(fashion => {
          const { tagNo } = fashion;

          return (
            <CheckBox
              key={tagNo}
              id={`checkKeyword${tagNo}`}
              name="checkKeyword"
              type={CheckBoxType.keyword}
              label={fashion.tagName}
              checked={isTagChecked(tagNo)}
              onChange={() => onChangeTag(fashion)}
            />
          );
        })}
      </div>
    </WriteBox>
  );
};

export default FashionTag;
