import React from 'react';

const DomainSettingIntro = () => {
  return (
    <div className="dialog-body">
      <div className="dialog-body__title title-type__guide">
        <h4 className="title">11번가 스토어 IP주소 : 220.103.231.155</h4>
        <p className="text_sub sub-type__2">
          도메인 구입 업체에서 PC와 모바일 도메인을 아래와 같이 각각
          등록해주세요.
        </p>
      </div>
      <div className="dialog-body__title title-type__info">
        <h5 className="title">
          예) 11mystore.com으로 11번가 스토어를 연결할 경우
        </h5>
      </div>
      <div className="table-wrap">
        <table className="table table-type__3">
          <caption>도메인 연결 예시</caption>
          <thead>
            <tr>
              <th scope="col">PC</th>
              <th scope="col">모바일</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-side">
                11mystore.com - 220.103.231.155 <br />
                www.11mystore.com - 220.103.231.155
              </td>
              <td className="td-side">m.11mystore.com - 220.103.231.155</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="dialog-body__title title-type__info">
        <h5 className="title">[유의사항]</h5>
      </div>
      <ul className="text_list list-style__lg">
        <li>
          호스팅 업체에서 IP설정 후 11번가 스토어 관리 메뉴에서 등록이 가능하며,
          개인 도메인 설정완료까지 최대 2~3일 소요될 수 있습니다.
        </li>
        <li>
          개인 도메인 주소를 통해 11번가 스토어로 이동할 경우에도 개인 도메인
          주소는 유지되지 않으며 11번가 스토어 주소로 변경되는 점 참고
          부탁드립니다.
        </li>
      </ul>
      <div className="dialog-body__title title-type__info">
        <h5 className="title">[참고]</h5>
        <h6 className="title_sub">도메인 구입처별 IP설정메뉴</h6>
      </div>
      <div className="table-wrap">
        <table className="table table-type__4">
          <caption>도메인 연결 예시</caption>
          <colgroup>
            <col style={{ width: '100px' }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">가비아</th>
              <td className="td-side">
                MY가비아 &gt; DNS 관리툴 바로가기 &gt; 도메인 우측 [설정] 클릭
              </td>
            </tr>
            <tr>
              <th scope="row">후이즈</th>
              <td className="td-side">
                도메인 활용/부가서비스 &gt; 네임서버 고급설정 클릭
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="text_info">
        그 외 도메인 구입업체의 경우, 구입 업체에서 제공하는 도움말을 이용해
        주세요.
      </p>
    </div>
  );
};

export default DomainSettingIntro;
