import React from 'react';

interface IProps {
  text?: string;
  style: object;
}

const TemplateTargetLabel = (props: IProps) => {
  const { text, style } = props;

  return (
    <div className="info" style={style}>
      <svg width="134" height="36">
        <path
          fill="#111"
          d="M15.873 0h102.253a4 4 0 0 1 3.073 1.44l11.667 14a4 4 0 0 1 0 5.12l-11.667 14a4 4 0 0 1-3.073 1.44H15.873a4 4 0 0 1-3.072-1.44l-11.667-14a4 4 0 0 1 0-5.12l11.667-14A4 4 0 0 1 15.873 0z"
        />
        <text fill="#fff" x="67" y="22" textAnchor="middle">
          {text}
        </text>
      </svg>
    </div>
  );
};

export default TemplateTargetLabel;
