import config from 'apis/endpoint.config';
import httpClient from 'apis/httpClient';

export interface IPutProfileData {
  displayPriority: string;
  moduleImageUrl: string;
  moduleObjectValue: string;
  moduleSubTitle: string;
  moduleTitle: string;
  profileTargetCode: string;
}
export interface PutProfileInfoParam {
  storeNo: string;
  imageViewTypeCode: string;
  profileDataList: IPutProfileData[];
}

export const getProfileInfo = (storeNo: string) =>
  httpClient(config.profile.profile(storeNo));

export const putProfileInfo = ({
  storeNo,
  imageViewTypeCode,
  profileDataList,
}: PutProfileInfoParam) =>
  httpClient(config.profile.putProfile(storeNo), 'PUT', {
    imageViewTypeCode,
    profileDataList,
  });
