import React from 'react';

interface IMenuDepth {
  noBorder: boolean;
  children: React.ReactNode;
}

const MenuDepth = ({ noBorder, children }: IMenuDepth) =>
  noBorder ? <>{children}</> : <div className="menu-depth">{children}</div>;

export default MenuDepth;
