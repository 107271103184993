import { useEffect } from 'react';
import { isSamePageId, setPageId } from 'utils/log';

const useCreateNoticeLog = (isUpdate: boolean) => {
  useEffect(() => {
    if (
      !isUpdate &&
      !isSamePageId('/seller_office/store/notice_registration')
    ) {
      setPageId('/seller_office/store/notice_registration');
    }
  }, [isUpdate]);
};

export default useCreateNoticeLog;
