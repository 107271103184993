import { addDays, format, startOfWeek, subDays } from 'date-fns';
import { RootState } from 'modules';
import { getPageViewCountsAsync, getPageViewDownloadAsync } from 'modules/user';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import VisitorGraph from './VisitorGraph';

const DAY_STRING = ['일', '월', '화', '수', '목', '금', '토'];
const formatDate = (date: Date) => format(date, 'yyyy.MM.dd');
const formatDateforGraph = (date: Date) => format(date, 'MM.dd.');

// 클릭 element 영역은 유지하되 보이지만 않게 하도록 style 지정.
const getVisible = (condition: boolean): CSSProperties => ({
  visibility: condition ? 'visible' : 'hidden',
});

interface IProps {
  onClose: () => void;
}

const VisitorGraphLayer: React.FC<IProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  // data는 [[-1,-1,1,2,3,4,5], ..., [1,2,3,4,5,6,7]]와 같이 Array(7)의 이중배열 형태임. 30일 정보를 가져오므로 일반적으로는 5개(7x5) 들어올 예정.
  const data = useSelector((state: RootState) => state.user.viewCounts);
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  // 이전/다음 버튼 클릭시 배열 index를 변경함.
  const [dataIndex, setDataIndex] = useState(0);
  // data 배열의 마지막 항목이 오늘자 기준의 이번주임.
  const thisWeekIndex = data.length - 1 - dataIndex;

  // dataIndex에 따라 그래프 시작일이 달라짐.
  const startDate = subDays(startOfWeek(new Date()), dataIndex * 7);
  const endDate = addDays(startDate, 6);

  const graphData = {
    dateValue: DAY_STRING.map(
      (day, idx) => `${formatDateforGraph(addDays(startDate, idx))}${day}`,
    ),
    thisWeek: data[thisWeekIndex],
    lastWeek: data[thisWeekIndex - 1],
  };

  useEffect(() => {
    dispatch(getPageViewCountsAsync.request({ storeNo }));
  }, [dispatch, storeNo]);

  return (
    <div className="seller_visit" style={{ display: 'block' }}>
      <div className="visit_title">
        <strong className="title">스토어 방문자</strong>
        <span className="text">스토어 방문자는 전일 기준으로 집계됩니다.</span>
      </div>
      {data.length > 0 && (
        <>
          <div className="visit_graph">
            <div className="graph_date">
              <span className="date__1">지난주</span>
              <span className="date__2">이번주</span>
            </div>
            <VisitorGraph data={graphData} />
          </div>
          <div className="visit_period">
            <div className="period">
              <button
                type="button"
                className="btn-style__icon btn-icon__prev"
                onClick={() => {
                  setDataIndex(dataIndex + 1);
                }}
                style={getVisible(thisWeekIndex > 1)}
                data-log-actionid-area="date"
                data-log-actionid-label="arrow"
                data-log-body={JSON.stringify({ direction: 'prev' })}
              >
                이전
              </button>
              <span className="date">
                {formatDate(startDate)} ~ {formatDate(endDate)}
              </span>
              <button
                type="button"
                className="btn-style__icon btn-icon__next"
                onClick={() => {
                  setDataIndex(dataIndex - 1);
                }}
                style={getVisible(thisWeekIndex < data.length - 1)}
                data-log-actionid-area="date"
                data-log-actionid-label="arrow"
                data-log-body={JSON.stringify({ direction: 'next' })}
              >
                다음
              </button>
            </div>
            <div className="btn">
              <p className="text_sub">
                방문자 데이터는 최근 30일만 제공됩니다.
              </p>
              <button
                type="button"
                className="btn-style__11 btn-size__11"
                onClick={() => {
                  dispatch(getPageViewDownloadAsync.request({ storeNo }));
                }}
                data-log-actionid-area="last_30_days"
                data-log-actionid-label="excel_download"
              >
                <span className="icon icon_excel" />
                최근 30일 정보 다운로드
              </button>
            </div>
          </div>
        </>
      )}
      <div className="visit_btn">
        <button
          type="button"
          className="btn-style__icon btn-icon__delete"
          onClick={onClose}
        >
          닫기
        </button>
      </div>
    </div>
  );
};

export default VisitorGraphLayer;
