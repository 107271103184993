import { CheckBox, Dropdown, WriteBox } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import {
  setNoticeDetailIsImportant,
  setNoticeDetailType,
} from 'modules/notice/actions';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const NOTICE_TYPES = [
  { id: '01', name: '일반' },
  { id: '02', name: '상품' },
  { id: '03', name: '배송' },
  { id: '04', name: '이벤트' },
].map(item => ({ ...item, value: item.id }));
const DEFAULT_NOTICE_TYPE_VALUE = NOTICE_TYPES[0].value;

const NoticeType = () => {
  const dispatch = useDispatch();
  const detail = useSelector((state: RootState) => state.notice.detail);

  const noticeClassifyCode = useMemo(() => detail.noticeClassifyCode, [
    detail.noticeClassifyCode,
  ]);
  const isImportant = useMemo(() => detail.isImportant, [detail.isImportant]);

  const onSelectNoticeDetailType = useCallback(
    (value: string) => {
      dispatch(
        setNoticeDetailType({
          noticeClassifyName:
            NOTICE_TYPES.find(({ value: v }) => v === value)?.name ??
            NOTICE_TYPES[0].name,
          noticeClassifyCode: value,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (!noticeClassifyCode) {
      onSelectNoticeDetailType(DEFAULT_NOTICE_TYPE_VALUE);
    }
  }, [noticeClassifyCode, onSelectNoticeDetailType]);

  return (
    <WriteBox title="공지 분류">
      <Dropdown
        id="listboxButton"
        options={NOTICE_TYPES}
        placeholder="선택하세요"
        initialValue={noticeClassifyCode || DEFAULT_NOTICE_TYPE_VALUE}
        width="w-200"
        onSelect={onSelectNoticeDetailType}
      />
      <CheckBox
        type={CheckBoxType.basic}
        id="checkboxDefault0"
        name="checkboxDefault"
        label="중요"
        labelClass="color__em"
        checked={isImportant}
        onChange={() => dispatch(setNoticeDetailIsImportant(!isImportant))}
      />
    </WriteBox>
  );
};

export default NoticeType;
