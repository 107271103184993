import { ICustomCategoryDetailParam } from 'apis/category';
import { IProductParams } from 'apis/product';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { IDisplayProduct } from './index';

export const resetFoundProducts = createAction('@PRODUCT/FOUND/RESET')<
  undefined
>();
export const setCurrentSelectedProducts = createAction(
  '@PRODUCT/SELECTED/CURRENT',
)<IDisplayProduct[]>();
export const setFinalSelectedProducts = createAction('@PRODUCT/SELECTED/FINAL')<
  IDisplayProduct[]
>();
export const resetFinalSelectedProducts = createAction(
  '@PRODUCT/SELECTED/FINAL/RESET',
)<undefined>();
export const getDisplayProductAsync = createAsyncAction(
  '@PRODUCT/DISPLAY/FETCH',
  '@PRODUCT/DISPLAY/SUCCESS',
  '@PRODUCT/DISPLAY/FAILURE',
)<IProductParams, { total: number; data: IDisplayProduct[] }, Error>();
export const getCustomCategoryProductDetailAsync = createAsyncAction(
  '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS/FETCH',
  '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS/SUCCESS',
  '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS/FAILURE',
)<ICustomCategoryDetailParam, number[], Error>();
export const setProductNumbers = createAction(
  '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS',
)<number[]>();
export const setProductTablePage = createAction('@PRODUCT/TABLE/PAGE')<
  number
>();
