import config from 'apis/endpoint.config';
import httpClient, { httpRequest } from 'apis/httpClient';

import { ICustomCategoryDetailParam } from './category';

export enum PRODUCT_REQUEST {
  ALL = 'ALL',
  CARD = 'CARD',
  MOVIE = 'MOVIE',
}

export enum PRODUCT_SORT {
  NEW = 'N',
  RANKING = 'NP',
  POPULARITY = 'SPC',
  LOW = 'L',
  HIGH = 'H',
  ACCUMULATION = 'A',
  GREAT = 'G',
  DISCOUNT_RATE = 'IM',
}

export interface IProductParams {
  encSellerNo: string;
  pageSize?: number;
  pageNumber?: number;
  productNumberList?: string[];
  largeCategoryNumber?: number;
  middleCategoryNumber?: number;
  smallCategoryNumber?: number;
  searchKeyword?: string;
  productRequestType?: PRODUCT_REQUEST;
  sort?: PRODUCT_SORT;
  isProductNoFirst?: boolean;
}

export const getDisplayProductList = (param: IProductParams) =>
  httpRequest(config.product.display(param));

export const getCustomCategoryProductDetail = (
  param: ICustomCategoryDetailParam,
) => httpClient(config.product.customProductDetail(param));
