import {
  INoticeDetailParam,
  INoticeParam,
  IPostNoticeDetailParam,
  IPutNoticeDetailParam,
} from 'apis/notice';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { IEditorContents } from 'utils/editor';

import { INoticeDetailType, INoticeItem } from './index';

interface INoticeDetailResponse {
  createDate: string;
  displayBeginDate: string;
  displayEndDate: string;
  isImportant: boolean;
  noticeNo: string;
  title: string;
  usable: boolean;
  content: string;
  editorJson: string;
}

export const getNoticeListAsync = createAsyncAction(
  '@NOTICE/LIST/FETCH',
  '@NOTICE/LIST/SUCCESS',
  '@NOTICE/LIST/FAILURE',
)<INoticeParam, INoticeItem[], Error>();

export const getNoticeDetailAsync = createAsyncAction(
  '@NOTICE/DETAIL/FETCH',
  '@NOTICE/DETAIL/SUCCESS',
  '@NOTICE/DETAIL/FAILURE',
)<INoticeDetailParam, INoticeDetailResponse[], undefined>();

export const postNoticeDetailAsync = createAsyncAction(
  '@NOTICE/DETAIL/POST/FETCH',
  '@NOTICE/DETAIL/POST/SUCCESS',
  '@NOTICE/DETAIL/POST/FAILURE',
)<IPostNoticeDetailParam, undefined, undefined>();

export const putNoticeDetailAsync = createAsyncAction(
  '@NOTICE/DETAIL/PUT/FETCH',
  '@NOTICE/DETAIL/PUT/SUCCESS',
  '@NOTICE/DETAIL/PUT/FAILURE',
)<IPutNoticeDetailParam, undefined, undefined>();

export const resetNoticeDetail = createAction('@NOTICE/DETAIL/RESET')<
  undefined
>();
export const setNoticeDetailType = createAction('@NOTICE/DETAIL/TYPE')<
  INoticeDetailType
>();
export const setNoticeDetailIsImportant = createAction(
  '@NOTICE/DETAIL/IMPORTANT',
)<boolean>();
export const setNoticeDetailTitle = createAction('@NOTICE/DETAIL/TITLE')<
  string
>();
export const setNoticeDetailEditorContents = createAction(
  '@NOTICE/DETAIL/CONTENTS',
)<IEditorContents>();
export const setNoticeDetailShowDate = createAction('@NOTICE/DETAIL/SHOW/DATE')<
  boolean
>();
export const setNoticeDetailStartDate = createAction(
  '@NOTICE/DETAIL/START/DATE',
)<string>();
export const setNoticeDetailEndDate = createAction('@NOTICE/DETAIL/END/DATE')<
  string
>();
