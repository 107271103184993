import {
  applyCustomCategory,
  deleteCustomCategory,
  getBasicLargeCategoryList,
  getBasicMiddleCategoryList,
  getBasicSmallCategoryList,
  getCustomCategoryDetail,
  getCustomCategoryList,
  IDeleteCustomCategoryResponse,
  IPostCustomCategoryResponse,
  IPutCustomCategoryResponse,
  postCustomCategory,
  putCustomCategory,
} from 'apis/category';
import { ICategoryListItem } from 'components/pages/category/CategoryList';
import produce from 'immer';
import { RootState } from 'modules';
import { closeAlert, openAlert } from 'modules/alert';
import {
  applyBasicCategoryAsync,
  applyCustomCategoryAsync,
  deleteSelectedCustomCategory,
  deleteSelectedCustomCategoryAsync,
  getBasicLargeCategoryAsync,
  getBasicMiddleCategoryAsync,
  getBasicSmallCategoryAsync,
  getCustomCategoryAsync,
  getCustomCategoryDetailAsync,
  ICategoryDetail,
  ICustomCategoryItem,
  postCustomCategoryAsync,
  putCustomCategoryAsync,
  resetCustomDetailModified,
  saveCategoryOrderAsync,
  setConfirmCategoryDelete,
  setCustomCategory,
  setCustomCategoryOrder,
  setCustomDetail,
  setCustomItemTemp,
  setIsShowDoneToast,
  setIsUsingCustom,
  setSelectedCustomId,
  setShouldCategorySave,
  setShouldStoreApply,
} from 'modules/category';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import { setFinalSelectedProducts, setProductNumbers } from 'modules/product';
import { setIsStoreCategorySet } from 'modules/user';
import { put } from 'redux-saga-test-plan/matchers';
import { select, take, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';

const getBasicLargeCategoryListSaga = createAsyncSaga(
  getBasicLargeCategoryAsync,
  getBasicLargeCategoryList,
);
const getBasicMiddleCategoryListSaga = createAsyncSaga(
  getBasicMiddleCategoryAsync,
  getBasicMiddleCategoryList,
);
const getBasicSmallCategoryListSaga = createAsyncSaga(
  getBasicSmallCategoryAsync,
  getBasicSmallCategoryList,
);
const getCustomCategoryListSaga = createAsyncSaga(
  getCustomCategoryAsync,
  getCustomCategoryList,
);
const getCustomCategoryDetailSaga = createAsyncSaga(
  getCustomCategoryDetailAsync,
  getCustomCategoryDetail,
);

const postCustomCategorySaga = createAsyncSaga(
  postCustomCategoryAsync,
  postCustomCategory,
);
function* postCustomCategorySuccessSaga(
  action: PayloadAction<string, IPostCustomCategoryResponse[]>,
) {
  const [data] = action.payload;

  const { custom, customOrder, customItemTemp } = yield select(
    (state: RootState) => state.category,
  );

  const customCategory: ICustomCategoryItem = {
    ...customItemTemp,
    storeCategoryNo: data.storeCategoryNo,
  };
  const order: ICategoryListItem = {
    id: data.storeCategoryNo,
    text: customItemTemp.storeCategoryName,
    mark: false,
  };

  if (data?.resultCode === 200) {
    yield put(setCustomCategory([customCategory, ...custom]));
    yield put(setCustomItemTemp(customCategory));
    yield put(setCustomCategoryOrder([order, ...customOrder]));
    yield put(
      setSelectedCustomId(data?.storeCategoryNo ?? SELECTED_CUSTOM_ID.INIT),
    );
    yield put(setShouldCategorySave(false));
    yield put(resetCustomDetailModified());
  }
}
const putCustomCategorySaga = createAsyncSaga(
  putCustomCategoryAsync,
  putCustomCategory,
);
function* putCustomCategorySuccessSaga(
  action: PayloadAction<string, IPutCustomCategoryResponse[]>,
) {
  const [data] = action.payload;

  const custom: ICustomCategoryItem[] = yield select(
    (state: RootState) => state.category.custom,
  );
  const customOrder: ICategoryListItem[] = yield select(
    (state: RootState) => state.category.customOrder,
  );
  const customItemTemp = yield select(
    (state: RootState) => state.category.customItemTemp,
  );

  const newCustom = produce(custom, draft => {
    const item =
      draft.find(
        ({ storeCategoryNo }) =>
          storeCategoryNo === customItemTemp.storeCategoryNo,
      ) ?? ({} as ICustomCategoryItem);

    item.storeCategoryName = customItemTemp.storeCategoryName;
    item.storeCategoryNo = customItemTemp.storeCategoryNo;
    item.typeCode = customItemTemp.typeCode;
    item.orderSeq = customItemTemp.orderSeq;
  });
  const newOrder = produce(customOrder, draft => {
    const item =
      draft.find(({ id }) => id === customItemTemp.storeCategoryNo) ??
      ({} as ICategoryListItem);
    item.text = customItemTemp.storeCategoryName;
  });
  const customDetail: ICategoryDetail[] = yield select(
    (state: RootState) => state.category.customDetail,
  );
  const sortedCustomDetail = [...customDetail].sort(
    (a, b) => (a.orderSeq ?? 0) - (b.orderSeq ?? 0),
  );

  if (data?.resultCode === 200) {
    yield put(setCustomCategory(newCustom));
    yield put(setCustomDetail(sortedCustomDetail));
    yield put(setCustomCategoryOrder(newOrder));
    yield put(
      setSelectedCustomId(
        customItemTemp?.storeCategoryNo ?? SELECTED_CUSTOM_ID.INIT,
      ),
    );
    yield put(setShouldCategorySave(false));
    yield put(resetCustomDetailModified());
  }
}

const deleteCustomCategorySaga = createAsyncSaga(
  deleteSelectedCustomCategoryAsync,
  deleteCustomCategory,
);

function* deleteCustomCategoryAlertSaga() {
  const {
    custom: categories,
    selectedCustomId: storeCategoryNo,
  } = yield select((state: RootState) => state.category);
  const { storeNo, isStoreCategorySet } = yield select(
    (state: RootState) => state.user.headerInfo,
  );

  if (isStoreCategorySet && categories.length === 4) {
    yield take(closeAlert);
    yield put(openAlert('DELETE_CATEGORY_MUST_CUSTOM_CATEGORY_MORE_4'));
    yield take(setConfirmCategoryDelete);
    yield take(closeAlert);

    const confirmCategoryDelete = yield select(
      (state: RootState) => state.category.confirmCategoryDelete,
    );

    if (!confirmCategoryDelete) {
      return;
    }

    yield put(
      applyBasicCategoryAsync.request({
        isStoreCategorySwitch: false,
        storeNo,
        values: [],
      }),
    );
    yield put(setIsUsingCustom(true));
  }
  yield put(
    deleteSelectedCustomCategoryAsync.request({ storeNo, storeCategoryNo }),
  );
}

function* deleteCustomCategorySuccessSaga(
  action: PayloadAction<string, IDeleteCustomCategoryResponse[]>,
) {
  const [data] = action.payload;

  const { custom: categories, selectedCustomId: deleteId } = yield select(
    (state: RootState) => state.category,
  );

  if (data?.resultCode === 200) {
    let firstId = categories[0]?.storeCategoryNo;
    if (firstId === deleteId) {
      firstId = categories[1]?.storeCategoryNo ?? SELECTED_CUSTOM_ID.INIT;
    }
    yield put(setSelectedCustomId(firstId));
    yield put(
      setCustomCategory(
        categories.filter(
          ({ storeCategoryNo }: ICustomCategoryItem) =>
            deleteId !== storeCategoryNo,
        ),
      ),
    );
    yield put(setProductNumbers([]));
    yield put(setFinalSelectedProducts([]));
  }
}

const saveCategoryOrderSaga = createAsyncSaga(
  saveCategoryOrderAsync,
  applyCustomCategory,
);
function* saveCategoryOrderSuccessSaga() {
  const custom: ICustomCategoryItem[] = yield select(
    (state: RootState) => state.category.custom,
  );
  const customOrder: ICategoryListItem[] = yield select(
    (state: RootState) => state.category.customOrder,
  );

  const orderedCustom = yield customOrder.map(
    ({ id }) =>
      custom.find(({ storeCategoryNo }) => storeCategoryNo === id) ??
      ({} as ICustomCategoryItem),
  );
  yield put(setCustomCategory(orderedCustom));
  yield put(setShouldStoreApply(false));
}

const applyBasicCategorySaga = createAsyncSaga(
  applyBasicCategoryAsync,
  applyCustomCategory,
);

function* applyBasicCategorySuccessSaga() {
  yield put(setIsUsingCustom(false));
  yield put(setIsStoreCategorySet(false));
}

const applyCustomCategorySaga = createAsyncSaga(
  applyCustomCategoryAsync,
  applyCustomCategory,
);

function* applyCustomCategorySuccessSaga() {
  const isStoreCategorySet: boolean = yield select(
    (state: RootState) => state.user.headerInfo.isStoreCategorySet,
  );
  yield put(setIsUsingCustom(!isStoreCategorySet));
  yield put(setIsStoreCategorySet(!isStoreCategorySet));
  yield put(setIsShowDoneToast(true));
  yield saveCategoryOrderSuccessSaga();
}

export default function* categorySaga() {
  yield takeLatest(
    getType(getBasicLargeCategoryAsync.request),
    getBasicLargeCategoryListSaga,
  );
  yield takeLatest(
    getType(getBasicMiddleCategoryAsync.request),
    getBasicMiddleCategoryListSaga,
  );
  yield takeLatest(
    getType(getBasicSmallCategoryAsync.request),
    getBasicSmallCategoryListSaga,
  );
  yield takeLatest(
    getType(getCustomCategoryAsync.request),
    getCustomCategoryListSaga,
  );
  yield takeLatest(
    getType(getCustomCategoryDetailAsync.request),
    getCustomCategoryDetailSaga,
  );
  yield takeLatest(
    getType(postCustomCategoryAsync.request),
    postCustomCategorySaga,
  );
  yield takeLatest(
    getType(postCustomCategoryAsync.success),
    postCustomCategorySuccessSaga,
  );
  yield takeLatest(
    getType(putCustomCategoryAsync.request),
    putCustomCategorySaga,
  );
  yield takeLatest(
    getType(putCustomCategoryAsync.success),
    putCustomCategorySuccessSaga,
  );
  yield takeLatest(
    getType(deleteSelectedCustomCategory),
    deleteCustomCategoryAlertSaga,
  );
  yield takeLatest(
    getType(deleteSelectedCustomCategoryAsync.request),
    deleteCustomCategorySaga,
  );
  yield takeLatest(
    getType(deleteSelectedCustomCategoryAsync.success),
    deleteCustomCategorySuccessSaga,
  );
  yield takeLatest(
    getType(applyBasicCategoryAsync.request),
    applyBasicCategorySaga,
  );
  yield takeLatest(
    getType(applyBasicCategoryAsync.success),
    applyBasicCategorySuccessSaga,
  );
  yield takeLatest(
    getType(applyCustomCategoryAsync.request),
    applyCustomCategorySaga,
  );
  yield takeLatest(
    getType(applyCustomCategoryAsync.success),
    applyCustomCategorySuccessSaga,
  );
  yield takeLatest(
    getType(saveCategoryOrderAsync.request),
    saveCategoryOrderSaga,
  );
  yield takeLatest(
    getType(saveCategoryOrderAsync.success),
    saveCategoryOrderSuccessSaga,
  );
}
