import { RootState } from 'modules';
import { menuIdType } from 'modules/display';
import React from 'react';
import { useSelector } from 'react-redux';

import Profile from './Profile';
import Recommend from './Recommend';
import Special from './Special';

const getContents = (id: string) => {
  switch (id) {
    case menuIdType.profile:
      return <Profile />;
    case menuIdType.recommend1:
    case menuIdType.recommend2:
    case menuIdType.recommend3:
      return <Recommend id={id} />;
    case menuIdType.special:
      return <Special />;
    default:
      return '';
  }
};

const ContentFrame = () => {
  const id = useSelector((state: RootState) => state.display.selectedModule.id);

  return <>{getContents(id)}</>;
};

export default ContentFrame;
