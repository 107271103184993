import { CategoryItemIdType } from 'modules/category';
import React, { useCallback } from 'react';

import useSaveCategoryOrder from '../Edit/useSaveCategoryOrder';
import useCategoryApplied from '../useCategoryApplied';
import { ICategoryListItem } from './index';

type MoveButtonType = 'up' | 'down' | 'top' | 'bottom';

interface IMoveButton {
  selectedId: CategoryItemIdType;
  categoryList: ICategoryListItem[];
  onChangeList: (categoryList: ICategoryListItem[]) => void;
}

const MoveButton = ({
  categoryList,
  selectedId,
  onChangeList,
}: IMoveButton) => {
  const { isCustomCategoryApplied } = useCategoryApplied();
  const { isOrderModified, saveCategoryOrder } = useSaveCategoryOrder();

  const onClickMoveButton = useCallback(
    (moveButtonType: MoveButtonType) => {
      const newList = [...categoryList];
      const elIndex = newList.findIndex(({ id }) => id === selectedId);

      if (elIndex >= 0) {
        const newElement = newList[elIndex];

        let startIndex = 0;
        if (
          (moveButtonType === 'up' || moveButtonType === 'top') &&
          elIndex <= 0
        ) {
          return;
        }
        if (moveButtonType === 'up') {
          startIndex = elIndex - 1;
        } else if (moveButtonType === 'down') {
          startIndex = elIndex + 1;
        } else if (moveButtonType === 'bottom') {
          startIndex = newList.length - 1;
        }
        newList.splice(elIndex, 1);
        newList.splice(startIndex, 0, newElement);
        if (onChangeList) {
          onChangeList(newList);
        }
      }
    },
    [categoryList, onChangeList, selectedId],
  );

  return (
    <>
      <div className="menu_btn">
        <div className="side">
          <button
            type="button"
            onClick={() => onClickMoveButton('up')}
            className="btn-style__img btn-img__prev"
          >
            위로 이동
          </button>
          <button
            type="button"
            onClick={() => onClickMoveButton('down')}
            className="btn-style__img btn-img__next"
          >
            아래로 이동
          </button>
          <button
            type="button"
            onClick={() => onClickMoveButton('top')}
            className="btn-style__img btn-img__first"
          >
            맨 처음으로 이동
          </button>
          <button
            type="button"
            onClick={() => onClickMoveButton('bottom')}
            className="btn-style__img btn-img__last"
          >
            맨 끝으로 이동
          </button>
        </div>
        <div className="side2">
          <button
            type="button"
            onClick={saveCategoryOrder}
            disabled={!isCustomCategoryApplied || !isOrderModified}
            className="btn-style__1 btn-size__12"
          >
            저장
          </button>
        </div>
      </div>
      <p className="text_alert">
        메뉴를 드래그해서 원하는 위치로 이동시키세요.
      </p>
    </>
  );
};

export default MoveButton;
