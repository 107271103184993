import produce from 'immer';
import { getType } from 'typesafe-actions';

import {
  setDragTargetId,
  setEnableMenu,
  setInitialPage,
  setModuleData,
  setSelectedModule,
  setSkin,
  setSortable,
  setTemplate,
  setTempModuleList,
  setTouched,
} from './actions';
import { DisplayAction, IDisplay } from './types';

const initialState: IDisplay = {
  touched: false,
  template: 'default',
  skin: 'skin_1',
  selectedModule: { id: '1', name: '대표이미지', enable: false },
  moduleData: [],
  tempModuleData: [],
  sortable: false,
  initialPage: '',
  targetId: '',
};

export default (state: IDisplay = initialState, action: DisplayAction) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(setTemplate):
        draft.template = action.payload;
        break;
      case getType(setSkin):
        draft.skin = action.payload;
        break;
      case getType(setSelectedModule):
        draft.selectedModule = action.payload;
        break;
      case getType(setEnableMenu): {
        const index = draft.moduleData.findIndex(
          menu => menu.id === action.meta,
        );
        if (index > -1) {
          draft.moduleData[index].enable = action.payload;
        }
        if (draft.selectedModule.id === action.meta) {
          draft.selectedModule.enable = action.payload;
        }
        break;
      }
      case getType(setSortable):
        draft.sortable = action.payload;
        break;
      case getType(setModuleData):
        draft.moduleData = action.payload;
        break;
      case getType(setInitialPage):
        draft.initialPage = action.payload;
        break;
      case getType(setTouched):
        draft.touched = action.payload;
        break;
      case getType(setTempModuleList):
        draft.tempModuleData = action.payload;
        break;
      case getType(setDragTargetId):
        draft.targetId = action.payload;
        break;
    }
  });
