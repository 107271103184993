import { RootState } from 'modules';
import { setSortable } from 'modules/display';
import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';

import DefaultItem from './DefaultItem';
import MenuButton from './MenuButton';
import SortableList from './SortableList';

const Menu = () => {
  const sortable = useSelector((state: RootState) => state.display.sortable);
  const moduleData = useSelector(
    (state: RootState) => state.display.moduleData,
  );

  const skin = useSelector((state: RootState) => state.display.skin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSortable(false));
  }, [dispatch]);

  const renderView = ({ style }: { style: React.CSSProperties }) => {
    const viewStyle = {
      paddingRight: 8,
    };

    return <div style={{ ...style, ...viewStyle }} />;
  };

  return (
    <div className={`cont-menu ${skin}`}>
      <MenuButton sortable={sortable} />
      {sortable ? (
        <SortableList />
      ) : (
        <div className="cont-menu__item">
          <div className="menu-item__scroll">
            <Scrollbars autoHide renderView={renderView}>
              {moduleData.map(module => (
                <DefaultItem key={module.id} module={module} />
              ))}
            </Scrollbars>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Menu);
