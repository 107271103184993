import Radio, { RadioType } from 'components/common/Radio';
import { setTemplate, setTouched, TemplateType } from 'modules/display';
import React from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
  type: TemplateType;
  selected: TemplateType;
  label: string;
}

const TemplateRadio = (props: IProps) => {
  const { type, selected, label } = props;
  const dispatch = useDispatch();
  const handleChange = (value: TemplateType) => {
    dispatch(setTemplate(value));
    dispatch(setTouched(true));
  };

  return (
    <Radio
      type={RadioType.bold}
      name="template"
      value={type}
      label={label}
      checked={selected === type}
      onChange={() => handleChange(type)}
    />
  );
};

export default TemplateRadio;
