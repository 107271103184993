/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { formatNumber } from 'utils/format';

const GRAPH_WIDTH = 678; // X축 라인 너비
const GRAPH_HEIGHT = 140; // 가장 긴 bar + 값 text를 합한 높이값. 디자인에 적당히 맞춤.
const SVG_WIDTH = GRAPH_WIDTH;
const SVG_HEIGHT = GRAPH_HEIGHT + 23; // x축 text 영역 추가. 디자인에 적당히 맞춤.
const BAR_MAX_HEIGHT = 120;
const BAR_WIDTH = 8;
const BAR_STROKE = 1;
const ITEM_SPACE = 102; // 2개 bar 묶음인 item 간의 간격 (계산으로 하는 방법도 있지만, 커스터마이징이 많을 것 같아서 고정값으로 함)

interface IBarProps {
  value: number;
  max: number;
  barColor: string;
  textColor: string;
}

interface IVisitorGraphProps {
  data: {
    dateValue: string[];
    lastWeek: number[];
    thisWeek: number[];
  };
}

const Bar = ({ value, max, barColor, textColor }: IBarProps) => {
  // max값를 이용해서 rect와 text용 좌표/사이즈를 계산
  const h = max > 0 ? (BAR_MAX_HEIGHT / max) * value : 0;

  return (
    <>
      <rect
        width={BAR_WIDTH}
        height={h}
        x={(-1 * BAR_WIDTH) / 2 - BAR_STROKE / 2}
        y={GRAPH_HEIGHT - h}
        stroke="rgba(0, 0, 0, 0.03)"
        fill={barColor}
      />
      <text
        x={0}
        y={GRAPH_HEIGHT - h - 6} // bar와 text 사이 간격을 6 정도로 둠
        textAnchor="middle"
        fontSize="10px"
        fill={textColor}
      >
        {formatNumber(value)}
      </text>
    </>
  );
};

const VisitorGraph = ({ data }: IVisitorGraphProps) => {
  const max = Math.max(...data.lastWeek, ...data.thisWeek);

  // lastWeek와 thisWeek 및 일자를 하나의 배열로 묶어준다.
  const items = data.dateValue.map((date, index) => ({
    date,
    lastWeek: data.lastWeek[index],
    thisWeek: data.thisWeek[index],
  }));

  return (
    <div>
      <svg width={SVG_WIDTH} height={SVG_HEIGHT}>
        <line
          x1="0"
          y1={GRAPH_HEIGHT - 0.5}
          x2={SVG_WIDTH}
          y2={GRAPH_HEIGHT - 0.5}
          stroke="#f4f4f4"
        />
        {items.map(({ date, lastWeek, thisWeek }, index) => (
          <g key={date} transform={`translate(${ITEM_SPACE * index},0)`}>
            {lastWeek >= 0 && (
              <g transform="translate(16,0)">
                <Bar
                  value={lastWeek}
                  max={max}
                  barColor="#dddddd"
                  textColor="#999999"
                />
              </g>
            )}
            {thisWeek >= 0 && (
              <g transform="translate(50,0)">
                <Bar
                  value={thisWeek}
                  max={max}
                  barColor="#0b83e6"
                  textColor="#0b83e6"
                />
              </g>
            )}
            <text
              x="33"
              y={SVG_HEIGHT - 2}
              textAnchor="middle"
              alignmentBaseline="baseline"
              fill="#666"
              fontSize="12px"
            >
              {date}
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

export default VisitorGraph;
