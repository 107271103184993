/* eslint-disable no-restricted-globals */
import store from '../configureStore';
import { setBasicInfoAddr } from '../modules/user';
// eslint-disable-next-line import/prefer-default-export
function getParams() {
  document.domain = '11st.co.kr';
  const objParam = {};
  objParam.tabDispType = 'road';
  objParam.tabSlctType = 'road';
  objParam.tabMartType = 'Y';
  objParam.fnCallback = 'fnCallback';
  objParam.sparam = '0';
  objParam.otherDomainYn = 'Y';

  return objParam;
}

export function onClickSearchAddr() {
  const objParam = getParams();
  (function searchAddr(data) {
    // eslint-disable-next-line no-underscore-dangle
    const _GNB_CONTEXT_PATH_ = '//11st.co.kr';
    let url = `${_GNB_CONTEXT_PATH_}/addr/searchAddrV2.tmall?`;
    const tabDispType = 'road';
    const tabSlctType = 'road';
    const fnCallback = 'fnCallback';
    const sparam = '';
    const otherDomainYn = 'N';
    const popupName = 'popupSearchAddr';
    const width = '550';
    const height = '600';
    let attr = `height=${height},innerHeight=${height}`;
    attr += `,width=${width},innerWidth=${width}`;
    if (window.screen) {
      const ah = window.screen.availHeight - 30;
      const aw = window.screen.availWidth - 10;

      const xc = (aw - width) / 2;
      const yc = (ah - height) / 2;

      attr += `,left=${xc},screenX=${xc}`;
      attr += `,top=${yc},screenY=${yc}`;
    }

    if (typeof data === 'undefined') {
      url += `tabDispType=${tabDispType}`;
      url += `&tabSlctType=${tabSlctType}`;
      url += `&fnCallback=${fnCallback}`;
      url += `&sparam=${sparam}`;
      url += `&otherDomainYn=${otherDomainYn}`;
    } else {
      if (
        typeof data.tabDispType === 'string' &&
        data.tabDispType.trim() !== ''
      ) {
        url += `tabDispType=${data.tabDispType}`;
      } else {
        url += `tabDispType=${tabDispType}`;
      }
      if (
        typeof data.tabSlctType === 'string' &&
        data.tabSlctType.trim() !== ''
      ) {
        url += `&tabSlctType=${data.tabSlctType}`;
      } else {
        url += `&tabSlctType=${tabSlctType}`;
      }
      if (
        typeof data.fnCallback === 'string' &&
        data.fnCallback.trim() !== ''
      ) {
        url += `&fnCallback=${data.fnCallback}`;
      } else {
        url += `&fnCallback=${fnCallback}`;
      }
      if (typeof data.sparam === 'string' && data.sparam !== '') {
        url += `&sparam=${data.sparam}`;
      } else {
        url += `&sparam=${sparam}`;
      }
      if (typeof data.otherDomainYn === 'string' && data.storeMngYn !== '') {
        url += `&otherDomainYn=${data.otherDomainYn}`;
      } else {
        url += `&otherDomainYn=${otherDomainYn}`;
      }
    }
    window.open(url, popupName, attr);
    // window.open(url, popupName);
  })(objParam);
}

export function bindAddrSearch() {
  window.fnCallback = function fnCallback(
    roadZipType,
    engTypeYn,
    mailNo,
    mailNoSeq,
    zipAddrNm,
    buildMngNo,
    roadAddrNm,
    sparam,
    jejuYn,
    islandYn,
    areaNo,
  ) {
    store.dispatch(
      setBasicInfoAddr({
        roadZipType,
        engTypeYn,
        mailNo,
        mailNoSeq,
        zipAddrNm,
        buildMngNo,
        roadAddrNm,
        sparam,
        jejuYn,
        islandYn,
        areaNo,
        lnmAddrSeq: '',
      }),
    );
  };
}
