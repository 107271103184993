import { PRODUCT_REQUEST } from 'apis/product';
import { RootState } from 'modules';
import { DialogType } from 'modules/dialog';
import React from 'react';
import { useSelector } from 'react-redux';

import DomainSettingIntro from './DomainSettingIntro';
import SelectCategory from './SelectCategory';
import SelectProduct from './SelectProduct';
import SelectPromotion from './SelectPromotion';
import SelectSpecial from './SelectSpecial';
import Talk11UsedInfo from './Talk11UsedInfo';

type DialogBodyMapperType = {
  [index in DialogType]: {
    component: React.FC<any>;
    props?: object;
  };
};

const dialogBodyMapper: DialogBodyMapperType = {
  SelectProduct: {
    component: SelectProduct,
  },
  SelectProducts: {
    component: SelectProduct,
    props: {
      isUsingInCategory: false,
      isSelectProducts: true,
    },
  },
  SelectProductsFromRecommendCardView: {
    component: SelectProduct,
    props: {
      isSelectProducts: true,
      isUsingInCategory: false,
      productRequestType: PRODUCT_REQUEST.CARD,
    },
  },
  SelectProductsFromRecommendVideo: {
    component: SelectProduct,
    props: {
      isSelectProducts: true,
      isUsingInCategory: false,
      productRequestType: PRODUCT_REQUEST.MOVIE,
    },
  },
  SelectProductsFromPromotionDetail: {
    component: SelectProduct,
    props: {
      isUsingInCategory: false,
      isSelectProducts: true,
    },
  },
  SelectPromotionFromRecommendPromotion: {
    component: SelectPromotion,
  },
  SelectPromotionFromProfile: {
    component: SelectPromotion,
  },
  SelectSpecial: {
    component: SelectSpecial,
  },
  SelectCategory: {
    component: SelectCategory,
  },
  DomainSettingIntro: {
    component: DomainSettingIntro,
  },
  Talk11UsedInfo: {
    component: Talk11UsedInfo,
  },
};

const DialogBody = () => {
  const type = useSelector((state: RootState) => state.dialog.type);
  const { component, props } = dialogBodyMapper[type];

  return React.createElement(component, props);
};

export default DialogBody;
