import { Radio } from 'components/common';
import { RadioType } from 'components/common/Radio';
import LocalAlert from 'components/layouts/default-layout/Alert/LocalAlert';
import { RootState } from 'modules';
import {
  deletePrivateDomainAsync,
  setPrivateDomain,
  setPrivateDomainOk,
  setStorePersonalDomainUrl,
} from 'modules/user';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStoreDomain from './useStoreDomain';

interface IStoreUrlRadioProps {
  isPrivateDomain: boolean;
  onClickStoreUrlRadio: (boolean: boolean) => void;
  onClickSave: () => void;
}

const StoreUrlRadio = ({
  isPrivateDomain,
  onClickStoreUrlRadio,
  onClickSave,
}: IStoreUrlRadioProps) => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const {
    serverSavedPrivateDomain,
    isPrivateDomainConfirmed,
    mobileStoreUrl,
    pcStoreUrl,
  } = useStoreDomain();
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);

  const onClickConfirmAlert = useCallback(() => {
    dispatch(
      deletePrivateDomainAsync.request({
        storeNo,
        domainAddress: serverSavedPrivateDomain,
        mobileStoreUrl,
        pcStoreUrl,
      }),
    );
    onClickStoreUrlRadio(false);
    dispatch(setPrivateDomainOk(false));
    dispatch(setPrivateDomain(''));
    dispatch(setStorePersonalDomainUrl(''));
    setIsShowAlert(false);
    onClickSave();
  }, [
    dispatch,
    mobileStoreUrl,
    onClickSave,
    onClickStoreUrlRadio,
    pcStoreUrl,
    serverSavedPrivateDomain,
    storeNo,
  ]);

  const onClickUsePublic = useCallback(() => {
    if (serverSavedPrivateDomain && isPrivateDomainConfirmed) {
      setIsShowAlert(true);
    } else {
      onClickStoreUrlRadio(false);
      dispatch(setPrivateDomain(''));
    }
  }, [
    dispatch,
    isPrivateDomainConfirmed,
    onClickStoreUrlRadio,
    serverSavedPrivateDomain,
  ]);

  return (
    <>
      {isShowAlert && (
        <LocalAlert
          bodyType="STORE_URL_SWITCH_PUBLIC"
          confirmText="확인"
          onClickConfirm={onClickConfirmAlert}
          cancelText="취소"
          onClickCancel={() => setIsShowAlert(false)}
        />
      )}
      <Radio
        type={RadioType.basic}
        name="radioUrl"
        value="0"
        label="11번가 도메인 사용"
        checked={!isPrivateDomain}
        onChange={onClickUsePublic}
      />
      <Radio
        type={RadioType.basic}
        name="radioUrl"
        value="1"
        label="개인 도메인 사용"
        checked={isPrivateDomain}
        onChange={() => onClickStoreUrlRadio(true)}
      />
    </>
  );
};

export default StoreUrlRadio;
