import {
  getNoticeDetail,
  getNoticeList,
  IPostNoticeDetailParam,
  IPutNoticeDetailParam,
  postNoticeDetail,
  putNoticeDetail,
} from 'apis/notice';
import { RootState } from 'modules';
import { resetAsyncTask } from 'modules/asyncTask';
import {
  getNoticeDetailAsync,
  getNoticeListAsync,
  postNoticeDetailAsync,
  putNoticeDetailAsync,
} from 'modules/notice/actions';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';

export const getNoticeListSaga = createAsyncSaga(
  getNoticeListAsync,
  getNoticeList,
);
const getNoticeDetailSaga = createAsyncSaga(
  getNoticeDetailAsync,
  getNoticeDetail,
);

function* saveNoticeDetail() {
  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  yield getNoticeListSaga(getNoticeListAsync.request({ storeNo }));
  yield delay(1200);
}

function* postNoticeDetailSaga(
  action: PayloadAction<string, IPostNoticeDetailParam>,
) {
  yield createAsyncSaga(
    postNoticeDetailAsync,
    postNoticeDetail,
  )(postNoticeDetailAsync.request(action.payload));

  yield saveNoticeDetail();
  yield put(resetAsyncTask(getType(postNoticeDetailAsync.request)));
}

function* putNoticeDetailSaga(
  action: PayloadAction<string, IPutNoticeDetailParam>,
) {
  yield createAsyncSaga(
    putNoticeDetailAsync,
    putNoticeDetail,
  )(putNoticeDetailAsync.request(action.payload));

  yield saveNoticeDetail();
  yield put(resetAsyncTask(getType(putNoticeDetailAsync.request)));
}

export default function* noticeSaga() {
  yield takeLatest(getType(getNoticeListAsync.request), getNoticeListSaga);
  yield takeLatest(getType(getNoticeDetailAsync.request), getNoticeDetailSaga);
  yield takeLatest(
    getType(postNoticeDetailAsync.request),
    postNoticeDetailSaga,
  );
  yield takeLatest(getType(putNoticeDetailAsync.request), putNoticeDetailSaga);
}
