import classNames from 'classnames';
import { RootState } from 'modules';
import { IRecommendProduct, RecommendMenuIdType } from 'modules/recommend';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Swiper from 'swiper';

const compareRecommendItem = (
  a: IRecommendProduct,
  b: IRecommendProduct,
): number => {
  if (a.orderSeq < b.orderSeq) return -1;
  if (a.orderSeq > b.orderSeq) return 1;
  if (a.productName < b.productName) return -1;
  if (a.productName > b.productName) return 1;
  if (a.productNo < b.productNo) return 1;
  if (a.productNo > b.productNo) return -1;

  return 0;
};
const Video = ({ id }: { id: RecommendMenuIdType }) => {
  const productData = useSelector(
    (state: RootState) => state.recommend[id].productData,
  );
  const title = useSelector((state: RootState) => state.recommend[id].title);
  const template = useSelector((state: RootState) => state.display.template);
  const sortedData = useMemo(
    () =>
      [...productData]
        .sort(compareRecommendItem)
        .filter(data => data.sellPrice !== null),
    [productData],
  );

  useEffect(() => {
    /* eslint-disable */
    // TODO: 실제 Front 모듈은 loop: true가 적용되어 있으나 해당 옵션이 dynamic contents를 감지하는 observer 옵션과 충돌하는 버그가 있음
    const swiper = new Swiper('.w-swiper', {
      slidesPerView:'auto',
      spaceBetween: 15,
      direction: 'horizontal',
      observer: true,
    });

    /* eslint-enable */
    return () => swiper.destroy(true, true);
  }, []);

  return (
    <div
      className="item_cont"
      style={{ height: sortedData.length <= 1 ? 303 : 281 }}
    >
      <div className="l-grid">
        <div className="l-grid__row" data-ui-type="Store_Product_Video">
          <div className="l-grid__col l-grid__col--12">
            <div className="c-headline c-headline--center">
              <h3 className="c-headline__title">{title}</h3>
            </div>
          </div>
          <div className="l-grid__col l-grid__col--12">
            <div
              className={classNames('c-swiper', {
                'c-swiper__none': sortedData.length <= 1,
              })}
            >
              <div className="w-swiper" id="videoSwiper">
                <ul className="swiper-wrapper">
                  {sortedData.length > 0 ? (
                    sortedData.map(data => (
                      <li className="swiper-slide" key={data.productNo}>
                        <div className="c-card c-card__video">
                          <div className="c-card__media">
                            <video
                              poster={`${process.env.REACT_APP_IMAGE_URL}${data.movieImageUrl}`}
                              muted
                            >
                              <source
                                src={`${process.env.REACT_APP_VIDEO_URL}${data.movieUrl}`}
                              />
                            </video>
                            <span className="c-card__mov__button" role="button">
                              동영상 정보
                            </span>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="swiper-slide">
                      <div className="c-card c-card__video">
                        <div className="c-card__media">
                          <video
                            poster={`${process.env.REACT_APP_STATIC_PATH}${
                              template === 'default'
                                ? '/img/store/content/layout_movie.png'
                                : '/img/store/content/layout_movie2.png'
                            }`}
                            muted
                          >
                            <source src="" />
                          </video>

                          <span className="c-card__mov__button">
                            동영상 정보
                          </span>
                        </div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Video;
