import { RootState } from 'modules';
import { putMenuPriorityAsync } from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SortablePreview from './SortablePreview';

const SaveButton = () => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const moduleData = useSelector(
    (state: RootState) => state.display.moduleData,
  );
  const tempModuleData = useSelector(
    (state: RootState) => state.display.tempModuleData,
  );

  return (
    <button
      type="submit"
      className="btn-style__3 btn-size__1"
      onClick={() => {
        dispatch(
          putMenuPriorityAsync.request({
            storeNo,
            modules: [moduleData[0], ...tempModuleData].map((item, index) => ({
              moduleNo: item.id,
              displayPriority: index + 1,
            })),
          }),
        );
      }}
      data-log-actionid-area="order_edit"
      data-log-actionid-label="bottom_save"
    >
      순서 저장
    </button>
  );
};
const SortableFrame = () => (
  <div className="cont-view">
    <div className="view-title title-style__3 skip">
      <h3 className="title">전시순서편집</h3>
    </div>
    <div className="view-display">
      <SortablePreview />
    </div>
    <div className="cont-btn">
      <SaveButton />
    </div>
  </div>
);

export default SortableFrame;
