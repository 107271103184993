import classNames from 'classnames';
import React from 'react';

export type TextInputType = 'text' | 'number';
export type TextInputWidthType =
  | 'wide'
  | 'w-270'
  | 'w-350'
  | 'size__2'
  | 'waring';

export interface TextInputProps {
  type?: TextInputType;
  /** 텍스트 입력 값 */
  value: string;
  /** 플레이스홀더 */
  placeholder?: string;
  /** 텍스트 인풋 식별자 */
  title: string;
  /** 텍스트 입력할 때마다 실행되는 함수 */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** 가로 사이즈 */
  width?: TextInputWidthType;
  /** 비활성화 여부 */
  disabled?: boolean;
  children?: React.ReactNode;
  innerChildren?: React.ReactNode;
}

/** 기본 텍스트 인풋 컴포넌트입니다. */
const TextInput = ({
  type = 'text',
  value,
  placeholder,
  title,
  onChange,
  width,
  disabled,
  children,
  innerChildren,
}: TextInputProps) => {
  return (
    <div className={classNames('c-input', width)}>
      <div className="input-text">
        <input
          type={type}
          className="inp"
          title={title}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
        {innerChildren}
      </div>
      {children}
    </div>
  );
};

export default TextInput;
