import { RootState } from 'modules';
import { menuIdType } from 'modules/display';
import React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  enable: boolean;
}

const markerMapper: { [key in string]: string } = {
  [menuIdType.profile]: '가',
  [menuIdType.recommend1]: '이',
  [menuIdType.recommend2]: '가',
  [menuIdType.recommend3]: '이',
  [menuIdType.special]: '이',
  [menuIdType.bestReview]: '가',
};

const ChangeMenuVisible = ({ enable }: IProps) => {
  const menuData = useSelector((state: RootState) => state.display.moduleData);
  const id = useSelector((state: RootState) => state.alert.id);

  return (
    <>
      <strong className="color_brand">
        {menuData[menuData.findIndex(menu => menu.id === id)].name}
      </strong>
      {enable
        ? `${
            markerMapper[
              menuData[menuData.findIndex(menu => menu.id === id)].id
            ]
          } 내 스토어에 노출됩니다.`
        : `${
            markerMapper[
              menuData[menuData.findIndex(menu => menu.id === id)].id
            ]
          } 내 스토어에 노출되지 않습니다.`}
      <sub className="sub_text">계속하시겠습니까?</sub>
    </>
  );
};

export default ChangeMenuVisible;
