const calcBytesByUTF8 = (s: string) => {
  if (s.charCodeAt(0) >> 11) return 3;
  if (s.charCodeAt(0) >> 7) return 2;

  return 1;
};

export const getBytesByUTF8 = (str: string) =>
  Array.from(str)
    .map(calcBytesByUTF8)
    .reduce((prev, step) => prev + step, 0);

export const cutByMaxLength = (str: string, max: number = 30) =>
  str.length > max ? str.substring(0, max) : str;

export const distinctArray = <T, K extends keyof T>(list: T[], prop: K): T[] =>
  list.filter((item, i) => list.findIndex(t => t[prop] === item[prop]) === i);

export const convertEmptyStringToNull = (string: string) =>
  string === '' ? null : string;

export const convertMinusOneToNull = (number: number) =>
  number === -1 ? null : number;

export const isShowNoticeDisplayDate = (date: string) => !date.includes('2999');

export { default as createAsyncSaga } from './sagaUtils';
