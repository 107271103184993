import rootReducer from 'modules';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas';

const sagaMiddleware = createSagaMiddleware();

declare global {
  interface Window {
    rakeLog: any;
    rakeLogPageInfo: any;
    ACE: any;
  }
}

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore();
