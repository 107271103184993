import { Radio } from 'components/common';
import { RadioType } from 'components/common/Radio';
import { ChangeEventType } from 'hooks/useTextInput';
import React, { memo } from 'react';

interface IUploadImageProps {
  onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}
export const UploadImage = React.forwardRef(
  ({ onChangeFile, children }: IUploadImageProps, ref: any) => (
    <label className="input-file size__3">
      <input ref={ref} type="file" onChange={onChangeFile} />
      {children}
    </label>
  ),
);

export const ImageDescription = memo(() => (
  <ul className="text_list">
    <li>
      스토어 프로필에 타인의 저작권 또는 초상권 등을 침해하는 이미지는
      등록해서는 안됩니다.
    </li>
    <li>
      이미지는 가로(600px~1000px), 세로(600px~1000px), 최대 250KB,
      jpg,jpeg,png만 등록 가능합니다.
    </li>
    {/* <li>스토어 프로필 이미지는 11톡 프로필 이미지와 같이 사용 됩니다.</li> */}
  </ul>
));

export const TextUrl = memo(
  ({
    isPrivate = false,
    children,
  }: {
    isPrivate?: boolean;
    children: string;
  }) => (
    <ul className="text_url">
      <li>
        <em className="title">PC</em>{' '}
        <span className="cont">
          {isPrivate ? (
            <a
              href={`http://${children}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              http://{children}
            </a>
          ) : (
            <a
              href={`http://shop.11st.co.kr/stores/${children}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              http://shop.11st.co.kr/stores/<i>{children}</i>
            </a>
          )}
        </span>
      </li>
      <li>
        <em className="title">모바일</em>{' '}
        <span className="cont">
          {isPrivate ? (
            <a
              href={`http://m.${children}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              http://m.{children}
            </a>
          ) : (
            <a
              href={`http://shop.11st.co.kr/m/${children}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              http://shop.11st.co.kr/m/
              <i>{children}</i>
            </a>
          )}
        </span>
      </li>
    </ul>
  ),
);

export const UrlError = memo(() => (
  <p className="text_info info-type__5">
    <em className="text_em">
      입력하신 도메인의 호스트 IP가 11번가 스토어 IP (220.103.231.155)가
      아닙니다. <br />
      도메인을 구입한 호스팅 업체에서 IP 를 PC와 모바일 도메인 각각
      220.103.231.155 로 각각 설정 후 11번가에서 다시 설정해주세요. <br />
    </em>{' '}
    <br />
    호스트 IP 등록 방법은 도메인 호스팅 업체마다 다를 수 있으며, 자세한 내용은
    업체로 문의 주시기 바랍니다.
  </p>
));

export const DomainAlreadyUsed = memo(() => (
  <p className="text_info info-type__5">
    <em className="text_em">
      이미 11번가에서 사용중인 도메인입니다. 도메인 주소를 다시 확인해주세요.
    </em>
  </p>
));

interface IOfflineRadioProps {
  isUsed: boolean;
  isRoadShopSeller: boolean;
  onChangeUseOffline: (boolean: boolean) => void;
}

export const OfflineRadio = ({
  isUsed,
  isRoadShopSeller,
  onChangeUseOffline,
}: IOfflineRadioProps) => {
  return (
    <>
      <Radio
        type={RadioType.basic}
        name="radioAddress"
        value="radioAddress0"
        label="사용안함"
        checked={!isUsed}
        onChange={() => onChangeUseOffline(false)}
        disabled={isRoadShopSeller}
      />
      <Radio
        type={RadioType.basic}
        name="radioAddress"
        value="radioAddress1"
        label="사용"
        checked={isUsed}
        onChange={() => onChangeUseOffline(true)}
      />
    </>
  );
};

export const InstagramForm = ({
  url,
  onChange,
}: {
  url: string;
  onChange: (e: ChangeEventType) => void;
}) => {
  return (
    <>
      <div className="c-input w-400">
        <span className="lab">http://instagram.com/</span>
        <div className="input-text">
          <input
            type="text"
            className="inp"
            placeholder="나머지 주소를 입력해주세요."
            value={url}
            onChange={onChange}
          />
        </div>
      </div>
      <p className="text_info">특수문자는 . _만 가능 합니다.</p>
    </>
  );
};

export const RoadShopInfo = () => (
  <p className="text_info">
    1개의 로드# 지역을 선택 해주세요. <br />
    선택하신 지역은 로드# 탭에 스토어명과 함께 노출됩니다.
  </p>
);

export const SelectedFashionItem = ({
  tagName,
  onClick,
}: {
  tagName: string;
  onClick: () => void;
}) => (
  <span className="item">
    {`${tagName} `}
    <button
      onClick={onClick}
      type="button"
      className="btn-style__icon btn-icon__delete3"
    >
      삭제
    </button>
  </span>
);
