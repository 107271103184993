import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';

interface IPropsCategoryListItemProps {
  rightArrow?: boolean;
  draggable?: boolean;
  index?: number;
  active?: boolean;
  createMode?: boolean;
  mark?: boolean;
  text: string;
  onClick?: () => void;
  onDragStart?: (index: number) => void;
  onDragOver?: (e: React.DragEvent, index: number) => void;
  onDragEnd?: (e: React.DragEvent) => void;
  onDragLeave?: (e: React.DragEvent) => void;
  onDrop?: (e: React.DragEvent) => void;
  dragTargetIndex?: number;
  dropTargetIndex?: number;
  dropOrder?: number;
}

const CategoryListItem = ({
  rightArrow = false,
  draggable = false,
  onDragStart = () => {},
  onDragOver = () => {},
  onDragEnd = () => {},
  onDragLeave = () => {},
  onDrop = () => {},
  index,
  dragTargetIndex,
  dropTargetIndex,
  dropOrder,
  active = false,
  createMode = false,
  mark = false,
  text = '',
  onClick = () => {},
}: IPropsCategoryListItemProps) => {
  const currentDragClassName = useMemo(
    () =>
      !createMode &&
      dropTargetIndex === index &&
      (dropOrder ? 'after' : 'before'),
    [createMode, dropOrder, dropTargetIndex, index],
  );

  const currentDragIndexElementStyle = useMemo(
    () =>
      dragTargetIndex === index
        ? { color: '#999', backgroundColor: '#fafafa' }
        : {},
    [dragTargetIndex, index],
  );

  const dragStart = useCallback(() => {
    onDragStart(index ?? 0);
  }, [index, onDragStart]);

  const mouseOver = useCallback(
    (e: React.DragEvent) => {
      (onDragOver as (e: React.DragEvent<Element>, index: number) => void)(
        e,
        index ?? 0,
      );
    },
    [index, onDragOver],
  );

  return (
    <li
      onDragStart={() => dragStart()}
      onDragOver={mouseOver}
      onDragEnd={onDragEnd}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      draggable={draggable}
      className={classNames(
        'item',
        {
          add: rightArrow,
        },
        draggable
          ? {
              edit_active: active,
            }
          : { active: !createMode && active, edit_dim: createMode && active },
      )}
    >
      <button
        type="button"
        onClick={onClick}
        draggable={draggable}
        className={classNames({ drop: draggable }, currentDragClassName)}
        style={currentDragIndexElementStyle}
      >
        {mark && <span className="icon icon-mark" />}
        {text}
      </button>
    </li>
  );
};

export default CategoryListItem;
