import httpClient from 'apis/httpClient';

import config from './endpoint.config';

export interface ICurationItem {
  displayPriority: number;
  moduleObjectValue: number;
}
export interface IGetRecommendInfoParam {
  storeNo: string;
  moduleNo: string;
}
export interface IPostRecommendInfoParam {
  storeNo: string;
  moduleNo: string;
  curationTypeCode: string;
  moduleTitle: string;
  moduleSubTitle: string;
  displayBeginDate: string;
  displayEndDate: string;
  adminCurationValueList: ICurationItem[];
}

export interface IPutRecommendInfoParam extends IPostRecommendInfoParam {
  curationModuleNo: string;
}

export const getRecommendInfo = (param: IGetRecommendInfoParam) =>
  httpClient(config.recommend.getRecommend(param));
export const postRecommendInfo = (param: IPostRecommendInfoParam) =>
  httpClient(config.recommend.postRecommend(param), 'POST', {
    curationTypeCode: param.curationTypeCode,
    moduleNo: param.moduleNo,
    moduleTitle: param.moduleTitle,
    moduleSubTitle: param.moduleSubTitle,
    displayBeginDate: param.displayBeginDate,
    displayEndDate: param.displayEndDate,
    curationDetailList: param.adminCurationValueList,
  });
export const putRecommendInfo = (param: IPutRecommendInfoParam) =>
  httpClient(config.recommend.putRecommend(param), 'PUT', {
    curationModuleNo: param.curationModuleNo,
    curationTypeCode: param.curationTypeCode,
    moduleNo: param.moduleNo,
    moduleTitle: param.moduleTitle,
    moduleSubTitle: param.moduleSubTitle,
    displayBeginDate: param.displayBeginDate,
    displayEndDate: param.displayEndDate,
    curationDetailList: param.adminCurationValueList,
  });
