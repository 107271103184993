import Radio, { RadioType } from 'components/common/Radio';
import { RootState } from 'modules';
import { setSkin, setTouched } from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const InputData = [
  {
    id: 1,
    value: 'skin_1',
    style: {
      backgroundImage:
        'linear-gradient(to right, #ff825e, #ff4b5b 49%, #ff5bb2)',
    },
  },
  {
    id: 2,
    value: 'skin_2',
    style: {
      backgroundImage: 'linear-gradient(to right, #fb90ad 0%, #b38fff)',
    },
  },
  {
    id: 3,
    value: 'skin_3',
    style: { backgroundImage: 'linear-gradient(to right, #43b2ff, #6c70f5)' },
  },
  {
    id: 4,
    value: 'skin_4',
    style: {
      backgroundImage: 'linear-gradient(to right, #13c5c4 2%, #41b77a 100%)',
    },
  },
  {
    id: 5,
    value: 'skin_5',
    style: {
      backgroundImage: 'linear-gradient(to right, #9dc216 1%, #6b9e54 100%)',
    },
  },
  { id: 6, value: 'skin_6', style: { backgroundColor: '#f18c7a' } },
  { id: 7, value: 'skin_7', style: { backgroundColor: '#ff91b3' } },
  { id: 8, value: 'skin_8', style: { backgroundColor: '#b0957a' } },
  { id: 9, value: 'skin_9', style: { backgroundColor: '#708be6' } },
  { id: 10, value: 'skin_10', style: { backgroundColor: '#678157' } },
  { id: 11, value: 'skin_11', style: { backgroundColor: '#5b6167' } },
  { id: 12, value: 'skin_12', style: { backgroundColor: '#111' } },
  {
    id: 13,
    value: 'skin_13',
    style: { border: 'solid 1px #ddd', backgroundColor: '#fff' },
  },
];

const SkinRadioGroup = () => {
  const skin = useSelector((state: RootState) => state.display.skin);

  const dispatch = useDispatch();

  return (
    <div className="view_skin__select">
      {InputData.map(({ id, value, style }) => (
        <Radio
          key={id}
          name="skin-radio-group"
          type={RadioType.color}
          value={value}
          colorStyle={style}
          checked={skin === value}
          onChange={() => {
            dispatch(setSkin(value));
            dispatch(setTouched(true));
          }}
        />
      ))}
    </div>
  );
};

export default SkinRadioGroup;
