import { RootState } from 'modules';
import {
  getCustomCategoryDetailAsync,
  setSelectedCustomId,
  TYPE_CODE,
} from 'modules/category';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import {
  getCustomCategoryProductDetailAsync,
  setProductTablePage,
} from 'modules/product';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useOnClickCategoryItem = (
  resetData: () => void,
  setCategoryName: (string: string) => void,
  setIsProductConnect: (boolean: boolean) => void,
) => {
  const dispatch = useDispatch();

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const custom = useSelector((state: RootState) => state.category.custom);

  const onClickCategoryItem = useCallback(
    (selectedCategoryId: number) => {
      if (
        selectedCategoryId !== SELECTED_CUSTOM_ID.INIT &&
        selectedCategoryId !== SELECTED_CUSTOM_ID.CREATE
      ) {
        resetData();
        const { typeCode = TYPE_CODE.PRODUCT, storeCategoryName } =
          custom.find(
            ({ storeCategoryNo }) => storeCategoryNo === selectedCategoryId,
          ) ?? {};

        setCategoryName(storeCategoryName ?? '');
        const isProductDetail = typeCode === TYPE_CODE.PRODUCT;
        setIsProductConnect(isProductDetail);
        dispatch(setSelectedCustomId(selectedCategoryId));
        dispatch(setProductTablePage(1));
        dispatch(
          (isProductDetail
            ? getCustomCategoryProductDetailAsync
            : getCustomCategoryDetailAsync
          ).request({
            storeNo,
            storeCategoryNo: selectedCategoryId,
          }),
        );
      }
    },
    [
      custom,
      dispatch,
      resetData,
      setCategoryName,
      setIsProductConnect,
      storeNo,
    ],
  );

  return onClickCategoryItem;
};

export default useOnClickCategoryItem;
