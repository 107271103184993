import classNames from 'classnames';
import React, { useState } from 'react';

import Calendar from '../Calendar';

interface DatePickerProps {
  value: string;
  source: string;
  placeholder?: string;
  title: string;
  onChange: (date: Date) => void;
  width?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DatePicker = ({
  value,
  source,
  placeholder,
  title,
  onChange,
  width,
  minDate,
  maxDate,
}: DatePickerProps) => {
  const [show, setShow] = useState<boolean>(false);

  return (
    <div className={classNames('c-input', width)}>
      <div className="input-text">
        <input
          type="text"
          className="inp"
          placeholder={placeholder}
          title={title}
          value={value}
          onChange={() => {}}
          onClick={() => {
            setShow(prev => !prev);
          }}
        />
        <div className="input-etc">
          <button
            type="button"
            className="btn-calender"
            onClick={() => {
              setShow(prev => !prev);
            }}
          >
            캘린더
          </button>
        </div>
      </div>
      {show && (
        <Calendar
          value={source ? new Date(source) : undefined}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          onSelectDay={(date: Date | null) => {
            if (date) {
              onChange(date);
            }
            setShow(false);
          }}
          show={show}
          setShow={setShow}
          minYear={2}
          maxYear={2}
        />
      )}
    </div>
  );
};

export default DatePicker;
