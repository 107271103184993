import classNames from 'classnames';
import React from 'react';

import { TextInputProps } from './TextInput';

export enum TextInputButtonType {
  clear = 'btn-clear',
  search = 'btn-search',
}

interface IProps extends TextInputProps {
  /** clear, saerch, calender */
  buttonType: TextInputButtonType;
  /** 버튼이 클릭될 때 실행되는 함수 */
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const textInputButtonLabel = {
  [`${TextInputButtonType.clear}`]: '입력어 삭제',
  [`${TextInputButtonType.search}`]: '검색',
};

const TextInputWithButton: React.FC<IProps> = ({
  value,
  placeholder,
  title,
  onChange,
  width,
  buttonType,
  onButtonClick,
}) => {
  return (
    <div className={classNames('c-input', width)}>
      <div className="input-text">
        <input
          type="text"
          className="inp"
          placeholder={placeholder}
          title={title}
          value={value}
          onChange={onChange}
        />
        <div className="input-etc">
          <button type="button" className={buttonType} onClick={onButtonClick}>
            {textInputButtonLabel[buttonType]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TextInputWithButton;
