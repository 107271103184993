import { RootState } from 'modules';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useCategoryApplied = () => {
  const customCategory = useSelector(
    (state: RootState) => state.category.custom,
  );
  const isStoreCategorySet = useSelector(
    (state: RootState) => state.user.headerInfo.isStoreCategorySet,
  );
  const isUsingCustom = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );

  const isValidatedCustomCategory = useMemo(() => customCategory.length >= 4, [
    customCategory.length,
  ]);
  const isCustomCategoryApplied = useMemo(
    () => isStoreCategorySet && isValidatedCustomCategory,
    [isStoreCategorySet, isValidatedCustomCategory],
  );

  const isAppliedBasicCategory = useMemo(
    () => !isCustomCategoryApplied && !isStoreCategorySet && !isUsingCustom,
    [isCustomCategoryApplied, isStoreCategorySet, isUsingCustom],
  );
  const isAppliedCustomCategory = useMemo(
    () => isCustomCategoryApplied && isStoreCategorySet && isUsingCustom,
    [isCustomCategoryApplied, isStoreCategorySet, isUsingCustom],
  );
  const applyButtonDisabled = useMemo(
    () => isAppliedBasicCategory || isAppliedCustomCategory,
    [isAppliedBasicCategory, isAppliedCustomCategory],
  );

  const applyButtonText = useMemo(() => {
    if (isAppliedBasicCategory) {
      return '기본카테고리 적용중';
    }
    if (isAppliedCustomCategory) {
      return '스토어 카테고리 적용중';
    }

    return '스토어에 적용';
  }, [isAppliedBasicCategory, isAppliedCustomCategory]);

  return {
    isCustomCategoryApplied,
    isValidatedCustomCategory,
    applyButtonDisabled,
    applyButtonText,
  };
};

export default useCategoryApplied;
