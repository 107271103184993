import { Radio } from 'components/common';
import { RadioType } from 'components/common/Radio';
import { addMonths } from 'date-fns';
import { RootState } from 'modules';
import {
  setNoticeDetailEndDate,
  setNoticeDetailShowDate,
  setNoticeDetailStartDate,
} from 'modules/notice/actions';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatNoticeDate } from 'utils/format';

import Calendar from './Calendar';

const DisplayDate = ({ editMode }: { editMode: boolean }) => {
  const dispatch = useDispatch();
  const { isShowDisplayDate } = useSelector(
    (state: RootState) => state.notice.detail,
  );

  const onClickShowDisplayDate = useCallback(() => {
    if (!editMode) {
      dispatch(setNoticeDetailStartDate(formatNoticeDate(new Date())));
    }
    dispatch(
      setNoticeDetailEndDate(formatNoticeDate(addMonths(new Date(), 6))),
    );

    dispatch(setNoticeDetailShowDate(true));
  }, [dispatch, editMode]);

  return (
    <>
      <Radio
        type={RadioType.basic}
        name="radioBanner"
        label="안함"
        value="0"
        checked={!isShowDisplayDate}
        onChange={() => dispatch(setNoticeDetailShowDate(false))}
      />
      <Radio
        type={RadioType.basic}
        name="radioBanner"
        label="설정함"
        value="1"
        checked={isShowDisplayDate}
        onChange={onClickShowDisplayDate}
      />
      {isShowDisplayDate && <Calendar />}
    </>
  );
};

export default DisplayDate;
