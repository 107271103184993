import React from 'react';
import { useParams } from 'react-router-dom';

// TODO: 삭제
const Read = () => {
  const { id } = useParams();

  return (
    <div>
      <div>Read </div>
      <div>{id}</div>
    </div>
  );
};

export default Read;
