import config from 'apis/endpoint.config';
import httpClient from 'apis/httpClient';
import { CategoryItemIdType, TypeCodeType } from 'modules/category';
import { StoreNoType } from 'modules/user';

export interface IBasicCategoryParam {
  fetchSize?: number;
  encSellerNo: string;
  storeNo: StoreNoType;
  targetCategoryDepth?: number;
  isMappedCategory?: boolean;
}

export interface IBasicMiddleCategoryParam extends IBasicCategoryParam {
  largeCategoryNumber: number;
}

export interface IBasicSmallCategoryParam extends IBasicCategoryParam {
  largeCategoryNumber: number;
  middleCategoryNumber: number;
}

export interface ICustomCategoryParam {
  storeNo: StoreNoType;
}

export interface IDeleteCustomCategoryParam {
  storeNo: string;
  storeCategoryNo: number;
}

export interface ICustomCategoryDetailParam {
  storeNo: StoreNoType;
  storeCategoryNo: CategoryItemIdType;
}

export interface IPostCustomCategoryParam {
  storeNo: StoreNoType;
  storeCategoryName: string;
  displayPriority: number;
  type: TypeCodeType;
  values: {
    displayPriority: number;
    keyValue: number;
    typeCode: string;
  }[];
}
export interface IPostCustomCategoryResponse {
  resultCode: number;
  resultMessage: string;
  storeCategoryNo: CategoryItemIdType;
}
export interface IPutCustomCategoryResponse {
  resultCode: number;
  resultMessage: string;
  storeCategoryNo: CategoryItemIdType;
}
export interface IDeleteCustomCategoryResponse {
  resultCode: number;
  resultMessage: string;
  storeCategoryNo: CategoryItemIdType;
}
export interface IPutCustomCategoryParam {
  storeNo: StoreNoType;
  storeCategoryNo: CategoryItemIdType;
  isTypeChanged: boolean;
  storeCategoryName: string;
  type: TypeCodeType;
  values: {
    displayPriority: number;
    keyValue: number;
    typeCode: string;
  }[];
}

export interface IApplyCategoryParam {
  storeNo: StoreNoType;
  isStoreCategorySwitch?: boolean;
  values: { displayPriority: number; storeCategoryNo: CategoryItemIdType }[];
}

export const getBasicLargeCategoryList = (param: IBasicCategoryParam) =>
  httpClient(config.category.basicLarge(param));

export const getBasicMiddleCategoryList = (param: IBasicMiddleCategoryParam) =>
  httpClient(config.category.basicMiddle(param));

export const getBasicSmallCategoryList = (param: IBasicSmallCategoryParam) =>
  httpClient(config.category.basicSmall(param));

export const getCustomCategoryList = (param: ICustomCategoryParam) =>
  httpClient(config.category.custom(param));

export const getCustomCategoryDetail = (param: ICustomCategoryDetailParam) =>
  httpClient(config.category.customDetail(param));

export const postCustomCategory = (param: IPostCustomCategoryParam) =>
  httpClient(config.category.postCustom(param), 'POST', param);

export const putCustomCategory = (param: IPutCustomCategoryParam) =>
  httpClient(config.category.putCustom(param), 'PUT', param);

export const deleteCustomCategory = (param: IDeleteCustomCategoryParam) =>
  httpClient(config.category.deleteCustom(param), 'DELETE');

export const applyCustomCategory = (param: IApplyCategoryParam) =>
  httpClient(config.category.applyCustom(param), 'PUT', param);
