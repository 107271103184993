/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useImageUpload from 'hooks/useImageUpload';
import React, { useMemo, useState } from 'react';

import useBasicInfoImageUpload from './useBasicInfoImageUpload';

const StoreLocation = ({ imageUrl = '' }: { imageUrl: string }) => {
  const [imageFileRef, onClickChangeImage] = useImageUpload();
  const { onChangeImage, onClearImage } = useBasicInfoImageUpload(
    'OFFLINE',
    imageFileRef,
  );
  const [mouseOver, setMouseOver] = useState(false);

  const url = useMemo(() => {
    if (imageUrl.trim() === '') {
      return '';
    }
    if (imageUrl.includes('http')) {
      return imageUrl;
    }

    return process.env.REACT_APP_CDN + imageUrl;
  }, [imageUrl]);

  return (
    <div className="cont_add type__2">
      <strong className="title">매장위치</strong>
      <div className="location">
        {imageUrl ? (
          <label onClick={onClickChangeImage} className="input-file size__4">
            <input
              type="file"
              title="이미지"
              ref={imageFileRef}
              onChange={onChangeImage}
            />
            <span
              className="img"
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
            >
              <img src={url} alt="매장위치" />
              {mouseOver && (
                <span className="input-file__btn">
                  <span className="input-file__btn--edit">수정</span>
                  <span
                    onClick={e => {
                      onClearImage();
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className="input-file__btn--del"
                    role="button"
                    tabIndex={0}
                  >
                    삭제
                  </span>
                </span>
              )}
            </span>
          </label>
        ) : (
          <label onClick={onClickChangeImage} className="input-file size__4">
            <input
              type="file"
              title="이미지"
              ref={imageFileRef}
              onChange={onChangeImage}
            />
            <span className="btn-style__img btn-img__file">찾아보기</span>
            <span className="text">이미지 사이즈 686 X 376</span>
          </label>
        )}
      </div>
      <ul className="text_list">
        <li>
          이미지는 가로(600px~1000px), 세로(300px~1000px), 최대 250KB,
          jpg,jpeg,png만 등록 가능합니다.
        </li>
        <li>686 X 376 사이즈를 권장합니다.</li>
      </ul>
    </div>
  );
};

export default StoreLocation;
