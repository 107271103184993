import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

const GuideText = () => {
  const isUsingCustom = useSelector(
    (state: RootState) => state.category.isUsingCustom,
  );

  return isUsingCustom ? (
    <p className="text_info info-type__2">
      최소 4개, 최대 20개 까지 생성 가능하며 중 카테고리 또는 특정 상품단위로
      연결 가능합니다.
    </p>
  ) : (
    <p className="text_info info-type__2">
      상품수가 많은 카테고리순으로 자동 노출됩니다.
    </p>
  );
};

export default GuideText;
