import { openAlert } from 'modules/alert';
import { setSelectedCustomId } from 'modules/category';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

const useCategoryFn = (isEditMode: boolean, resetData: () => void) => {
  const dispatch = useDispatch();

  const onClickAddCategory = useCallback(() => {
    resetData();
    dispatch(setSelectedCustomId(SELECTED_CUSTOM_ID.CREATE));
  }, [dispatch, resetData]);
  const onClickDeleteCategory = useCallback(() => {
    if (isEditMode) {
      dispatch(openAlert('DELETE_CATEGORY_WARNING'));
    } else {
      dispatch(openAlert('DELETE_AFTER_SELECT_CATEGORY'));
    }
  }, [dispatch, isEditMode]);

  return [onClickAddCategory, onClickDeleteCategory];
};

export default useCategoryFn;
