import React, { useMemo } from 'react';

const PageTitle = ({ editMode }: { editMode: boolean }) => {
  const title = useMemo(
    () => (editMode ? '공지사항 수정' : '신규 공지사항 등록'),
    [editMode],
  );

  return (
    <div className="view-title title-style__2">
      <h2 className="title">{title}</h2>
    </div>
  );
};

export default PageTitle;
