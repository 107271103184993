import classNames from 'classnames';
import CheckBox, { CheckBoxType } from 'components/common/CheckBox';
import TableHead, { IColumn } from 'components/common/TableHead';
import useCheckBox from 'hooks/useCheckBox';
import produce from 'immer';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import {
  addCustomDetailModified,
  CATEGORY_DEFAULT,
  setCustomDetail,
  setCustomDetailModified,
  setSelectedCustomCategory,
} from 'modules/category';
import { openDialog } from 'modules/dialog';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const COLUMNS: ReadonlyArray<IColumn> = Object.freeze(
  [
    { text: '전시순서', width: 'auto' },
    { text: '상품 카테고리 정보', width: 'auto' },
  ].map((col, index) => ({ ...col, id: index })),
);

const EMPTY_COLSPAN = COLUMNS.length + 1;
const EmptyCategory = memo(({ onClick }: { onClick: () => void }) => (
  <tr>
    <td colSpan={EMPTY_COLSPAN} className="non-data__2">
      <p>상품이 등록된 카테고리를 연결할 수 있습니다.</p>
      <button
        type="button"
        onClick={onClick}
        className="btn-style__1 btn-size__5"
      >
        카테고리 조회
      </button>
    </td>
  </tr>
));

const CategoryTable = ({
  editMode,
  isLoading,
}: {
  editMode: boolean;
  isLoading: boolean;
}) => {
  const dispatch = useDispatch();
  const categories = useSelector(
    (state: RootState) => state.category.customDetail,
  );
  const customDetailFetched = useSelector(
    (state: RootState) => state.category.customDetailFetched,
  );
  const customDetailModified = useSelector(
    (state: RootState) => state.category.customDetailModified,
  );
  const categoryLength = useMemo(() => categories.length, [categories]);
  const [
    isCheckedAll,
    onClickSelectAll,
    isChecked,
    onClickCheckBox,
    selectedCategoryIndexes,
  ] = useCheckBox(categoryLength);

  const onClickSelectCategory = useCallback(() => {
    dispatch(openDialog('SelectCategory'));
    dispatch(setSelectedCustomCategory(categories));
  }, [categories, dispatch]);

  const onClickDelete = useCallback(() => {
    if (selectedCategoryIndexes.every(flag => !flag)) {
      dispatch(openAlert('DELETE_AFTER_SELECT_CATEGORY'));

      return;
    }
    dispatch(
      setCustomDetail(
        categories.filter((__, i) => !selectedCategoryIndexes[i]),
      ),
    );

    if (editMode) {
      const selectedCategories = categories.filter(
        (__, i) => selectedCategoryIndexes[i],
      );

      const map = new Map(customDetailModified);
      selectedCategories.forEach(({ categoryNo, orderSeq }) => {
        if (map.has(categoryNo)) {
          map.delete(categoryNo);
        } else {
          map.set(categoryNo, {
            displayPriority: orderSeq ?? CATEGORY_DEFAULT.ORDER,
            typeCode: 'DELETE',
          });
        }
      });
      dispatch(setCustomDetailModified(map));
    }
  }, [
    categories,
    customDetailModified,
    dispatch,
    editMode,
    selectedCategoryIndexes,
  ]);

  const onChangeOrderSequence = useCallback(
    (value: string, id: number) => {
      const modifiedDetail = produce(categories, draft => {
        const index = categories.findIndex(
          ({ categoryNo }) => categoryNo === id,
        );
        draft[index].orderSeq = Number(value);
      });
      dispatch(setCustomDetail(modifiedDetail));
      if (editMode) {
        dispatch(
          addCustomDetailModified([
            {
              keyValue: id,
              displayPriority: Number(value),
              typeCode: customDetailFetched.some(
                ({ categoryNo }) => categoryNo === id,
              )
                ? 'UPDATE'
                : 'SAVE',
            },
          ]),
        );
      }
    },
    [categories, customDetailFetched, dispatch, editMode],
  );

  return (
    <>
      <div className="cont-filter">
        <div className="filter-side__1">
          <button
            type="button"
            onClick={onClickSelectCategory}
            className="btn-style__1 btn-size__6"
          >
            카테고리 조회
          </button>
        </div>
        <div className="filter-side__2">
          <button
            type="button"
            onClick={onClickDelete}
            className="btn-style__1 btn-size__6"
          >
            선택 삭제
          </button>
        </div>
      </div>

      <div className="table-wrap table-style__2">
        <table className="table">
          <caption>카테고리 연결</caption>
          <colgroup>
            <col width="40px" />
            <col width="90px" />
            <col />
          </colgroup>
          <TableHead columns={COLUMNS}>
            <th scope="col">
              <div className="th-text">
                <CheckBox
                  id="checkSpecial0"
                  name="checkSpecial"
                  type={CheckBoxType.basic}
                  label="전체선택"
                  labelHidden
                  checked={isCheckedAll}
                  onChange={onClickSelectAll}
                />
              </div>
            </th>
          </TableHead>
          {isLoading ? null : (
            <tbody>
              {categoryLength > 0 ? (
                categories.map(
                  (
                    {
                      categoryName,
                      parentCategoryName,
                      categoryNo: id,
                      orderSeq,
                    },
                    index,
                  ) => (
                    <tr
                      key={`${id}`}
                      className={classNames({ active: isChecked(index) })}
                    >
                      <td>
                        <CheckBox
                          id={`checkSpecial${index + 1}`}
                          name="checkSpecial"
                          type={CheckBoxType.basic}
                          label={`전시순서${index}`}
                          labelHidden
                          checked={isChecked(index)}
                          onChange={() => onClickCheckBox(index)}
                        />
                      </td>
                      <td>
                        <div className="c-input size__2">
                          <div className="input-text">
                            <input
                              type="number"
                              className="inp"
                              title="전시순서"
                              value={orderSeq}
                              onChange={e =>
                                onChangeOrderSequence(e.target.value, id)
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td className="td-side">{`${parentCategoryName} > ${categoryName}`}</td>
                    </tr>
                  ),
                )
              ) : (
                <EmptyCategory onClick={onClickSelectCategory} />
              )}
            </tbody>
          )}
        </table>
      </div>
    </>
  );
};

export default CategoryTable;
