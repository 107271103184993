import config from 'apis/endpoint.config';
import httpClient from 'apis/httpClient';

export interface INoticeParam {
  storeNo: string;
}
export interface INoticeDetailParam extends INoticeParam {
  noticeNo: string;
}

export interface IPostNoticeDetailParam {
  storeNo: string;
  content: string;
  displayBeginDate?: string;
  displayEndDate?: string;
  editorJson: string;
  isImportant: boolean;
  isPeriodOn: boolean;
  noticeClassifyCode: string;
  title: string;
}
export interface IPutNoticeDetailParam extends IPostNoticeDetailParam {
  noticeNo: string;
}

export const getNoticeList = ({ storeNo }: INoticeParam) =>
  httpClient(config.notice.noticeList({ storeNo }));
export const getNoticeDetail = ({ storeNo, noticeNo }: INoticeDetailParam) =>
  httpClient(config.notice.noticeDetail({ storeNo, noticeNo }));
export const postNoticeDetail = ({
  storeNo,
  ...body
}: IPostNoticeDetailParam) =>
  httpClient(config.notice.postNoticeDetail({ storeNo }), 'POST', body);
export const putNoticeDetail = ({
  storeNo,
  noticeNo,
  ...body
}: IPutNoticeDetailParam) =>
  httpClient(config.notice.putNoticeDetail({ storeNo, noticeNo }), 'PUT', body);
