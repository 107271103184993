import {
  getPromotionItem,
  getPromotionList,
  IPutPromotionExpiresParam,
  postPromotionDetail,
  putPromotionDetail,
  putPromotionExpires,
} from 'apis/promotion';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { AsyncTaskType, resetAsyncTask } from 'modules/asyncTask';
import { closeDialog } from 'modules/dialog';
import { getDisplayProductAsync, IDisplayProduct } from 'modules/product';
import {
  addLiveProductListFromSearchResult,
  clearPromotionDetail,
  createPromotionDetail,
  expiresPromotionListItem,
  getPromotionItemAsync,
  getPromotionListAsync,
  IPromotionDetail,
  IPromotionsProduct,
  modifyPromotionDetail,
  postPromotionDetailAsync,
  putPromotionDetailAsync,
  putPromotionExpiresAsync,
  setLiveProductList,
  setPromotionItem,
} from 'modules/promotion';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { getType, PayloadAction } from 'typesafe-actions';
import { createAsyncSaga } from 'utils';
import { formatDate, getProductCategoryString } from 'utils/format';

import { getDisplayProductListSaga } from './product';

export const getPromotionListSaga = createAsyncSaga(
  getPromotionListAsync,
  getPromotionList,
);

export const getPromotionItemSaga = createAsyncSaga(
  getPromotionItemAsync,
  getPromotionItem,
);

export function* getPromotionItemSuccessSaga(
  action: PayloadAction<
    '@PROMOTION/PROMOTION_DETAIL/Success',
    IPromotionDetail[]
  >,
) {
  const [data] = action.payload;
  if (data) {
    const encSellerNo = yield select(
      (state: RootState) => state.user.headerInfo.encSellerNo,
    );

    yield getDisplayProductListSaga(
      getDisplayProductAsync.request({
        encSellerNo,
        productNumberList: data.liveProductList.map(item =>
          item.productNo.toString(),
        ),
        isProductNoFirst: true,
      }),
    );

    const displayProductList: IDisplayProduct[] = yield select(
      (state: RootState) => state.product.foundProducts,
    );

    const liveProductListSortedByProductNo = [...data.liveProductList].sort(
      (a, b) => {
        if (a.productNo < b.productNo) return 1;
        if (a.productNo === b.productNo) return 0;

        return -1;
      },
    );

    yield put(
      setPromotionItem({
        ...data,
        liveProductList: [
          ...displayProductList
            .map(
              ({
                productNo,
                productName,
                sellStatusDesc,
                displayCategoryName1,
                displayCategoryName2,
                displayCategoryName3,
                displayCategoryName4,
              }) => ({
                productNo,
                sellStatusTypeDesc: sellStatusDesc,
                productName,
                displayCategoryName: getProductCategoryString(
                  displayCategoryName1,
                  displayCategoryName2,
                  displayCategoryName3,
                  displayCategoryName4,
                ),
              }),
            )
            .map((item, index) => ({
              ...liveProductListSortedByProductNo[index],
              ...item,
            }))
            .sort((a, b) => {
              if (a.orderSeq < b.orderSeq) return -1;
              if (a.orderSeq === b.orderSeq) return 0;

              return 1;
            }),
        ],
      }),
    );
  }
}

export const putPromotionExpiresSaga = createAsyncSaga(
  putPromotionExpiresAsync,
  putPromotionExpires,
);

function* promotionExpiresSaga(
  action: PayloadAction<
    '@PROMOTION/PUT_EXPIRES/FETCH',
    IPutPromotionExpiresParam
  >,
) {
  yield putPromotionExpiresSaga(action);
  const status: AsyncTaskType = yield select(
    (state: RootState) => state.asyncTask['@PROMOTION/PUT_EXPIRES/FETCH'],
  );
  if (status === 'SUCCESS') {
    yield put(expiresPromotionListItem(action.payload.promotionNo));
  }
}

function* addLiveProductListFromSearchResultSaga() {
  const searchResult: IDisplayProduct[] = yield select(
    (state: RootState) => state.product.currentSelectedProducts,
  );
  const data: IPromotionsProduct[] = yield select(
    (state: RootState) => state.promotion.promotionDetail.liveProductList,
  );

  const filteredSearchResult = searchResult
    .filter(
      ({ productNo }) => !data.map(item => item.productNo).includes(productNo),
    )
    .map(
      ({
        productNo,
        productName,
        sellStatusDesc,
        displayCategoryName1,
        displayCategoryName2,
        displayCategoryName3,
        displayCategoryName4,
      }) => ({
        orderSeq: 10,
        productNo,
        productName,
        sellStatusTypeDesc: sellStatusDesc,
        displayCategoryName: getProductCategoryString(
          displayCategoryName1,
          displayCategoryName2,
          displayCategoryName3,
          displayCategoryName4,
        ),
      }),
    );

  if (filteredSearchResult.length + data.length > 100) {
    yield put(openAlert('SAVE_PROMOTION_OVER_100_ITEMS'));
  } else {
    yield put(setLiveProductList([...filteredSearchResult, ...data]));
    yield put(closeDialog());
  }
}

const postPromotionDetailSaga = createAsyncSaga(
  postPromotionDetailAsync,
  postPromotionDetail,
);

function* createPromotionDetailSaga() {
  const { storeNo } = yield select((state: RootState) => state.user.headerInfo);
  const {
    startDate,
    endDate,
    title,
    subTitle,
    liveProductList,
  }: IPromotionDetail = yield select(
    (state: RootState) => state.promotion.promotionDetail,
  );

  const sortedLiveProductList = [...liveProductList].sort((a, b) => {
    if (a.orderSeq > b.orderSeq) return 1;
    if (a.orderSeq === b.orderSeq) return 0;

    return -1;
  });

  yield postPromotionDetailSaga(
    postPromotionDetailAsync.request({
      storeNo,
      startDate: formatDate(startDate).replace(/[.]/g, '-'),
      endDate: formatDate(endDate).replace(/[.]/g, '-'),
      title,
      subTitle,
      promotionProducts: sortedLiveProductList.map(
        ({ orderSeq, productNo }: IPromotionsProduct) => ({
          orderSeq,
          productNo,
        }),
      ),
    }),
  );
  yield put(clearPromotionDetail());
  yield getPromotionListSaga(
    getPromotionListAsync.request({ storeNo, status: 'ALL' }),
  );
  yield delay(1200);
  yield put(resetAsyncTask(getType(postPromotionDetailAsync.request)));
}

const putPromotionDetailSaga = createAsyncSaga(
  putPromotionDetailAsync,
  putPromotionDetail,
);

function* modifyPromotionDetailSaga(
  action: PayloadAction<'@PROMOTION/PROMOTION_DETAIL/MODIFY', string>,
) {
  const storeNo = yield select(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const {
    startDate,
    endDate,
    title,
    subTitle,
    liveProductList,
  }: IPromotionDetail = yield select(
    (state: RootState) => state.promotion.promotionDetail,
  );

  const sortedLiveProductList = [...liveProductList].sort((a, b) => {
    if (a.orderSeq > b.orderSeq) return 1;
    if (a.orderSeq === b.orderSeq) return 0;

    return -1;
  });

  yield putPromotionDetailSaga(
    putPromotionDetailAsync.request({
      storeNo,
      startDate: formatDate(startDate).replace(/[.]/g, '-'),
      endDate: formatDate(endDate).replace(/[.]/g, '-'),
      title,
      subTitle,
      promotionProducts: sortedLiveProductList.map(
        ({ orderSeq, productNo }: IPromotionsProduct) => ({
          orderSeq,
          productNo,
        }),
      ),
      promotionNo: action.payload,
    }),
  );
  yield put(clearPromotionDetail());
  yield getPromotionListSaga(
    getPromotionListAsync.request({ storeNo, status: 'ALL' }),
  );
  yield delay(1200);
  yield put(resetAsyncTask(getType(putPromotionDetailAsync.request)));
}

export default function* promotionSaga() {
  yield takeLatest(
    getType(getPromotionListAsync.request),
    getPromotionListSaga,
  );
  yield takeLatest(
    getType(getPromotionItemAsync.request),
    getPromotionItemSaga,
  );
  yield takeLatest(
    getType(getPromotionItemAsync.success),
    getPromotionItemSuccessSaga,
  );
  yield takeLatest(
    getType(putPromotionExpiresAsync.request),
    promotionExpiresSaga,
  );
  yield takeLatest(
    getType(addLiveProductListFromSearchResult),
    addLiveProductListFromSearchResultSaga,
  );
  yield takeLatest(getType(createPromotionDetail), createPromotionDetailSaga);
  yield takeLatest(getType(modifyPromotionDetail), modifyPromotionDetailSaga);
}
