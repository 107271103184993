import { useMemo } from 'react';

const useImageStyle = (src: string): { backgroundImage: string } => {
  const imageSrc = useMemo(() => {
    if (!src || src.trim() === '') {
      return '';
    }
    if (src.includes('http')) {
      return src;
    }
    if (src.includes(process.env.REACT_APP_CDN as string)) {
      return src;
    }

    return `${process.env.REACT_APP_CDN}${src}`;
  }, [src]);

  const imageStyle = useMemo(
    () => ({ backgroundImage: `url('${imageSrc}')` }),
    [imageSrc],
  );

  return imageStyle;
};

export default useImageStyle;
