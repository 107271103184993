import { Dropdown, Radio } from 'components/common';
import { RadioType } from 'components/common/Radio';
import { RootState } from 'modules';
import {
  getSpecialListAsync,
  setAddSpecialSelectedRadio,
} from 'modules/special';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const OPTIONS = [
  { id: '1', value: 'ON', name: '진행중' },
  { id: '2', value: 'READY_FOR', name: '진행예정' },
];

const SelectBySeller = () => {
  const [query, setQuery] = useState<string>('');
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    dispatch(getSpecialListAsync.request(query));
  }, [dispatch, query]);

  return (
    <>
      <p>현재 진행중 또는 진행예정인 기획전만 등록할 수 있습니다.</p>
      <div className="side">
        <Dropdown
          id="listboxButton31"
          width="size__2"
          options={OPTIONS}
          placeholder="선택하세요"
          onSelect={(value: string) => setQuery(value)}
        />
        <button
          type="button"
          className="btn-style__1 btn-size__6"
          onClick={handleClick}
        >
          검색
        </button>
      </div>
    </>
  );
};

const Title = () => {
  const dispatch = useDispatch();
  const addSpecialSelectedRadio = useSelector(
    (state: RootState) => state.special.addSpecialSelectedRadio,
  );

  const isSelectByNumber = useMemo(() => addSpecialSelectedRadio === 'NUMBER', [
    addSpecialSelectedRadio,
  ]);

  return (
    <div className="dialog-body__title">
      <ul role="tablist" className="event_inquiry_select">
        <li role="presentation">
          <Radio
            value="basic1"
            type={RadioType.basic}
            name="basic1"
            label="셀러 기획전 조회"
            checked={!isSelectByNumber}
            onChange={() => dispatch(setAddSpecialSelectedRadio('SELLER'))}
            role="tab"
            aria-selected={String(!isSelectByNumber)}
            aria-controls="tabEventInquiry1"
            id="tabEventInquiryMenu1"
          />
        </li>
        <li role="presentation">
          <Radio
            value="basic2"
            type={RadioType.basic}
            name="basic2"
            label="기획전 번호 입력"
            checked={isSelectByNumber}
            onChange={() => dispatch(setAddSpecialSelectedRadio('NUMBER'))}
            role="tab"
            aria-selected={String(isSelectByNumber)}
            aria-controls="tabEventInquiry2"
            id="tabEventInquiryMenu2"
          />
        </li>
      </ul>
      {!isSelectByNumber && <SelectBySeller />}
    </div>
  );
};

export default Title;
