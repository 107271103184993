import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const CancelButton = () => {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <button
      type="button"
      onClick={onClick}
      className="btn-style__2 btn-size__2"
    >
      취소
    </button>
  );
};

export default CancelButton;
