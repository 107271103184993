import { useCallback, useState } from 'react';
import { IIsValidTextInputOptions, isValidTextInput } from 'utils/validate';

interface IUseTextInput {
  initialValue?: string;
  validatorOption?: IIsValidTextInputOptions;
  textModifier?: (text: string) => string;
}

export type ChangeEventType = React.ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

/**
 * [text, onChangeText, setText]
 */

const useTextInput = ({
  initialValue = '',
  validatorOption = {},
  textModifier = (text: string) => text,
}: IUseTextInput = {}): [
  string,
  (e: ChangeEventType) => void,
  (s: string) => void,
] => {
  const [text, setText] = useState(initialValue);

  const onChangeText = useCallback(
    (e: ChangeEventType) => {
      const { value } = e.target;
      const isUsingValidator = !!Object.keys(validatorOption).length;
      if (
        !isUsingValidator ||
        (isUsingValidator && isValidTextInput(value, validatorOption))
      ) {
        setText(textModifier(value));
      }
    },
    [textModifier, validatorOption],
  );

  return [text, onChangeText, setText];
};

export default useTextInput;
