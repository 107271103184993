import { RootState } from 'modules';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/format';

import VisitorGraphLayer from './VisitorGraphLayer';

const VisitorContainer = () => {
  const [graph, showGraph] = useState<boolean>(false);
  const visitCount = useSelector(
    (state: RootState) => state.user.headerInfo.visitCount,
  );

  return (
    <span>
      <button
        type="button"
        className="info_visit"
        onClick={() => {
          showGraph(true);
        }}
        data-log-actionid-area="visits"
        data-log-actionid-label="number"
      >
        방문자
        <em>{formatNumber(visitCount)}</em>명
      </button>
      {graph && <VisitorGraphLayer onClose={() => showGraph(false)} />}
    </span>
  );
};

export default VisitorContainer;
