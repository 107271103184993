import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

import ContentButton from './ContentButton';
import ContentFrame from './ContentFrame';
import CotnentTitle from './CotnentTitle';
import SortableFrame from './SortableFrame';

const Contents = ({ fixed }: { fixed: string }) => {
  const sortable = useSelector((state: RootState) => state.display.sortable);

  return !sortable ? (
    <>
      <div
        className="cont-view"
        style={{
          paddingTop: fixed === 'fixed' ? 100 : 0,
        }}
        id="soContent"
      >
        <CotnentTitle />
        <ContentFrame />
        <ContentButton />
      </div>
    </>
  ) : (
    <SortableFrame />
  );
};

export default React.memo(Contents);
