import classNames from 'classnames';
import { RootState } from 'modules';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Swiper from 'swiper';

const DefaultProfile = () => {
  const type = useSelector((state: RootState) => state.profile.type);

  return (
    <li className="swiper-slide">
      <div
        className={classNames(
          'c-lazyload',
          { 'c-lazyload--ratio_1x1': type === 'SQUARE' },
          { 'c-lazyload--ratio_360x240': type === 'RECTANGLE' },
        )}
      >
        <img
          src={
            type === 'SQUARE'
              ? `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template02_02visual.png`
              : `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template01_02visual.png`
          }
          alt="대표이미지 미리보기"
        />
      </div>
    </li>
  );
};

const Profile = () => {
  const type = useSelector((state: RootState) => state.profile.type);
  const data = useSelector((state: RootState) => state.profile.data);
  const index = useRef(0);

  const sortedData = useMemo(
    () => data.filter(item => item.statusCode === '0'),
    [data],
  );

  useEffect(() => {
    /* eslint-disable */
    // TODO: 실제 Front 모듈은 loop: true가 적용되어 있으나 해당 옵션이 dynamic contents를 감지하는 observer 옵션과 충돌하는 버그가 있음
    const swiper = new Swiper('.w-swiper', {
      pagination: {
        el: '.c-pagination__list',
        type: 'fraction',
        clickable: true,
        renderFraction: (currentClass, totalClass) =>
          `<span class=${currentClass}></span> / <span class=${totalClass}></span>`,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      initialSlide: index.current,

    });
    swiper.on('slideChange', () => {index.current = swiper.activeIndex})

    /* eslint-enable */
    return () => swiper.destroy(true, true);
  }, [sortedData]);

  return (
    <div
      className="item_cont"
      style={{ height: type === 'SQUARE' ? '255px' : '170px' }}
    >
      <div className="l-grid">
        <div className="l-grid__row" data-ui-type="Store_Contents_Home_Swiper">
          <div className="l-grid__col l-grid__col--12">
            <div className="w-swiper swiper-container-horizontal">
              <ul className="swiper-wrapper">
                {sortedData.length > 0 ? (
                  sortedData.map(item => (
                    <li className="swiper-slide" key={item.id}>
                      <div
                        className={classNames(
                          'c-lazyload',
                          { 'c-lazyload--ratio_1x1': type === 'SQUARE' },
                          { 'c-lazyload--ratio_360x240': type === 'RECTANGLE' },
                        )}
                      >
                        {item.imageUrl ? (
                          <img
                            src={`${process.env.REACT_APP_CDN}${item.imageUrl}`}
                            alt="대표이미지 미리보기"
                          />
                        ) : (
                          <img
                            src={`${process.env.REACT_APP_STATIC_PATH}${
                              type === 'SQUARE'
                                ? '/img/store/content/layout_img.png'
                                : '/img/store/content/layout_img2.png'
                            }`}
                            alt="대표이미지 미리보기"
                          />
                        )}
                        <div className="c-imgcover__text">
                          <p className="c-imgcover__info">
                            <strong className="c-imgcover__title">
                              {item.title}
                            </strong>
                            <span>{item.subTitle}</span>
                          </p>
                          {item.type !== 'IMAGE' && (
                            <p className="c-imgcover__more">
                              <span>자세히보기</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <DefaultProfile />
                )}
              </ul>
              <nav className="c-pagination c-pagination__small">
                <span className="c-pagination__list" />
              </nav>
              <button
                type="button"
                className="swiper-button-prev"
                tabIndex={0}
                aria-label="Previous slide"
              >
                이전
              </button>
              <button
                type="button"
                className="swiper-button-next"
                tabIndex={0}
                aria-label="Next slide"
              >
                다음
              </button>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
