import classNames from 'classnames';
import React from 'react';

interface IAceEditorButtonProps {
  editMode?: boolean;
  hasContent?: boolean;
  onClick: () => void;
}

const AceEditorButton = ({
  editMode = false,
  hasContent = false,
  onClick,
}: IAceEditorButtonProps) => {
  return (
    <div className="view-item__editor">
      <div className="item_in">
        {editMode && (
          <p className={classNames('text_confirm', { type__em: hasContent })}>
            {`작성된 내용이 ${hasContent ? '있' : '없'}습니다.`}
          </p>
        )}
        <button
          type="button"
          className="btn-style__3 btn-size__0"
          onClick={onClick}
        >
          <span className="icon icon-11st">11ST</span>
          {`Editor로 ${editMode ? '수정' : '작성'}하기`}
        </button>
      </div>
    </div>
  );
};

export default AceEditorButton;
