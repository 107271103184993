import {
  IPutMenuEnableParam,
  IPutMenuPriorityParam,
  IPutSkinTypeParam,
  IPutTemplateTypeParam,
} from 'apis/display';
import { createAction, createAsyncAction } from 'typesafe-actions';

import { IDisplayBasicInfoResponse, IModule, TemplateType } from './types';

export const getDisplayBasicInfoAsync = createAsyncAction(
  '@DISPLAY/GET_BASIC_INFO/FETCH',
  '@DISPLAY/GET_BASIC_INFO/SUCCESS',
  '@DISPLAY/GET_BASIC_INFO/FAILURE',
)<undefined, IDisplayBasicInfoResponse[], Error>();

export const setInitialPage = createAction('@DISPLAY/INITIAL_PAGE/SET')<
  string
>();

// Template
export const setTemplate = createAction('@DISPLAY/SET_TEMPLATE')<
  TemplateType
>();
export const saveTemplate = createAction('@DISPLAY/TEMPLATE/SAVE')();

export const putTemplateTypeAsync = createAsyncAction(
  '@DISPLAY/PUT_TEMPLATE/FETCH',
  '@DISPLAY/PUT_TEMPLATE/SUCCESS',
  '@DISPLAY/PUT_TEMPLATE/FAILURE',
)<IPutTemplateTypeParam, undefined, undefined>();

// Skin
export const setSkin = createAction('@DISPLAY/SET_SKIN')<string>();
export const saveSkin = createAction('@DISPLAY/SKIN/SAVE')();

export const putSkinTypeAsync = createAsyncAction(
  '@DISPLAY/PUT_SKIN/FETCH',
  '@DISPLAY/PUT_SKIN/SUCCESS',
  '@DISPLAY/PUT_SKIN/FAILURE',
)<IPutSkinTypeParam, undefined, undefined>();

/* HOME - MENU */
export const setModuleData = createAction('@DISPLAY/MODULE_DATA/SET')<
  IModule[]
>();

export const setSelectedModule = createAction('@DISPLAY/SET_SELECTED_MODULE')<
  IModule
>();

export const setEnableMenu = createAction('@DISPLAY/SET_ENABLE_MENU')<
  boolean,
  string
>();

export const putEnableMenuAsync = createAsyncAction(
  '@DISPLAY/PUT_ENABLE_MENU/FETCH',
  '@DISPLAY/PUT_ENABLE_MENU/SUCCESS',
  '@DISPLAY/PUT_ENABLE_MENU/FAILURE',
)<IPutMenuEnableParam, undefined, Error>();

export const setSortable = createAction('@DISPLAY/SET_SORTABLE')<boolean>();

export const saveContents = createAction('@DISPLAY/CONTENT/SAVE')();

export const setTouched = createAction('@DISPLAY/TOUCHED/SET')<boolean>();

export const putMenuPriorityAsync = createAsyncAction(
  '@DISPLAY/PUT_MENU_PRIORITY/FETCH',
  '@DISPLAY/PUT_MENU_PRIORITY/SUCCESS',
  '@DISPLAY/PUT_MENU_PRIORITY/FAILURE',
)<IPutMenuPriorityParam, undefined, Error>();

export const setTempModuleList = createAction('@DISPLAY/TEMP_MENU/SET')<
  IModule[]
>();

export const setDragTargetId = createAction('DISPLAY/MENU/TARGET_ID/SET')<
  string
>();
