import classNames from 'classnames';
import React from 'react';

import { IHelpers } from './index';

type DaysInMonthType = Date | null;
interface ICalendarTable {
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  year: number;
  month: number;
  handleSelectDay: (day: DaysInMonthType) => void;
  calendarHelpers: IHelpers;
}
const CalendarTable = ({
  value,
  minDate,
  maxDate,
  year,
  month,
  handleSelectDay,
  calendarHelpers,
}: ICalendarTable) => {
  const { weekName, isSameDay, isPast, isExpired } = calendarHelpers;
  const renderHeader = (dayOfWeek: number) => (
    <th scope="col" key={dayOfWeek}>
      <abbr title={weekName(dayOfWeek)}>{weekName(dayOfWeek)}</abbr>
    </th>
  );

  const renderDay = (day: DaysInMonthType, index: number): JSX.Element => {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const isToday = day && isSameDay(day, date);
    const isActive = day && isSameDay(value, day);
    const isDisabled =
      (day && minDate && isPast(day, minDate)) ||
      (day && maxDate && isExpired(day, maxDate));

    return (
      <td key={`${year}-${month}-${index}th-day`}>
        <button
          type="button"
          className={classNames(
            'td_day',
            { disabled: isDisabled },
            { today: isToday },
            { active: isActive && !isDisabled },
          )}
          style={isDisabled ? { color: '#ddd' } : {}}
          onClick={isDisabled ? () => {} : () => handleSelectDay(day)}
        >
          {day ? day.getDate() : ''}
        </button>
      </td>
    );
  };
  const renderWeek = (days: DaysInMonthType[], index: number): JSX.Element => (
    <tr key={`${index}th-week`}>{days.map(renderDay)}</tr>
  );
  const days = () => {
    const daysInMonth: number = new Date(year, month + 1, 0).getDate();
    const offset: number = new Date(year, month, 1).getDay();
    const leftPad: DaysInMonthType[] = [...Array(offset < 7 ? offset : 0)].map(
      () => null,
    );
    const dateTable: DaysInMonthType[] = [...Array(daysInMonth)].map(
      (_, i) => new Date(year, month, i + 1, 0, 0, 0),
    );

    return leftPad.concat(dateTable);
  };
  const weeks = () => {
    const weekCount: number = Math.ceil(days().length / 7);

    return [...Array(weekCount)].map((_, i) =>
      days().slice(i * 7, (i + 1) * 7),
    );
  };

  return (
    <div className="st-calendar__body">
      <table className="calendar-table" role="grid">
        <caption>날짜 선택</caption>
        <thead>
          <tr>{[...Array(7)].map((_, i) => renderHeader(i))}</tr>
        </thead>
        <tbody>{weeks().map(renderWeek)}</tbody>
      </table>
    </div>
  );
};

export default CalendarTable;
