import { combineReducers } from 'redux';

import alert from './alert';
import asyncTask from './asyncTask';
import category from './category';
import coupon from './coupon';
import dialog from './dialog';
import display from './display';
import notice from './notice';
import product from './product';
import profile from './profile';
import promotion from './promotion';
import recommend from './recommend';
import special from './special';
import user from './user';

const rootReducer = combineReducers({
  promotion,
  alert,
  asyncTask,
  category,
  display,
  product,
  user,
  coupon,
  notice,
  special,
  profile,
  recommend,
  dialog,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
