import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import LocalAlert from 'components/layouts/default-layout/Alert/LocalAlert';
import useTextInput from 'hooks/useTextInput';
import { RootState } from 'modules';
import {
  getCustomCategoryAsync,
  postCustomCategoryAsync,
  putCustomCategoryAsync,
  resetCustomDetail,
  resetSelectedCustomId,
  saveCategoryOrderAsync,
  setIsUsingCustom,
  setShouldCategorySave,
} from 'modules/category';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import {
  resetFinalSelectedProducts,
  setProductTablePage,
} from 'modules/product';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getType } from 'typesafe-actions';

import CategoryList from '../CategoryList';
import StoreApplyButton from '../StoreApplyButton';
import CategoryForm from './CategoryForm';
import CategoryTable from './CategoryTable';
import ProductTable from './ProductTable';
import SelectCategoryType from './SelectCategoryType';
import useCategoryFn from './useCategoryFn';
import useCategorySave from './useCategorySave';
import useCustomDetailLoading from './useCustomDetailLoading';
import useOnChangeCategories from './useOnChangeCategories';
import useOnClickCategoryItem from './useOnClickCategoryItem';
import useTouched from './useTouched';

export const DATA_TABLE_SAVE_ID = 'DATA_TABLE_SAVE_ID';
const Edit = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const selectedCustomId = useSelector(
    (state: RootState) => state.category.selectedCustomId,
  );
  const custom = useSelector((state: RootState) => state.category.custom);
  const customOrder = useSelector(
    (state: RootState) => state.category.customOrder,
  );
  const isShowDoneToast = useSelector(
    (state: RootState) => state.category.isShowDoneToast,
  );
  const selectedCustomName = useMemo(
    () =>
      custom.find(({ storeCategoryNo }) => storeCategoryNo === selectedCustomId)
        ?.storeCategoryName ?? '',
    [custom, selectedCustomId],
  );

  const [isProductConnect, setIsProductConnect] = useState<boolean>(false);

  const [categoryName, onChangeCategoryName, setCategoryName] = useTextInput({
    validatorOption: { type: 'title', max: 8 },
    textModifier: (text: string) => {
      const isChanged = selectedCustomName !== text;
      dispatch(setShouldCategorySave(isChanged));

      return text;
    },
  });

  const isCreateMode = useMemo(
    () => selectedCustomId === SELECTED_CUSTOM_ID.CREATE,
    [selectedCustomId],
  );
  const isEditMode = useMemo(
    () => !isCreateMode && selectedCustomId !== SELECTED_CUSTOM_ID.INIT,
    [isCreateMode, selectedCustomId],
  );
  const largeCategories = useMemo(() => customOrder, [customOrder]);

  const resetData = useCallback(() => {
    setCategoryName('');
    setIsProductConnect(false);
    dispatch(resetSelectedCustomId());
    dispatch(resetCustomDetail());
    dispatch(resetFinalSelectedProducts());
  }, [dispatch, setCategoryName]);

  const [onClickAddButton, onClickDeleteCategory] = useCategoryFn(
    isEditMode,
    resetData,
  );

  const onChangeCategories = useOnChangeCategories();

  const onClickCategoryItem = useOnClickCategoryItem(
    resetData,
    setCategoryName,
    setIsProductConnect,
  );

  const onClickSave = useCategorySave({
    isEditMode,
    isProductConnect,
    categoryName,
  });

  const {
    dataTableRef,
    containerRef,
    isShowAlert,
    alertType,
    onClickConfirmAlert,
    onClickCancelAlert,
  } = useTouched();

  const isLoading = useCustomDetailLoading();

  useEffect(() => {
    if (selectedCustomId === SELECTED_CUSTOM_ID.INIT) {
      onClickCategoryItem(
        custom[0]?.storeCategoryNo ?? SELECTED_CUSTOM_ID.CREATE,
      );
    } else if (selectedCustomId !== SELECTED_CUSTOM_ID.CREATE) {
      onClickCategoryItem(selectedCustomId);
    }
  }, [custom, onClickCategoryItem, selectedCustomId, storeNo]);

  useEffect(() => {
    if (isShowDoneToast) {
      history.push('/category/done?mod');
    }
    // eslint-disable-next-line
  }, [isShowDoneToast]);

  useEffect(() => {
    if (storeNo && custom.length === 0) {
      onClickAddButton();
    }
  }, [custom.length, dispatch, onClickAddButton, storeNo]);

  useEffect(() => {
    dispatch(setProductTablePage(1));
  }, [dispatch, selectedCustomId]);

  useEffect(() => {
    if (storeNo) {
      dispatch(getCustomCategoryAsync.request({ storeNo }));
      dispatch(setIsUsingCustom(true));
    }
  }, [dispatch, storeNo]);

  return (
    <>
      <DocumentTitle title="스토어 카테고리 직접 관리 - 카테고리 관리 - 스토어 관리" />
      {isShowAlert && (
        <LocalAlert
          bodyType={alertType}
          confirmText="확인"
          onClickConfirm={onClickConfirmAlert}
          cancelText="취소"
          onClickCancel={onClickCancelAlert}
        />
      )}
      <div className="content-in">
        <div className="view-title title-style__2">
          <h2 className="title">카테고리 전시 방식</h2>
        </div>
        <div className="view-cont">
          <SelectCategoryType />
          <div ref={containerRef} className="st-cont__cate">
            <div className="cate-menu">
              <CategoryList
                createMode={isCreateMode}
                editMode={isEditMode}
                editingText={categoryName}
                list={largeCategories}
                onClickItem={onClickCategoryItem}
                onClickAddButton={onClickAddButton}
                addButtonDisabled={custom.length >= 20}
                onClickDelete={onClickDeleteCategory}
                deleteButtonDisabled={!isEditMode}
                onChange={onChangeCategories}
                selectedId={selectedCustomId}
                draggable
              />
            </div>
            <div
              ref={dataTableRef}
              className="cate-view view-type__3"
              id="soContent"
            >
              <CategoryForm
                categoryName={categoryName}
                onChangeCategoryName={onChangeCategoryName}
                isProductConnect={isProductConnect}
                setIsProductConnect={setIsProductConnect}
              />
              {isProductConnect ? (
                <ProductTable editMode={isEditMode} isLoading={isLoading} />
              ) : (
                <CategoryTable editMode={isEditMode} isLoading={isLoading} />
              )}
              {!isLoading && (
                <div className="view-btn__area area-type__2">
                  <button
                    type="submit"
                    id={DATA_TABLE_SAVE_ID}
                    onClick={onClickSave}
                    className="btn-style__4 btn-size__2"
                  >
                    저장
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <StoreApplyButton />
        <ToastMessage
          action={getType(putCustomCategoryAsync.request)}
          successText="저장되었습니다."
        />
        <ToastMessage
          action={getType(saveCategoryOrderAsync.request)}
          successText="저장되었습니다."
        />
        <ToastMessage
          action={getType(postCustomCategoryAsync.request)}
          successText="저장되었습니다."
        />
      </div>
    </>
  );
};

export default Edit;
