/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import useDrag, { IUseDrag } from 'hooks/useDrag';
import { RootState } from 'modules';
import {
  IModule,
  menuIdType,
  setDragTargetId,
  setTempModuleList,
} from 'modules/display';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface IProps extends IUseDrag {
  module: IModule;
  index: number;
}

const SortableItem = ({
  module,
  onDragStart,
  onDragOver,
  onDragEnd,
  onDragLeave,
  onDrop,
  dropTargetIndex,
  dropOrder,
  index,
}: IProps) => {
  const dispatch = useDispatch();
  const recommend = useSelector((state: RootState) => state.recommend);

  const currentDragClassName = useMemo(
    () => dropTargetIndex === index && (dropOrder ? 'after' : 'before'),
    [dropOrder, dropTargetIndex, index],
  );

  return (
    <div
      className="item item-type__link"
      onDragStart={() => {
        onDragStart(index);
      }}
      onDragOver={e => onDragOver(e, index ?? 0)}
      onDragEnd={onDragEnd}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      draggable
    >
      <div className="item_title">
        <button
          type="button"
          onClick={() => {}}
          onMouseDown={() => {
            dispatch(setDragTargetId(module.id));
          }}
          draggable
          className={classNames('drop', currentDragClassName)}
        >
          {`${module.name} `}
          {(module.id === menuIdType.recommend1 ||
            module.id === menuIdType.recommend2 ||
            module.id === menuIdType.recommend3) && (
            <i>
              {recommend[module.id].type === '01'
                ? '카드뷰'
                : recommend[module.id].type === '02'
                ? '동영상'
                : '프로모션'}
            </i>
          )}
        </button>
      </div>
    </div>
  );
};
const SortableList = () => {
  const moduleData = useSelector(
    (state: RootState) => state.display.moduleData,
  );
  const tempModuleData = useSelector(
    (state: RootState) => state.display.tempModuleData,
  );

  const dispatch = useDispatch();

  const {
    onDragStart,
    onDragOver,
    onDragEnd,
    onDragLeave,
    onDrop,
    dropTargetIndex,
    dropOrder,
  } = useDrag(tempModuleData, (newList: IModule[]) => {
    dispatch(setTempModuleList(newList));
  });

  useEffect(() => {
    dispatch(setDragTargetId(''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setTempModuleList([...moduleData].splice(1)));
  }, [dispatch, moduleData]);

  return (
    <>
      <div className="cont-menu__guide">
        드래그 해서 원하는 위치에 놓으세요.
      </div>
      <div className="cont-menu__item type_move">
        {tempModuleData.map((module, index) => (
          <SortableItem
            key={module.id}
            module={module}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            dropTargetIndex={dropTargetIndex}
            dropOrder={dropOrder}
            index={index}
          />
        ))}
      </div>
    </>
  );
};

export default SortableList;
