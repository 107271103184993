import classNames from 'classnames';
import { closeAlert } from 'modules/alert';
import React from 'react';
import { useDispatch } from 'react-redux';

interface IProps {
  text: string;
  type: 'CONFIRM' | 'CANCEL';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const AlertButton = ({ text, type, onClick }: IProps) => {
  const dispatch = useDispatch();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (onClick) {
      onClick(event);
    }
    dispatch(closeAlert());
  };

  return (
    <button
      type="button"
      className={classNames(
        { 'btn-style__2': type === 'CANCEL' },
        { 'btn-style__3': type === 'CONFIRM' },
        'btn-size__1',
      )}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default AlertButton;
