import { format } from 'date-fns';
import { IOfflineShopInfo } from 'modules/user';

export const formatDate = (dateString: string): string =>
  dateString ? format(new Date(dateString), 'yyyy.MM.dd') : '';

export const formatNumber = (num: number): string => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;

  return num.toString().replace(regexp, ',');
};

export const getProductCategoryString = (...categoryList: string[]) =>
  categoryList.filter(v => !!v).join(' > ');

export const getSalesTimeByOfflineShopInfo = (
  offlineShopInfo?: IOfflineShopInfo | undefined,
) => ({
  weekDaySaleBeginTime: offlineShopInfo?.weekDaySaleBeginTime ?? '',
  weekDaySaleEndTime: offlineShopInfo?.weekDaySaleEndTime ?? '',
  weekEndSaleBeginTime: offlineShopInfo?.weekEndSaleBeginTime ?? '',
  weekEndSaleEndTime: offlineShopInfo?.weekEndSaleEndTime ?? '',
  closedSaleDescription: offlineShopInfo?.closedSaleDescription ?? '',
});

export const formatNoticeDate = (date: Date) => format(date, 'yyyy-MM-dd');
