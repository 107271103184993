import { getDisplayBasicInfoAsync } from 'modules/display';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GNB from './GNB';
import HeaderNameButton from './HeaderNameButton';
import HeaderProfileImage from './HeaderProfileImage';
import VisitorContainer from './VisitorContainer';

const handleLogout = () => {
  window.location.replace(
    `//soffice.11st.co.kr/login/Logout.tmall?returnURL=//www.11st.co.kr/`,
  );
};

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDisplayBasicInfoAsync.request());
  }, [dispatch]);

  return (
    <header className="st-header">
      <div className="st-header__seller">
        <div className="seller_in">
          <h1 className="seller_title">
            <a
              href="//www.11st.co.kr/"
              className="logo_11st"
              target="_blank"
              rel="noopener noreferrer"
              data-log-actionid-area="11st"
              data-log-actionid-label="logo"
            >
              11번가
            </a>
            <button
              className="logo_store"
              type="button"
              onClick={() => history.push('/display')}
            >
              스토어관리
            </button>
          </h1>
          <div className="seller_info">
            <HeaderProfileImage />
            <HeaderNameButton />
            <VisitorContainer />
          </div>
          <ul className="seller_menu" data-log-actionid-area="top_menu">
            <li>
              <a
                href="//soffice.11st.co.kr/"
                target="_blank"
                rel="noopener noreferrer"
                data-log-actionid-label="options"
                data-log-body={JSON.stringify({
                  btn_name: '셀러오피스',
                })}
              >
                셀러오피스
              </a>
            </li>
            <li>
              <a
                href="//soffice.11st.co.kr/analytics/DashBoard.tmall#none"
                target="_blank"
                rel="noopener noreferrer"
                data-log-actionid-label="options"
                data-log-body={JSON.stringify({
                  btn_name: '셀러애널리틱스',
                })}
              >
                셀러애널리틱스
              </a>
            </li>
            <li>
              <button
                type="button"
                onClick={handleLogout}
                data-log-actionid-label="options"
                data-log-body={JSON.stringify({
                  btn_name: '로그아웃',
                })}
              >
                로그아웃
              </button>
            </li>
          </ul>
        </div>
      </div>
      <GNB />
    </header>
  );
};

export default Header;
