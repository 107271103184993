import { ActionType } from 'typesafe-actions';

import * as actions from './actions';

export type DisplayAction = ActionType<typeof actions>;

export interface IModuleResponse {
  moduleNo: string;
  moduleName: string;
  moduleTypeCode: string;
  displayPriority: number;
  isModuleOn: boolean;
  curationTypeCode: string;
}
export interface IDisplayBasicInfoResponse {
  storeNo: string;
  storeName: string;
  storeID: string;
  visitCount: number;
  repImageURL: string;
  sellerInstaID: string;
  templateTypeCode: string;
  colorType: string;
  moduleInfoList: IModuleResponse[];
  displayStoreTemplateTab: boolean;
  isRoadShop: boolean;
  isStoreCategorySet: boolean;
}

export type TemplateType = 'default' | 'visual';

export enum menuIdType {
  profile = '1',
  recommend1 = '2',
  recommend2 = '3',
  recommend3 = '4',
  special = '5',
  bestReview = '6',
}

export interface IModule {
  id: string;
  name: string;
  enable: boolean;
  type?: string;
}

export interface IDisplay {
  touched: boolean;
  template: TemplateType;
  skin: string;
  selectedModule: IModule;
  moduleData: IModule[];
  tempModuleData: IModule[];
  targetId: string;
  sortable: boolean;
  initialPage: string;
}
