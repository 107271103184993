import useMobilePreview from 'hooks/useMobilePreview';
import { RootState } from 'modules';
import { menuIdType, saveContents, setTouched } from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ContentButton = () => {
  const dispatch = useDispatch();
  const selectedModule = useSelector(
    (state: RootState) => state.display.selectedModule,
  );
  const profile = useSelector((state: RootState) => state.profile);
  const recommend1 = useSelector((state: RootState) => state.recommend['2']);
  const recommend2 = useSelector((state: RootState) => state.recommend['3']);
  const recommend3 = useSelector((state: RootState) => state.recommend['4']);
  const special = useSelector((state: RootState) => state.special);

  const handleClick = () => {
    dispatch(saveContents());
    dispatch(setTouched(false));
  };
  const [openMobilePreview] = useMobilePreview();

  const getLogArea = (id: string) => {
    switch (id) {
      case menuIdType.profile:
        return 'main_Image';
      case menuIdType.recommend1:
        return 'seller_recommend1';
      case menuIdType.recommend2:
        return 'seller_recommend2';
      case menuIdType.recommend3:
        return 'seller_recommend3';
      case menuIdType.special:
        return 'exhibition';
      default:
        return '';
    }
  };

  const getRecommendType = (code: string) => {
    if (code === '01') return '카드뷰';
    if (code === '02') return '동영상';
    if (code === '03') return '프로모션컬렉션';

    return '';
  };

  const getLogBody = (id: string) => {
    switch (id) {
      case menuIdType.profile:
        return JSON.stringify({ btn_name: profile.dataLength });
      case menuIdType.recommend1:
        return JSON.stringify({ btn_name: getRecommendType(recommend1.type) });
      case menuIdType.recommend2:
        return JSON.stringify({ btn_name: getRecommendType(recommend2.type) });
      case menuIdType.recommend3:
        return JSON.stringify({ btn_name: getRecommendType(recommend3.type) });
      case menuIdType.special:
        return JSON.stringify({ btn_name: special.data.length });
      default:
        return '';
    }
  };

  return (
    <div className="view-btn">
      <button
        type="button"
        className="btn-style__2 btn-size__1"
        onClick={() => {
          openMobilePreview();
        }}
        data-log-actionid-area={getLogArea(selectedModule.id)}
        data-log-actionid-label="mobile_preview"
      >
        모바일 미리보기
      </button>
      <button
        type="submit"
        className="btn-style__3 btn-size__1"
        onClick={handleClick}
        data-log-actionid-area={getLogArea(selectedModule.id)}
        data-log-actionid-label="save"
        data-log-body={getLogBody(selectedModule.id)}
      >
        저장
      </button>
    </div>
  );
};

export default ContentButton;
