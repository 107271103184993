/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { postImageUpload } from 'apis/display';
import classNames from 'classnames';
import { TextInputWithLabel } from 'components/common';
import Radio, { RadioType } from 'components/common/Radio';
import useImageUpload from 'hooks/useImageUpload';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { openDialogWithId } from 'modules/dialog';
import { setTouched } from 'modules/display';
import {
  deleteProfileItem,
  setProfileItemImageUrl,
  setProfileItemNo,
  setProfileItemStatusCode,
  setProfileItemSubTitle,
  setProfileItemTitle,
  setProfileItemType,
} from 'modules/profile';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cutByMaxLength } from 'utils';
import {
  INPUT_REGEX,
  isValidExtension,
  isValidImageFileSize,
  validateImageWidthAndHeight,
} from 'utils/validate';

interface IProps {
  id: string;
  index: number;
}

const RADIO_GROUP_DATA = [
  { text: '이미지', value: 'IMAGE' },
  { text: '상품', value: 'PRODUCT' },
  { text: '스토어 프로모션', value: 'PROMOTION' },
];

const ItemTypeRadioGroup = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const type = useSelector(
    (state: RootState) => state.profile.data.find(item => item.id === id)?.type,
  );

  return (
    <>
      {RADIO_GROUP_DATA.map(({ text, value }) => (
        <Radio
          key={value}
          type={RadioType.basic}
          label={text}
          value={value}
          checked={value === type}
          name={`profileTypeRadio_${id}`}
          onChange={() => {
            dispatch(setProfileItemNo('', id));
            dispatch(setProfileItemType(value, id));
            dispatch(setTouched(true));
            dispatch(setProfileItemStatusCode('0', id));
          }}
        />
      ))}
    </>
  );
};

const ItemTextPanel = ({ id }: { id: string }) => {
  const title = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.title,
  );
  const subTitle = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.subTitle,
  );
  const show = useSelector((state: RootState) => state.alert.show);
  const dispatch = useDispatch();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 30) {
        dispatch(setProfileItemTitle(e.target.value, id));
      } else {
        dispatch(setProfileItemTitle(cutByMaxLength(e.target.value, 30), id));
      }
      dispatch(setTouched(true));
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  const handleSubTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 30) {
        dispatch(setProfileItemSubTitle(e.target.value, id));
      } else {
        dispatch(
          setProfileItemSubTitle(cutByMaxLength(e.target.value, 30), id),
        );
      }
      dispatch(setTouched(true));
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  return (
    <div className="box_sub">
      <div className="box_sub_row">
        <TextInputWithLabel
          label="대제목"
          title={`profileItemTitle_${id}`}
          value={title ?? ''}
          width="wide"
          onChange={handleTitleChange}
          placeholder="한글 12자, 영문 20자 이내로 입력해주세요."
        />
      </div>
      <div className="box_sub_row">
        <TextInputWithLabel
          label="소제목"
          title={`profileItemSubTitle_${id}`}
          value={subTitle ?? ''}
          width="wide"
          onChange={handleSubTitleChange}
          placeholder="한글 16자, 영문 24자 이내로 입력해주세요."
        />
      </div>
      <div className="box_sub_row">
        <p className="text_sub">
          이미지는 가로(600px~1000px), 세로(500px~1000px), 최대 250KB,
          jpg,jpeg,png만 등록 가능합니다.
        </p>
      </div>
    </div>
  );
};

const getSearchPanelPlaceHolder = (type: string) =>
  `${
    type === 'PRODUCT' ? '판매중인 상품' : '진행중인 프로모션'
  }을 전시할 수 있습니다.`;

const ItemSearchPanel = ({ id }: { id: string }) => {
  const [placeHolder, setPlaceHolder] = useState('');
  const data = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.itemNo ?? '',
  );
  const type = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.type ?? 'IMAGE',
  );

  useEffect(() => {
    if (type) {
      setPlaceHolder(getSearchPanelPlaceHolder(type));
    }
  }, [type]);

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (type === 'PRODUCT') {
      dispatch(openDialogWithId('SelectProduct', id));
    } else {
      dispatch(openDialogWithId('SelectPromotionFromProfile', id));
    }
  };

  return (
    <>
      {type !== 'IMAGE' && (
        <div className="write-box__row add">
          <TextInputWithLabel
            label={`${type === 'PRODUCT' ? '상품' : '프로모션'} 번호`}
            title={`dataNo_${id}`}
            value={data}
            width="wide"
            onChange={() => {}}
            onButtonClick={handleButtonClick}
            placeholder={placeHolder}
          />
        </div>
      )}
    </>
  );
};

const getImageSize = (type: string) => {
  if (type === 'SQUARE') return '750 X 750';

  return '750 X 500';
};

const ItemImagePanel = ({ id }: { id: string }) => {
  const type = useSelector((state: RootState) => state.profile.type);
  const imageUrl = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.imageUrl,
  );
  const dispatch = useDispatch();
  const [inputFileRef, onClickChangeImage] = useImageUpload();

  // TODO: hook으로 분리
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      if (!isValidImageFileSize(e.target.files[0])) {
        dispatch(openAlert('IS_INVALID_PROFILE_IMAGE_FILE_SIZE'));
      } else if (!isValidExtension(e.target.files[0])) {
        dispatch(openAlert('IS_INVALID_PROFILE_IMAGE_EXTENSION'));
      } else {
        const tempUrl = URL.createObjectURL(e.target.files[0]);
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        validateImageWidthAndHeight(tempUrl).then(isValid => {
          if (isValid) {
            postImageUpload('PROFILE', formData).then(data => {
              const [item] = data;
              if (item.resultCode === 200) {
                dispatch(setProfileItemImageUrl(item.uploadImagePath, id));
                dispatch(setTouched(true));
              }
            });
          } else {
            dispatch(openAlert('IS_INVALID_PROFILE_IMAGE_WIDTH_AND_HEIGHT'));
          }
          URL.revokeObjectURL(tempUrl);
        });
      }
    }
  };

  const onClickClearImage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(setProfileItemImageUrl('', id));
  };

  const [mouseOver, setMouseOver] = useState<boolean>(false);

  return (
    <div
      className={classNames('add_upload', { type__2: type === 'RECTANGLE' })}
    >
      <label
        className={classNames('input-file', {
          size__2: type === 'RECTANGLE',
        })}
      >
        {imageUrl ? (
          <span
            className="img"
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
          >
            <input
              type="file"
              title="이미지"
              value=""
              onChange={handleChange}
              ref={inputFileRef}
            />
            <img src={`${process.env.REACT_APP_CDN}${imageUrl}`} alt="이미지" />
            {mouseOver && (
              <span className="input-file__btn">
                <span
                  onClick={onClickChangeImage}
                  className="input-file__btn--edit"
                  role="button"
                  tabIndex={0}
                >
                  수정
                </span>
                <span
                  className="input-file__btn--del"
                  onClick={onClickClearImage}
                  role="button"
                  tabIndex={0}
                >
                  삭제
                </span>
              </span>
            )}
          </span>
        ) : (
          <>
            <input
              type="file"
              title="이미지"
              value=""
              onChange={handleChange}
              ref={inputFileRef}
            />
            <span className="btn-style__img btn-img__file">찾아보기</span>
            <span className="text">
              {`이미지 사이즈 ${getImageSize(type)}`}
            </span>
          </>
        )}
      </label>
    </div>
  );
};

const ItemDeleteButton = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const dataLength = useSelector(
    (state: RootState) => state.profile.dataLength,
  );

  return (
    <>
      {dataLength > 1 && (
        <button
          type="button"
          className="btn-style__icon btn-icon__delete"
          onClick={() => {
            dispatch(deleteProfileItem(id));
            dispatch(setTouched(true));
          }}
        >
          삭제
        </button>
      )}
    </>
  );
};

const getProductGuideText = (statusCode: string) => {
  if (statusCode === '101') {
    return '현재 선택된 상품의 정보가 없습니다. 다른 상품을 선택해주세요.';
  }
  if (statusCode === '102') {
    return '현재 선택된 상품은 전시 전입니다. 다른 상품을 선택해주세요.';
  }
  if (statusCode === '104') {
    return '현재 선택된 상품은 품절입니다. 다른 상품을 선택해주세요.';
  }
  if (statusCode === '105') {
    return '현재 선택된 상품은 판매 중지되었습니다. 다른 상품을 선택해주세요.';
  }
  if (statusCode === '106') {
    return '현재 선택된 상품은 판매 종료되었습니다. 다른 상품을 선택해주세요.';
  }
  if (statusCode === '107') {
    return '현재 선택된 상품은 판매 강제 종료되었습니다. 다른 상품을 선택해주세요.';
  }

  return '판매중인 상품만 대표이미지에 등록 가능하며, 추후 판매상태가 변경된 경우 스토어 화면에서 비노출됩니다.';
};
const getPromotionGuideText = (statusCode: string) => {
  if (statusCode === '201') {
    return '현재 선택된 프로모션이 진행중이 아닙니다. 다른 프로모션을 선택해주세요.';
  }
  if (statusCode === '202') {
    return '프로모션에 판매중인 상품이 없습니다. 다른 프로모션으로 변경해주세요.';
  }

  return '전시중인 프로모션만 등록 가능하며, 판매중인 상품이 없거나 프로모션이 종료된 경우 스토어 화면에 비노출됩니다.';
};
const getGuideText = ({
  type,
  statusCode,
}: {
  type: string;
  statusCode: string;
}) => {
  if (type === 'PRODUCT') {
    return getProductGuideText(statusCode);
  }

  return getPromotionGuideText(statusCode);
};

const ItemGuideText = ({ id }: { id: string }) => {
  const type = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.type ?? 'IMAGE',
  );
  const statusCode = useSelector(
    (state: RootState) =>
      state.profile.data.find(item => item.id === id)?.statusCode ?? '0',
  );

  return (
    <>
      {type !== 'IMAGE' && (
        <div className="write-box__row add">
          <p className="text_guide guide-type__2">
            {getGuideText({ type, statusCode })}
          </p>
        </div>
      )}
    </>
  );
};

const ProfileItem = ({ id, index }: IProps) => {
  return (
    <div className="write-box type_bg">
      <div className="write-box__row inline">
        <div className="row_title">{`${index + 1}번 배너`}</div>
        <div className="row_cont">
          <ItemTypeRadioGroup id={id} />
        </div>
      </div>
      <ItemGuideText id={id} />

      <ItemSearchPanel id={id} />
      <div className="write-box__row add">
        <ItemImagePanel id={id} />
        <ItemTextPanel id={id} />
      </div>
      <ItemDeleteButton id={id} />
    </div>
  );
};

export default React.memo(ProfileItem);
