import useClickOutside from 'hooks/useClickOutside';
import { RootState } from 'modules';
import { AlertType } from 'modules/alert';
import {
  applyBasicCategoryAsync,
  resetCustomDetail,
  resetCustomDetailModified,
  setShouldCategorySave,
} from 'modules/category';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { STORE_APPLY_BUTTON } from '../StoreApplyButton';
import { DATA_TABLE_SAVE_ID } from './index';
import useSaveCategoryOrder from './useSaveCategoryOrder';

const isRoutingTag = (tag: string) =>
  tag === 'BUTTON' || tag === 'A' || tag === 'LABEL';
const isClickedRoutingElement = (el: HTMLElement) => {
  const { tagName } = el;

  return (
    isRoutingTag(tagName) || isRoutingTag(el?.parentElement?.tagName ?? '')
  );
};

const useTouched = () => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const isStoreCategorySet = useSelector(
    (state: RootState) => state.user.headerInfo.isStoreCategorySet,
  );

  const isAlertOn = useSelector((state: RootState) => state.alert.show);
  const isDialogOn = useSelector((state: RootState) => state.dialog.show);

  const customDetailModified = useSelector(
    (state: RootState) => state.category.customDetailModified,
  );
  const shouldCategorySave = useSelector(
    (state: RootState) => state.category.shouldCategorySave,
  );

  const alertInfo = useRef<{ alertType: AlertType; onConfirm: () => void }>({
    alertType: 'SAVE_CATCH',
    onConfirm: () => {},
  });
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false);
  const [clickElementOnAlertConfirm, setClickElementOnAlertConfirm] = useState<
    any
  >(null);

  const { isOrderModified } = useSaveCategoryOrder();

  const isModalOn = useMemo(() => isShowAlert || isAlertOn || isDialogOn, [
    isAlertOn,
    isDialogOn,
    isShowAlert,
  ]);

  const isDetailModified = useMemo(
    () => customDetailModified.size > 0 || shouldCategorySave,
    [customDetailModified.size, shouldCategorySave],
  );

  const showAlert = useCallback(
    (alertType: AlertType, targetElement, onConfirm = () => {}) => {
      setClickElementOnAlertConfirm(targetElement);
      alertInfo.current.alertType = alertType;
      alertInfo.current.onConfirm = onConfirm;

      setIsShowAlert(true);
    },
    [],
  );

  const dataTableRef = useRef(null) as React.MutableRefObject<null>;
  useClickOutside(dataTableRef, (e: Event) => {
    if (isModalOn) {
      return;
    }
    const targetElement = e.target as HTMLElement;
    const isButton = isClickedRoutingElement(targetElement);

    if (isButton) {
      if (targetElement.id === DATA_TABLE_SAVE_ID) {
        return;
      }
      if (targetElement?.parentElement?.className === 'c-alert') {
        return;
      }
    }

    if (isDetailModified && isButton) {
      const alertType =
        targetElement.id === STORE_APPLY_BUTTON
          ? 'CATEGORY_TOUCHED_APPLY_WITHOUT_SAVE'
          : 'CATEGORY_TOUCHED_WITHOUT_SAVE';
      showAlert(alertType, targetElement, () => {
        dispatch(resetCustomDetail());
        dispatch(resetCustomDetailModified());
        dispatch(setShouldCategorySave(false));
      });
    }
  });

  const containerRef = useRef(null) as React.MutableRefObject<null>;
  useClickOutside(containerRef, (e: Event) => {
    const targetElement = e.target as HTMLElement;
    if (isModalOn) {
      return;
    }
    const isButton = isClickedRoutingElement(targetElement);
    if (isButton) {
      if (
        targetElement.id === DATA_TABLE_SAVE_ID ||
        targetElement.id === STORE_APPLY_BUTTON
      ) {
        return;
      }
      if (isOrderModified) {
        showAlert('CATEGORY_TOUCHED_WITHOUT_ORDER_SAVE', targetElement);
      }
      if (!isStoreCategorySet) {
        showAlert(
          'CATEGORY_TOUCHED_WILL_APPLY_BASIC_CATEGORY',
          targetElement,
          () => {
            dispatch(
              applyBasicCategoryAsync.request({
                isStoreCategorySwitch: false,
                storeNo,
                values: [],
              }),
            );
          },
        );
      }
    }
  });

  const onCloseAlert = useCallback(() => {
    setIsShowAlert(false);
    alertInfo.current.onConfirm = () => {};
    setClickElementOnAlertConfirm(null);
  }, []);
  const onClickConfirmAlert = useCallback(() => {
    alertInfo.current.onConfirm();
    // eslint-disable-next-line no-unused-expressions
    clickElementOnAlertConfirm?.click();
    onCloseAlert();
  }, [clickElementOnAlertConfirm, onCloseAlert]);

  const onClickCancelAlert = useCallback(() => {
    onCloseAlert();
  }, [onCloseAlert]);

  return {
    dataTableRef,
    containerRef,
    isShowAlert,
    alertType: alertInfo.current.alertType,
    onClickConfirmAlert,
    onClickCancelAlert,
  };
};

export default useTouched;
