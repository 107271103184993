import {
  deleteSelectedCustomCategory,
  setShouldStoreApply,
} from 'modules/category';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import AlertBottom from './index';

const DeleteCategoryWarning = () => {
  const dispatch = useDispatch();

  const onClickConfirm = useCallback(() => {
    dispatch(deleteSelectedCustomCategory());
    dispatch(setShouldStoreApply(true));
  }, [dispatch]);

  return (
    <AlertBottom
      confirmText="확인"
      confirmAction={onClickConfirm}
      cancelText="취소"
    />
  );
};

export default DeleteCategoryWarning;
