import { TextInput, TextInputWithLabel } from 'components/common';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { openDialog } from 'modules/dialog';
import { setTouched } from 'modules/display';
import {
  RecommendMenuIdType,
  setRecommendSubTitle,
  setRecommendTitle,
} from 'modules/recommend';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cutByMaxLength } from 'utils';
import { INPUT_REGEX } from 'utils/validate';

interface IProps {
  id: RecommendMenuIdType;
}
const Promotion = ({ id }: IProps) => {
  const data = useSelector((state: RootState) => state.recommend[id]);
  const dispatch = useDispatch();
  const promotionNo = useSelector(
    (state: RootState) => state.recommend[id].promotionNo,
  );
  const show = useSelector((state: RootState) => state.alert.show);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 30) {
        dispatch(setRecommendTitle(e.target.value, id));
      } else {
        dispatch(setRecommendTitle(cutByMaxLength(e.target.value, 30), id));
      }
      dispatch(setTouched(true));
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  const handleSubTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((INPUT_REGEX.basic.test(e.target.value) || !e.target.value) && !show) {
      if (e.target.value.length < 30) {
        dispatch(setRecommendSubTitle(e.target.value, id));
      } else {
        dispatch(setRecommendSubTitle(cutByMaxLength(e.target.value, 30), id));
      }
      dispatch(setTouched(true));
    } else {
      dispatch(openAlert('ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'));
    }
  };

  return (
    <div className="write-box type_bg">
      <div className="write-box__row split">
        <TextInputWithLabel
          title="프로모션검색"
          label="프로모션 번호"
          value={promotionNo}
          onChange={() => {}}
          onButtonClick={() => {
            dispatch(openDialog('SelectPromotionFromRecommendPromotion'));
          }}
          width="wide"
        />
      </div>
      <div className="write-box__row inline">
        <div className="row_title">
          {'대제목 '}
          <span className="icon icon-must">필수입력</span>
        </div>
        <div className="row_cont">
          <TextInput
            value={data.title}
            width="wide"
            onChange={handleTitleChange}
            title="RecommendTitle"
            placeholder="한글 12자, 영문 20자 이내로 입력해주세요."
          />
        </div>
      </div>
      <div className="write-box__row inline">
        <div className="row_title">
          {'소제목 '}
          <span className="icon icon-must">필수입력</span>
        </div>
        <div className="row_cont">
          <TextInput
            value={data.subTitle}
            width="wide"
            onChange={handleSubTitleChange}
            title="RecommendSubTitle"
            placeholder="한글 12자, 영문 20자 이내로 입력해주세요."
          />
        </div>
      </div>
      <div className="write-box__row add">
        <p className="text_sub">
          프로모션이 종료되면 프로모션 컬렉션 모듈도 비노출 처리됩니다.
        </p>
      </div>
    </div>
  );
};

export default Promotion;
