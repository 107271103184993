/* eslint-disable no-misleading-character-class */
type IsValidTextInputOptionsType =
  | 'basic'
  | 'notBasic'
  | 'title'
  | 'notMatchTitle'
  | 'promotion'
  | 'insta'
  | 'url'
  | 'urlParam'
  | 'number'
  | 'productNumbers';

export interface IIsValidTextInputOptions {
  type?: IsValidTextInputOptionsType;
  max?: number;
  isAllowEmpty?: boolean;
}

const MAX_IMAGE_FILE_SIZE = 250000;
const MAX_IMAGE_WIDTH = 1000;
const MAX_IMAGE_HEIGHT = 1000;
const MIN_IMAGE_WIDTH = 600;
const MIN_IMAGE_HEIGHT = 500;

const COMMON_IMAGE_SIZE = Object.freeze({
  minWidth: 600,
  maxWidth: 1000,
  maxHeight: 1000,
});
type ImageType = 'DEFAULT' | 'PROFILE' | 'LOCATION';
type ImageSizeType = {
  [key in ImageType]: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
  };
};
const IMAGE_SIZE: ImageSizeType = {
  DEFAULT: {
    ...COMMON_IMAGE_SIZE,
    minHeight: 500,
  },
  PROFILE: {
    ...COMMON_IMAGE_SIZE,
    minHeight: 600,
  },
  LOCATION: {
    ...COMMON_IMAGE_SIZE,
    minHeight: 300,
  },
};

const VALID_IMAGE_EXTENSION = ['jpg', 'jpeg', 'png'];

export const INPUT_REGEX: Readonly<
  {
    [key in IsValidTextInputOptionsType]: RegExp;
  }
> = Object.freeze({
  // 한자로 특수문자를 입력했을 때 %u2605%uFE0E와 같이 입력하는 브라우저가 있어 린트 비활성화함.
  // %u2665%uFE0E = ♥︎, %u2665 = ♥
  basic: /^[\s|ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|!@#$%^&*()[\]\-_+:;~'",.|☆★♡♥|★︎☆︎♥︎♡︎]+$/,
  notBasic: /[^\s|ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|0-9|A-Z|!@#$%^&*()[\]\-_+:;~'",.|☆★♡♥|★︎☆︎♥︎♡︎]+/g,
  title: /^[\s|ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|_]+$/,
  notMatchTitle: /[^\s|ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|_]+/g,
  promotion: /^[\s|ㄱ-ㅎ|가-힣|ㅏ-ㅣ|a-z|A-Z|0-9|#_/!*%]+$/,
  insta: /^[\s|a-z|0-9|_|.]+$/,
  url: /^[\s|a-z|A-Z|0-9|_]+$/,
  urlParam: /^[\s|a-z|A-Z|0-9]+$/,
  number: /^[0-9]*$/,
  productNumbers: /^[\d\n]+$/,
});

export const isValidExtension = (file: File) => {
  const [fileType, fileExtension] = file.type.split('/');
  if (fileType !== 'image') return false;

  return VALID_IMAGE_EXTENSION.includes(fileExtension);
};

export const isValidImageFileSize = (file: File) =>
  file.size <= MAX_IMAGE_FILE_SIZE;

export const isValidImageWidthAndHeight = (width: number, height: number) =>
  width >= MIN_IMAGE_WIDTH &&
  width <= MAX_IMAGE_WIDTH &&
  height >= MIN_IMAGE_HEIGHT &&
  height <= MAX_IMAGE_HEIGHT;

export const validateImageWidthAndHeight = (imgSrc: string) =>
  new Promise<boolean>(resolve => {
    const img = new Image();
    img.onload = () =>
      resolve(isValidImageWidthAndHeight(img.naturalWidth, img.naturalHeight));
    img.src = imgSrc;
  });

const isValidImageSize = (image: HTMLImageElement, imageType: ImageType) => {
  const { naturalWidth: width, naturalHeight: height } = image;
  const { minWidth, minHeight, maxWidth, maxHeight } = IMAGE_SIZE[imageType];

  return (
    width >= minWidth &&
    width <= maxWidth &&
    height >= minHeight &&
    height <= maxHeight
  );
};
export const validateImageSize = (imgSrc: string, imageType: ImageType) =>
  new Promise<boolean>(resolve => {
    const img = new Image();
    img.onload = () => resolve(isValidImageSize(img, imageType));
    img.src = imgSrc;
  });

export const isValidTextInput = (
  value: string,
  {
    type = 'basic',
    max = 30,
    isAllowEmpty = true,
  }: IIsValidTextInputOptions = {},
) => {
  const { length } = value;
  if (length === 0) {
    return isAllowEmpty;
  }

  return length <= max && INPUT_REGEX[type].test(value);
};
