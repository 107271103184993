import { menuIdType } from 'modules/display';
import React from 'react';

import Profile from './Profile';
import Recommend from './Recommend';
import Special from './Special';

interface IProps {
  id: string;
}

const getPreview = (id: string) => {
  switch (id) {
    case menuIdType.profile:
      return <Profile />;
    case menuIdType.recommend1:
    case menuIdType.recommend2:
    case menuIdType.recommend3:
      return <Recommend id={id} />;
    case menuIdType.special:
      return <Special />;
    default:
      return null;
  }
};
const ContentPreview = ({ id }: IProps) => {
  return <>{getPreview(id)}</>;
};

export default ContentPreview;
