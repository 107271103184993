import { CheckBox } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import { setTouched } from 'modules/display';
import { selectSpecialItemAll } from 'modules/special';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SpecialAllCheckBox = () => {
  const dispatch = useDispatch();
  const dataLength = useSelector(
    (state: RootState) => state.special.data.length,
  );
  const selectedLength = useSelector(
    (state: RootState) => state.special.selected.length,
  );
  const isSelected = useMemo(
    () => dataLength > 0 && dataLength === selectedLength,
    [dataLength, selectedLength],
  );

  return (
    <CheckBox
      id="checkSpecialAll"
      name="checkSpecialAll"
      type={CheckBoxType.basic}
      label="전체선택"
      labelHidden
      checked={isSelected}
      onChange={() => {
        dispatch(selectSpecialItemAll());
        dispatch(setTouched(true));
      }}
    />
  );
};

const SpecialTableHeader = () => (
  <thead>
    <tr>
      <th scope="col">
        <SpecialAllCheckBox />
      </th>
      <th scope="col">전시순서</th>
      <th scope="col">기획전번호</th>

      <th scope="col">기획전명</th>
      <th scope="col">진행상태</th>
      <th scope="col">시작일</th>
      <th scope="col">종료일</th>
    </tr>
  </thead>
);

export default SpecialTableHeader;
