import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type AlertType =
  | 'SAVE_OK'
  | 'SAVE_CATCH'
  | 'SELECT_ENABLE_MENU'
  | 'SELECT_DISABLE_MENU'
  | 'SAVE_MENU_WITH_DISABLE'
  | 'MOVE_MENU_WITHOUT_SAVING'
  | 'SAVE_CATEGORY_WITHOUT_CONNECTING'
  | 'REGISTER_OVER_50_CATEGORIES'
  | 'INPUT_CATEGORY_NAME'
  | 'INPUT_CATEGORY_NAME_LENGTH'
  | 'DELETE_CATEGORY_MUST_CUSTOM_CATEGORY_MORE_4'
  | 'DELETE_CATEGORY_WARNING'
  | 'DELETE_AFTER_SELECT_CATEGORY'
  | 'DELETE_AFTER_SELECT_PRODUCT'
  | 'CATEGORY_TOUCHED_WITHOUT_ORDER_SAVE'
  | 'CATEGORY_TOUCHED_WITHOUT_SAVE'
  | 'CATEGORY_TOUCHED_WILL_APPLY_BASIC_CATEGORY'
  | 'CATEGORY_TOUCHED_APPLY_WITHOUT_SAVE'
  | 'WILL_UNCHECK_ON_MOVE_PAGE'
  | 'SAVE_PROFILE_WITHOUT_PRODUCT_NO'
  | 'SAVE_PROFILE_WITHOUT_PROMOTION_NO'
  | 'CHANGE_PROFILE_TYPE_WITH_DATA'
  | 'IS_INVALID_PROFILE_IMAGE_FILE_SIZE'
  | 'IS_INVALID_PROFILE_IMAGE_EXTENSION'
  | 'IS_INVALID_PROFILE_IMAGE_WIDTH_AND_HEIGHT'
  | 'IS_INVALID_STORE_IMAGE_EXTENSION'
  | 'IS_OVER_100_SPECIAL_ITEM_ORDER'
  | 'IS_NEGATIVE_SPECIAL_ITEM_ORDER'
  | 'SAVE_SPECIAL_WITH_EMPTY'
  | 'PREVIEW_SPECIAL_WITH_EMPTY'
  | 'SAVE_SPECIAL_OVER_5_ITEMS'
  | 'SAVE_PROMOTION_WITH_INVALID_DATE'
  | 'SAVE_PROMOTION_OVER_100_ITEMS'
  | 'SAVE_PROFILE_WITH_EMPTY_IMAGE'
  | 'ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS'
  | 'ILLEGAL_INPUT_OF_PROMOTION_CHARACTERS'
  | 'SPECIAL_CHARACTERS_ONLY_UNDERBAR'
  | 'SAVE_RECOMMEND_OVER_3_ITEMS'
  | 'SAVE_RECOMMEND_OVER_6_ITEMS'
  | 'SAVE_RECOMMEND_WITHOUT_PRODUCT_DATA'
  | 'SAVE_RECOMMEND_WITHOUT_PROMOTION_NO'
  | 'SAVE_RECOMMEND_WITHOUT_TITLE'
  | 'SAVE_RECOMMEND_WITHOUT_SUBTITLE'
  | 'SPECIAL_CHARACTERS_ONLY_UNDERBAR_DOT'
  | 'SPECIAL_CHARACTERS_ONLY_UNDERBAR'
  | 'PRIVATE_DOMAIN_OK'
  | 'PRIVATE_DOMAIN_CATCH'
  | 'NEED_PRIVATE_DOMAIN_CONFIRM'
  | 'INPUT_OFFLINE_INFO'
  | 'MOVE_MENU_WITHOUT_SAVING_DATA'
  | 'STORE_NAME_OK'
  | 'STORE_NAME_NOT_CONFIRMED'
  | 'STORE_NAME_ALREADY_USED'
  | 'STORE_NAME_NO_SELLER_ID'
  | 'STORE_NAME_INVALID_LENGTH'
  | 'STORE_NAME_SPECIAL_CHAR'
  | 'STORE_URL_OK'
  | 'STORE_URL_NO_SELLER_ID'
  | 'STORE_URL_ALREADY_SET'
  | 'STORE_URL_INVALID'
  | 'STORE_URL_ALREADY_USED'
  | 'STORE_NAME_NOT_EXIST'
  | 'STORE_URL_INVALID_LENGTH'
  | 'STORE_URL_SWITCH_PUBLIC'
  | 'DELETE_PRODUCT_WITH_NO_DATA'
  | 'DELETE_SPECIAL_WITH_NO_DATA'
  | 'SAVE_PROMOTION_WITHOUT_DATE'
  | 'OPEN_PREVIEW_WITH_IE'
  | 'SEND_11TALK_WITH_INVALID_COUPON'
  | 'SAVE_PROFILE_WITH_INVALID_STATUS_CODE'
  | 'SET_PROFILE_DATA_WITH_INVALID_PROMOTION'
  | 'NOTICE_INPUT_TITLE_EMPTY'
  | 'NOTICE_INPUT_CONTENT_EMPTY'
  | 'NOTICE_INVALID_DATE';

export const openTextAlert = createAction('@ALERT/OPEN/TEXT')<string>();
export const openAlert = createAction('@ALERT/OPEN')<AlertType>();
export const openAlertWithId = createAction('@ALERT/OPEN_WITH_ID')<
  AlertType,
  string
>();
export const closeAlert = createAction('@ALERT/ClOSE')();

const actions = {
  openAlert,
  openAlertWithId,
  closeAlert,
};

interface IAlert {
  show: boolean;
  type?: AlertType;
  id: string;
}

const initialState: IAlert = {
  show: false,
  id: '',
};

export default createReducer<IAlert, ActionType<typeof actions>>(initialState, {
  '@ALERT/OPEN': (state, action) => ({
    ...state,
    show: true,
    type: action.payload,
  }),
  '@ALERT/OPEN_WITH_ID': (state, action) => ({
    ...state,
    show: true,
    type: action.payload,
    id: action.meta,
  }),
  '@ALERT/ClOSE': () => ({ show: false, type: undefined, id: '' }),
});
