import { DefaultLayout, DisplayLayout } from 'components/layouts';
import * as Pages from 'components/pages';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

// TODO: NotFound 인경우에 layout 조정 필요.
const Router = () => {
  return (
    <BrowserRouter>
      <DefaultLayout>
        <Switch>
          <Redirect exact from="/" to="/display" />
          <Route path="/display">
            <DisplayLayout>
              <Switch>
                <Route
                  exact
                  path="/display/template"
                  component={Pages.Display.template}
                />
                <Route
                  exact
                  path="/display/skin"
                  component={Pages.Display.Skin}
                />
                <Route
                  exact
                  path="/display/home"
                  component={Pages.Display.Home}
                />
              </Switch>
            </DisplayLayout>
          </Route>
          <Route exact path="/basicinfo" component={Pages.BasicInfo} />
          <Route path="/category">
            <Route exact path="/category" component={Pages.Category.Basic} />
            <Route
              exact
              path="/category/edit"
              component={Pages.Category.Edit}
            />
            <Route
              exact
              path="/category/done"
              component={Pages.Category.Done}
            />
          </Route>
          <Route exact path="/coupon" component={Pages.Coupon} />
          <Route path="/promotion">
            <Route exact path="/promotion" component={Pages.Promotion.List} />
            <Route
              exact
              path="/promotion/new"
              component={Pages.Promotion.Edit}
            />
            <Route
              exact
              path="/promotion/edit/:id"
              component={Pages.Promotion.Edit}
            />
            <Route
              exact
              path="/promotion/view/:id"
              component={Pages.Promotion.Read}
            />
          </Route>
          <Route path="/notice">
            <Route exact path="/notice" component={Pages.Notice.List} />
            <Route exact path="/notice/new" component={Pages.Notice.Edit} />
            <Route
              exact
              path="/notice/edit/:id"
              component={Pages.Notice.Edit}
            />
          </Route>
          <Route component={Pages.NotFound} />
        </Switch>
      </DefaultLayout>
    </BrowserRouter>
  );
};

export default Router;
