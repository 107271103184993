import {
  basicCategoryListMapper,
  ICategoryListItem,
} from 'components/pages/category/CategoryList';
import { RootState } from 'modules';
import {
  CategoryItemIdType,
  getBasicMiddleCategoryAsync,
} from 'modules/category';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * [basicLargeCategories, basicMiddleCategories, onClickLargeCategoryItem, onClickMiddleCategoryItem, selectedMiddleCategoryId]
 */

const useBasicCategory = (): [
  ICategoryListItem[],
  ICategoryListItem[],
  (id: CategoryItemIdType) => void,
  CategoryItemIdType,
  (id: CategoryItemIdType) => void,
  CategoryItemIdType,
] => {
  const dispatch = useDispatch();
  const {
    category: { basicLarge, basicMiddle },
    user: {
      headerInfo: { storeNo, encSellerNo },
    },
  } = useSelector((state: RootState) => state);

  const [selectedLargeCategoryId, setSelectedLargeCategoryId] = useState<
    CategoryItemIdType
  >(-1);
  const [selectedMiddleCategoryId, setSelectedMiddleCategoryId] = useState<
    CategoryItemIdType
  >(-1);

  const basicLargeCategories = useMemo(
    () => basicLarge.map(basicCategoryListMapper),
    [basicLarge],
  );
  const basicMiddleCategories = useMemo(
    () => basicMiddle.map(basicCategoryListMapper),
    [basicMiddle],
  );

  const onClickLargeCategoryItem = useCallback(
    (largeCategoryNumber: CategoryItemIdType) => {
      setSelectedLargeCategoryId(largeCategoryNumber);
      dispatch(
        getBasicMiddleCategoryAsync.request({
          encSellerNo,
          largeCategoryNumber,
          storeNo,
          isMappedCategory: true,
        }),
      );
    },
    [dispatch, encSellerNo, storeNo],
  );
  const onClickMiddleCategoryItem = useCallback(
    (categoryNumber: CategoryItemIdType) => {
      setSelectedMiddleCategoryId(categoryNumber);
    },
    [],
  );

  return [
    basicLargeCategories,
    basicMiddleCategories,
    onClickLargeCategoryItem,
    selectedLargeCategoryId,
    onClickMiddleCategoryItem,
    selectedMiddleCategoryId,
  ];
};

export default useBasicCategory;
