import { RootState } from 'modules';
import {
  setHasPrivateDomainIPError,
  setIsPrivateDomainAlreadyUsed,
} from 'modules/user';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useStoreDomain = () => {
  const dispatch = useDispatch();
  const isUsingPrivateDomain = useSelector(
    (state: RootState) => state.user.basicInfo.isUsingPrivateDomain,
  );
  const privateDomain =
    useSelector((state: RootState) => state.user.basicInfo.privateDomain) ?? '';
  const serverSavedPrivateDomain =
    useSelector(
      (state: RootState) => state.user.basicInfo.details.storePersonalDomainUrl,
    ) ?? '';
  const isPrivateDomainConfirmed = useSelector(
    (state: RootState) => state.user.basicInfo.privateDomainOk,
  );
  const hasPrivateDomainError = useSelector(
    (state: RootState) => state.user.basicInfo.hasPrivateDomainError,
  );
  const isPrivateDomainAlreadyUsed = useSelector(
    (state: RootState) => state.user.basicInfo.isPrivateDomainAlreadyUsed,
  );
  const isNeedPrivateDomainConfirm = useMemo(
    () => isUsingPrivateDomain && !isPrivateDomainConfirmed,
    [isPrivateDomainConfirmed, isUsingPrivateDomain],
  );

  const storeID = useSelector(
    (state: RootState) => state.user.headerInfo.storeID,
  );

  const mobileStoreUrl = useMemo(() => `http://shop.11st.co.kr/m/${storeID}`, [
    storeID,
  ]);
  const pcStoreUrl = useMemo(() => `http://shop.11st.co.kr/stores/${storeID}`, [
    storeID,
  ]);

  const resetPrivateDomainGuideText = useCallback(() => {
    dispatch(setIsPrivateDomainAlreadyUsed(false));
    dispatch(setHasPrivateDomainIPError(false));
  }, [dispatch]);

  return {
    isUsingPrivateDomain,
    serverSavedPrivateDomain,
    privateDomain,
    isNeedPrivateDomainConfirm,
    isPrivateDomainConfirmed,
    hasPrivateDomainError,
    isPrivateDomainAlreadyUsed,
    mobileStoreUrl,
    pcStoreUrl,
    resetPrivateDomainGuideText,
  };
};

export default useStoreDomain;
