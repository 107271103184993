import TableHead from 'components/common/TableHead';
import React from 'react';

const COLUMNS = [
  {
    id: 1,
    text: '',
    width: 'auto',
  },
  {
    id: 2,
    text: '기획전',
    width: 'auto',
  },
  {
    id: 3,
    text: '기획전명',
    width: 'auto',
  },
  {
    id: 4,
    text: '진행상태',
    width: 'auto',
  },
  {
    id: 5,
    text: '시작일',
    width: 'auto',
  },
  {
    id: 6,
    text: '종료일',
    width: 'auto',
  },
];
const TableHeader = () => {
  return <TableHead columns={COLUMNS} />;
};

export default TableHeader;
