import { isFuture, isToday } from 'date-fns';
import { RootState } from 'modules';
import { RecommendMenuIdType } from 'modules/recommend';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const DefaultPromotionPreview = () => {
  return (
    <>
      <li className="l-grid__col l-grid__col--6">
        <div className="c-card c-card__gallery">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/layout_promotion.png`}
                alt=""
              />
            </span>
          </div>
          <div className="c-card__info">
            <span className="c-card__name">
              [가을신상! 최다판매]프리미엄 청바지~!
              남자남성/슬림부츠컷/블랙스키니진/의류옷청바지
            </span>
            <span className="c-card__rate">
              <strong>30%</strong>
            </span>
            <div className="c-card__price">
              <strong>99,999,999</strong> 원
            </div>
          </div>
        </div>
      </li>
      <li className="l-grid__col l-grid__col--6">
        <div className="c-card c-card__gallery">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/layout_promotion2.png`}
                alt=""
              />
            </span>
            <span className="c-card__mov-button">동영상 정보</span>
          </div>
          <div className="c-card__info">
            <span className="c-card__name">[가을신상! 최다판매]프리미엄</span>
            <span className="c-card__rate">
              <strong>30%</strong>
            </span>
            <div className="c-card__price">
              <strong>99,999,999</strong> 원
            </div>
          </div>
        </div>
      </li>
      <li className="l-grid__col l-grid__col--6">
        <div className="c-card c-card__gallery">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/layout_promotion3.png`}
                alt=""
              />
            </span>
          </div>
          <div className="c-card__info">
            <span className="c-card__name">
              [가을신상! 최다판매]프리미엄 청바지~!
              남자남성/슬림부츠컷/블랙스키니진/의류옷청바지
            </span>
            <div className="c-card__price">
              <strong>99,999,999</strong> 원
            </div>
          </div>
        </div>
      </li>
      <li className="l-grid__col l-grid__col--6">
        <div className="c-card c-card__gallery">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_STATIC_PATH}/img/store/content/layout_promotion4.png`}
                alt=""
              />
            </span>
          </div>
          <div className="c-card__info">
            <span className="c-card__name">[가을신상! 최다판매]</span>
            <div className="c-card__price">
              <strong>99,999,999</strong> 원
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

const VisualPromotionPreview = () => {
  return (
    <>
      <li className="l-grid__col l-grid__col--6 medium-3">
        <div className="c-card c-card__onlyprice">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/ex_t/R/450x450/1/90/1/src/pd/17/3/8/3/1/7/6/DnKGf/1799383176_B.jpg`}
                alt=""
              />
            </span>
          </div>
          <div className="c-card__info">
            <span className="c-card__counsel">
              <em>무료상담상품</em>
            </span>
          </div>
        </div>
      </li>
      <li className="l-grid__col l-grid__col--6 medium-3">
        <div className="c-card c-card__onlyprice">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/ex_t/R/450x450/1/90/1/src/pd/17/3/8/3/1/7/6/DnKGf/1799383176_B.jpg`}
                alt=""
              />
            </span>
            <span className="c-card__mov-button">동영상 정보</span>
          </div>
          <div className="c-card__info">
            <div className="c-card__price">
              <strong>17,300</strong>원~
            </div>
          </div>
        </div>
      </li>
      <li className="l-grid__col l-grid__col--6 medium-3">
        <div className="c-card c-card__onlyprice">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/ex_t/R/450x450/1/90/1/src/pd/17/3/8/3/1/7/6/DnKGf/1799383176_B.jpg`}
                alt=""
              />
            </span>
          </div>
          <div className="c-card__info">
            <div className="c-card__price">
              <strong>17,300</strong>원~
            </div>
          </div>
        </div>
      </li>
      <li className="l-grid__col l-grid__col--6 medium-3">
        <div className="c-card c-card__onlyprice">
          <div className="c-card__thumb">
            <span className="c-lazyload c-lazyload--ratio_1x1">
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/ex_t/R/450x450/1/90/1/src/pd/17/3/8/3/1/7/6/DnKGf/1799383176_B.jpg`}
                alt=""
              />
            </span>
          </div>
          <div className="c-card__info">
            <div className="c-card__price">
              <strong>17,300</strong>원~
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

// FIXME: 426, 309 상수 분리
// FIXME: 조건식 분리
const getContainerHeight = (template: string, l: number, endDate: string) => {
  if (template === 'default') {
    if (isToday(new Date(endDate)) || isFuture(new Date(endDate))) {
      if (l > 4) return 600;

      return 426;
    }

    return 426;
  }
  if (template === 'visual') {
    if (isToday(new Date(endDate)) || isFuture(new Date(endDate))) {
      if (l > 4) return 423;

      return 309;
    }

    return 309;
  }

  return 426;
};

const Promotion = ({ id }: { id: RecommendMenuIdType }) => {
  const title = useSelector((state: RootState) => state.recommend[id].title);
  const subTitle = useSelector(
    (state: RootState) => state.recommend[id].subTitle,
  );
  const endDate = useSelector(
    (state: RootState) => state.recommend[id].endDate,
  );
  const rawProductData = useSelector(
    (state: RootState) => state.recommend[id].productData,
  );
  const templateType = useSelector(
    (state: RootState) => state.display.template,
  );

  const productData = useMemo(
    () => rawProductData.filter(data => data.sellPrice !== null),
    [rawProductData],
  );

  return (
    <div
      className="item_cont"
      style={{
        height: getContainerHeight(templateType, productData.length, endDate),
      }}
    >
      <div className="l-grid">
        <div className="l-grid__row" data-ui-type="Store_PageTitle">
          <div className="l-grid__col l-grid__col--12">
            <div className="c-headline c-headline--center">
              <div className="c-headline__link">
                <h3 className="c-headline__title">{title}</h3>
                <strong className="c-headline__subtext">
                  <span>{subTitle}</span>
                </strong>
              </div>
            </div>
          </div>
        </div>
        {templateType === 'default' ? (
          <ul className="l-grid__row" data-ui-type="Store_Product_Basic">
            {productData.length > 0 &&
            (isToday(new Date(endDate)) || isFuture(new Date(endDate))) ? (
              productData.map(data => (
                <li className="l-grid__col l-grid__col--6" key={data.productNo}>
                  <div className="c-card c-card__gallery">
                    <div className="c-card__thumb">
                      {data.movieUrl ? (
                        <>
                          <span className="c-lazyload c-lazyload--ratio_1x1">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${data.movieImageUrl}`}
                              alt=""
                            />
                          </span>
                          <span className="c-card__mov-button">
                            동영상 정보
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="c-lazyload c-lazyload--ratio_1x1">
                            <img
                              src={`${process.env.REACT_APP_CDN}${data.originalImageUrl}`}
                              alt=""
                            />
                          </span>
                        </>
                      )}
                    </div>
                    <div className="c-card__info">
                      <span className="c-card__name">{data.productName}</span>
                      {data.discountRate >= 1 && (
                        <span className="c-card__rate">
                          <strong>{`${data.discountRate}%`}</strong>
                        </span>
                      )}
                      <div className="c-card__price">
                        <strong>{data.finalDiscountPrice}</strong> 원
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <DefaultPromotionPreview />
            )}
          </ul>
        ) : (
          <ul className="l-grid__row" data-ui-type="Store_Product_ImgOverPrice">
            {productData.length > 0 &&
            (isToday(new Date(endDate)) || isFuture(new Date(endDate))) ? (
              productData.map(data => (
                <li
                  className="l-grid__col l-grid__col--6 medium-3"
                  key={data.productNo}
                >
                  <div className="c-card c-card__onlyprice">
                    <div className="c-card__thumb">
                      {data.movieUrl ? (
                        <>
                          <span className="c-lazyload c-lazyload--ratio_1x1">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${data.movieImageUrl}`}
                              alt=""
                            />
                          </span>
                          <span className="c-card__mov-button">
                            동영상 정보
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="c-lazyload c-lazyload--ratio_1x1">
                            <img
                              src={`${process.env.REACT_APP_CDN}${data.originalImageUrl}`}
                              alt=""
                            />
                          </span>
                        </>
                      )}
                    </div>
                    <div className="c-card__info">
                      {data.productType === 'COUNSEL' ? (
                        <span className="c-card__counsel">
                          <em>무료상담상품</em>
                        </span>
                      ) : (
                        <div className="c-card__price">
                          <strong>{data.finalDiscountPrice}</strong>원~
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <VisualPromotionPreview />
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Promotion;
