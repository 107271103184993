/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { ToastMessage } from 'components/common';
import CheckBox, { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import { openAlertWithId } from 'modules/alert';
import {
  IModule,
  menuIdType,
  putEnableMenuAsync,
  setSelectedModule,
} from 'modules/display';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';

import ContentPreview from './ContentPreview';

interface IProps {
  module: IModule;
}

const DefaultItem = ({ module }: IProps) => {
  const dispatch = useDispatch();
  const selectedModule = useSelector(
    (state: RootState) => state.display.selectedModule,
  );

  const recommend = useSelector((state: RootState) => state.recommend);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (window.rakeLog) {
      window.rakeLog.sendRakeLog(e.target);
    }
    if (module.enable) {
      dispatch(openAlertWithId('SELECT_DISABLE_MENU', module.id));
    } else {
      dispatch(openAlertWithId('SELECT_ENABLE_MENU', module.id));
    }
  };

  const getLogArea = (id: string) => {
    switch (id) {
      case menuIdType.profile:
        return 'main_Image';
      case menuIdType.recommend1:
        return 'seller_recommend1';
      case menuIdType.recommend2:
        return 'seller_recommend2';
      case menuIdType.recommend3:
        return 'seller_recommend3';
      case menuIdType.special:
        return 'exhibition';
      case menuIdType.bestReview:
        return 'best_review';
      default:
        return '';
    }
  };

  return (
    <div
      className={classNames('item', {
        'item-type__link': module.id !== menuIdType.bestReview,
        active: selectedModule.id === module.id,
      })}
    >
      <div className="item_title">
        <button
          type="button"
          onClick={() => {
            dispatch(setSelectedModule(module));
          }}
          disabled={module.id === menuIdType.bestReview}
          data-log-actionid-area={getLogArea(module.id)}
          data-log-actionid-label="edit"
        >
          {`${module.name} `}
          {(module.id === menuIdType.recommend1 ||
            module.id === menuIdType.recommend2 ||
            module.id === menuIdType.recommend3) && (
            <i>
              {recommend[module.id].type === '01'
                ? '카드뷰'
                : recommend[module.id].type === '02'
                ? '동영상'
                : '프로모션'}
            </i>
          )}
          {module.id !== menuIdType.bestReview && (
            <span className="icon-link">이동</span>
          )}
        </button>
        <CheckBox
          id={`DefaultItem_${module.id}`}
          type={CheckBoxType.toggle}
          name="DefaultItem"
          label={module.enable ? '사용함' : '사용안함'}
          checked={module.enable}
          onChange={handleChange}
          logArea={getLogArea(module.id)}
          logLabel="apply"
          logBody={JSON.stringify({
            check_value: !module.enable ? 'on' : 'off',
          })}
        />
      </div>
      {module.id === selectedModule.id && <ContentPreview id={module.id} />}
      <ToastMessage
        action={getType(putEnableMenuAsync.request)}
        successText="저장되었습니다."
      />
    </div>
  );
};

export default DefaultItem;
