import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

const ColorPreview = () => {
  const skin = useSelector((state: RootState) => state.display.skin);

  return (
    <div className={`view-skin__img ${skin}`}>
      <div className="img_in">
        <span className="skin_gnb">GNB</span>
        <span className="skin_visual">스토어정보</span>
        <span className="skin_store">스토어정보</span>
        <span className="skin_info">스토어쇼핑정보</span>
        <span className="skin_search">스토어 검색</span>
        <span className="skin_recommend">추천상품</span>
        <span className="skin_shortcut">바로가기</span>
        <span className="skin_promotion">프로모션</span>
        <span className="skin_cardview">카드뷰</span>
        <span className="skin_movie">동영상뷰</span>
        <span className="skin_banner">기획전</span>
        <span className="skin_review">베스트리뷰</span>
        <span className="skin_product">전체상품</span>
      </div>
    </div>
  );
};

export default ColorPreview;
