import { Radio } from 'components/common';
import { RadioType } from 'components/common/Radio';
import { setIsUsingCustom } from 'modules/category';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GuideText from '../GuideText';

const SelectCategoryType = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onClickBasic = () => {
    dispatch(setIsUsingCustom(false));
    history.push('/category?mod');
  };

  return (
    <div className="st-filter__cate">
      <div className="c-radio__group">
        <Radio
          type={RadioType.bold}
          name="radioStyle"
          value="0"
          checked={false}
          label="기본 카테고리"
          onChange={onClickBasic}
        />
        <Radio
          type={RadioType.bold}
          name="radioStyle"
          value="1"
          checked
          label="스토어 카테고리 직접 관리"
          onChange={() => {}}
        />
      </div>
      <GuideText />
    </div>
  );
};

export default SelectCategoryType;
