import { ActionType, createAction, createReducer } from 'typesafe-actions';

export type DialogType =
  | 'SelectProduct'
  | 'SelectProducts'
  | 'SelectPromotionFromRecommendPromotion'
  | 'SelectPromotionFromProfile'
  | 'SelectSpecial'
  | 'SelectCategory'
  | 'SelectProductsFromRecommendCardView'
  | 'SelectProductsFromRecommendVideo'
  | 'SelectProductsFromPromotionDetail'
  | 'DomainSettingIntro'
  | 'Talk11UsedInfo';

export const openDialog = createAction('@DIALOG/OPEN')<DialogType>();
export const openDialogWithId = createAction('@DIALOG/OPEN_WITH_ID')<
  DialogType,
  string
>();
export const closeDialog = createAction('@DIALOG/ClOSE')();

const actions = {
  openDialog,
  openDialogWithId,
  closeDialog,
};

export interface IDialog {
  show: boolean;
  type: DialogType;
  id: string;
}

const initialState: IDialog = {
  show: false,
  type: 'SelectProduct',
  id: '',
};

export default createReducer<IDialog, ActionType<typeof actions>>(
  initialState,
  {
    '@DIALOG/OPEN': (state, action) => ({
      ...state,
      show: true,
      type: action.payload,
    }),
    '@DIALOG/OPEN_WITH_ID': (state, action) => ({
      ...state,
      show: true,
      type: action.payload,
      id: action.meta,
    }),
    '@DIALOG/ClOSE': state => ({
      ...state,
      show: false,
      id: '',
    }),
  },
);
