import { Radio } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import { RadioType } from 'components/common/Radio';
import useBasicCategory from 'hooks/useBasicCategory';
import { RootState } from 'modules';
import {
  getBasicLargeCategoryAsync,
  getCustomCategoryAsync,
  getCustomCategoryDetailAsync,
  setIsUsingCustom,
  setSelectedCustomId,
  TYPE_CODE,
} from 'modules/category';
import { SELECTED_CUSTOM_ID } from 'modules/category/reducer';
import { getCustomCategoryProductDetailAsync } from 'modules/product';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CategoryList from './CategoryList';
import GuideText from './GuideText';
import useLargeCategories from './useLargeCategories';

const BaseIntro = ({
  done = false,
  children,
}: {
  done?: boolean;
  children: React.ReactNode;
}) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isUsingCustom: isCustomMode, custom, customDetail } = useSelector(
    (state: RootState) => state.category,
    shallowEqual,
  );
  const productNumbers = useSelector(
    (state: RootState) => state.product.productNumbers,
  );
  const { storeNo, encSellerNo, isStoreCategorySet } = useSelector(
    (state: RootState) => state.user.headerInfo,
    shallowEqual,
  );

  const [
    basicLargeCategories,
    basicMiddleCategories,
    onClickLargeCategoryItem,
  ] = useBasicCategory();

  const [selectedLargeCategoryId, setSelectedLargeCategoryId] = useState(-1);
  const [isCustomProductDetail, setIsCustomProductDetail] = useState<boolean>(
    false,
  );

  const foundProductsTotal = useMemo(() => productNumbers.length, [
    productNumbers.length,
  ]);
  const isDone = useMemo(() => location.pathname.includes('category/done'), [
    location.pathname,
  ]);
  const documentTitle = useMemo(
    () =>
      isCustomMode
        ? '스토어 카테고리 직접 관리 - 카테고리 관리 - 스토어 관리'
        : '기본 카테고리 - 카테고리 관리 - 스토어 관리',
    [isCustomMode],
  );
  const middleCategories = useMemo(
    () =>
      isCustomMode
        ? customDetail.map(({ categoryNo, categoryName }) => ({
            id: categoryNo,
            text: categoryName,
            mark: false,
          }))
        : basicMiddleCategories,
    [basicMiddleCategories, customDetail, isCustomMode],
  );

  const { hasNotProducts, largeCategories } = useLargeCategories();

  const onClickLargeCategory = useCallback(
    (categoryNo: number) => {
      setSelectedLargeCategoryId(categoryNo);
      if (isCustomMode) {
        const isProductDetail =
          custom.find(({ storeCategoryNo }) => storeCategoryNo === categoryNo)
            ?.typeCode === TYPE_CODE.PRODUCT;
        setIsCustomProductDetail(isProductDetail);
        dispatch(setSelectedCustomId(categoryNo));
        if (isProductDetail) {
          dispatch(
            getCustomCategoryProductDetailAsync.request({
              storeNo,
              storeCategoryNo: categoryNo,
            }),
          );

          return;
        }
        dispatch(
          getCustomCategoryDetailAsync.request({
            storeNo,
            storeCategoryNo: categoryNo,
          }),
        );
      } else {
        onClickLargeCategoryItem(categoryNo);
      }
    },
    [custom, dispatch, isCustomMode, onClickLargeCategoryItem, storeNo],
  );

  const onClickAddCategory = useCallback(() => history.push('/category/edit'), [
    history,
  ]);

  useEffect(() => {
    if ((!isDone && storeNo) || !basicLargeCategories.length) {
      dispatch(getBasicLargeCategoryAsync.request({ encSellerNo, storeNo }));
    }
  }, [basicLargeCategories.length, dispatch, encSellerNo, isDone, storeNo]);

  useEffect(() => {
    if ((!isDone && storeNo) || !custom.length) {
      dispatch(getCustomCategoryAsync.request({ storeNo }));
    }
  }, [custom.length, dispatch, isDone, storeNo]);

  useEffect(() => {
    if (storeNo && !location.search.includes('mod')) {
      setSelectedLargeCategoryId(-1);
      dispatch(setIsUsingCustom(isStoreCategorySet));
    }
  }, [dispatch, isStoreCategorySet, location.search, storeNo]);

  return (
    <>
      <DocumentTitle title={documentTitle} />
      <>
        <div className="content-in">
          <div className="view-title title-style__2">
            <h2 className="title">카테고리 전시 방식</h2>
          </div>
          <div className="view-cont">
            <div className="st-filter__cate">
              <div className="c-radio__group">
                <Radio
                  type={RadioType.bold}
                  name="radioStyle"
                  value="0"
                  checked={!isCustomMode}
                  label="기본 카테고리"
                  onChange={() => {
                    setSelectedLargeCategoryId(-1);
                    dispatch(setIsUsingCustom(false));
                    history.push('/category?mod');
                  }}
                />
                <Radio
                  type={RadioType.bold}
                  name="radioStyle"
                  value="1"
                  checked={isCustomMode}
                  label="스토어 카테고리 직접 관리"
                  onChange={() => {
                    setSelectedLargeCategoryId(-1);
                    dispatch(setSelectedCustomId(SELECTED_CUSTOM_ID.INIT));
                    dispatch(setIsUsingCustom(true));
                    if (custom.length > 0) {
                      history.push('/category/edit');
                    }
                  }}
                />
              </div>
              <GuideText />
            </div>
            <div className="st-cont__cate">
              <div className="cate-menu">
                <CategoryList
                  title="대카테고리"
                  depth="1"
                  selectedId={selectedLargeCategoryId}
                  list={largeCategories}
                  onClickItem={onClickLargeCategory}
                  onClickAddButton={
                    !done && isCustomMode ? onClickAddCategory : undefined
                  }
                  rightArrow
                  firstSelected
                  productCount={hasNotProducts ? 0 : undefined}
                >
                  {isCustomMode && isCustomProductDetail ? (
                    <CategoryList
                      title="상품"
                      depth="2"
                      productCount={foundProductsTotal}
                    />
                  ) : (
                    <CategoryList
                      title="중카테고리"
                      depth="2"
                      list={middleCategories}
                    />
                  )}
                </CategoryList>
              </div>
              {children}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default BaseIntro;
