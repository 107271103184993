import React from 'react';

interface IWriteBox {
  title: React.ReactNode;
  essential?: boolean;
  children?: React.ReactNode;
}

const WriteBox = ({ title, essential = false, children }: IWriteBox) => {
  return (
    <div className="write-box__row inline">
      <div className="row_title">
        {title}
        {essential && (
          <>
            {' '}
            <span className="icon icon-must">필수입력</span>
          </>
        )}
      </div>
      <div className="row_cont">{children}</div>
    </div>
  );
};

export default WriteBox;
