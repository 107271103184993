import { CheckBox } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import { setSpecialCandidate } from 'modules/special';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'utils/format';

const TableBody = () => {
  const data = useSelector((state: RootState) => state.special.searchResult);
  const candidateList = useSelector(
    (state: RootState) => state.special.candidateList,
  );
  const dispatch = useDispatch();

  return (
    <tbody>
      {data.length > 0 ? (
        <>
          {data.map(
            ({
              exhibitionNo,
              exhibitionName,
              display,
              displayBeginDate,
              displayEndDate,
            }) => (
              <tr
                key={exhibitionNo}
                className={candidateList.includes(exhibitionNo) ? 'active' : ''}
              >
                <td>
                  <CheckBox
                    id={`specialSearchItem_${exhibitionNo}`}
                    name="specialSearchItem"
                    checked={candidateList.includes(exhibitionNo)}
                    onChange={() => dispatch(setSpecialCandidate(exhibitionNo))}
                    type={CheckBoxType.basic}
                    labelHidden
                  />
                </td>
                <td>
                  <span className="text-num">{exhibitionNo}</span>
                </td>
                <td>
                  <button
                    type="button"
                    className="text-title"
                    onClick={() =>
                      window.open(
                        `//www.11st.co.kr/browsing/MallPlanDetail.tmall?method=getMallPlanDetail&isPlan=Y&planDisplayNumber=${exhibitionNo}`,
                        '_blank',
                      )
                    }
                  >
                    {exhibitionName}
                  </button>
                </td>
                <td>{display ? '진행중' : '진행예정'}</td>
                <td>
                  <span className="text-date">
                    {formatDate(displayBeginDate)}
                  </span>
                </td>
                <td>
                  <span className="text-date">
                    {formatDate(displayEndDate)}
                  </span>
                </td>
              </tr>
            ),
          )}
        </>
      ) : (
        <tr>
          <td colSpan={6} className="non-data">
            검색 조건에 맞는 결과가 없습니다.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
