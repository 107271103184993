import { PutProfileInfoParam } from 'apis/profile';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from 'typesafe-actions';

export interface IProfileItem {
  id: string;
  type: string;
  imageUrl: string;
  title: string;
  subTitle: string;
  statusCode: string;
  itemNo?: string;
}

export interface IProfile {
  type: string;
  data: IProfileItem[];
  dataLength: number;
}

export interface IProfileInfoResponse {
  displayPriority: string;
  imageViewTypeCode: string;
  moduleImageUrl: string;
  moduleObjectValue: string;
  moduleSubTitle: string;
  moduleTitle: string;
  profileTargetCode: string;
  profileValueStatusCode: string;
}

export const setProfileType = createAction('@DISPLAY/PROFILE/TYPE/SET')<
  string
>();

export const addProfileItem = createAction('@DISPLAY/PROFILE/ITEM/ADD')<
  IProfileItem
>();

export const deleteProfileItem = createAction('@DISPLAY/PROFILE/ITEM/DELETE')<
  string
>();

export const setProfileItemType = createAction(
  '@DISPLAY/PROFILE/ITEM/TYPE/SET',
)<string, string>();

export const setProfileItemTitle = createAction(
  '@DISPLAY/PROFILE/ITEM/TITLE/SET',
)<string, string>();

export const setProfileItemSubTitle = createAction(
  '@DISPLAY/PROFILE/ITEM/SUB_TITLE/SET',
)<string, string>();

export const setProfileItemImageUrl = createAction(
  '@DISPLAY/PROFILE/ITEM/IMAGE_URL/SET',
)<string, string>();

export const setProfileItemStatusCode = createAction(
  '@DISPLAY/PROFILE/ITEM/STATUS_CODE/SET',
)<string, string>();

export const setProfileItemNo = createAction('@DISPLAY/PROFILE/ITEM/NO/SET')<
  string,
  string
>();

export const addProfilePromotionFromSearchResult = createAction(
  '@DISPLAY/PROFILE/PROMOTION/ADD',
)();

export const addProfileProductFromSearchResult = createAction(
  '@DISPLAY/PROFILE/PRODUCT/ADD',
)();

export const clearProfileList = createAction('@DISPLAY/PROFILE/LIST/CLEAR')();

export const clearProfileItem = createAction('@DISPLAY/PROFILE/ITEM/CLEAR')<
  string
>();

export const getProfileInfoAsync = createAsyncAction(
  '@DISPLAY/PROFILE/GET_INFO/FETCH',
  '@DISPLAY/PROFILE/GET_INFO/SUCCESS',
  '@DISPLAY/PROFILE/GET_INFO/FAILURE',
)<string, IProfileInfoResponse[], Error>();

export const putProfileInfoAsync = createAsyncAction(
  '@DISPLAY/PROFILE/PUT_INFO/FETCH',
  '@DISPLAY/PROFILE/PUT_INFO/SUCCESS',
  '@DISPLAY/PROFILE/PUT_INFO/FAILURE',
)<PutProfileInfoParam, undefined, Error>();

export const setProfileList = createAction('@DISPLAY/PROFILE/LIST/SET')<
  IProfileItem[]
>();

const actions = {
  addProfileItem,
  deleteProfileItem,
  setProfileType,
  setProfileItemTitle,
  setProfileItemSubTitle,
  setProfileItemImageUrl,
  setProfileItemStatusCode,
  setProfileItemType,
  setProfileItemNo,
  clearProfileItem,
  clearProfileList,
  setProfileList,
};

const initialState: IProfile = {
  type: 'SQUARE',
  data: [],
  dataLength: 0,
};

export default createReducer<IProfile, ActionType<typeof actions>>(
  initialState,
  {
    '@DISPLAY/PROFILE/TYPE/SET': (state, action) => ({
      ...state,
      type: action.payload,
    }),
    '@DISPLAY/PROFILE/ITEM/ADD': (state, action) => ({
      ...state,
      data: [...state.data, action.payload],
      dataLength: state.data.length + 1,
    }),
    '@DISPLAY/PROFILE/ITEM/DELETE': (state, action) => ({
      ...state,
      data: state.data.filter(({ id }) => id !== action.payload),
      dataLength: state.data.length - 1,
    }),
    '@DISPLAY/PROFILE/ITEM/TYPE/SET': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.meta ? { ...item, type: action.payload } : item,
      ),
    }),
    '@DISPLAY/PROFILE/ITEM/TITLE/SET': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.meta ? { ...item, title: action.payload } : item,
      ),
    }),
    '@DISPLAY/PROFILE/ITEM/SUB_TITLE/SET': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.meta ? { ...item, subTitle: action.payload } : item,
      ),
    }),
    '@DISPLAY/PROFILE/ITEM/IMAGE_URL/SET': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.meta ? { ...item, imageUrl: action.payload } : item,
      ),
    }),
    '@DISPLAY/PROFILE/ITEM/NO/SET': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.meta ? { ...item, itemNo: action.payload } : item,
      ),
    }),
    '@DISPLAY/PROFILE/ITEM/STATUS_CODE/SET': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.meta
          ? { ...item, statusCode: action.payload }
          : item,
      ),
    }),
    '@DISPLAY/PROFILE/LIST/CLEAR': state => ({
      ...state,
      data: [],
    }),
    '@DISPLAY/PROFILE/ITEM/CLEAR': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.id === action.payload
          ? {
              ...item,
              title: '',
              subTitle: '',
              imageUrl: '',
              itemNo: '',
              statusCode: '0',
            }
          : item,
      ),
    }),
    '@DISPLAY/PROFILE/LIST/SET': (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
);
