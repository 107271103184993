import { TemplateType } from 'modules/display';
import React, { useMemo, useRef, useState } from 'react';
import { positionValues } from 'react-custom-scrollbars';

import ScrollMotion from './ScrollMotion';

const IMAGE_PATH = `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template`;
// 기본형 템플릿에서 사용하는 이미지 정보. 이미지를 감싸는 <span>의 classname을 key로 사용함.
const ITEMS_COMMON = {
  'template-gnb': {
    src: `${IMAGE_PATH}/template01_01gnb.png`,
    text: 'GNB',
  },
  'template-visual': {
    src: `${IMAGE_PATH}/template01_02visual.png`,
    text: '대표이미지',
  },
  'template-store': {
    src: `${IMAGE_PATH}/template01_03store.png`,
    text: '스토어정보',
  },
  'template-info': {
    src: `${IMAGE_PATH}/template01_04info.png`,
    text: '스토어쇼핑정보',
  },
  'template-search': {
    src: `${IMAGE_PATH}/template01_05search.png`,
    text: '스토어 검색',
  },
  'template-recommend': {
    src: `${IMAGE_PATH}/template01_06recommend.png`,
    text: '추천상품',
  },
  'template-shortcut': {
    src: `${IMAGE_PATH}/template01_07shortcut.png`,
    text: '바로가기',
  },
  'template-promotion': {
    src: `${IMAGE_PATH}/template01_08promotion.png`,
    text: '프로모션',
  },
  'template-cardview': {
    src: `${IMAGE_PATH}/template01_09cardview.png`,
    text: '카드뷰',
  },
  'template-movie': {
    src: `${IMAGE_PATH}/template01_10movie.png`,
    text: '동영상뷰',
  },
  'template-banner': {
    src: `${IMAGE_PATH}/template01_11banner.png`,
    text: '기획전',
  },
  'template-review': {
    src: `${IMAGE_PATH}/template01_13review.png`,
    text: '베스트리뷰',
  },
  'template-product': {
    src: `${IMAGE_PATH}/template01_14product.png`,
    text: '전체상품',
  },
};

// 비주얼 강조형 템플릿은 3개 항목만 제외하면 기본형과 동일함.
// spread로 바로 연결하는 경우 ie지원 모드로 babel 변환이 일어나면 safari에서 순서가 변경되어 버림.
const ITEMS_VISUAL = { ...ITEMS_COMMON };
ITEMS_VISUAL['template-store'] = {
  ...ITEMS_COMMON['template-store'],
  src: `${IMAGE_PATH}/template02_03store.png`,
};
ITEMS_VISUAL['template-promotion'] = {
  ...ITEMS_COMMON['template-promotion'],
  src: `${IMAGE_PATH}/template02_08promotion.png`,
};
ITEMS_VISUAL['template-review'] = {
  ...ITEMS_COMMON['template-review'],
  src: `${IMAGE_PATH}/template02_13review.png`,
};

const TEMPLATES = {
  default: ITEMS_COMMON,
  visual: ITEMS_VISUAL,
};

interface IImageHeight {
  [key: string]: number;
}

interface IProps {
  type: TemplateType;
  target?: string;
  top?: number;
  duration?: number;
  fadeStyle: object;
}

const getTargetElement = (type: TemplateType, target: string) => {
  const selector = `.type_${type} .${target}`;
  const element = document.querySelector(selector) as HTMLElement;

  return element;
};

const TemplateItem = (props: IProps) => {
  const { type, target, top, duration, fadeStyle } = props;
  const barRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<IImageHeight>({});

  const scrollTop = useMemo(() => {
    // target이 우선.
    if (target !== undefined) {
      return target === 'template-store'
        ? 0
        : Math.max(0, getTargetElement(type, target).offsetTop - 40);
    }
    if (top !== undefined) {
      return top;
    }

    return undefined;
  }, [type, target, top]);

  // sync scroll
  const handleScroll = (values: positionValues) => {
    if (barRef.current) {
      barRef.current.scrollTop = values.scrollTop;
    }
  };

  // set images height
  const handleLoadImage = (key: string, image: HTMLImageElement) => {
    setImageHeight(state => ({ ...state, [key]: image.height }));
  };

  return (
    <>
      <ScrollMotion
        onScrollFrame={handleScroll}
        to={scrollTop}
        duration={duration}
      >
        <div className="scroll-wrap" style={fadeStyle}>
          {Object.entries(TEMPLATES[type]).map(([key, item]) => (
            <span key={key} className={key}>
              <img
                src={item.src}
                alt={item.text}
                onLoad={e => handleLoadImage(key, e.target as HTMLImageElement)}
              />
            </span>
          ))}
        </div>
      </ScrollMotion>

      <div ref={barRef} className="bar-wrap">
        {Object.keys(TEMPLATES[type]).map(key => (
          <span
            key={`bar-${key}`}
            className={key}
            style={{
              height: `${imageHeight[key]}px`,
              visibility: target === key ? 'visible' : 'hidden',
            }}
          />
        ))}
      </div>
    </>
  );
};

export default TemplateItem;
