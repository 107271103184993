import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { openDialog } from 'modules/dialog';
import { deleteSpecialItem, postMySpecialListAsync } from 'modules/special';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';

import SpecialTable from './SpecialTable';

const SpecialAddButton = () => {
  const dispatch = useDispatch();
  const dataLength = useSelector(
    (state: RootState) => state.special.data.length,
  );

  return (
    <>
      {dataLength < 5 && (
        <button
          type="button"
          className="btn-style__1 btn-size__6"
          onClick={() => {
            dispatch(openDialog('SelectSpecial'));
          }}
        >
          추가
        </button>
      )}
    </>
  );
};

const SpecialDeleteButton = () => {
  const dispatch = useDispatch();
  const selected = useSelector((state: RootState) => state.special.selected);

  return (
    <button
      type="button"
      className="btn-style__1 btn-size__6"
      onClick={() => {
        if (selected.length > 0) {
          dispatch(deleteSpecialItem());
        } else {
          dispatch(openAlert('PREVIEW_SPECIAL_WITH_EMPTY'));
        }
      }}
    >
      삭제
    </button>
  );
};

const Special = () => (
  <>
    <DocumentTitle title="기획전 - 스토어 전시관리 - 스토어 관리" />
    <div className="view-title title-style__4">
      <h4 className="title">배너 설정</h4>
    </div>
    <div className="write-box type_bg">
      <div className="write-box__row">
        <div className="row_title">
          <em>기획전 선택 </em>
          <i>(최대 5개)</i>
          <p>기획전이 종료되면 스토어 메인에서도 비노출됩니다.</p>
          <div className="side_btn">
            <SpecialAddButton />
            <SpecialDeleteButton />
          </div>
        </div>
        <ToastMessage
          action={getType(postMySpecialListAsync.request)}
          successText="저장되었습니다."
        />
        <div className="row_cont">
          <SpecialTable />
        </div>
      </div>
    </div>
  </>
);

export default Special;
