import { IPostMySpecialListParam } from 'apis/special';
import {
  ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from 'typesafe-actions';

type SpecialItemRequestDisplayType = 'ON_AND_READY_FOR' | 'ON' | 'READY_FOR';
export interface ISpecialItemRequest {
  exhibitionNo: number;
  display?: SpecialItemRequestDisplayType;
}

export interface ISpecialItemResponse {
  exhibitionNo: string;
  exhibitionName: string;
  display: boolean;
  displayBeginDate: string;
  displayEndDate: string;
  bannerImageUrl: string;
  orderSeq: number;
}
export interface ISpecialItem {
  exhibitionNo: string;
  exhibitionName: string;
  display: boolean;
  displayBeginDate: string;
  displayEndDate: string;
  bannerImageUrl: string;
  orderSeq: number;
}

export type SelectedRadioType = 'SELLER' | 'NUMBER';
export interface ISpecial {
  searchResult: ISpecialItemResponse[];
  candidateList: string[];
  selected: string[];
  data: ISpecialItem[];
  addSpecialSelectedRadio: SelectedRadioType;
  exhibitionNumberInput: string;
  exhibitionNumberError: boolean;
}

export const initializeSpecial = createAction('@DISPLAY/SPECIAL/INITIALIZE')();
export const setSpecialList = createAction('@DISPLAY/SPECIAL/LIST/SET')<
  ISpecialItem[]
>();
export const setAddSpecialSelectedRadio = createAction(
  '@DISPLAY/SPECIAL/ADD/SELECTED/RADIO',
)<SelectedRadioType>();
export const addSpecialItem = createAction('@DISPLAY/SPECIAL/ITEM/ADD')<
  ISpecialItem
>();
export const deleteSpecialItem = createAction('@DISPLAY/SPECIAL/ITEM/DELETE')();
export const toggleSpecialItemSelected = createAction(
  '@DISPLAY/SPECIAL/ITEM/TOGGLE_SELECTED',
)<string>();
export const selectSpecialItemAll = createAction(
  '@DISPLAY/SPECIAL/ITEM/SELECT_ALL',
)();
export const setSpecialItemOrder = createAction(
  '@DISPLAY/SPECIAL/ITEM/SET_ORDER',
)<number, string>();

export const getMySpecialListAsync = createAsyncAction(
  '@DISPLAY/SPECIAL/MY_LIST/FETCH',
  '@DISPLAY/SPECIAL/MY_LIST/SUCCESS',
  '@DISPLAY/SPECIAL/MY_LIST/FAILURE',
)<string, ISpecialItemResponse[], Error>();

export const getSpecialListAsync = createAsyncAction(
  '@DISPLAY/SPECIAL/LIST/FETCH',
  '@DISPLAY/SPECIAL/LIST/SUCCESS',
  '@DISPLAY/SPECIAL/LIST/FAILURE',
)<string, ISpecialItemResponse[], Error>();

export const getSpecialByIdAsync = createAsyncAction(
  '@DISPLAY/SPECIAL/ID/FETCH',
  '@DISPLAY/SPECIAL/ID/SUCCESS',
  '@DISPLAY/SPECIAL/ID/FAILURE',
)<ISpecialItemRequest, ISpecialItemResponse[], Error>();

export const setExhibitionNumberInput = createAction(
  '@DISPLAY/SPECIAL/EXHIBITION/NUMBER/INPUT',
)<string>();

export const setExhibitionNumberError = createAction(
  '@DISPLAY/SPECIAL/EXHIBITION/NUMBER/ERROR',
)<boolean>();

export const addSpecialItemFromSearchResult = createAction(
  '@DISPLAY/SPECIAL/ITEM/ADD_FROM_SEARCH_RESULT',
)();

export const setSpecialCandidate = createAction(
  '@DISPLAY/SPECIAL/CANDIDATE/SET',
)<string>();

export const postMySpecialListAsync = createAsyncAction(
  '@DISPLAY/SPECIAL/MY_LIST/POST/FETCH',
  '@DISPLAY/SPECIAL/MY_LIST/POST/SUCCESS',
  '@DISPLAY/SPECIAL/MY_LIST/POST/FAILURE',
)<IPostMySpecialListParam, undefined, Error>();

export const clearSpecialSearchData = createAction(
  '@DISPLAY/SPECIAL/SEARCH_DATA/CLEAR',
)();

const actions = {
  setAddSpecialSelectedRadio,
  addSpecialItem,
  setSpecialList,
  deleteSpecialItem,
  initializeSpecial,
  toggleSpecialItemSelected,
  selectSpecialItemAll,
  setSpecialItemOrder,
  getMySpecialListAsync,
  getSpecialListAsync,
  addSpecialItemFromSearchResult,
  setSpecialCandidate,
  clearSpecialSearchData,
  setExhibitionNumberInput,
  setExhibitionNumberError,
};

const initialState: ISpecial = {
  searchResult: [],
  candidateList: [],
  selected: [],
  data: [],
  addSpecialSelectedRadio: 'SELLER',
  exhibitionNumberInput: '',
  exhibitionNumberError: false,
};

export default createReducer<ISpecial, ActionType<typeof actions>>(
  initialState,
  {
    '@DISPLAY/SPECIAL/INITIALIZE': () => initialState,
    '@DISPLAY/SPECIAL/ADD/SELECTED/RADIO': (state, action) => ({
      ...state,
      addSpecialSelectedRadio: action.payload,
    }),
    '@DISPLAY/SPECIAL/LIST/SET': (state, action) => ({
      ...state,
      data: action.payload,
    }),
    '@DISPLAY/SPECIAL/ITEM/ADD': (state, action) => ({
      ...state,
      data: [...state.data, action.payload],
    }),
    '@DISPLAY/SPECIAL/ITEM/DELETE': state => ({
      ...state,
      data: state.data.filter(
        ({ exhibitionNo }) => !state.selected.includes(exhibitionNo),
      ),
      selected: [],
    }),
    '@DISPLAY/SPECIAL/ITEM/TOGGLE_SELECTED': (state, action) =>
      state.selected.includes(action.payload)
        ? {
            ...state,
            selected: state.selected.filter(id => id !== action.payload),
          }
        : { ...state, selected: [...state.selected, action.payload] },
    '@DISPLAY/SPECIAL/ITEM/SELECT_ALL': state =>
      state.data.length > 0 && state.selected.length === state.data.length
        ? {
            ...state,
            selected: [],
          }
        : {
            ...state,
            selected: state.data.map(({ exhibitionNo }) => exhibitionNo),
          },
    '@DISPLAY/SPECIAL/ITEM/SET_ORDER': (state, action) => ({
      ...state,
      data: state.data.map(item =>
        item.exhibitionNo === action.meta
          ? { ...item, orderSeq: action.payload }
          : item,
      ),
    }),
    '@DISPLAY/SPECIAL/LIST/SUCCESS': (state, action) => ({
      ...state,
      searchResult: action.payload,
    }),
    '@DISPLAY/SPECIAL/LIST/FAILURE': state => ({ ...state, searchResult: [] }),
    '@DISPLAY/SPECIAL/CANDIDATE/SET': (state, action) =>
      state.candidateList.includes(action.payload)
        ? {
            ...state,
            candidateList: state.candidateList.filter(
              item => item !== action.payload,
            ),
          }
        : { ...state, candidateList: [...state.candidateList, action.payload] },
    '@DISPLAY/SPECIAL/SEARCH_DATA/CLEAR': state => ({
      ...state,
      candidateList: [],
      searchResult: [],
    }),
    '@DISPLAY/SPECIAL/EXHIBITION/NUMBER/INPUT': (state, { payload }) => ({
      ...state,
      exhibitionNumberInput: payload,
    }),
    '@DISPLAY/SPECIAL/EXHIBITION/NUMBER/ERROR': (state, { payload }) => ({
      ...state,
      exhibitionNumberError: payload,
    }),
  },
);
