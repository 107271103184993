import { CheckBox, TextInput } from 'components/common';
import { CheckBoxType } from 'components/common/CheckBox';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { setTouched } from 'modules/display';
import {
  getMySpecialListAsync,
  setSpecialItemOrder,
  toggleSpecialItemSelected,
} from 'modules/special';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const SpecialItemCheckBox = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const isSelected = useSelector((state: RootState) =>
    state.special.selected.includes(id),
  );

  return (
    <CheckBox
      id={`checkSpecial${id}`}
      name="checkSpecial"
      type={CheckBoxType.basic}
      label={`전시순서${id}`}
      labelHidden
      checked={isSelected}
      onChange={() => {
        dispatch(toggleSpecialItemSelected(id));
        dispatch(setTouched(true));
      }}
    />
  );
};

const SpecialItemNumberInput = ({
  id,
  orderSeq,
}: {
  id: string;
  orderSeq: number;
}) => {
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.floor(Number(e.target.value));
    if (value > 100) {
      dispatch(openAlert('IS_OVER_100_SPECIAL_ITEM_ORDER'));
      dispatch(setSpecialItemOrder(100, id));
    } else if (value < 0) {
      dispatch(openAlert('IS_NEGATIVE_SPECIAL_ITEM_ORDER'));
      dispatch(setSpecialItemOrder(0, id));
    } else {
      dispatch(setSpecialItemOrder(value, id));
    }
    dispatch(setTouched(true));
  };

  return (
    <TextInput
      type="number"
      value={orderSeq.toString()}
      title={`전시순서_${id}`}
      width="size__2"
      onChange={handleChange}
    />
  );
};

const SpecialTableBody = () => {
  const data = useSelector((state: RootState) => state.special.data);
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const selected = useSelector((state: RootState) => state.special.selected);

  useEffect(() => {
    if (storeNo) {
      dispatch(getMySpecialListAsync.request(storeNo));
    }
  }, [dispatch, storeNo]);

  return (
    <tbody>
      {data.length > 0 ? (
        <>
          {data.map(
            ({
              exhibitionNo,
              exhibitionName,
              display,
              displayBeginDate,
              displayEndDate,
              orderSeq,
            }) => (
              <tr
                key={exhibitionNo}
                className={selected.includes(exhibitionNo) ? 'active' : ''}
              >
                <td>
                  <SpecialItemCheckBox id={exhibitionNo} />
                </td>
                <td>
                  <SpecialItemNumberInput
                    id={exhibitionNo}
                    orderSeq={orderSeq}
                  />
                </td>
                <td>
                  <span className="text-num">{exhibitionNo}</span>
                </td>
                <td>
                  <button
                    type="button"
                    className="text-title"
                    onClick={() =>
                      window.open(
                        `//www.11st.co.kr/browsing/MallPlanDetail.tmall?method=getMallPlanDetail&isPlan=Y&planDisplayNumber=${exhibitionNo}`,
                        '_blank',
                      )
                    }
                  >
                    {exhibitionName}
                  </button>
                </td>
                <td>{display ? '진행중' : '진행예정'}</td>
                <td>
                  <span className="text-date">{displayBeginDate}</span>
                </td>
                <td>
                  <span className="text-date">{displayEndDate}</span>
                </td>
              </tr>
            ),
          )}
        </>
      ) : (
        <tr>
          <td colSpan={7} className="non-data">
            기획전이 없습니다.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default SpecialTableBody;
