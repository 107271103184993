import {
  IApplyCategoryParam,
  IBasicCategoryParam,
  IBasicMiddleCategoryParam,
  IBasicSmallCategoryParam,
  ICustomCategoryDetailParam,
  ICustomCategoryParam,
  IDeleteCustomCategoryParam,
  IDeleteCustomCategoryResponse,
  IPostCustomCategoryParam,
  IPostCustomCategoryResponse,
  IPutCustomCategoryParam,
  IPutCustomCategoryResponse,
} from 'apis/category';
import { ICategoryListItem } from 'components/pages/category/CategoryList';
import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  CategoryItemIdType,
  CustomDetailModifiedType,
  IBasicCategoryItem,
  ICategoryDetail,
  ICustomCategoryItem,
  ICustomDetailModified,
} from './types';

export const setIsUsingCustom = createAction('@CATEGORY/IS_USING_CUSTOM')<
  boolean
>();
export const setIsModeAutoSwitched = createAction(
  '@CATEGORY/IS/MODE/AUTO/SWITCHED',
)<boolean>();

export const getBasicLargeCategoryAsync = createAsyncAction(
  '@CATEGORY/BASIC/LARGE/FETCH',
  '@CATEGORY/BASIC/LARGE/SUCCESS',
  '@CATEGORY/BASIC/LARGE/FAILURE',
)<IBasicCategoryParam, IBasicCategoryItem[], Error>();

export const getBasicMiddleCategoryAsync = createAsyncAction(
  '@CATEGORY/BASIC/MIDDLE/FETCH',
  '@CATEGORY/BASIC/MIDDLE/SUCCESS',
  '@CATEGORY/BASIC/MIDDLE/FAILURE',
)<IBasicMiddleCategoryParam, IBasicCategoryItem[], Error>();

export const getBasicSmallCategoryAsync = createAsyncAction(
  '@CATEGORY/BASIC/SMALL/FETCH',
  '@CATEGORY/BASIC/SMALL/SUCCESS',
  '@CATEGORY/BASIC/SMALL/FAILURE',
)<IBasicSmallCategoryParam, IBasicCategoryItem[], Error>();

export const setSelectedCustomId = createAction('@CATEGORY/CUSTOM/SELECTED/ID')<
  CategoryItemIdType
>();

export const resetSelectedCustomId = createAction(
  '@CATEGORY/CUSTOM/SELECTED/ID/RESET',
)<undefined>();

export const getCustomCategoryAsync = createAsyncAction(
  '@CATEGORY/CUSTOM/FETCH',
  '@CATEGORY/CUSTOM/SUCCESS',
  '@CATEGORY/CUSTOM/FAILURE',
)<ICustomCategoryParam, ICustomCategoryItem[], Error>();

export const setCustomCategory = createAction('@CATEGORY/CUSTOM/SUCCESS')<
  ICustomCategoryItem[]
>();
export const setCustomItemTemp = createAction('@CATEGORY/CUSTOM/ITEM/TEMP')<
  ICustomCategoryItem
>();
export const setShouldCategorySave = createAction(
  '@CATEGORY/CUSTOM/SHOULD/SAVE',
)<boolean>();
export const setCustomCategoryOrder = createAction('@CATEGORY/CUSTOM/ORDER')<
  ICategoryListItem[]
>();

export const setSelectedCustomCategory = createAction(
  '@CATEGORY/CUSTOM/SELECTED',
)<ICategoryDetail[]>();

export const deleteSelectedCustomCategory = createAction(
  '@CATEGORY/CUSTOM/DELETE/SELECTED',
)<undefined>();

export const deleteSelectedCustomCategoryAsync = createAsyncAction(
  '@CATEGORY/CUSTOM/DELETE/SELECTED/FETCH',
  '@CATEGORY/CUSTOM/DELETE/SELECTED/SUCCESS',
  '@CATEGORY/CUSTOM/DELETE/SELECTED/FAILURE',
)<IDeleteCustomCategoryParam, IDeleteCustomCategoryResponse[], Error>();

export const resetCustomDetail = createAction('@CATEGORY/CUSTOM/DETAIL/RESET')<
  undefined
>();

export const setCustomDetail = createAction('@CATEGORY/CUSTOM/DETAIL')<
  ICategoryDetail[]
>();

export const addCustomDetailModified = createAction(
  '@CATEGORY/CUSTOM/DETAIL/MODIFIED/ADD',
)<ICustomDetailModified[]>();

export const setCustomDetailModified = createAction(
  '@CATEGORY/CUSTOM/DETAIL/MODIFIED/SET',
)<CustomDetailModifiedType>();

export const resetCustomDetailModified = createAction(
  '@CATEGORY/CUSTOM/DETAIL/MODIFIED/RESET',
)<undefined>();

export const setCustomDetailModifiedInModal = createAction(
  '@CATEGORY/CUSTOM/DETAIL/MODIFIED/MODAL',
)<CustomDetailModifiedType>();

export const getCustomCategoryDetailAsync = createAsyncAction(
  '@CATEGORY/CUSTOM/DETAIL/FETCH',
  '@CATEGORY/CUSTOM/DETAIL/SUCCESS',
  '@CATEGORY/CUSTOM/DETAIL/FAILURE',
)<ICustomCategoryDetailParam, ICategoryDetail[], Error>();

export const postCustomCategoryAsync = createAsyncAction(
  '@CATEGORY/CUSTOM/POST/FETCH',
  '@CATEGORY/CUSTOM/POST/SUCCESS',
  '@CATEGORY/CUSTOM/POST/FAILURE',
)<IPostCustomCategoryParam, IPostCustomCategoryResponse[], Error>();

export const putCustomCategoryAsync = createAsyncAction(
  '@CATEGORY/CUSTOM/PUT/FETCH',
  '@CATEGORY/CUSTOM/PUT/SUCCESS',
  '@CATEGORY/CUSTOM/PUT/FAILURE',
)<IPutCustomCategoryParam, IPutCustomCategoryResponse[], Error>();

export const applyCustomCategoryAsync = createAsyncAction(
  '@CATEGORY/APPLY/CUSTOM/PUT/FETCH',
  '@CATEGORY/APPLY/CUSTOM/PUT/SUCCESS',
  '@CATEGORY/APPLY/CUSTOM/PUT/FAILURE',
)<IApplyCategoryParam, undefined, Error>();

export const applyBasicCategoryAsync = createAsyncAction(
  '@CATEGORY/APPLY/BASIC/PUT/FETCH',
  '@CATEGORY/APPLY/BASIC/PUT/SUCCESS',
  '@CATEGORY/APPLY/BASIC/PUT/FAILURE',
)<IApplyCategoryParam, undefined, Error>();

export const saveCategoryOrderAsync = createAsyncAction(
  '@CATEGORY/CATEGORY/ORDER/PUT/FETCH',
  '@CATEGORY/CATEGORY/ORDER/PUT/SUCCESS',
  '@CATEGORY/CATEGORY/ORDER/PUT/FAILURE',
)<IApplyCategoryParam, undefined, Error>();

export const setShouldStoreApply = createAction('@CATEGORY/SHOULD/STORE/APPLY')<
  boolean
>();
export const setIsShowDoneToast = createAction('@CATEGORY/STORE/APPLY/CLICKED')<
  boolean
>();
export const setConfirmCategoryDelete = createAction(
  '@CATEGORY/CONFIRM/DELETE',
)<boolean>();
