import { ToastMessage } from 'components/common';
import DocumentTitle from 'components/common/DocumentTitle';
import { RootState } from 'modules';
import { menuIdType } from 'modules/display';
import {
  getRecommend2InfoAsync,
  getRecommend3InfoAsync,
  getRecommend4InfoAsync,
  getRecommendInfoAsync,
  postRecommendInfoAsync,
  putRecommendInfoAsync,
  RecommendMenuIdType,
} from 'modules/recommend';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';

import DataPanel from './DataPanel';
import RecommendTypeRadioGroup from './RecommendTypeRadioGroup';

interface IProps {
  id: RecommendMenuIdType;
}

const Recommend = ({ id }: IProps) => {
  const dispatch = useDispatch();
  const storeNo = useSelector(
    (state: RootState) => state.user.headerInfo.storeNo,
  );
  const curationModuleNo = useSelector(
    (state: RootState) => state.recommend[id].curationModuleNo,
  );

  useEffect(() => {
    if (storeNo) {
      dispatch(getRecommendInfoAsync.request({ storeNo, moduleNo: id }));
    }

    return () => {
      if (storeNo) {
        if (id === menuIdType.recommend1)
          dispatch(getRecommend2InfoAsync.request({ storeNo, moduleNo: id }));
        else if (id === menuIdType.recommend2)
          dispatch(getRecommend3InfoAsync.request({ storeNo, moduleNo: id }));
        else if (id === menuIdType.recommend3)
          dispatch(getRecommend4InfoAsync.request({ storeNo, moduleNo: id }));
      }
    };
  }, [dispatch, id, storeNo]);

  return (
    <>
      <DocumentTitle title="셀러추천상품 - 스토어 전시관리 - 스토어 관리" />
      <div className="write-box">
        <dl className="write-box__row inline-type__2">
          <dt className="row_title">타입 선택</dt>
          <dt className="row_cont">
            <RecommendTypeRadioGroup id={id} />
          </dt>
        </dl>
      </div>
      <DataPanel id={id} />
      <ToastMessage
        action={getType(
          curationModuleNo
            ? putRecommendInfoAsync.request
            : postRecommendInfoAsync.request,
        )}
        successText="저장되었습니다."
      />
    </>
  );
};

export default Recommend;
