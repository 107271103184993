import React from 'react';

interface IProps {
  onClick: () => void;
}

const TemplateCompareButton = (props: IProps) => {
  const { onClick } = props;

  return (
    <div className="btn">
      <button
        type="button"
        className="btn-style__1 btn-size__4"
        onClick={onClick}
      >
        <span className="icon icon-play" />
        템플릿 비교하기
      </button>
    </div>
  );
};

export default TemplateCompareButton;
