import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  get11TalkAgreementsAsync,
  getHeaderInfoAsync,
  getPageViewCountsAsync,
  getRoadShopListAsync,
  getTagsAsync,
  getUserBasicInfoAsync,
  setAuthentication,
  setBasicInfoAddr,
  setBasicInfoAddrDetail,
  setElevenStreetDomainUrl,
  setHasOfflineShopAddress,
  setHasPrivateDomainIPError,
  setInstagramAddress,
  setIsPrivateDomainAlreadyUsed,
  setIsStoreCategorySet,
  setIsStoreUrlLocked,
  setIsUsingPrivateDomain,
  setOfflineShopSalesTime,
  setPrivateDomain,
  setPrivateDomainOk,
  setSelectedRoadShops,
  setSelectedTags,
  setStoreDescription,
  setStoreImage,
  setStoreImageTemp,
  setStoreLocationImage,
  setStoreName,
  setStoreNameInput,
  setStorePersonalDomainUrl,
} from './actions';
import { IOfflineShopInfo, IUser, UserAction } from './types';

export const initialState: IUser = {
  authentication: true,
  headerInfo: {
    storeNo: '',
    sellerNo: '',
    encSellerNo: '',
    storeName: '',
    storeID: '',
    visitCount: 0,
    repImageURL: '',
    sellerInstaID: '',
    templateTypeCode: '',
    colorType: '',
    moduleInfoList: [],
    displayStoreTemplateTab: false,
    isRoadShop: false,
    isStoreCategorySet: false,
  },
  basicInfo: {
    details: {},
    isStoreUrlLocked: false,
    isUsingPrivateDomain: false,
    privateDomain: '',
    privateDomainOk: false,
    hasPrivateDomainError: false,
    isPrivateDomainAlreadyUsed: false,
    isRoadShop: false,
    addr: {
      roadZipType: '',
      engTypeYn: '',
      mailNo: '',
      mailNoSeq: '',
      zipAddrNm: '',
      buildMngNo: '',
      roadAddrNm: '',
      sparam: '',
      jejuYn: '',
      islandYn: '',
      areaNo: '',
    },
    addrDetail: '',
    locationImageUrl: '',
  },
  roadShops: [],
  selectedRoadShops: [],
  tags: [],
  selectedTags: [],
  viewCounts: [],
  talk11: {
    used: false,
    agreement: false,
  },
};

export default (state: IUser = initialState, action: UserAction) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(setAuthentication):
        draft.authentication = action.payload;
        break;
      case getType(getHeaderInfoAsync.success): {
        const [data] = action.payload;
        draft.headerInfo = { ...draft.headerInfo, ...data };
        break;
      }
      case getType(getUserBasicInfoAsync.success): {
        const [data] = action.payload;
        draft.basicInfo.details = data;
        draft.headerInfo.storeName = data.storeName ?? '';
        draft.headerInfo.repImageURL = data.profileImageUrl ?? '';
        draft.basicInfo.isStoreUrlLocked = !!data.elevenStreetDomainUrl;
        draft.basicInfo.isUsingPrivateDomain = !!data.storePersonalDomainUrl;
        draft.basicInfo.privateDomain = data.storePersonalDomainUrl ?? '';
        if (data.offlineShopInfo) {
          const {
            roadName,
            sidoName,
            sigunguBuildingName,
            sigunguName,
            lawDongName,
            buildingMainNo,
            locationInfoImageUrl,
          } = data.offlineShopInfo;
          draft.basicInfo.locationImageUrl = locationInfoImageUrl ?? '';
          draft.basicInfo.addr.roadAddrNm = `${sidoName ?? ''} ${sigunguName ??
            ''} ${roadName ?? ''} ${buildingMainNo ?? ''} ${lawDongName ??
            ''} ${sigunguBuildingName ?? ''}`;
          draft.basicInfo.addrDetail =
            data.offlineShopInfo?.storeDetailAddress ?? '';
          draft.basicInfo.addr.buildMngNo =
            data.offlineShopInfo?.buildingManageNo ?? '';
        } else {
          draft.basicInfo.details.offlineShopInfo = {} as IOfflineShopInfo;
        }
        const fetchedTagNo = data?.roadShopGroupNo;
        draft.selectedRoadShops = [
          {
            tagNo: String(fetchedTagNo),
            tagName:
              state.roadShops.find(
                ({ tagNo }) => tagNo === String(fetchedTagNo),
              )?.tagName ?? '',
          },
        ];
        draft.selectedTags = data?.styleTagList ?? [];

        break;
      }
      case getType(setStoreNameInput): {
        draft.basicInfo.details.storeName = action.payload;
        break;
      }
      case getType(setStoreName): {
        draft.headerInfo.storeName = action.payload;
        break;
      }
      case getType(setStoreImage): {
        draft.headerInfo.repImageURL = action.payload;
        break;
      }
      case getType(setStoreImageTemp): {
        draft.basicInfo.details.profileImageUrl = action.payload;
        break;
      }
      case getType(setIsStoreUrlLocked): {
        draft.basicInfo.isStoreUrlLocked = action.payload;
        break;
      }
      case getType(setIsUsingPrivateDomain): {
        draft.basicInfo.isUsingPrivateDomain = action.payload;
        break;
      }
      case getType(setStorePersonalDomainUrl): {
        draft.basicInfo.details.storePersonalDomainUrl = action.payload;
        break;
      }
      case getType(setPrivateDomain): {
        draft.basicInfo.privateDomain = action.payload;
        break;
      }
      case getType(setPrivateDomainOk): {
        draft.basicInfo.privateDomainOk = action.payload;
        break;
      }
      case getType(setHasPrivateDomainIPError): {
        draft.basicInfo.hasPrivateDomainError = action.payload;
        break;
      }
      case getType(setIsPrivateDomainAlreadyUsed): {
        draft.basicInfo.isPrivateDomainAlreadyUsed = action.payload;
        break;
      }
      case getType(setStoreDescription): {
        draft.basicInfo.details.introduceContents = action.payload;
        break;
      }
      case getType(setStoreLocationImage):
        if (draft.basicInfo.details.offlineShopInfo) {
          draft.basicInfo.details.offlineShopInfo.locationInfoImageUrl =
            action.payload;
        }
        break;
      case getType(setInstagramAddress):
        if (draft.basicInfo.details) {
          draft.basicInfo.details.instagramAddress = action.payload;
        }
        break;
      case getType(setElevenStreetDomainUrl):
        if (draft.basicInfo.details) {
          draft.basicInfo.details.elevenStreetDomainUrl = action.payload;
        }
        break;
      case getType(setBasicInfoAddr):
        draft.basicInfo.addr = action.payload;
        break;
      case getType(setBasicInfoAddrDetail):
        draft.basicInfo.addrDetail = action.payload;
        break;
      case getType(getRoadShopListAsync.success):
        draft.roadShops = action.payload;
        break;
      case getType(setSelectedRoadShops):
        draft.selectedRoadShops = action.payload;
        break;
      case getType(setHasOfflineShopAddress):
        if (draft.basicInfo.details) {
          draft.basicInfo.details.hasOfflineShopAddress = action.payload;
        }
        break;
      case getType(setOfflineShopSalesTime):
        if (draft.basicInfo.details.offlineShopInfo) {
          draft.basicInfo.details.offlineShopInfo = {
            ...draft.basicInfo.details.offlineShopInfo,
            ...action.payload,
          };
        }
        break;
      case getType(getTagsAsync.success):
        draft.tags = action.payload;
        break;
      case getType(setSelectedTags):
        draft.selectedTags = action.payload;
        break;
      case getType(getPageViewCountsAsync.success):
        draft.viewCounts = action.payload;
        break;
      case getType(get11TalkAgreementsAsync.success):
        draft.talk11 = action.payload;
        break;
      case getType(setIsStoreCategorySet):
        draft.headerInfo.isStoreCategorySet = action.payload;
        break;
    }
  });
