import Radio, { RadioType } from 'components/common/Radio';
import { RootState } from 'modules';
import { openAlert } from 'modules/alert';
import { setTouched } from 'modules/display';
import { setProfileType } from 'modules/profile';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const DATA = [
  {
    value: 'SQUARE',
    label: '정사각형 (750x750)',
    previewUrl: `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template02_02visual.png`,
    style: 'item-style__1',
  },
  {
    value: 'RECTANGLE',
    label: '직사각형 (750x500)',
    previewUrl: `${process.env.REACT_APP_STATIC_PATH}/img/store/content/display_template/template01_02visual.png`,
    style: 'item-style__2',
  },
];

const ProfileTypeRadioGroup = () => {
  const type = useSelector((state: RootState) => state.profile.type);
  const data = useSelector((state: RootState) => state.profile.data);
  const dispatch = useDispatch();

  const hasContents = useMemo(
    () =>
      data.some(
        item => item.imageUrl || item.title || item.subTitle || item.itemNo,
      ),
    [data],
  );

  return (
    <ul className="view-item__select">
      {DATA.map(({ value, label, previewUrl, style }) => (
        <li className={`item ${style}`} key={value}>
          <Radio
            type={RadioType.basic}
            value={value}
            label={label}
            name="ProfileType"
            checked={value === type}
            onChange={() => {
              if (hasContents) {
                dispatch(openAlert('CHANGE_PROFILE_TYPE_WITH_DATA'));
              }
              dispatch(setProfileType(value));
              dispatch(setTouched(true));
            }}
          />
          <div className="item_cont">
            <img src={`${previewUrl}`} alt={`대표이미지-${label}`} />
          </div>
        </li>
      ))}
    </ul>
  );
};

export default React.memo(ProfileTypeRadioGroup);
