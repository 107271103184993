import classNames from 'classnames';
import { RootState } from 'modules';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isSamePageId, setPageId } from 'utils/log';

import Alert from './Alert';
import Dialog from './Dialog';
import Footer from './Footer';
import Header from './Header';

interface IDefaultLayout {
  children?: React.ReactNode;
}

const isCategoryBasic = (pathname: string) => pathname === '/category';
const isCategoryEdit = (pathname: string) => pathname === '/category/edit';
const isCategoryDone = (pathname: string) => pathname === '/category/done';

const DefaultLayout = ({ children }: IDefaultLayout) => {
  const { pathname } = useLocation();
  const sortable = useSelector((state: RootState) => state.display.sortable);
  const auth = useSelector((state: RootState) => state.user.authentication);
  const hasFixedButton = useCallback(
    () =>
      [
        'display/template',
        '/display/skin',
        '/category',
        '/category/edit',
        '/category/done',
        '/basicinfo',
      ].includes(pathname) || sortable,
    [pathname, sortable],
  );

  useEffect(() => {
    if (pathname.includes('basicinfo')) {
      if (!isSamePageId('/seller_office/store/information')) {
        setPageId('/seller_office/store/information');
      }
    } else if (pathname.includes('category')) {
      if (!isSamePageId('/seller_office/store/category')) {
        setPageId('/seller_office/store/category');
      }
    } else if (pathname.includes('display')) {
      if (!isSamePageId('/seller_office/store/exhibition_management')) {
        setPageId('/seller_office/store/exhibition_management');
      }
    } else if (pathname === '/promotion') {
      if (!isSamePageId('/seller_office/store/promotion_list')) {
        setPageId('/seller_office/store/promotion_list');
      }
    } else if (pathname === '/notice') {
      if (!isSamePageId('/seller_office/store/notice_list')) {
        setPageId('/seller_office/store/notice_list');
      }
    }
  }, [pathname]);

  return (
    <>
      {auth && (
        <>
          <div
            className={classNames('st-wrap', {
              add_area: hasFixedButton(),
              cate_default: isCategoryBasic(pathname),
              cate_step2: isCategoryEdit(pathname),
              cate_step3: isCategoryDone(pathname),
            })}
          >
            <div className="skip_nav">
              <p>
                <a href="#soContent">본문 바로가기</a>
              </p>
            </div>
            <Header />
            <section id="st-content" className="st-content">
              {children}
            </section>
            <Footer />
          </div>
          <Dialog />
          <Alert />
        </>
      )}
    </>
  );
};

export default DefaultLayout;
