import { AlertType } from 'modules/alert';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import AlertBottom from './AlertBottom';
import alertMapper from './alertMapper';

const appRoot = document.getElementById('root');
const el = document.createElement('div');
el.className = 'alert-bg';
el.style.display = 'block';

interface ILocalAlert {
  bodyType: AlertType;
  confirmText: string;
  onClickConfirm?: () => void;
  cancelText: string;
  onClickCancel?: () => void;
  children?: React.ReactNode;
}
const LocalAlert = ({
  bodyType,
  confirmText = '',
  onClickConfirm = () => {},
  cancelText = '',
  onClickCancel = () => {},
  children,
}: ILocalAlert) => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    appRoot?.appendChild?.(el);

    return () => {
      // eslint-disable-next-line no-unused-expressions
      appRoot?.removeChild?.(el);
    };
  });

  return ReactDOM.createPortal(
    <div
      role="dialog"
      id="alert"
      aria-labelledby="alert_label"
      aria-hidden="true"
      aria-modal="true"
      className="alert"
      style={{ display: 'block' }}
    >
      {children ?? (
        <>
          <div className="alert-body">
            <p className="c-alert">{alertMapper[bodyType].body}</p>
          </div>
          <div className="alert-bottom">
            <p className="c-alert">
              <AlertBottom
                confirmText={confirmText}
                confirmAction={onClickConfirm}
                cancelText={cancelText}
                cancelAction={onClickCancel}
              />
            </p>
          </div>
        </>
      )}
    </div>,
    el,
  );
};

export default LocalAlert;
