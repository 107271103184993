import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

import alertMapper from './alertMapper';

const Alert = () => {
  const show = useSelector((state: RootState) => state.alert.show);
  const type = useSelector((state: RootState) => state.alert.type);

  return show ? (
    <div className="alert-bg" style={{ display: 'block' }}>
      <div
        role="dialog"
        id="alert"
        aria-labelledby="alert_label"
        aria-hidden="true"
        aria-modal="true"
        className="alert"
        style={{ display: 'block' }}
      >
        {type && (
          <>
            <div className="alert-body">
              <p className="c-alert">{alertMapper[type].body}</p>
            </div>
            <div className="alert-bottom">
              <p className="c-alert">{alertMapper[type].bottom}</p>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default Alert;
