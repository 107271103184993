import { AlertType } from 'modules/alert';
import React from 'react';

import ChangeMenuEnableBody from './AlertBody/ChangeMenuEnable';
import SaveMenuWithDisableBody from './AlertBody/SaveMenuWithDisable';
import SaveProfileWithInValidStatusCode from './AlertBody/SaveProfileWithInValidStatusCode';
import AlertBottom from './AlertBottom';
import ChangeMenuEnableBottom from './AlertBottom/ChangeMenuEnable';
import DeleteCategoryWarning from './AlertBottom/DeleteCategoryWarning';
import MoveTabWithoutSaving from './AlertBottom/MoveTabWithoutSaving';
import SaveMenuWithDisableBottom from './AlertBottom/SaveMenuWithDisable';
import WarningCategoryTypeChange from './AlertBottom/WarningCategoryTypeChange';

type IAlertMapper = {
  [key in AlertType]: { body: React.ReactNode; bottom: React.ReactNode };
};

const alertMapper: IAlertMapper = {
  MOVE_MENU_WITHOUT_SAVING: {
    body: (
      <>
        화면 이동 시, 수정된 내용이 반영되지 않습니다.
        <br />
        계속 하시겠습니까?
      </>
    ),
    bottom: <AlertBottom confirmText="사용함" cancelText="사용안함" />,
  },
  SELECT_ENABLE_MENU: {
    body: <ChangeMenuEnableBody enable />,
    bottom: <ChangeMenuEnableBottom enable />,
  },
  SELECT_DISABLE_MENU: {
    body: <ChangeMenuEnableBody enable={false} />,
    bottom: <ChangeMenuEnableBottom enable={false} />,
  },
  SAVE_MENU_WITH_DISABLE: {
    body: <SaveMenuWithDisableBody />,
    bottom: <SaveMenuWithDisableBottom />,
  },
  INPUT_CATEGORY_NAME: {
    body: '카테고리명을 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  INPUT_CATEGORY_NAME_LENGTH: {
    body: '카테고리명은 1자 이상 8자 이내로 입력해주세요',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_CATEGORY_WITHOUT_CONNECTING: {
    body: '카테고리 또는 상품을 1개 이상 연결해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  REGISTER_OVER_50_CATEGORIES: {
    body: '카테고리는 50개까지 등록이 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  DELETE_CATEGORY_MUST_CUSTOM_CATEGORY_MORE_4: {
    body: `스토어카테고리는 4개 이상시에만 사용할 수 있습니다.
    카테고리 삭제 시 기본카테고리 사용으로 변경됩니다`,
    bottom: <WarningCategoryTypeChange />,
  },
  DELETE_CATEGORY_WARNING: {
    body: '카테고리 삭제 시, 연결된 내용이 모두 사라집니다. 계속 하시겠습니까?',
    bottom: <DeleteCategoryWarning />,
  },
  DELETE_AFTER_SELECT_CATEGORY: {
    body: '카테고리를 선택하신 후 삭제 버튼을 선택해 주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  DELETE_AFTER_SELECT_PRODUCT: {
    body: '상품 선택하신 후 삭제 버튼을 선택해 주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  WILL_UNCHECK_ON_MOVE_PAGE: {
    body: '페이지 이동 시, 체크 상태가 해제됩니다. 계속하시겠습니까?',
    bottom: null,
  },
  CATEGORY_TOUCHED_WITHOUT_ORDER_SAVE: {
    body: (
      <>
        화면 이동 시, 수정된 카테고리 순서가 반영되지 않습니다.
        <br />
        계속하시겠습니까?
      </>
    ),
    bottom: null,
  },
  CATEGORY_TOUCHED_WITHOUT_SAVE: {
    body: (
      <>
        화면 이동 시, 수정된 내용이 반영되지 않습니다.
        <br />
        계속하시겠습니까?
      </>
    ),
    bottom: null,
  },
  CATEGORY_TOUCHED_WILL_APPLY_BASIC_CATEGORY: {
    body: (
      <>
        화면 이동 시, 기본카테고리로 노출됩니다.
        <br />
        계속하시겠습니까?
      </>
    ),
    bottom: null,
  },
  CATEGORY_TOUCHED_APPLY_WITHOUT_SAVE: {
    body: (
      <>
        저장되지 않은 수정된 내용이 있습니다.
        <br />
        계속하시겠습니까?
      </>
    ),
    bottom: null,
  },
  CHANGE_PROFILE_TYPE_WITH_DATA: {
    body:
      '이미 등록된 컨텐츠가 있습니다. 미리보기로 이미지/텍스트가 제대로 노출되는지 확인해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  IS_INVALID_PROFILE_IMAGE_FILE_SIZE: {
    body: '250KB 이하의 이미지만 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  IS_INVALID_PROFILE_IMAGE_EXTENSION: {
    body: '파일 확장자를 확인해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  IS_INVALID_STORE_IMAGE_EXTENSION: {
    body: 'jpg, jpeg, png 파일만 등록가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  IS_INVALID_PROFILE_IMAGE_WIDTH_AND_HEIGHT: {
    body: '이미지 등록 권장 사이즈를 확인해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  IS_OVER_100_SPECIAL_ITEM_ORDER: {
    body: '전시 순서는 최고 100까지 설정 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  IS_NEGATIVE_SPECIAL_ITEM_ORDER: {
    body: '전시 순서는 음수가 될 수 없습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_SPECIAL_WITH_EMPTY: {
    body: '기획전을 선택 후 저장해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  PREVIEW_SPECIAL_WITH_EMPTY: {
    body: '기획전을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROMOTION_WITH_INVALID_DATE: {
    body: '프로모션 기간을 다시 확인해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_SPECIAL_OVER_5_ITEMS: {
    body: '기획전은 5개까지 등록 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROMOTION_OVER_100_ITEMS: {
    body: '프로모션 상품은 100개까지 등록 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROFILE_WITH_EMPTY_IMAGE: {
    body: '대표 이미지를 등록해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  ILLEGAL_INPUT_OF_SPECIAL_CHARACTERS: {
    body:
      '입력가능한 특수문자는 ! @ # $ % ^ & * ( ) [ ] - _ + : ; ~ \' " , . | ☆ ★ ♡ ♥ 입니다',
    bottom: <AlertBottom confirmText="확인" />,
  },
  ILLEGAL_INPUT_OF_PROMOTION_CHARACTERS: {
    body: '입력가능한 특수문자는 # _ / ! * % 입니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SPECIAL_CHARACTERS_ONLY_UNDERBAR: {
    body: '명은 3~25자 이내로 등록가능하며 특수문자는 _만 사용가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_RECOMMEND_OVER_3_ITEMS: {
    body: '카드뷰 컬렉션 상품은 3개까지 등록 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_RECOMMEND_OVER_6_ITEMS: {
    body: '동영상 컬렉션 상품은 6개까지 등록 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SPECIAL_CHARACTERS_ONLY_UNDERBAR_DOT: {
    body: '특수문자는 _ . 만 입력 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  PRIVATE_DOMAIN_OK: {
    body:
      '스토어 주소 설정이 완료되었습니다. 실제 적용까지 최대 2~3일 소요될 수 있습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  PRIVATE_DOMAIN_CATCH: {
    body: '입력하신 도메인 주소를 다시 확인해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  NEED_PRIVATE_DOMAIN_CONFIRM: {
    body: '도메인 입력 후 변경 버튼을 눌러주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  INPUT_OFFLINE_INFO: {
    body: '오프라인 매장주소, 영업시간 정보를 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  MOVE_MENU_WITHOUT_SAVING_DATA: {
    body: (
      <>
        화면 이동 시, 수정된 내용이 반영되지 않습니다.
        <br />
        계속 하시겠습니까?
      </>
    ),
    bottom: <MoveTabWithoutSaving />,
  },
  STORE_NAME_OK: {
    body: '스토어명이 수정되었습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_NAME_ALREADY_USED: {
    body: '이미 등록된 스토어명입니다. 다른 스토어명을 사용해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_NAME_NO_SELLER_ID: {
    body: '셀러 ID와 동일한 스토어명은 사용하실 수 없습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_NAME_INVALID_LENGTH: {
    body: '스토어명은 3~25자 이내로 등록 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_NAME_NOT_CONFIRMED: {
    body: '스토어명이 변경되지 않았습니다. 이대로 저장하시겠습니까?',
    bottom: <AlertBottom confirmText="확인" cancelText="취소" />,
  },
  STORE_NAME_SPECIAL_CHAR: {
    body: '특수문자는 _ 만 사용가능합니다',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_NAME_NOT_EXIST: {
    body: '스토어명이 조회되지 않습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_OK: {
    body: '스토어 주소가 변경되었습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_NO_SELLER_ID: {
    body: '셀러 id는 주소로 사용하실 수 없습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_ALREADY_SET: {
    body: '이미 스토어 주소를 설정하셨습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_INVALID: {
    body: '스토어 주소는 영문, 영문과 숫자 조합으로 만들어주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_ALREADY_USED: {
    body: '이미 등록된 주소입니다. 다른 주소를 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_INVALID_LENGTH: {
    body: '스토어 주소는 6자~25자 내로 등록 가능합니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  STORE_URL_SWITCH_PUBLIC: {
    body: '11번가 도메인 사용으로 설정을 변경하시겠습니까?',
    bottom: null,
  },
  SAVE_OK: {
    body: '저장되었습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_CATCH: {
    body: '저장 실패하였습니다. 다시 시도해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_RECOMMEND_WITHOUT_PRODUCT_DATA: {
    body: '셀러추천에 노출할 상품을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_RECOMMEND_WITHOUT_PROMOTION_NO: {
    body: '셀러추천에 노출할 프로모션을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_RECOMMEND_WITHOUT_SUBTITLE: {
    body: '소제목을 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_RECOMMEND_WITHOUT_TITLE: {
    body: '제목을 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  DELETE_PRODUCT_WITH_NO_DATA: {
    body: '상품을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  DELETE_SPECIAL_WITH_NO_DATA: {
    body: '상품을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROMOTION_WITHOUT_DATE: {
    body: '기간을 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROFILE_WITHOUT_PRODUCT_NO: {
    body: '상품을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROFILE_WITHOUT_PROMOTION_NO: {
    body: '프로모션을 선택해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  OPEN_PREVIEW_WITH_IE: {
    body: (
      <>
        모바일 미리보기는 인터넷 익스플로러에서 지원되지 않습니다.
        <br /> 크롬이나 다른 웹 브라우저로 접속해주세요
      </>
    ),
    bottom: <AlertBottom confirmText="확인" />,
  },
  SEND_11TALK_WITH_INVALID_COUPON: {
    body: '발행중인 쿠폰만 11톡 보내기를 하실 수 있습니다.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  SAVE_PROFILE_WITH_INVALID_STATUS_CODE: {
    body: <SaveProfileWithInValidStatusCode />,
    bottom: <AlertBottom confirmText="확인" />,
  },
  SET_PROFILE_DATA_WITH_INVALID_PROMOTION: {
    body:
      '프로모션에 판매중인 상품이 없습니다. 다른 프로모션으로 변경해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  NOTICE_INPUT_TITLE_EMPTY: {
    body: '제목을 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  NOTICE_INPUT_CONTENT_EMPTY: {
    body: '공지사항을 입력해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
  NOTICE_INVALID_DATE: {
    body: '전시시간을 확인해주세요.',
    bottom: <AlertBottom confirmText="확인" />,
  },
};

export default alertMapper;
