import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './actions';
import { IProductState } from './types';

const initialState: IProductState = {
  foundProductsTotal: 0,
  foundProducts: [],
  currentSelectedProducts: [],
  finalSelectedProducts: [],
  productNumbers: [],
  productTableCurrentPage: 1,
};

export default createReducer<IProductState, ActionType<typeof actions>>(
  initialState,
  {
    '@PRODUCT/FOUND/RESET': state => ({
      ...state,
      foundProductsTotal: 0,
      foundProducts: [],
    }),
    '@PRODUCT/SELECTED/CURRENT': (state, action) => ({
      ...state,
      currentSelectedProducts: action.payload,
    }),
    '@PRODUCT/SELECTED/FINAL': (state, action) => ({
      ...state,
      finalSelectedProducts: action.payload,
    }),
    '@PRODUCT/SELECTED/FINAL/RESET': state => ({
      ...state,
      finalSelectedProducts: [],
    }),
    '@PRODUCT/DISPLAY/SUCCESS': (state, { payload }) => ({
      ...state,
      foundProductsTotal: payload.total ?? 0,
      foundProducts: payload.data,
    }),
    '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS': (state, action) => ({
      ...state,
      productNumbers: action.payload,
    }),
    '@PRODUCT/CUSTOM/PRODUCT/DETAIL/NUMBERS/SUCCESS': (state, action) => ({
      ...state,
      productNumbers: action.payload,
    }),
    '@PRODUCT/TABLE/PAGE': (state, { payload }) => ({
      ...state,
      productTableCurrentPage: payload,
    }),
  },
);

export * from './actions';
export * from './types';
