import { RootState } from 'modules';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useClickNoticeItem = () => {
  const history = useHistory();

  const storeId = useSelector(
    (state: RootState) => state.user.headerInfo.storeID,
  );

  const onClickReadNotice = useCallback(
    (noticeId: string, storeNumber: number) => {
      window.open(
        `//shop.11st.co.kr/m/${storeId}/notice/${noticeId}?type=${
          storeNumber === 0 ? 'official' : 'seller'
        }`,
        '',
        'resizable=yes,scrollbars=yes,width=375,height=812',
      );
    },
    [storeId],
  );

  const onClickEditNotice = useCallback(
    (noticeId: string) => {
      history.push(`/notice/edit/${noticeId}`);
    },
    [history],
  );

  return { onClickReadNotice, onClickEditNotice };
};

export default useClickNoticeItem;
