import { RootState } from 'modules';
import React from 'react';
import { useSelector } from 'react-redux';

const headerTitle = {
  SelectProduct: '상품 조회',
  SelectProducts: '상품 조회',
  SelectProductsFromRecommendCardView: '상품조회',
  SelectProductsFromRecommendVideo: '상품조회',
  SelectProductsFromPromotionDetail: '상품조회',
  SelectPromotionFromRecommendPromotion: '프로모션 조회',
  SelectPromotionFromProfile: '프로모션 조회',
  SelectSpecial: '기획전 조회',
  SelectCategory: '카테고리 조회',
  DomainSettingIntro: '도메인 설정 방법 안내',
  Talk11UsedInfo: '11톡 서비스 사용안내',
};

const DialogHeader = () => {
  const type = useSelector((state: RootState) => state.dialog.type);

  return (
    <div className="dialog-header">
      <h3 id="dialog_label" className="dialog-header__title">
        {headerTitle[type]}
      </h3>
    </div>
  );
};

export default DialogHeader;
