import classNames from 'classnames';
import React from 'react';

import { TextInputProps } from './TextInput';

interface IProps extends TextInputProps {
  /** 최대값 */
  max: number;
}

/**
 * 바이트 체크가 있는 텍스트 인풋입니다.
 *
 * 렌더링만 있으므로 실제 validate는 onChange에 추가해주세요.
 */
const TextInputWithCheck = ({
  value,
  placeholder,
  title,
  onChange,
  width,
  max,
}: IProps) => {
  return (
    <div className={classNames('c-input', width, 'check')}>
      <div className="input-text">
        <input
          type="text"
          className="inp"
          placeholder={placeholder}
          title={title}
          value={value}
          onChange={onChange}
        />
        <div className="input-etc">
          <span className="character-check">
            <em>{value.length}</em>
            {` / ${max} 자`}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TextInputWithCheck;
