import { ISpecialItemRequest } from 'modules/special';
import queryString from 'query-string';
import { convertEmptyStringToNull } from 'utils';

import {
  IApplyCategoryParam,
  IBasicCategoryParam,
  IBasicMiddleCategoryParam,
  IBasicSmallCategoryParam,
  ICustomCategoryDetailParam,
  ICustomCategoryParam,
  IDeleteCustomCategoryParam,
  IPostCustomCategoryParam,
  IPutCustomCategoryParam,
} from './category';
import {
  IPutMenuEnableParam,
  IPutSkinTypeParam,
  IPutTemplateTypeParam,
} from './display';
import { INoticeDetailParam, INoticeParam } from './notice';
import { IProductParams, PRODUCT_SORT } from './product';
import {
  IGetPromotionItemParam,
  IGetPromotionListParam,
  IPutPromotionExpiresParam,
} from './promotion';
import {
  IGetRecommendInfoParam,
  IPostRecommendInfoParam,
  IPutRecommendInfoParam,
} from './recommend';
import { IGetPageViewCounts, IGetPageViewDownload } from './user';

const DOMAIN = process.env.REACT_APP_DOMAIN;
const PREFIX = 'app-store/admin';

interface Config {
  user: {
    headerInfo(): string;
    basicInfo({ storeNo }: { storeNo: string }): string;
    putBasicInfo({ storeNo }: { storeNo: string }): string;
    putStoreName({ storeNo }: { storeNo: string }): string;
    putStoreUrl({ storeNo }: { storeNo: string }): string;
    savePrivateDomain({ storeNo }: { storeNo: string }): string;
    roadShop(): string;
    tags(): string;
    pageViewDownload(param: IGetPageViewDownload): string;
    pageViewCounts(param: IGetPageViewCounts): string;
  };
  talk11: {
    agreements(): string;
  };
  image: {
    Upload(areaDivision: string): string;
  };
  display: {
    template(param: IPutTemplateTypeParam): string;
    skin(param: IPutSkinTypeParam): string;
    basicInfo(): string;
    menuEnable(param: IPutMenuEnableParam): string;
    menuPriority(storeNo: string): string;
  };
  profile: {
    profile(storeNo: string): string;
    putProfile(storeNo: string): string;
  };
  recommend: {
    getRecommend(param: IGetRecommendInfoParam): string;
    postRecommend(param: IPostRecommendInfoParam): string;
    putRecommend(param: IPutRecommendInfoParam): string;
  };
  exhibition: {
    myExhibitions(storeNo: string): string;
    exhibition(display: string): string;
    exhibitionNo({ exhibitionNo, display }: ISpecialItemRequest): string;
  };
  category: {
    basicLarge(param: IBasicCategoryParam): string;
    basicMiddle(param: IBasicMiddleCategoryParam): string;
    basicSmall(param: IBasicSmallCategoryParam): string;
    custom(param: ICustomCategoryParam): string;
    customDetail(param: ICustomCategoryDetailParam): string;
    postCustom(param: IPostCustomCategoryParam): string;
    putCustom(param: IPutCustomCategoryParam): string;
    deleteCustom(param: IDeleteCustomCategoryParam): string;
    applyCustom(param: IApplyCategoryParam): string;
  };
  product: {
    display(param: IProductParams): string;
    customProductDetail(param: ICustomCategoryDetailParam): string;
  };
  coupon: {
    list(): string;
    item(couponNo: string): string;
  };
  notice: {
    noticeList(param: INoticeParam): string;
    noticeDetail(param: INoticeDetailParam): string;
    postNoticeDetail(param: { storeNo: string }): string;
    putNoticeDetail(param: INoticeDetailParam): string;
  };
  promotion: {
    promotionList(param: IGetPromotionListParam): string;
    promotionItem(param: IGetPromotionItemParam): string;
    expiresPromotion(param: IPutPromotionExpiresParam): string;
    createPromotion(param: {
      startDate: string;
      endDate: string;
      title: string;
      subTitle: string;
      storeNo: string;
    }): string;
    modifyPromotion(param: {
      startDate: string;
      endDate: string;
      title: string;
      subTitle: string;
      storeNo: string;
      promotionNo: string;
    }): string;
  };
}

const config: Config = {
  user: {
    headerInfo: () => `${DOMAIN}/${PREFIX}/store/data/top`,
    basicInfo: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/baseinfo/details`,
    putBasicInfo: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/baseinfo/details`,
    putStoreName: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/baseinfo/name`,
    putStoreUrl: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/baseinfo/store-url`,
    savePrivateDomain: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/domain/personal`,
    roadShop: () => `${DOMAIN}/${PREFIX}/store/tags/roadshop/locations`,
    tags: () => `${DOMAIN}/${PREFIX}/store/tags/style`,
    pageViewDownload: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/statistics/page-view?period=30&format=xls`,
    pageViewCounts: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/statistics/page-views/30days`,
  },
  talk11: {
    agreements: () => `${DOMAIN}/talk/v4/sellers/agreements`,
  },
  image: {
    Upload: areaDivision => `${DOMAIN}/${PREFIX}/store/${areaDivision}/image`,
  },
  display: {
    template: ({ storeNo }) => `${DOMAIN}/${PREFIX}/stores/${storeNo}/template`,
    skin: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/template/color`,
    basicInfo: () => `${DOMAIN}/${PREFIX}/store/data/display`,
    menuEnable: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/switch`,
    menuPriority: (storeNo: string) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/priority`,
  },
  recommend: {
    getRecommend: ({ storeNo, moduleNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/curation?${queryString.stringify(
        { moduleNo },
      )}`,
    postRecommend: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/curation`,
    putRecommend: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/curation`,
  },
  profile: {
    profile: (storeNo: string) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/profile`,
    putProfile: storeNo =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/modules/profile`,
  },
  exhibition: {
    myExhibitions: storeNo =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/exhibitions`,
    exhibition: display =>
      `${DOMAIN}/${PREFIX}/store/exhibitions?${queryString.stringify({
        display,
      })}`,
    exhibitionNo: ({ exhibitionNo, display }) =>
      `${DOMAIN}/${PREFIX}/store/exhibitions/${exhibitionNo}?${queryString.stringify(
        {
          display,
        },
      )}`,
  },
  category: {
    basicLarge: ({ encSellerNo, targetCategoryDepth = 1, isMappedCategory }) =>
      `${DOMAIN}/${PREFIX}/store/categories?${queryString.stringify({
        encSellerNo,
        targetCategoryDepth,
        isMappedCategory,
      })}`,
    basicMiddle: ({
      encSellerNo,
      targetCategoryDepth = 2,
      largeCategoryNumber,
      isMappedCategory,
    }) =>
      `${DOMAIN}/${PREFIX}/store/categories?${queryString.stringify({
        encSellerNo,
        targetCategoryDepth,
        displayCategoryNo1: largeCategoryNumber,
        isMappedCategory,
      })}`,
    basicSmall: ({
      encSellerNo,
      targetCategoryDepth = 3,
      largeCategoryNumber,
      middleCategoryNumber,
      isMappedCategory,
    }) =>
      `${DOMAIN}/${PREFIX}/store/categories?${queryString.stringify({
        encSellerNo,
        targetCategoryDepth,
        displayCategoryNo1: largeCategoryNumber,
        displayCategoryNo2: middleCategoryNumber,
        isMappedCategory,
      })}`,
    custom: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories`,
    customDetail: ({ storeNo, storeCategoryNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories/${storeCategoryNo}?${queryString.stringify(
        { typeCode: 'CATEGORY' },
      )}`,
    postCustom: ({ storeNo }: IPostCustomCategoryParam) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories`,
    putCustom: ({ storeNo, storeCategoryNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories/${storeCategoryNo}`,
    deleteCustom: ({ storeNo, storeCategoryNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories/${storeCategoryNo}`,
    applyCustom: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories/switch`,
  },
  product: {
    display: ({
      encSellerNo,
      pageSize,
      pageNumber,
      productNumberList = [],
      largeCategoryNumber = -1,
      middleCategoryNumber = -1,
      smallCategoryNumber = -1,
      searchKeyword = '',
      productRequestType,
      sort = PRODUCT_SORT.NEW,
      isProductNoFirst = false,
    }) =>
      `${DOMAIN}/${PREFIX}/store/products?${queryString.stringify(
        {
          encSellerNo,
          productNos: convertEmptyStringToNull(productNumberList.join(',')),
          fetchSize: pageSize,
          startNum: pageNumber,
          addDispCtgr1List: convertEmptyStringToNull(
            largeCategoryNumber === -1 ? '' : [largeCategoryNumber].join(','),
          ),
          addDispCtgr2List: convertEmptyStringToNull(
            middleCategoryNumber === -1 ? '' : [middleCategoryNumber].join(','),
          ),
          addDispCtgr3List: convertEmptyStringToNull(
            smallCategoryNumber === -1 ? '' : [smallCategoryNumber].join(','),
          ),
          keyword: convertEmptyStringToNull(searchKeyword),
          productRequestType,
          sort,
          isProductNoFirst,
        },
        { skipNull: true, encode: false },
      )}`,
    customProductDetail: ({ storeNo, storeCategoryNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/store-categories/${storeCategoryNo}?${queryString.stringify(
        { typeCode: 'PRODUCT' },
      )}`,
  },
  promotion: {
    promotionList: ({ storeNo, status }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/promotions?${queryString.stringify(
        { status },
      )}`,
    promotionItem: ({ storeNo, promotionNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/promotions/${promotionNo}`,
    expiresPromotion: ({ storeNo, promotionNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/promotion/expires/${promotionNo}`,
    createPromotion: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/promotion`,
    modifyPromotion: ({ storeNo, promotionNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/promotion/${promotionNo}`,
  },
  coupon: {
    list: () => `${DOMAIN}/${PREFIX}/store/coupons?encSellerNo=1`,
    item: couponNo =>
      `${DOMAIN}/${PREFIX}/store/coupons/info?${queryString.stringify({
        couponNos: couponNo,
      })}`,
  },
  notice: {
    noticeList: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/notices`,
    noticeDetail: ({ storeNo, noticeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/notices/${noticeNo}`,
    postNoticeDetail: ({ storeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/notices`,
    putNoticeDetail: ({ storeNo, noticeNo }) =>
      `${DOMAIN}/${PREFIX}/stores/${storeNo}/notices/${noticeNo}`,
  },
};

export default config;
